import {createContext, useRef, useState} from "react";
import Service from "service/Service";
import AuthenticationService from "service/AuthenticationService";
import {
    REFRESH_URL
} from "service/UrlConstant";
import { trackPromise} from 'react-promise-tracker';

export const Context = createContext({});

export const Provider = props => {

    const {children} = props;
    const [sessionExpired, setSessionExpired] = useState(false);
    const [needLogin, setNeedLogin] = useState(true);
    const resetFlagLeft = useRef(false);
    const resetFlagRight = useRef(false);
    const sessionRefreshTimeSecs = (60 * 15);

    const _errorHandler = (error, onError) => {

        if (error && error.response && error.response.status && error.response.status === 401) {
            setSessionExpired(true);
            AuthenticationService.logout();
        }
        else {
            console.log("Cause:" + error);
            if (onError) onError(error);
        }
    };

    const verifySession = () => {

        //Check session time (time should have been added as part of login)
        let lastRequestTimeMs = AuthenticationService.getLastRequestTime();
        let timeNowMs = new Date().getTime();
        let diff = timeNowMs - lastRequestTimeMs;
        let diffInSecs = diff / 1000;

        //If more than 15 mins since last refresh, then
        // 1. refresh token
        // 2. Add current time to session
        if (diffInSecs > sessionRefreshTimeSecs) {
            refreshSession();
            AuthenticationService.updateLastRequestTime();
        }
    }

    const refreshSession = () => {
        const onSuccessResponse = (response) => {
            AuthenticationService.updateSessionToken(response);
        }
        const onErrorResponse = (err) => {
            console.error(err);
        }

        let params = null;
        trackPromise(
        Service.makeGetRequest(REFRESH_URL, params, onSuccessResponse, onErrorResponse)
            .then(res => {
                if(onSuccessResponse) onSuccessResponse(res);
            }).catch(err => {
            _errorHandler(err, onErrorResponse);
        }))
    }

    const makeGetRequest = (url, params, onSuccess, onError) => {
        verifySession();
        trackPromise(
        Service.makeGetRequest(url, params, onSuccess, onError)
            .then(res => {
                if(onSuccess) onSuccess(res);
            }).catch(err => {
            _errorHandler(err, onError);
        }))
    };

    const makeAsyncGetRequest = (url, params, onSuccess, onError) => {
        verifySession();
        Service.makeGetRequest(url, params, onSuccess, onError)
            .then(res => {
                if(onSuccess) onSuccess(res);
            }).catch(err => {
            _errorHandler(err, onError);
        })
    };

    const makeGetRequestNoParams = (url, onSuccess, onError) => {
        verifySession();
        trackPromise(
        Service.makeGetRequestNoParams(url, onSuccess, onError)
            .then(res => {
                if(onSuccess) onSuccess(res);
            }).catch(err => {
            _errorHandler(err, onError);
        }))
    };

    const makeBackgroundPostRequest = (data, url, onSuccess, onError) => {
        verifySession();

        Service.makePostRequest(data,url)
            .then(res => {
                if(onSuccess) onSuccess(res);
            }).catch(err => {
                _errorHandler(err, onError);
            })
    };

    const makePostRequest = (data, url, onSuccess, onError) => {
        verifySession();
        trackPromise(
            Service.makePostRequest(data,url)
                .then(res => {
                    if(onSuccess) onSuccess(res);
                }).catch(err => {
                _errorHandler(err, onError);
            }))
    };

    const makePostRequestWithParams = (data,params, url, onSuccess, onError) => {
        verifySession();
        trackPromise(
            Service.makePostRequestWithParam(data,params,url)
                .then(res => {
                    if(onSuccess) onSuccess(res);
                }).catch(err => {
                _errorHandler(err, onError);
            }))
    };

    const makePutRequest = (data, url, onSuccess, onError) => {
        verifySession();
        trackPromise(
        Service.makePutRequest(data,url)
            .then(res => {
                if(onSuccess) onSuccess(res);
            }).catch(err => {
            _errorHandler(err, onError);
        }))
    };

    const makePutRequestWithParams = (data, url, params, onSuccess, onError) => {
        verifySession();
        trackPromise(
        Service.makePutRequestWithParams(data,url, params)
            .then(res => {
                if(onSuccess) onSuccess(res);
            }).catch(err => {
            _errorHandler(err, onError);
        }))
    };

    const makePatchRequestWithParams = ( url, params,onSuccess, onError) => {
        verifySession();
        trackPromise(
        Service.makePatchRequestWithParams(url,params)
            .then(res => {
                if(onSuccess) onSuccess(res);
            }).catch(err => {
            _errorHandler(err, onError);
        }))
    };

    const makeDeleteRequest = ( url, onSuccess, onError) => {
        verifySession();
        trackPromise(
        Service.makeDeleteRequest(url)
            .then(res => {
                if(onSuccess) onSuccess(res);
            }).catch(err => {
            _errorHandler(err, onError);
        }))
    };


    const securityContext = {
        sessionExpired,
        setSessionExpired,
        makeGetRequest,
        makeAsyncGetRequest,
        makeGetRequestNoParams,
        makePostRequest,
        makeBackgroundPostRequest,
        makePostRequestWithParams,
        makePutRequest,
        makePutRequestWithParams,
        makePatchRequestWithParams,
        makeDeleteRequest,
        needLogin,
        setNeedLogin,
        resetFlagLeft,
        resetFlagRight

    }

    return <Context.Provider value={securityContext}>{children}</Context.Provider>

};

export const { Consumer } = Context;
