import React, {useContext, useState, createContext} from "react";
import {CloseButton, Col, Collapse, Container, Row} from "react-bootstrap";
import 'App.css'
import useWindowDimensions from 'hooks/Dimensions'
import ActionModal from "components/ActionModal";
import ResponseToast from "components/ResponseToast";

export const FilterContext = createContext();

export default function SidePanelLayout(props) {

    const SideComponent = props.sideComponent;
    const MainComponent = props.mainComponent;
    const customTitle = props.title;
    const refreshOnToggle = props.refreshOnToggle;

    const [open, setOpen] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [panelDisplayState, setPanelDisplayState] = useState(null);
    const [showMainComponent, setShowMainComponent] = useState(true);

    const {isDesktop, isTab, isMobile} = useWindowDimensions();

    const refresh = ()=> {
        if( refreshOnToggle ) {
            setShowMainComponent(false);
            setTimeout(()=> setShowMainComponent(true), 10);
        }
    }


    const sidePanelLayout =()=>(
        <Collapse in={open} dimension="width" >
            <Col md={open ? 3 : "auto"} xs={12} className="td-vertical-divider-line-1">
                <Container className='min-vh-100' fluid>
                    <Row>
                        <Col style={{paddingTop:20, paddingLeft:0, paddingRight:0, paddingBottom:0}}>
                            <CloseButton style={{position:"absolute",right:16, top:5}} aria-label={"close"} onClick={() => {
                                setOpen(false);
                                refresh();
                            }}/>
                           <SideComponent setPanelDisplayState={setPanelDisplayState} panelDisplayState={panelDisplayState} setShow={setShowFilter}/>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Collapse>);

    const modalBody=()=>{
        return <SideComponent setPanelDisplayState={setPanelDisplayState} panelDisplayState={panelDisplayState} setShow={setShowFilter}/>
    }

    const handleHide=()=>{
        setShowFilter(false);
    }

    const title=()=>{
        return customTitle;
    }

    return(<>
        <FilterContext.Provider value={{showFilter, setShowFilter}}>
            <Container fluid style={{paddingLeft:0, paddingRight:0}}>
                <Row>
                    { isDesktop && sidePanelLayout()}
                    <Col md={open ? 9 : 12} xs={12}>
                        <Container className='min-vh-100' fluid>
                            <Row>
                                <Col style={{padding:0}}>
                                    <Container>
                                        <Row>
                                            <Col style={{paddingTop:0}}>
                                                <span id="mySidenav" className="sidenav">
                                                    <a href="#" id="sidebar-filter" onClick={() => {
                                                        setOpen(true);
                                                        refresh();
                                                    }} hidden={open} style={{float:"right"}}>
                                                        <i className="bi bi-layout-sidebar"></i>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <i className="bi bi-chevron-double-right"></i>
                                                    </a>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Container>
                                        {showMainComponent && <MainComponent filterOpen={open} filterCallback={() => {
                                            setOpen(true);
                                        }} panelDisplayState={panelDisplayState} setPanelDisplayState={setPanelDisplayState}/>}
                                        <ResponseToast/>
                                </Col>
                            </Row>
                        </Container>
                    </Col>

                        { ( isTab || isMobile ) && <ActionModal body={modalBody} title={title} show={showFilter} onHide={handleHide} dialogClassName={isTab ? 'custom-dialog' : 'fullscreen-dialog'}/> }
                </Row>
            </Container>
        </FilterContext.Provider>

    </>);
}


SidePanelLayout.defaultProps = {
    title:'Search'
}
