import React, {createContext, useMemo, useState} from 'react';
import AuthenticationService from "service/AuthenticationService";
import {getSessionAttribute, setSessionAttribute} from "../../../utils/SessionUtils";
import {ACCOUNTS_OPTIONS, SELECTED_ACCOUNT, SELECTED_ENTITY} from "../../../utils/Constants";

const initialEntityAccounts = {
    managerId: '',
    managerName: '',
    accountId: '',
    accountName: '',
    accountCode: null,
    formErrors: {search: {}},
    showAccounts: true,
    disableAccounts : false,
    validateAccount:true
};

export const Context = createContext({})
export const Provider = props => {
    const {children} = props;

    const [entityAccountsOptions, setEntityAccountsOptions] = useState(getSessionAttribute(ACCOUNTS_OPTIONS) ?? []);
    const [accountsOptions, setAccountsOptions] = useState([]);
    const [entityAccounts, setEntityAccounts] = useState(JSON.parse(JSON.stringify(initialEntityAccounts)));
    const getMemoizedEntityAccounts = useMemo(() => AuthenticationService.getEntityAccounts(),[]);

    const setInitEntityAccountsOptions = () => {
        const userEntityAccounts = getMemoizedEntityAccounts
        const EntityAccountsOptions = JSON.parse(JSON.stringify(userEntityAccounts))
        setEntityAccountsOptions(EntityAccountsOptions);
        setSessionAttribute(ACCOUNTS_OPTIONS, EntityAccountsOptions);
    }

    const loadAccountOptionsOnManagerChange = (managerId) => {

        let accountsForEntity = getAccountsForSelectedEntity(managerId);
        if (accountsForEntity) {
            const availableAccountsOptions = JSON.parse(JSON.stringify(accountsForEntity))
            setAccountsOptions(availableAccountsOptions);
            return availableAccountsOptions.length;
        }
        setAccountsOptions([]);
        return 0;
    }

    const getAccountsForSelectedEntity = (selectedManagerId) => {
       // let selectedEntity = entityAccountsOptions.find(getEntityForId);
        let managerAccounts = entityAccountsOptions.find(({ managerId }) => managerId.toString() === selectedManagerId.toString());

        if (managerAccounts) {
            return managerAccounts.accounts;
        }
        return null;
    };

    const setManagerDetails = (managerId, managerName) => {
        const newEntityAccounts = JSON.parse(JSON.stringify(entityAccounts));
        newEntityAccounts.managerId = managerId;
        newEntityAccounts.managerName = managerName;
        newEntityAccounts['formErrors']['search']['entity'] = '';
        newEntityAccounts.accountId = '';
        newEntityAccounts.accountName = '';
        newEntityAccounts.accountCode = null;

       loadAccountOptionsOnManagerChange(managerId);
       setEntityAccounts(newEntityAccounts);
    };

    const setManagerDetailsWithOptionalAccount = (managerId, managerName, flag) => {
        const newEntityAccounts = JSON.parse(JSON.stringify(entityAccounts));
        newEntityAccounts.managerId = managerId;
        newEntityAccounts.managerName = managerName;
        newEntityAccounts.showAccounts = flag;
        newEntityAccounts['formErrors']['search']['entity'] = '';

        loadAccountOptionsOnManagerChange(managerId);
        setEntityAccounts(newEntityAccounts);
    };

    const setAccountDetails = (accountId, accountName, accountCode) => {
        const newEntityAccounts = {...entityAccounts};
        newEntityAccounts.accountId = accountId;
        newEntityAccounts.accountName = accountName;
        newEntityAccounts.accountCode = accountCode;
        newEntityAccounts['formErrors']['search']['account'] = '';
        setEntityAccounts(newEntityAccounts);
    };

    const clearAccountDetails = () => {
        const newEntityAccounts = {...entityAccounts};
        newEntityAccounts.accountId = '';
        newEntityAccounts.accountName = '';
        newEntityAccounts.accountCode = null;
        newEntityAccounts['formErrors']['search']['account'] = '';
        setEntityAccounts(newEntityAccounts);
    };

    //setManagerAndAccountDetails can be used to set the entity and account selections outside without using EntityAccountsComponent.
    //Note: The update to the entity and account selections will not result in a state change to the top level entityAccounts.
    //The underlying data in entityAccounts is changed. This is the intended behaviour.
    const setManagerAndAccountDetails = (managerId, managerName, accountId, accountName, accountCode, showAccounts) => {

        entityAccounts.managerId = managerId;
        entityAccounts.managerName = managerName;
        entityAccounts.accountId = accountId;
        entityAccounts.accountName = accountName;
        entityAccounts.accountCode = accountCode;
        entityAccounts['formErrors']['search']['entity'] = '';
        entityAccounts['formErrors']['search']['account'] = '';

        entityAccounts.showAccounts = showAccounts;

        if(showAccounts) {
            if(loadAccountOptionsOnManagerChange(managerId) === 0){
                entityAccounts.accountId = '';
                entityAccounts.accountName = '';
                entityAccounts.accountCode = null;
            }
        }

        setEntityAccounts(entityAccounts);
        const correspondingEntity = getMemoizedEntityAccounts.find((entity) => entity.managerId === managerId);
        if (correspondingEntity) {
            const selectedEntity = {}
            selectedEntity['managerId'] = correspondingEntity['managerId'];
            selectedEntity['managerName'] = correspondingEntity['shortName'] + '-' + correspondingEntity['longName'];
            setSessionAttribute(SELECTED_ENTITY, selectedEntity);
        }
    }

    const setShowAccounts = (flag) => {
        setEntityAccounts({...entityAccounts, ['showAccounts']:flag, ['validateAccount']:true});
    };

    const setDisableAccount = (flag) => {
        const savedEntityAccounts = {...entityAccounts};
        savedEntityAccounts.showAccounts = true;
        setEntityAccounts({...savedEntityAccounts, ['disableAccounts']:flag});
    };

    const setShowAccountValidateAccount = (showFlag,validateAccountFlag) => {
        setEntityAccounts({...entityAccounts, ['showAccounts']:showFlag ?? true, ['validateAccount']:validateAccountFlag ?? true});
    };

    const clearEntityAccounts = (showAccountFlag, validateAccountFlag) => {
        setSessionAttribute(SELECTED_ENTITY, null);
        setSessionAttribute(SELECTED_ACCOUNT, null);
        setInitEntityAccountsOptions();
        populateDefaultEntity(true)
        if (showAccountFlag !== undefined)
            setShowAccounts(showAccountFlag);
        if (validateAccountFlag !== undefined)
            setShowAccountValidateAccount(showAccountFlag, validateAccountFlag);
    };

    const validateEntityAccountSearch = (accountMandatory=true) => {
        let errors = {}
        if (!entityAccounts.managerId || entityAccounts.managerId === '') {
            errors['entity'] = 'Please select entity'
        }

        if((entityAccounts.showAccounts && entityAccounts.validateAccount && !entityAccounts.disableAccounts) && !entityAccounts.accountId && accountMandatory){
            errors['account'] = 'Please select account'
        }

        const newFormErrors = {...entityAccounts.formErrors}
        newFormErrors['search'] = errors;
        entityAccounts.formErrors = newFormErrors;
        setEntityAccounts(entityAccounts);
        return errors;
    };

    const validateEntitySearch = () => {
        let errors = {}
        if (!entityAccounts.managerId || entityAccounts.managerId === '') {
            errors['entity'] = 'Please select entity'
        }

        const newFormErrors = {...entityAccounts.formErrors}
        newFormErrors['search'] = errors;
        entityAccounts.formErrors = newFormErrors;
        setEntityAccounts(entityAccounts);
        return errors;
    };

    const paramBadgeInfo = () => {
        let badgeInfo='';

        if(entityAccounts.accountCode === null || entityAccounts.accountCode === ''){
            badgeInfo = 'ALL'
        }
        else {
            badgeInfo = !!entityAccounts.accountCode? entityAccounts.accountCode + '-'+entityAccounts.accountName:entityAccounts.accountName
        }
        return badgeInfo
    }

    const populateDefaultEntity = (flag) => {
        const selectedEntityFromSession = getSessionAttribute(SELECTED_ENTITY);
        const selectedAccountFromSession = getSessionAttribute(SELECTED_ACCOUNT);
        if(selectedEntityFromSession !== null && selectedAccountFromSession !== null){
            setManagerAndAccountDetails(selectedEntityFromSession.managerId, selectedEntityFromSession.managerName, selectedAccountFromSession.accountId, selectedAccountFromSession.name, selectedAccountFromSession.accountCode, flag);
        }
        else if(selectedEntityFromSession !== null){
            setManagerDetailsWithOptionalAccount(selectedEntityFromSession.managerId, selectedEntityFromSession.managerName, flag);
            setSessionAttribute(SELECTED_ACCOUNT, {accountId: '', name: '', accountCode: null});
        }
        else if(entityAccountsOptions && entityAccountsOptions.length > 0) {
            setManagerDetailsWithOptionalAccount(entityAccountsOptions[0].managerId, entityAccountsOptions[0].shortName+"-"+entityAccountsOptions[0].longName, flag)
            setSessionAttribute(SELECTED_ENTITY, {managerId:entityAccountsOptions[0].managerId, managerName: entityAccountsOptions[0].shortName+"-"+entityAccountsOptions[0].longName});
            setSessionAttribute(SELECTED_ACCOUNT, {accountId: '', name: '', accountCode: null});
        }
    }

    const entityAccountsContext = {
        entityAccountsOptions,
        setEntityAccountsOptions,
        accountsOptions,
        entityAccounts,
        setEntityAccounts,
        setAccountsOptions,
        getAccountsForSelectedEntity,
        setManagerDetailsWithOptionalAccount,
        setManagerDetails,
        setAccountDetails,
        setManagerAndAccountDetails,
        setShowAccounts,
        clearEntityAccounts,
        validateEntityAccountSearch,
        validateEntitySearch,
        setShowAccountValidateAccount,
        setDisableAccount,
        clearAccountDetails,
        setInitEntityAccountsOptions,
        paramBadgeInfo,
        populateDefaultEntity
    }
    return <Context.Provider value={entityAccountsContext}>
        {children}
    </Context.Provider>

}
export const {Consumer} = Context;
