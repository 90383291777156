import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import AgGridToolbar from "components/AgGridToolbar/AgGridToolbar";
import ActionsRenderer from "components/ActionsRenderer";
import PageHeader from "components/PageHeader";
import useWindowDimensions from "hooks/Dimensions";
import {ShortSaleContext} from "./context";
import {ActionPanelContext} from "context/action";
import {SecurityContext} from "context/security";
import {dateToString, formatDateString, stringToDate} from "utils/FormatUtils";
import * as URL from "service/UrlConstant";
import ShortSaleParamsBadge from "./ShortSaleParamsBadge";
import {
    ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE,
    GRID_COLUMN_PROPERTY
} from "utils/Constants";
import {numberFormatter} from "utils/NumberUtils";
import {addPropertiesToGridColumns, getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";
import {UtilsContext} from "../../context/utils";
import {createExcelExportParams, getFilteredColumns} from "../../utils/ExcelExportUtils";
import ActionPanelLayout from "../../layouts/ActionPanelLayout";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";


export default function ShortSaleSearchPanel() {

    const {shortSaleApprovalSearchDisplayState, setShortSaleApprovalSearchDisplayState,handlePageRequest, showBadge} = useContext(ShortSaleContext);
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [showExport, setShowExport] = useState(true);
    const [show, setShow] = useState(false)
    const [actionForAccount, setActionForAccount] = useState('');
    const {setHandleRefreshFunction, setHandleExportFunction} = useContext(ActionPanelContext);
    const {makePostRequest} = useContext(SecurityContext);
    const {isMobile} = useWindowDimensions();
    const {hiddenExportGridApi,paginationPageSize, setPaginationPageSize, showButton, setShowButton} = useContext(UtilsContext)
    const ssaResultsGrid = useRef(null);
    const myGrid = useRef(null);

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:!!isMobile,
        minWidth: 138
    };

    const columnTypes ={
        flexColType: {
            sortable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu:true,
            suppressMovable:true,
        }
    }

    const refreshShortSalePanelSearch = (refreshCriteria ,gridApi , onSuccess, onError) => {
        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        const onSuccessResponse = (response) => {
            const shortSaleApprovalSearchDisplayDetails = {
                resData : response.data,
                searchCriteria : refreshCriteria
            }
            setShortSaleApprovalSearchDisplayState(shortSaleApprovalSearchDisplayDetails);

            if(onSuccess) onSuccess();
        }

        makePostRequest(refreshCriteria,
            URL.SHORT_SALE_URL_SEARCH_APPROVALS , onSuccessResponse, onErrorResponse);

    };

    const exportSSAApproverList = (type, rowData, gridApi, onSuccess, onError) => {
        //Set Column definition
        hiddenExportGridApi.current.api.setColumnDefs(columnDefs);
        //Set Data
        hiddenExportGridApi.current.api.setRowData(rowData || shortSaleApprovalSearchDisplayState.resData);
        let filteredColumnNames = getFilteredColumns(ssaResultsGrid);
        //Export as csv/excel
        type==='EXCEL' ?
            hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams('SSA', filteredColumnNames)) :
            hiddenExportGridApi.current.api.exportDataAsCsv({ columnKeys: filteredColumnNames})
    }

    const statusRenderer = function (props){
        if(props.value === 'Rejected')
            return <span className="red-cell">{lookupValue(statusMappings, props.value)}</span>;
        else if(props.value === 'Pending' || props.value === 'Partial')
            return <span className="orange-cell">{lookupValue(statusMappings, props.value)}</span>;
        else if(props.value === 'All' || props.value === 'Processed')
            return <span className="green-cell">{lookupValue(statusMappings, props.value)}</span>;
        else
            return <span>{lookupValue(statusMappings, props.value)}</span>;
    }

    const statusMappings = {
        'All': 'All',
        'Pending': 'Pending',
        'Processed' : 'Complete',
        'Rejected': 'No Good',
        'Partial': 'Partial',
    };

    useEffect(() => {
        setRowData(shortSaleApprovalSearchDisplayState?.resData);
        setHandleRefreshFunction({handleRefresh: refreshShortSalePanelSearch, gridApi:null });
        setHandleExportFunction({handleExport:exportSSAApproverList, gridApi: gridApi});
    }, [shortSaleApprovalSearchDisplayState])

    const lookupValue = (mappings, key) => {
        return mappings[key];
    };

    const handleExportActionClick = (field) => {
        setActionForAccount(field.savedName);
        setShow(true);
        setShowExport(true);
    }

    const statusFilterValueGetter = (row) => {
        return statusMappings[row.getValue("status")];
    }

    const columnDefs = [
        {headerName: 'Status', field: 'status', filter: 'agTextColumnFilter', valueFormatter: lookupValue, filterValueGetter: statusFilterValueGetter, cellRenderer: 'statusRenderer' },
        {headerName: 'Requester Id', field: 'requestedBy', filter:  'agTextColumnFilter'},
        {headerName: 'Counter Party Acc', field: 'counterPartyAcct', filter:  'agTextColumnFilter'},
        {headerName: 'Basket', field: 'requestSource', filter:  'agTextColumnFilter'},
        {headerName: 'Requested Time', field: 'requestTime', valueGetter: (params)=>formatDateString(params.data.requestTime, "MM/DD/yyyy hh:mm:ss"), filter: 'agTextColumnFilter'},
        {headerName: 'Security', field: 'srcSecCd', filter: 'agTextColumnFilter'},
        {headerName: 'Security Description', field: 'longName', filter: 'agTextColumnFilter'},
        {headerName: 'SHO', field: 'regSho', filter: 'agTextColumnFilter'},
        {headerName: 'Requested Amt', field: 'requestedQty', valueFormatter: numberFormatter, filter: 'agTextColumnFilter'},
        {headerName: 'Approved Qty', field: 'approvedQty', valueFormatter: numberFormatter, filter: 'agTextColumnFilter'},
        {headerName: 'Kill', field: 'killQty', valueFormatter: numberFormatter, filter: 'agTextColumnFilter'},
         {headerName: 'Lvs', field: 'lvs', valueFormatter: numberFormatter, filter: 'agTextColumnFilter'},
        {headerName: 'Indicative Rate', field: 'lendingRate', valueFormatter: numberFormatter, filter: 'agTextColumnFilter'},
        {headerName: 'Update Time', field: 'updatedAt', valueGetter: (params)=>formatDateString(params.data.requestTime, "MM/DD/yyyy hh:mm:ss"), filter: 'agTextColumnFilter'},
        {headerName: 'House Comment', field: 'comment', filter: 'agTextColumnFilter'},
        {headerName: 'Approval Id', field: 'id', filter: 'agTextColumnFilter'},
        {headerName: 'Date Effective', field: 'processingDate', filter: 'agTextColumnFilter', valueGetter: (params)=>stringToDate(dateToString(params.data.processingDate))},
        // {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];

    const updatedColumnDefs = useMemo(() => addPropertiesToGridColumns(columnDefs, GRID_COLUMN_PROPERTY), []);

    const onGridReady = (params) => {
        !isMobile && params.api.sizeColumnsToFit()
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }
    const onDataRendered = () => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    return(<>
        <Container fluid>
            <PageHeader text="Short Sale Approval Activities">
                {(showBadge) && <ShortSaleParamsBadge onPageRequest={handlePageRequest} />}
                <h3 className="mt-4">Locate entries</h3>
            </PageHeader>
            <AgGridToolbar shortSaleApprovalScreen={true} gridApi={gridApi} gridColumnApi={gridColumnApi} onPageRequest={handlePageRequest} refreshCriteria={shortSaleApprovalSearchDisplayState?.searchCriteria} rowData={rowData}
                           paginationPageSize={paginationPageSize}  setPaginationPageSize={setPaginationPageSize}
                           showButton={showButton} setShowButton={setShowButton}
            ></AgGridToolbar>
            <div
                ref={myGrid}
                id="myGrid"
                className="ag-theme-alpine">
                <AgGridReact
                    frameworkComponents={{
                        statusRenderer: statusRenderer,
                        actionsRenderer: ActionsRenderer,
                    }}
                    ref={ssaResultsGrid}
                    paginationPageSize={paginationPageSize}
                    domLayout={'autoHeight'}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    pagination={true}
                    allowShowChangeAfterFilter={false}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={false}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onDataRendered}
                    defaultColDef={defaultColDef}
                    columnDefs={updatedColumnDefs}
                    columnTypes={columnTypes}
                    rowData={rowData}
                    animateRows={true}
                    onPaginationChanged={onPaginationChange}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
        <ActionPanelLayout actionForAccount={actionForAccount} show={show} setShow={setShow}
                           showExport={showExport} setShowExport={setShowExport} gridApi={gridApi}
        />
    </>);

}
