import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Container} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import ActionsRenderer from "components/ActionsRenderer";
import AgGridToolbar from "components/AgGridToolbar/AgGridToolbar";
import PageHeader from "components/PageHeader";
import useWindowDimensions from "hooks/Dimensions";
import {TradeCaptureContext} from "./context";
import * as URL from "service/UrlConstant";
import {
    dateToString, formatDateString,
    stringToDate,
    yearMonthDayToPickerDateFormat
} from "utils/FormatUtils";
import TradeSearchParamBadge from "./TradeSearchParamBadge";
import {
    TRADES_DELETE_LIST_URL,
    TRADES_LIST_URL,
    TRADES_SAVED_LIST_URL,
    TRADES_UPDATE_LIST_NAME_URL
} from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {ToastContext} from "context/Toast";
import {ActionPanelContext} from "context/action";
import AuthenticationService from "service/AuthenticationService";
import {UtilsContext} from "context/utils";
import * as Routes from "routes/Routes";
import {useHistory, useLocation} from "react-router-dom";
import {
    cellAlignment,
    cellStyleChecker,
    numberFormat3FractionalDigits,
    numberFormat5FractionalDigits,
    numberFormatter
} from "utils/NumberUtils";
import {createExcelExportParams, getFilteredColumns} from "../../utils/ExcelExportUtils";
import {ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE, GRID_COLUMN_PROPERTY} from "../../utils/Constants";
import {EntityAccountsContext} from "../../components/entity-accounts/context";
import {
    addPropertiesToGridColumns,
    getDisplayedNumberOfGridRows,
    getSelectedRows,
    updateGridColumDefs
} from "../../utils/gridColumnUtils";
import {TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE} from "./context/TradeCaptureContext";
import {getAllErrorValuesAsString} from "../../utils/ErrorUtils";
import {FilterContext} from "../../layouts/SidePanelLayout";
import {TRADECAPTURE_LIST} from "routes/Routes";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";

export default function TradeActivitiesPanel() {
    const {hiddenExportGridApi,paginationPageSize, setPaginationPageSize, showButton, setShowButton} = useContext(UtilsContext);
    const {tradeCaptureDisplayState, setTradeCaptureDisplayState, clearTradeSearchAndLoadTradingHome, showGridToolbar,
        headerCheckboxChecked,
        setHeaderCheckedboxChecked,
        selectedRows,
        setSelectedRows,
        selectedAnyCheckbox,
        setSelectedAnyCheckbox,
        tradePanelFilterState,
        setShowGridToolbar,
        buildTradeActivitiesSearch
    } = useContext(TradeCaptureContext);
    const {makePostRequest, makePatchRequestWithParams, makeDeleteRequest, makeGetRequest} = useContext(SecurityContext);
    const {success,error} = useContext(ToastContext);
    const { setHandleSaveFunction,setHandleDeleteFunction,setHandleRenameFunction,setHandleExportFunction,setSavedSearchName, handleTradeCancel, setHandleCancelTradeFunction} = useContext(ActionPanelContext);
    const {setManagerAndAccountDetails, clearEntityAccounts, entityAccounts, paramBadgeInfo} = useContext(EntityAccountsContext);
    const {showFilter, setShowFilter} = useContext(FilterContext);

    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);

    const tradeActivitiesGrid = useRef(null);

    const [columnDefs, setColumnDefs] = useState([]);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const {isMobile} = useWindowDimensions();
    const myGrid = useRef(null);
    let history = useHistory();
    let pathname = useLocation().pathname;

    function navigateToPage(path) {
        history.push(path);
    }

    const saveTradeSearch = (savedName, gridApi, onSuccess, onError) => {
        const onSuccessResponse = (response) => {
            if(onSuccess) onSuccess();
            tradeCaptureDisplayState.isSavedTradeList = true;
            tradeCaptureDisplayState.savedName = response.data.savedName;
            tradeCaptureDisplayState.savedNameListId = response.data.tradeListId;
            setTradeCaptureDisplayState(tradeCaptureDisplayState);
            setSavedSearchName(savedName);
            success("Trade activities search successfully saved")
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        const savedListToCreate = {
            savedName: savedName,
            cdUser : AuthenticationService.getAuthenticatedUserName(),
            managerId: tradeCaptureDisplayState.tradeActivitiesSearch.managerId,
            subManagerId: tradeCaptureDisplayState.tradeActivitiesSearch.subManagerId,
            accountId: tradeCaptureDisplayState.tradeActivitiesSearch.accountId,
            status: tradeCaptureDisplayState.tradeActivitiesSearch.status,
            startDate: dateToString(tradeCaptureDisplayState.tradeActivitiesSearch.startDate),
            endDate: dateToString(tradeCaptureDisplayState.tradeActivitiesSearch.endDate)
        }

        makePostRequest(savedListToCreate,
            TRADES_SAVED_LIST_URL , onSuccessResponse, onErrorResponse);
    };

    const updateTradeSearch = (updatedSavedName, gridApi, onSuccess, onError) => {
        const onSuccessResponse = () => {
            if(onSuccess) onSuccess();
            tradeCaptureDisplayState.savedName = updatedSavedName;
            setTradeCaptureDisplayState(tradeCaptureDisplayState);
            setSavedSearchName(updatedSavedName);
            success("Trade activities search name successfully updated")
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
            error("Error occurred with updating trade activities search name");
        }

        makePatchRequestWithParams(
            TRADES_UPDATE_LIST_NAME_URL + "/" + tradeCaptureDisplayState.savedNameListId, {savedName: updatedSavedName}, onSuccessResponse, onErrorResponse);
    };

    const deleteTradeSearch = (gridApi, onSuccess, onError) => {

        const onSuccessResponse = () => {
            if (onSuccess) onSuccess();
            clearTradeSearchAndLoadTradingHome();
            success('Trade activities search successfully deleted');
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if (onError) onError(err);
            error('Error occurred with deleting trade activities search');
        }

        makeDeleteRequest(
            TRADES_DELETE_LIST_URL + "/" + tradeCaptureDisplayState.savedNameListId, onSuccessResponse, onErrorResponse);
    };

    const loadTradeActivitiesForSavedList = (savedTradeListData) => {
        const searchCriteria = {
            savedName: savedTradeListData.savedName,
            managerId: savedTradeListData.tradeActivitiesSearch.managerId,
            managerNameShort: savedTradeListData.tradeActivitiesSearch.managerName,
            subManagerId: savedTradeListData.tradeActivitiesSearch.subManagerId,
            subManagerNameShort: savedTradeListData.tradeActivitiesSearch.subManagerName,
            accountId: savedTradeListData.tradeActivitiesSearch.accountId,
            accountNameShort: savedTradeListData.tradeActivitiesSearch.accountName,
            status: savedTradeListData.tradeActivitiesSearch.status,
            startDate: (savedTradeListData.tradeActivitiesSearch.startDate ? savedTradeListData.tradeActivitiesSearch.startDate.toString() : ""),
            endDate: (savedTradeListData.tradeActivitiesSearch.endDate ? savedTradeListData.tradeActivitiesSearch.endDate.toString() : "")
        }

        const onSuccessResponse = (response) => {

            if (!response.data || response.data.length === 0) {
                alert('No Trade activities found matching your saved trades list');
            } else {
                let tradeCaptureDisplayActivities = JSON.parse(JSON.stringify(tradeCaptureDisplayState));
                tradeCaptureDisplayActivities.tradeActivitiesSearch = buildTradeActivitiesSearch(
                    searchCriteria.managerId,
                    searchCriteria.managerNameShort,
                    searchCriteria.subManagerId,
                    searchCriteria.subManagerNameShort,
                    searchCriteria.accountId,
                    savedTradeListData.accountCode,
                    searchCriteria.accountNameShort,
                    searchCriteria.status,
                    searchCriteria.startDate,
                    searchCriteria.endDate,
                    response.data
                );

                tradeCaptureDisplayActivities.isSavedTradeList = true;
                tradeCaptureDisplayActivities.savedName = searchCriteria.savedName;
                tradeCaptureDisplayActivities.savedNameListId = savedTradeListData.savedNameListId;
                tradeCaptureDisplayActivities.currentPage = TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE;
                setTradeCaptureDisplayState(tradeCaptureDisplayActivities);
            }
        }

        const onErrorResponse = (err) => {
            console.error("Error", err);
            error("Error occurred with load trade activities for saved trades list");
        }

        makePostRequest(searchCriteria,
            URL.TRADES_SEARCH_URL, onSuccessResponse, onErrorResponse);
    };


    const loadTradesActivitiesCanceledTrade = ()=>{
        const searchCriteria = {
            managerId: entityAccounts.managerId,
            subManagerId: entityAccounts.subManagerId,
            accountId: entityAccounts.accountId,
            status: tradePanelFilterState.status,
            startDate: dateToString(tradePanelFilterState.startDate),
            endDate: dateToString(tradePanelFilterState.endDate)
        }

        setShowFilter(!showFilter)

        const onSuccessResponse = (response) => {

            let tradeCaptureDisplayStateShowActivities = {...tradeCaptureDisplayState};
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.status = searchCriteria.status;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.startDate = searchCriteria.startDate;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.endDate = searchCriteria.endDate;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.managerId = searchCriteria.managerId;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.subManagerId = searchCriteria.subManagerId;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.accountId = searchCriteria.accountId;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.managerName = entityAccounts.managerName;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.subManagerName = entityAccounts.subManagerNameShort;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.accountName = entityAccounts.accountName;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.accountCode = entityAccounts.accountCode;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.badgeInfo = paramBadgeInfo();

            if (!response.data || response.data.length === 0) {
                tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.resData = [];
                alert('No Trade activities found matching your search');
            } else {
                tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.resData = response.data;
            }
            setShowGridToolbar(response.data && response.data.length > 0)
            tradeCaptureDisplayStateShowActivities.isSavedTradeList = false;
            tradeCaptureDisplayStateShowActivities.currentPage = TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE;
            setTradeCaptureDisplayState(tradeCaptureDisplayStateShowActivities);

        }

        const onErrorResponse = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error("Error occurred with searching trade activities",errorMsg);
            error("Error occurred with searching trade activities");
        }

        makePostRequest(searchCriteria,
            URL.TRADES_SEARCH_URL, onSuccessResponse, onErrorResponse);

    }

    const cancelTradeSearch = (userSelectedRows, onSuccess, onError)=>{
        const params = userSelectedRows.map((row)=>{
            return  {
                "tradeUploadId": row.tradeBridgeTradeId,
                "tradeUploadVersion": String(row.version),
                "dbSource": row.dbSource
            }
        })

        const onSuccessResponse = () => {
            if (onSuccess) onSuccess();
            success("Trade was cancelled successfully");

            setHeaderCheckedboxChecked(false);
            setSelectedAnyCheckbox(false)
            setSelectedRows([]);

            if(tradeCaptureDisplayState.isSavedTradeList){
                loadTradeActivitiesForSavedList(tradeCaptureDisplayState)
            }{
                loadTradesActivitiesCanceledTrade();
            }
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if (onError) onError(err);
            error('Error on cancelling Trade(s)');
        }
        makePostRequest(params, URL.TRADES_CANCEL_URL, onSuccessResponse, onErrorResponse);
    }

    const exportQueryList = (type, rowData) => {
        //Set Column definition
        const tradesActsColumnsDef = columnDefsActivitiesPanel.filter(cols => cols.field !== 'checkbox' && cols.field !== 'View')
        hiddenExportGridApi.current.api.setColumnDefs(tradesActsColumnsDef);
        //Set Data
        hiddenExportGridApi.current.api.setRowData(rowData || tradeCaptureDisplayState.tradeActivitiesSearch.resData);
        let filteredColumnNames = getFilteredColumns(tradeActivitiesGrid);
        //Export as csv/excel
        type==='EXCEL' ?
            hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams('Trades', filteredColumnNames)) :
            hiddenExportGridApi.current.api.exportDataAsCsv({ columnKeys: filteredColumnNames});
    }

    const setupActionColumns = () => {
        let updatedColumnDefs = [...columnDefsActivitiesPanel];
        updatedColumnDefs =  addPropertiesToGridColumns(updatedColumnDefs, GRID_COLUMN_PROPERTY)
        setColumnDefs(updatedColumnDefs);
    }

    useEffect(() => {
        setupActionColumns();
        setRowData(tradeCaptureDisplayState.tradeActivitiesSearch.resData);
        setHandleSaveFunction({handleSave: saveTradeSearch, gridApi:null });
        setHandleRenameFunction({handleRename: updateTradeSearch, gridApi: gridApi});
        setHandleDeleteFunction({handleDelete: deleteTradeSearch, gridApi: gridApi});
        setHandleExportFunction({handleExport:exportQueryList, gridApi: gridApi});
        setHandleCancelTradeFunction({handleTradeCancel:cancelTradeSearch, gridApi: gridApi})
    }, [tradeCaptureDisplayState])

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:!!isMobile,
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }

    const onViewClicked = (props) => {
        setManagerAndAccountDetails(tradeCaptureDisplayState.tradeActivitiesSearch.managerId, tradeCaptureDisplayState.tradeActivitiesSearch.managerName,tradeCaptureDisplayState.tradeActivitiesSearch.accountId, tradeCaptureDisplayState.tradeActivitiesSearch.accountName,tradeCaptureDisplayState.tradeActivitiesSearch.accountCode, true);
        const newTradeState = JSON.parse(JSON.stringify(tradeCaptureDisplayState));
        newTradeState.tradeEntry.managerId = props.managerId
        newTradeState.tradeEntry.accountId = props.accountId
        newTradeState.tradeEntry.accountType = props.accountType.trim()
        newTradeState.tradeEntry.tradeType = props.tradeType.trim()
        newTradeState.tradeEntry.quantity = props.quantity
        newTradeState.tradeEntry.securityOption = props.securityOption
        newTradeState.tradeEntry.security = props.securityCode
        newTradeState.tradeEntry.securityOptionSelected = props.securityCode
        newTradeState.tradeEntry.price = props.price
        newTradeState.tradeEntry.status = props.status
        newTradeState.tradeEntry.currency = props.currency ? props.currency.trim() : ''
        newTradeState.tradeEntry.brokerCode = props.brokerCode ? props.brokerCode.trim() : ''
        newTradeState.tradeEntry.commissionType = props.commissionType
        newTradeState.tradeEntry.commission = props.commission
        newTradeState.tradeEntry.tradeDate = props.tradeDate ? yearMonthDayToPickerDateFormat(props.tradeDate.toString()) : ''
        newTradeState.tradeEntry.settleDate = props.settleDate ? yearMonthDayToPickerDateFormat(props.settleDate.toString()) : ''
        newTradeState.tradeEntry.accruedInterest = props.accruedInterest
        newTradeState.tradeEntry.comments = props.comments
        newTradeState.tradeEntry.clientId = props.clientId
        newTradeState.tradeEntry.openClose = props.openClose
        newTradeState.tradeEntry.indTradeCancel = props.tradeCancelIndicator
        newTradeState.tradeEntry.fxUSDRate = props.fxUSDRate
        newTradeState.tradeEntry.tradeBridgeTradeId = props.tradeBridgeTradeId
        newTradeState.tradeEntry.version = props.version
        newTradeState.tradeEntry.dbSource = props.dbSource
        newTradeState.tradeEntry.cdBlockIdClient = props.cdBlockIdClient

        setTradeCaptureDisplayState(newTradeState)

    }



    const viewCellRenderer = function (props){
        return (<>
            {
                <a className="td-link" href="#" onClick={() => {
                    onViewClicked(props.data);
                    navigateToPage(Routes.TRADECAPTURE_ENTRY)
                }}>View</a>
            }
        </>);
    }

    const statusRenderer = function (props){
        return(<>
            <span className={props.data.status==='CANCELLED' ? 'red-cell' : 'green-cell'} >{props.data.status}</span>
        </>);
    }

    function pageTitle(){
        return (<>
            Trade Activities
        </>)
    }

    const onDisplayedColumnsChanged = ()=> {
        if(gridColumnApi){
            const updatedColumnDefs = updateGridColumDefs(gridColumnApi, columnDefs)
            setColumnDefs(updatedColumnDefs)
        }
    }

    const onDataRendered = (params) => {
        setGridApi(params.api);
        params.columnApi.autoSizeAllColumns();
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE)
    }

    const handleClearButtonClick = ()=>{
        setHeaderCheckedboxChecked(false);
        setSelectedAnyCheckbox(false)
        if(gridApi){
            gridApi.deselectAll();
        }
    }

    const handleCancelTrade = ()=>{
        const selected = getSelectedRows(gridApi);
        setSelectedRows(selected);
    }


    const isRowSelectable = useCallback((params) => {
        return (!!params.data) && params.data.status === 'ACCEPT' &&
            params.data.dbSource !== null &&
            params.data.tradeBridgeTradeId !== null;
    }, []);

    const onFirstDataRendered = useCallback((params) => {
        params.api.forEachNode((node)=>{
            if(node.data && node.data.status !== 'ACCEPT'){
                node.setSelected(true);
            }
        })
    }, []);

    const onSelectionChanged = useCallback((params)=>{
        const userSelectedRows = params.api.getSelectedRows();
        setHeaderCheckedboxChecked(userSelectedRows.length > 0)
        setSelectedAnyCheckbox(userSelectedRows.length > 0)
    },[])

    useEffect(()=>{
        if(gridApi){
            gridApi.deselectAll();
        }
        setHeaderCheckedboxChecked(false);
        setSelectedAnyCheckbox(false);
    },[pathname.key])

    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    return(<>
        <Container fluid>
            <PageHeader text={pageTitle()}>
                <TradeSearchParamBadge/>
            </PageHeader>

            {showGridToolbar && <AgGridToolbar title={tradeCaptureDisplayState.savedName} tradeScreen={true} gridApi={gridApi} gridColumnApi={gridColumnApi}
                                               columnDefs={columnDefs} setColumnDefs={setColumnDefs} isSaved={tradeCaptureDisplayState.isSavedTradeList}
                                               headerCheckboxChecked={headerCheckboxChecked}  setHeaderCheckedboxChecked={setHeaderCheckedboxChecked} onClearButtonClick={handleClearButtonClick}
                                               handleCancelTrade={handleCancelTrade} selectedRows={selectedRows} selectedAnyCheckbox={selectedAnyCheckbox}
                                               paginationPageSize={paginationPageSize}  setPaginationPageSize={setPaginationPageSize}
                                               showButton={showButton} setShowButton={setShowButton}></AgGridToolbar>}
            <div
                ref={myGrid}
                id="myGrid"
                className="ag-theme-alpine">
                <AgGridReact
                    pagination={true}
                    ref={tradeActivitiesGrid}
                    onPaginationChanged={onPaginationChange}
                    paginationPageSize={paginationPageSize}
                    frameworkComponents={{
                        statusRenderer: statusRenderer,
                        actionsRenderer: ActionsRenderer,
                        viewCellRenderer:viewCellRenderer
                    }}
                    domLayout={"autoHeight"}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={true}
                    onGridReady={onGridReady}
                    onRowDataChanged={onDataRendered}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    animateRows={true}
                    isRowSelectable={isRowSelectable}
                    onFirstDataRendered={onFirstDataRendered}
                    onSelectionChanged={onSelectionChanged}
                    rowSelection={"multiple"}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}

    </>);
}

export const columnDefsActivitiesPanel = [

        {headerName: '',
            headerCheckboxSelection: true,
            field: "checkbox",
            minWidth: 120,
            checkboxSelection: true ,
            showDisabledCheckboxes: true,
            cellClassRules: {
                "checkbox-disabled": (params)=> {
                    return params.data.status !== 'ACCEPT' ||
                        params.data.dbSource === '' ||
                        params.data.tradeBridgeTradeId === null
                } ,
            }
        },
    {
        headerName: 'View',
        field:'View',
        cellRenderer: 'viewCellRenderer'
    },
    {headerName: '', field:'tradeCancelIndicator', hide:true, suppressColumnsToolPanel:true},
    {headerName: 'Status', field: 'status', cellRenderer: "statusRenderer"},
    {headerName: 'Account', field: 'accountCode'},
    {headerName: 'C', field: 'tradeType'},
    {headerName: 'Type', field: 'accountTypeForDisplay'},
    {headerName: 'Security', field: 'securityCode'},
    {headerName: 'Trade Date', field: 'tradeDate', valueGetter: (params)=>stringToDate(dateToString(params.data.tradeDate))},
    {headerName: 'Settle Date', field: 'settleDate', valueGetter: (params)=>stringToDate(dateToString(params.data.settleDate))},
    {headerName: 'B / S', field: 'tradeTypeForDisplay'},
    {headerName: 'OC', field: 'openCloseCode'},
    {headerName: 'Class', field: 'assetClass'},
    {headerName: 'Option', field: 'securityOption'},
    {headerName: 'Quantity', field: 'quantity', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
    {headerName: 'PX', field: 'price', valueFormatter: numberFormat3FractionalDigits,cellStyle: cellAlignment},
    {headerName: 'CCY', field: 'currency'},
    {headerName: 'FxUSD', field: 'fxUSDRate', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment},
    {headerName: 'Comm Type', field: 'commissionType'},
    {headerName: 'Comm', field: 'commission',  valueFormatter: numberFormat3FractionalDigits, cellStyle: cellStyleChecker},
    {headerName: 'Interest', field: 'accruedInterest', valueFormatter: numberFormat5FractionalDigits,cellStyle: cellAlignment},
    {headerName: 'Bkr', field: 'brokerCode'},
    {headerName: 'Effective', field: 'effectiveDate', valueGetter: (params)=>stringToDate(dateToString(params.data.effectiveDate))},
    {headerName: 'ClientID', field: 'clientId'},
    {headerName: 'Src', field: 'source'},
    {headerName: 'OrigUser', field: 'originalUserId'},
    {headerName: 'OrigUserStampDate', field: 'originalDate', valueGetter: (params)=>formatDateString(params.data.originalDate, "MM/DD/yyyy hh:mm:ss")},
    {headerName: 'Tran', field: 'comment'},
    {headerName: 'TranComment', field: 'bookingMessage'},
    {headerName: 'File Name', field: 'fileName'},
    {headerName: 'ID', field: 'tradeUploadId'},
    {headerName: 'cdBlockIdClient', field: 'cdBlockIdClient', hide:true, suppressColumnsToolPanel:true},
    {headerName: 'version', field: 'version', hide:true, suppressColumnsToolPanel:true},
];