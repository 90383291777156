import {createContext, useState} from "react";

export const Context = createContext({});

export const Provider = props => {

    const {children} = props;
    const [handleSaveFunction, setHandleSaveFunction] = useState(null);
    const [handleRenameFunction, setHandleRenameFunction] = useState(null);
    const [handleDeleteFunction, setHandleDeleteFunction] = useState(null);
    const [handleRefreshFunction, setHandleRefreshFunction] = useState(null);
    const [handleExportFunction, setHandleExportFunction] = useState(null);
    const [handleDownloadFunction, setHandleDownloadFunction] = useState(null);
    const [handleCancelTradeFunction, setHandleCancelTradeFunction] = useState(null);
    const [savedSearchName, setSavedSearchName] = useState('');
    const [exportRowData, setExportRowData] = useState(null)


    const handleRefresh = (refreshCriteria, onSuccess, onError) => {
        handleRefreshFunction.handleRefresh(refreshCriteria, handleRefreshFunction.gridApi, onSuccess, onError)
    }

    const handleSave = (name, onSuccess, onError) => {
        handleSaveFunction.handleSave(name, handleSaveFunction.gridApi, onSuccess, onError)
    }

    const handleRename = (listName, onSuccess, onError) => {
        handleRenameFunction.handleRename(listName,
            handleRenameFunction.gridApi, onSuccess, onError);
    }

    const handleDelete = (onSuccess, onError) => {
        handleDeleteFunction.handleDelete(handleDeleteFunction.gridApi, onSuccess, onError);
    }

    const handleExport = (type, rowData, onSuccess, onError) => {
        handleExportFunction.handleExport(type, rowData, handleExportFunction.gridApi, onSuccess, onError);
    }

    const handleDownload = () => {
        handleDownloadFunction.handleDownload(handleDownloadFunction.gridApi.getSelectedRows()[0]);
    }

    const  handleTradeCancel = (userSelectedRows, onSuccess, onError) => {
        handleCancelTradeFunction.handleTradeCancel(userSelectedRows, onSuccess, onError);
    }

    const actionPanelContext = {
        setHandleSaveFunction,
        setHandleRenameFunction,
        setHandleDeleteFunction,
        setHandleRefreshFunction,
        setSavedSearchName,
        setHandleExportFunction,
        setHandleDownloadFunction,
        setHandleCancelTradeFunction,
        handleSave,
        handleRename,
        handleDelete,
        handleRefresh,
        handleExport,
        handleDownload,
        handleTradeCancel,
        savedSearchName,
        handleExportFunction,
        exportRowData,
        setExportRowData
    }
    return <Context.Provider value={actionPanelContext}>{children}</Context.Provider>

};

export const { Consumer } = Context;
