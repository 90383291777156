import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import RightArrowButton from "./RightArrowButton";

export default function SaveQueryButton(props){
    return(<>
        <Container fluid className={"m-0 p-0"}>
            <a role="button" className="green" onClick={props.onSaveQueryButtonClick}>
                <span className='d-sm-block d-none'>Save</span>
            </a>
            <Row onClick={props.onSaveQueryButtonClick}
                 className={'d-sm-none'}>
                <Col xs={6} className={'py-2 pl-4'} style={{alignSelf: 'center'}}><p style={{float: 'left'}}>Save</p>
                </Col>
                <Col xs={6} className={'py-2 pr-2'} style={{alignSelf: 'center'}}> <RightArrowButton/></Col>
                <Col xs={12} className={'py-2 pr-0 pl-3'}>
                    <hr/>
                </Col>
            </Row>
        </Container>
    </>)
}
