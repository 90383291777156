import {assignDate} from "./FormatUtils";
import {isValidDate} from "./RegexUtils";
export const getAllErrorValuesAsString = (errorObj) => {
    let result = ""
    let obj2 = null
    if (errorObj){
        try {
            obj2 = JSON.parse(errorObj)
            result = JSON.stringify(errorObj)
        }  catch (e) {
            result = errorObj
        }
    }
    return result
}

export    const updateQueriesFilterFormErrors = (name, value, formErrors,
                                                 setFormErrors, queryPanelFilterState)=>{
    const errors = JSON.parse(JSON.stringify(formErrors));
    if(name?.trim() !== ''){
        if(name.toLowerCase().includes('date')) {

            // Get date values
            let {startDate, endDate} = assignDate(name, value, queryPanelFilterState)
            const isValid = isValidDate(startDate) && isValidDate(endDate);

            if(isValidDate(startDate) && !isValidDate(endDate)){
                errors.search['startDate'] = '';
            }

            if((!isValidDate(startDate) || !isValidDate(endDate))
                && (errors.search['startDate'] === 'Start date should be less than end date' ||
                    errors.search['endDate'] === 'Start date should be less than end date')
            ) {
                errors.search[name] =  name === 'startDate'?  'Please Enter valid date' : ''
            }

            if(isValid){
                const startDateD = new Date(startDate).getTime();
                const endDateD = new Date(endDate).getTime();

                if (startDateD <= endDateD){
                    errors.search['startDate'] = '';
                    errors.search['endDate'] = '';
                } else if(startDateD > endDateD){
                    if(formErrors.search['startDate'] ===  'Start date should be less than end date'
                    ) {
                        errors.search['startDate'] = 'Start date should be less than end date';
                        errors.search['endDate'] = 'Start date should be less than end date';
                    }
                }
            }
            setFormErrors(errors);
        } else {
            setFormErrors((prevFormErrorsState)=>({
                ...prevFormErrorsState,
                search: {
                    ...prevFormErrorsState.search,
                    [name]: ''
                }
            }))
        }
    }
}

export    const updateTradesFilterFormErrors = (name, value, formErrors,
                                                setFormErrors, tradesFilterState)=>{

    const errors = JSON.parse(JSON.stringify(formErrors));
    if(name?.trim() !== ''){
        if(name.toLowerCase().includes('date')) {

            // Get date values
            let {startDate, endDate} = assignDate(name, value, tradesFilterState)
            const isValid = isValidDate(startDate) && isValidDate(endDate);

            if(isValidDate(startDate)) {
                errors['startDate'] = '';
            }

            if(isValidDate(endDate)) {
                errors['endDate'] = '';
            }

            if((!isValidDate(startDate) || !isValidDate(endDate))
                && (errors['startDate'] === 'Start date should be less than end date' ||
                    errors['endDate'] === 'Start date should be less than end date')
            ) {
                errors[name] = 'Please Enter valid date'
            }

            if(isValid){
                const startDateD = new Date(startDate).getTime();
                const endDateD = new Date(endDate).getTime();

                if (startDateD <= endDateD){
                    errors['startDate'] = '';
                    errors['endDate'] = '';
                } else if(startDateD > endDateD){
                    if(formErrors['startDate'] ===  'Start date should be less than end date'
                    ) {
                        errors['startDate'] = 'Start date should be less than end date';
                        errors['endDate'] = 'Start date should be less than end date';
                    }
                }
            }
            setFormErrors(errors);

        } else {
            setFormErrors((prevFormErrorsState)=>({
                ...prevFormErrorsState,
                [name]:''
            }))

        }

    }
}

export    const filterFormErrors = (name, value, formErrors,
                                    setFormErrors, ssaFilterState)=>{
    const errors = JSON.parse(JSON.stringify(formErrors));
    if(name?.trim() !== ''){
        if(name.toLowerCase().includes('date')) {

            // Get date values
            let {startDate, endDate} = assignDate(name, value, ssaFilterState)
            const isValid = isValidDate(startDate) && isValidDate(endDate);

            if(isValidDate(startDate)) {
                errors.search['startDate'] = '';
            }

            if(isValidDate(endDate)) {
                errors.search['endDate'] = '';
            }

            if((!isValidDate(startDate) || !isValidDate(endDate))
                && (errors.search['startDate'] === 'Start date should be less than end date' ||
                    errors.search['endDate'] === 'Start date should be less than end date')
            ) {
                errors.search[name] = 'Please Enter valid date'
            }

            if(isValid){
                const startDateD = new Date(startDate).getTime();
                const endDateD = new Date(endDate).getTime();


                if (startDateD <= endDateD) {
                    errors.search['startDate'] = '';
                    errors.search['endDate'] = '';
                } else if (startDateD > endDateD) {
                    if(formErrors.search['startDate'] ===  'Start date should be less than end date'
                    ) {
                        errors.search['startDate'] = 'Start date should be less than end date';
                        errors.search['endDate'] = 'Start date should be less than end date';
                    }
                }

            }

            setFormErrors(errors);

        } else {
            setFormErrors((prevFormErrorsState)=>({
                ...prevFormErrorsState,
                search: {
                    ...prevFormErrorsState.search,
                    [name]: ''
                }
            }))

        }

    }
}