import {createContext, useState} from "react";

export const Context = createContext({});

export const WIDGET_TOP_QUERIES = "TopQueries";
export const WIDGET_TOP_REPORTS = "TopReports";
export const WIDGET_TOP_LONG_POS = "TopLongPos";
export const WIDGET_TOP_SHORT_POS = "TopShortPos";

export const Provider = props => {

    const {children} = props;
    const [homePagePreferences, setHomePagePreferences] = useState({

        sidePanelOpen: true,

        widgets: {
            [WIDGET_TOP_QUERIES]   : {md:6, index:0},
            [WIDGET_TOP_REPORTS]   : {md:6, index:1},
            [WIDGET_TOP_LONG_POS]  : {md:6, index:2},
            [WIDGET_TOP_SHORT_POS] : {md:6, index:3}
        },
        savedEntity: {managerId: '', accountId: '' }
    });
    const [preferencesLoaded, setPreferencesLoaded ] = useState(false);

    const userPreferencesContext = {
        setHomePagePreferences,
        homePagePreferences,
        preferencesLoaded,
        setPreferencesLoaded
    }

    return <Context.Provider value={userPreferencesContext}>{children}</Context.Provider>

};

export const { Consumer } = Context;
