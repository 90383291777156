import React, {useContext, useEffect, useRef, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import useWindowDimensions from "hooks/Dimensions";
import {
    dateToString,
    yearMonthDayToPickerDateFormat, getPrevBusDate, formatDateString
} from "utils/FormatUtils";
import * as URL from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {ToastContext} from "context/Toast";
import {HomepageContext} from "./context";
import {HOME_PAGE_TOP_REPORT_COUNT} from "../home/context/HomepageContext"
import {useHistory} from "react-router-dom";
import {REPORTS} from "routes/Routes";
import $ from "jquery";
import * as b64 from "base64-js";
import {gunzipSync} from "fflate";
import {REPORTS_PANEL_VIEW_REPORT_PAGE} from "../reports/context/ReportsPanelContext";
import {ReportsPanelContext} from "../reports/context";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {getAllErrorValuesAsString} from "../../utils/ErrorUtils";


class DatePicker {
        init(params) {
            this.eInput = document.createElement('input');
            this.eInput.type = 'date';
            this.eInput.classList.add('form-control');
            this.eInput.style.height = '100%';
            this.eInput.value = params.value ? formatDateString(String(getPrevBusDate()), 'YYYY-MM-DD') : params.value;
        }

    getGui() {
        return this.eInput;
    }

    afterGuiAttached() {
        this.eInput.focus();
        this.eInput.select();
    }

    getValue() {
        return this.eInput.value;
    }

    isPopup() {
        return false;
    }
}

export default function TopReports(props) {

    let history = useHistory();
    const {entityAccounts} = useContext(HomepageContext);
    const {
        reportsPdfDisplayState,
        setReportsPdfDisplayState,
        displayReportPanelPage,
        reportsFilterSearchState,
        setReportsFilterSearchState,
        forceReloadOfFoldersAndFiles,
        setIsHomeRequest,
    } = useContext(ReportsPanelContext);
    const [rowData, setRowData] = useState([]);
    const {isMobile} = useWindowDimensions()
    const {error} = useContext(ToastContext);
    const {makeGetRequest} = useContext(SecurityContext);
    const {setManagerAndAccountDetails} = useContext(EntityAccountsContext);

    const myGrid = useRef(null);

    const defaultColDef = {
        sortable:true,
        resizable:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:isMobile?true:false,
    };

    const columnTypes ={
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
            suppressColumnsToolPanel:true
        }
    }

    const loadTop5Reports = (accountId) => {
        const params = {
            totalRowsRequested:  HOME_PAGE_TOP_REPORT_COUNT,
            accountId: accountId
        }
        const onSuccess = (response) => {
            if (!response.data || response.data.length === 0) {
                console.log("No saved reports found")
                setRowData([]);
            } else {
                let resRowData = [...response.data]
                let prevBusDate = getPrevBusDate()
                resRowData.map(e => {
                    e.effDt = prevBusDate
                })
                setRowData([...resRowData]);
            }
        }
        const onError = (err) => {
            console.error(err);
            error('Error occurred while loading top 5 reports');
            setRowData([]);
        }
        if (accountId) {
            makeGetRequest(URL.REPORT_SAVED_REPORTS_URL, params, onSuccess, onError);
        }
    }


    const handleSearchNameRendererClicked =(data)=> {
        //const effDate = (data.effDt) ? data.effDt :getPrevBusDate();
        const effDate = (data.effDt) ? data.effDt.toString() : data.effectiveDate
        const searchParams = {
            accountId: data.accountId,
            effectiveDate: dateToString(effDate.toString()),
            folder: data.folder,
            fileName: data.fileName
        }

        const onSuccess = (response) => {

            const reportsPdfDisplay = JSON.parse(JSON.stringify(reportsPdfDisplayState));

            if (response.data) {
                const based64Decoded = b64.toByteArray(response.data);
                const decompressed = gunzipSync(based64Decoded);

                reportsPdfDisplay.reportPdfData = {data: decompressed};
                reportsPdfDisplay.reportPdfSearchData.fileName = data.fileName
                reportsPdfDisplay.reportPdfSearchData.folder = data.folder
            }else{
                reportsPdfDisplay.reportPdfData = null
            }
            reportsPdfDisplay.isReportSavedSearch = false;
            reportsPdfDisplay.reportSavedSearchName = '';
            reportsPdfDisplay.reportSavedSearchId = '';

            reportsPdfDisplay.reportPdfSearchData.managerId = data.managerId;
            reportsPdfDisplay.reportPdfSearchData.managerName = data.managerNameShort
            reportsPdfDisplay.reportPdfSearchData.subManagerId = data.subManagerId
            reportsPdfDisplay.reportPdfSearchData.accountId = data.accountId
            reportsPdfDisplay.reportPdfSearchData.accountName = data.accountNameShort
            reportsPdfDisplay.reportPdfSearchData.clientCode = data.clientCode
            reportsPdfDisplay.reportPdfSearchData.effectiveDate = effDate


            let reportsFilterSearchStateSavedReport = JSON.parse(JSON.stringify(reportsFilterSearchState));
            reportsFilterSearchStateSavedReport.reportsSearch.managerId = data.managerId;
            reportsFilterSearchStateSavedReport.reportsSearch.managerName = data.managerNameShort;
            reportsFilterSearchStateSavedReport.reportsSearch.accountId = data.accountId;
            reportsFilterSearchStateSavedReport.reportsSearch.clientCode = data.clientCode;
            reportsFilterSearchStateSavedReport.reportsSearch.accountName = data.accountNameShort;
            reportsFilterSearchStateSavedReport.reportsSearch.effectiveDate = formatDateString(effDate, 'YYYY-MM-DD');
            reportsFilterSearchStateSavedReport.formErrors.reportsSearch = {};
            setManagerAndAccountDetails(data.managerId, data.managerShortName,
                data.accountId, data.accountNameShort, data.clientCode, true);

            setReportsFilterSearchState(reportsFilterSearchStateSavedReport);
            //Force reload and selection for folder and fileName
            forceReloadOfFoldersAndFiles(data.folder, data.fileName,
                data.accountId, effDate);

            setIsHomeRequest(true)

            setReportsPdfDisplayState(reportsPdfDisplay);

            displayReportPanelPage(REPORTS_PANEL_VIEW_REPORT_PAGE);
            history.push(REPORTS);

        }

        const onError = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error('Error when retrieving file list - ', errorMsg);
            error("There was an error with loading selected report");
        }

        makeGetRequest(URL.REPORT_FOLDERS_FILES_FILE_URL, searchParams, onSuccess, onError);
    }

    function managerGetter(params) {
        return params.data.managerNameShort + "-" + params.data.managerNameLong;
    }

    function accountGetter(params) {
        return params.data.accountCode + "-" + params.data.accountNameLong;
    }


    useEffect(() => {
        if(entityAccounts)
            if(entityAccounts.accountId)
                loadTop5Reports(entityAccounts.accountId);
            else
                setRowData([]);
    }, [entityAccounts])

    const columnDefs = [
        {headerName: 'Name', field: 'savedName', cellRenderer: "reportNameRenderer"},
        {headerName: 'Entity', valueGetter: managerGetter},
        {headerName: 'Account',valueGetter: accountGetter},
        {headerName: 'Close of Business Date', field:'effDt',width:135,
            editable:true,cellEditor: DatePicker,cellEditorPopup: true,
            valueFormatter:params => formatDateString(String(params.value), 'MM/DD/YYYY'),},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];


    const searchNameRenderer = function (props){
        return(<>
            <a className="td-link" href="#" onClick={()=>{handleSearchNameRendererClicked(props.data)}}>{props.value}</a>
        </>);
    }

    return(<>
        <div
            ref={myGrid}
            id="myGrid"
            className="ag-theme-alpine"
            style={{height:220}}
        >
            <AgGridReact
                frameworkComponents={{
                    reportNameRenderer: searchNameRenderer,
                }}
                groupDefaultExpanded={'1'}
                enableRowGroup={false}
                suppressDragLeaveHidesColumns={true}
                suppressMakeColumnVisibleAfterUnGroup={true}
                suppressModelUpdateAfterUpdateTransaction={true}
                suppressScrollOnNewData={true}
                suppressAggFuncInHeader={true}
                allowShowChangeAfterFilter={false}
                rowGroupPanelShow={'never'}
                enableSorting={true}
                defaultColDef={defaultColDef}
                columnTypes={columnTypes}
                columnDefs={columnDefs}
                rowData={rowData}
                animateRows={true}
            >
            </AgGridReact>
        </div>
    </>);
}