import React, {useContext} from 'react';
import {Badge, Container} from "react-bootstrap";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import {TradeCaptureContext} from "./context";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {stringToDate} from "utils/FormatUtils";

export default function TradeSearchParamBadge() {

    const {
        tradeCaptureDisplayState,
        clearTradeSearchAndLoadTradingHome
    } = useContext(TradeCaptureContext);

    const {
        clearEntityAccounts
    } = useContext(EntityAccountsContext);

    const settings = {
        infinite: false,
        speed: 500,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: Number.MAX_VALUE,
                settings: "unslick"
            },
            {
                breakpoint: 767,
                settings: "slick"
            }
        ]
    };

    const handleClear = (e) => {
        clearEntityAccounts();
        clearTradeSearchAndLoadTradingHome();
    }

    return (<>
        <Container fluid style={{width: "100%"}} className="justify-content-start pt-0 px-md-0">
            <Slider {...settings}>
                <span>
                    <Badge
                        className={'badge-outline'}>{tradeCaptureDisplayState.tradeActivitiesSearch.managerName}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{tradeCaptureDisplayState.tradeActivitiesSearch.badgeInfo}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{tradeCaptureDisplayState.tradeActivitiesSearch.status}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{tradeCaptureDisplayState.tradeActivitiesSearch.startDate ? stringToDate(tradeCaptureDisplayState.tradeActivitiesSearch.startDate) : ""}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{tradeCaptureDisplayState.tradeActivitiesSearch.endDate ? stringToDate(tradeCaptureDisplayState.tradeActivitiesSearch.endDate) : ""}</Badge>
                </span>
                <span>
                    <Badge className={'badge-button'} onClick={handleClear}>
                        CLEAR
                    </Badge>
                </span>
            </Slider>
        </Container>

    </>)
}
