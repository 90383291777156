import React from 'react';
import * as Routes from "routes/Routes"
import {Nav, NavDropdown, Row} from "react-bootstrap";
import TDNavDropDown from "components/navbar/TDNavDropdown";
import UserRoleService, {
    HOME_MENU_PERMISSION_KEY,
    QUERIES_MENU_PERMISSION_KEY,
    REPORTS_MENU_PERMISSION_KEY,
    SSA_MENU_PERMISSION_KEY,
    TRADE_MENU_PERMISSION_KEY,
    TRADE_MENU_UPLOAD_PERMISSION_KEY,
    WIRES_MENU_VIEW_ONLY_PERMISSION_KEY,
    WIRES_MENU_SUBMITTER_PERMISSION_KEY,
    WIRES_MENU_CLIENT_APPROVER_PERMISSION_KEY
} from "service/UserRoleService";
import {TRADECAPTURE_BREAKS} from "routes/Routes";

const hrForTab = <Row className={'d-block d-md-none'}><hr/></Row>;
export default function TDDropDrownNavBar(props) {
    const navigateToPage = props.navigateToPage;

    const checkPermission = (menuKey) => {
        return UserRoleService.hasPermission(menuKey);
    }

    return (
        <>
            {checkPermission(HOME_MENU_PERMISSION_KEY) && (<Nav.Link className="tds-nav-item" onClick={()=>{ navigateToPage(Routes.HOME);}}><b>Home</b></Nav.Link>)}
            {hrForTab}

            {(checkPermission(REPORTS_MENU_PERMISSION_KEY) || checkPermission(QUERIES_MENU_PERMISSION_KEY)) &&
            (<TDNavDropDown title="Reporting" id="collapsible-nav-dropdown">
                {checkPermission(REPORTS_MENU_PERMISSION_KEY) && (<NavDropdown.Item className="tds-nav-item" onClick={()=>{navigateToPage(Routes.REPORTS)}}>Reports</NavDropdown.Item>)}
                {checkPermission(QUERIES_MENU_PERMISSION_KEY) && (<NavDropdown.Item className="tds-nav-item" onClick={()=>{navigateToPage(Routes.QUERIES)}}>Queries</NavDropdown.Item>)}
            </TDNavDropDown>)}


            {hrForTab}
            {(checkPermission(TRADE_MENU_PERMISSION_KEY) || checkPermission(TRADE_MENU_UPLOAD_PERMISSION_KEY)) && (
            <TDNavDropDown title="Trading" id="collapsible-nav-dropdown">
                {checkPermission(TRADE_MENU_PERMISSION_KEY) && (<NavDropdown.Item className="tds-nav-item" onClick={()=>{navigateToPage(Routes.TRADECAPTURE_LIST)}}>Trade List</NavDropdown.Item>)}
                {checkPermission(TRADE_MENU_PERMISSION_KEY) && (<NavDropdown.Item className="tds-nav-item" onClick={()=>{navigateToPage(Routes.TRADECAPTURE_ENTRY)}}>Trade Entry</NavDropdown.Item>)}
                {checkPermission(TRADE_MENU_UPLOAD_PERMISSION_KEY) && (<NavDropdown.Item className="tds-nav-item" onClick={()=>{navigateToPage(Routes.TRADECAPTURE_UPLOAD)}}>Trade Upload</NavDropdown.Item>)}
            </TDNavDropDown>)}


            {hrForTab}
            {(checkPermission(WIRES_MENU_SUBMITTER_PERMISSION_KEY) || checkPermission(WIRES_MENU_CLIENT_APPROVER_PERMISSION_KEY) || checkPermission(WIRES_MENU_VIEW_ONLY_PERMISSION_KEY)) && (
            <TDNavDropDown title="Asset Transfers" id="collapsible-nav-dropdown">
                <NavDropdown.Item className="tds-nav-item" onClick={()=>{navigateToPage(Routes.WIRES)}}>Wires</NavDropdown.Item>
            </TDNavDropDown>)}


            {hrForTab}
            {checkPermission(SSA_MENU_PERMISSION_KEY) && (
                <TDNavDropDown title="Short Sale Approvals" id="collapsible-nav-dropdown">
                    <NavDropdown.Item className="tds-nav-item" onClick={()=>{navigateToPage(Routes.SHORTSALE_APPROVAL_LOCATE)}}>Locate</NavDropdown.Item>
                    <NavDropdown.Item className="tds-nav-item" onClick={()=>{navigateToPage(Routes.SHORTSALE_APPROVAL_HISTORY)}}>Persistent Locate</NavDropdown.Item>
                    <NavDropdown.Item className="tds-nav-item" onClick={()=>{navigateToPage(Routes.SHORTSALE_APPROVAL_SEARCH)}}>Search History</NavDropdown.Item>
                </TDNavDropDown>)}


            {hrForTab}
            <TDNavDropDown title="Disclaimers" id="collapsible-nav-dropdown">
                <NavDropdown.Item className="tds-nav-item" onClick={()=>{navigateToPage(Routes.MMF)}}>MMF</NavDropdown.Item>
            </TDNavDropDown>
            {hrForTab}

        </>)
}