import React from "react";
import spinner from "../../assets/image/spinner.gif";

export default function ErrorSpinner(){
    return(
        <div className="spinner">
            <div className="spinner-div">
                <img
                    src={spinner}
                    height="50"
                    className="d-inline-block"
                    alt="React Bootstrap logo"
                />
            </div>
        </div>
    )
}