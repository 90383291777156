import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import useWindowDimensions from "hooks/Dimensions";
import ResponseToast from "components/ResponseToast";

export default function MFF() {

    const [rowData1, setRowData1] = useState([]);
    const [rowData2, setRowData2] = useState([]);
    const [rowData3, setRowData3] = useState([]);
    const [rowData4, setRowData4] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const {isMobile} = useWindowDimensions();

    const defaultColDef = {
        resizable:true,
        lockPinned:isMobile?true:false,
    };

    const columnTypes={
        firstColType:{
            width: 350,
            suppressSizeToFit: true,
        },
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
        }
    }

    useEffect(() => {
        setRowData1([
            {"dreyfuscaymanfunds":"Dreyfus Government Cash Management", "shareclass":"Inst'l", "cusip":"262006208", "symbol":"DGCXX"
                ,  "link":"https://im.bnymellon.com/us/en/cash-management/funds/ebook/262006208/551179?sci=262006208&file=/us/en/documents/compliancedocs/prospectus/annual/cmgt_ins.pdf#zoom=page-width"},
            {"dreyfuscaymanfunds":"Dreyfus Government Securities Cash Management", "shareclass":"Inst'l", "cusip":"262006885", "symbol":"DIPXX"
                ,"link":"https://im.bnymellon.com/us/en/cash-management/funds/ebook/262006208/551179?sci=262006208&file=/us/en/documents/compliancedocs/prospectus/annual/cmgt_ins.pdf#zoom=page-width"},
            {"dreyfuscaymanfunds":"Dreyfus Treasury & Agency Cash Management", "shareclass":"Inst'l", "cusip":"261908107", "symbol":"DTRXX"
                ,"link":"https://im.bnymellon.com/us/en/cash-management/funds/ebook/262006208/551179?sci=262006208&file=/us/en/documents/compliancedocs/prospectus/annual/cmgt_ins.pdf#zoom=page-width"},
            {"dreyfuscaymanfunds":"Dreyfus Treasury Securities Cash Management", "shareclass":"Inst'l", "cusip":"261908107", "symbol":"DIRXX"
                ,"link":"https://im.bnymellon.com/us/en/cash-management/funds/ebook/262006208/551179?sci=262006208&file=/us/en/documents/compliancedocs/prospectus/annual/cmgt_ins.pdf#zoom=page-width"},
        ])

        setRowData2([
            {"federatedfunds":"Treasury Obligation Fund", "shareclass":"Ticker", "cusip":"60934N500", "symbol":"TOIXX"
                ,"link":"http://www.Federatedinvestors.com/teamsite-file-server/public/daf/pdf/regulatory/summary_prospectus/Q450628.pdf"},
            {"federatedfunds":"Government Obligations Fund", "shareclass":"Ticker", "cusip":"608919718", "symbol":"GOFXX"
                ,"link":"http://www.Federatedinvestors.com/teamsite-file-server/public/daf/pdf/regulatory/summary_prospectus/Q452349.pdf"},
        ])

        setRowData3([
            {"HSBCcaymanfunds":"HSBC Global Asset Management (USA) Inc", "shareclass":"Intermediary", "cusip":"44330V464", "symbol":"HGGXX"
                ,"link":"https://investorfunds.us.hsbc.com/resources/documents/funds/prospectus/MoneyMarket/Prospectus-MoneyMarket.pdf"},
            {"HSBCcaymanfunds":"HSBC Global Asset Management (USA) Inc", "shareclass":"Intermediary", "cusip":"44330V456", "symbol":"HTGXX"
                ,"link":"https://investorfunds.us.hsbc.com/resources/documents/funds/prospectus/MoneyMarket/Prospectus-MoneyMarket.pdf"},
        ])

        setRowData4([
            {"SSGAcaymanfunds":"Inistitutional US Government Money Market Fund", "shareclass":"Inst'l", "cusip":"857492573", "symbol":"SAHXX"
                ,"link":"https://www.ssga.com/cash/funds/SAHXX/fund_documents_SAHXX.html"},
            {"SSGAcaymanfunds":"Inistitutional Treasury Plus Money Fund", "shareclass":"Inst'l", "cusip":"857492557", "symbol":"SAJXX"
                ,"link":"https://www.ssga.com/cash/funds/SAJXX/fund_overview_SAJXX.html"},
            {"SSGAcaymanfunds":"Inistitutional Treasury Money Market Fund", "shareclass":"Inst'l", "cusip":"857492565", "symbol":"SSJXX"
                ,"link":"https://www.ssga.com/cash/funds/SSJXX/fund_documents_SSJXX.html"},
        ])
    }, [])

    const colDef1 =[
        {headerName: 'Dreyfus US & Cayman Funds', field: 'dreyfuscaymanfunds', cellRenderer: "rowRenderer", type:'firstColType'},
        {headerName: 'Share Class', field: 'shareclass'},
        {headerName: 'CUSIP', field: 'cusip'},
        {headerName: 'BBG SYMBOL', field: 'symbol'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
     ];

    const colDef2 =[
        {headerName: 'Federated Funds', field: 'federatedfunds', cellRenderer: "rowRenderer", type:'firstColType'},
        {headerName: 'Share Class', field: 'shareclass'},
        {headerName: 'CUSIP', field: 'cusip'},
        {headerName: 'BBG SYMBOL', field: 'symbol'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];

    const colDef3 =[
        {headerName: 'HSBC US & Cayman Funds', field: 'HSBCcaymanfunds', cellRenderer: "rowRenderer", type:'firstColType'},
        {headerName: 'Share Class', field: 'shareclass'},
        {headerName: 'CUSIP', field: 'cusip'},
        {headerName: 'BBG SYMBOL', field: 'symbol'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];

    const colDef4 =[
        {headerName: 'SSGA US & Cayman Funds', field: 'SSGAcaymanfunds', cellRenderer: "rowRenderer", type:'firstColType'},
        {headerName: 'Share Class', field: 'shareclass'},
        {headerName: 'CUSIP', field: 'cusip'},
        {headerName: 'BBG SYMBOL', field: 'symbol'},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];
    
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }

    const rowRenderer = function(props){

        return(<>
            <a className="td-link" href={props.data.link} target="_blank" >{props.value}</a>
        </>);
    }

    const gridTable = (param) => {
        const {columnDefs, rowData} = param
        return (<>
            <div className="ag-theme-alpine">
                <AgGridReact
                    frameworkComponents={{
                        rowRenderer: rowRenderer
                    }}
                    domLayout={'autoHeight'}         
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressScrollOnNewData={true}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    columnTypes={columnTypes}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                >
                </AgGridReact>
            </div>
        </>)
    }

	return (<>
        <Container fluid>
            <Row className="py-4 py-md-5">
                <Col>
                    <h1>Money Market Funds</h1>
                </Col>
            </Row>
            <Row>
                <Col className="pb-3 pt-0">
                    {gridTable({columnDefs:colDef1, rowData: rowData1})}
                </Col>
            </Row>
            <Row>
                <Col className="py-3">
                    {gridTable({columnDefs:colDef2, rowData: rowData2})}
                </Col>
            </Row>
           <Row>
                <Col className="py-3">
                    {gridTable({columnDefs:colDef3, rowData: rowData3})}
                </Col>
            </Row>
            <Row>
                <Col className="py-3">
                    {gridTable({columnDefs:colDef4, rowData: rowData4})}
                </Col>
            </Row>
        </Container>
        <ResponseToast isFullWidth={true}/>
    </>);
}
