import React from 'react';
import PropTypes from 'prop-types';

export default function ActionsRenderer(props) {
    const {clicked, action1, onAction1Clicked, action2, onAction2Clicked, action3, onAction3Clicked, showAction1} = props;
    return (
        <>
            <div className={'d-md-block d-sm-none d-none'}>
                {showAction1 && <a className="td-link mr-4" href="#" onClick={()=>onAction1Clicked(props.value)}>{action1}</a>}
                <a className="td-link mr-4" href="#" onClick={()=>onAction2Clicked(props.value)}>{action2}</a>
                <a className="td-link" href="#" onClick={()=>onAction3Clicked(props.value)}>{action3}</a>
            </div>
            <div className={'d-md-none d-sm-block d-block'} style={{textAlign: 'center'}}>
                <a className="td-link" href="#responsive">
                    <i className='td-icon-colour-primary td-icon icon-regular  td-icon-all'
                       onClick={() => clicked(props.value)}></i>
                </a>
            </div>
        </>
    );
}

ActionsRenderer.propTypes = {
    onActionIconClick: PropTypes.func
}

ActionsRenderer.defaultProps = {
    action1: "Export", action2: "Rename", action3: 'Delete', showAction1:true
}