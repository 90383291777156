import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import ShortSaleSearchPanel from "./ShortSaleSearchPanel";
import ShortSaleFileList from "./ShortSaleFileList";
import {ActionPanelContextProvider} from "../../context/action";
import {SecurityContext} from "../../context/security";
import {ShortSaleContext} from "./context";


export default function ShortSalePanel() {

    const {panelDisplayState,setPanelDisplayState} = useContext(ShortSaleContext);
    const {resetFlagRight} = useContext(SecurityContext);

    useEffect(()=>{
        if(resetFlagRight.current){
            resetFlagRight.current = false;
            const newFilterState = {...panelDisplayState}
            newFilterState['currentPage'] = 'defaultlanding';
            setPanelDisplayState(newFilterState);
        }
    },[]);


    const currentComponent = function () {
        return <ActionPanelContextProvider><ShortSaleSearchPanel/></ActionPanelContextProvider>
    }

    return(<>

        <Container fluid>
            <Row>
                <Col style={{padding:0}}>
                    {currentComponent()}
                </Col>
            </Row>
            <Row>
                <br/>
            </Row>
        </Container>
    </>);

}
