import React, {useEffect, useRef, useState, useContext, useMemo} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import ActionsRenderer from "components/ActionsRenderer";
import useWindowDimensions from 'hooks/Dimensions'
import ActionPanelLayout from "layouts/ActionPanelLayout";
import {
    SHORT_SALE_URL_DELETE_PERSIST_FILE, SHORT_SALE_URL_GET_PERSIST_FILE_DETAILS,
    SHORT_SALE_URL_GET_PERSIST_FILES,
    SHORT_SALE_URL_RENAME_PERSIST_FILE
} from "service/UrlConstant";
import {ActionPanelContext} from "context/action";
import {SecurityContext} from "context/security";
import {ShortSaleContext} from "./context";
import {ToastContext} from "context/Toast";
import {SHORTSALE_APPROVAL_PERSIST_DETAILS} from "routes/Routes";
import {useHistory} from "react-router-dom";
import ResponseToast from "components/ResponseToast";
import {
    DEFAULT_GRID_PAGINATION_SIZE,
    ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE,
    GRID_COLUMN_PROPERTY
} from "utils/Constants";
import {addPropertiesToGridColumns, getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";
import {UtilsContext} from "../../context/utils";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";
import {columnDefs as ssaColumnDefs} from "./ShortSalePersistDetails";
import {createExcelExportParams} from "../../utils/ExcelExportUtils";

export default function ShortSaleHistory() {
    let history = useHistory();
    const {setShortSalePersistState} = useContext(ShortSaleContext);
    const [rowData, setRowData] = useState([]);
    const {success,alert,error} = useContext(ToastContext);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [show, setShow] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [actionForAccount, setActionForAccount] = useState('');
    const myGrid = useRef(null);
    const {isDesktop, isMobile } = useWindowDimensions();

    const {makePostRequest, makePostRequestWithParams} = useContext(SecurityContext);
    const {setHandleExportFunction, setHandleRenameFunction, setHandleDeleteFunction,setSavedSearchName } = useContext(ActionPanelContext);
    const {hiddenExportGridApi, paginationPageSize, showButton, setShowButton} = useContext(UtilsContext);
    const columnTypes ={
        firstColType:{
            minWidth: isMobile?200:350,
            suppressSizeToFit: true,
        },
        actionColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            pinned:isDesktop?'':'right',
            width:isDesktop?200:100,
        },
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
        }
    }

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:isMobile?true:false,
    };

    const loadPersistLists = () => {
        const onSuccess = (response) => {
            setRowData([...response.data]);
        }
        const onError = (err) => {
            console.error(err);
        }

        makePostRequest({}, SHORT_SALE_URL_GET_PERSIST_FILES, onSuccess, onError);
    };

    const exportSSHistory = (type, rowData, gridApi, onSuccess, onError) => {
        const selectedRow = gridApi?.getSelectedRows()[0];

        const searchParam = {
            manager:selectedRow.manager,
            counterPartyAcct:selectedRow.counterPartyAcct,
            requestFileName:selectedRow.requestFileName
        }

        const onSuccessResponse = (response) => {
            hiddenExportGridApi.current.api.setColumnDefs(ssaColumnDefs);
            //Set Data
            hiddenExportGridApi.current.api.setRowData(response.data)
            //Export as csv/excel
            type==='EXCEL' ?
                hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams('SSA History', hiddenExportGridApi)) :
                hiddenExportGridApi.current.api.exportDataAsCsv(hiddenExportGridApi)
            if(onSuccess) onSuccess();
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        makePostRequestWithParams({}, searchParam, SHORT_SALE_URL_GET_PERSIST_FILE_DETAILS, onSuccessResponse, onErrorResponse)
    }

    const updatePersistFileName = (listName, gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];
        const onSuccessResponse = (response) => {
            selectedRow.requestFileName = listName;
            gridApi.applyTransaction({update: [selectedRow]});
            if(onSuccess) onSuccess();
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        makePostRequestWithParams({}, {
                manager:selectedRow.manager,
                counterPartyAcct:selectedRow.counterPartyAcct,
                requestFileName:selectedRow.requestFileName,
                renamedFileName:listName
            },
            SHORT_SALE_URL_RENAME_PERSIST_FILE, onSuccessResponse, onErrorResponse);

    };

    const deletePersistFile = (gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];
        const onSuccessResponse = (response) => {
            gridApi.applyTransaction({remove: [selectedRow]});
            if(onSuccess) onSuccess();
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        makePostRequestWithParams({},  {
                manager:selectedRow.manager,
                counterPartyAcct:selectedRow.counterPartyAcct,
                requestFileName:selectedRow.requestFileName,
            },
            SHORT_SALE_URL_DELETE_PERSIST_FILE, onSuccessResponse, onErrorResponse);


    };

    useEffect(() => {
        loadPersistLists();
    }, []);

    const handleActionIconClick = (field)=>{
        setActionForAccount(field);
        setSavedSearchName(field);
        setShow(true);
    }

    const handleAction1IconClicked =(field)=>{
        setActionForAccount(field);
        setSavedSearchName(field);
        setShow(true);
        setShowExport(true);
    }

    const handleAction2IconClicked =(field)=>{
        setActionForAccount(field);
        setSavedSearchName(field);
        setShow(true);
        setShowRename(true);
    }

    const handleAction3IconClicked =(field)=>{
        setActionForAccount(field);
        setShow(true);
        setShowDelete(true);
    }

    const handleSearchNameRendererClicked =(data)=>{

        const onSuccessResponse = (response) => {
            const shortSalePersistDisplayDetails = {
                resData : response.data
            }
            shortSalePersistDisplayDetails.savedName = data.requestFileName;
            setShortSalePersistState({...shortSalePersistDisplayDetails});
            history.push(SHORTSALE_APPROVAL_PERSIST_DETAILS);
        }

        const onErrorResponse = (err) => {
            console.log("Error occured in processing",err);
            error('Error Occurred '+err.response.data);
        }
        makePostRequestWithParams({},{"manager" : data.manager,
                "requestFileName" : data.requestFileName
            },
            SHORT_SALE_URL_GET_PERSIST_FILE_DETAILS , onSuccessResponse, onErrorResponse);



    }

    const columnDefs = [
        {headerName: 'SSA File', field: 'requestFileName', cellRenderer: "searchNameRenderer", type:'firstColType'},
        {headerName: 'Entity', field: 'manager'},
        {headerName: 'Account', field: 'counterPartyAcct'},
        {headerName: 'Actions', field: 'requestFileName', cellRenderer: "actionsRenderer", type:'actionColType',
            cellRendererParams:{
                clicked:(field)=>handleActionIconClick(field),
                onAction1Clicked:(field)=>handleAction1IconClicked(field),
                onAction2Clicked:(field)=>handleAction2IconClicked(field),
                onAction3Clicked:(field)=>handleAction3IconClicked(field)
            }
        },
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},];

    const updatedColumnDefs = useMemo(() => addPropertiesToGridColumns(columnDefs, GRID_COLUMN_PROPERTY), []);

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setHandleExportFunction({handleExport: exportSSHistory, gridApi: params.api});
        setHandleRenameFunction({handleRename: updatePersistFileName, gridApi: params.api});
        setHandleDeleteFunction({handleDelete: deletePersistFile, gridApi: params.api});
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const cellClicked=( params )=> {
        params.node.setSelected(true);
        params.api.ensureIndexVisible(params.node.rowIndex,'top');
    }

    const searchNameRenderer = function (props){
        return(<>
            <a className="td-link" href="#" onClick={(event)=>{
                handleSearchNameRendererClicked(props.data)
            }}>{props.value}</a>
        </>);
    }
    const onDataRendered = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    return(<>

        <Container fluid>
            <Row className="pt-3 pb-2 pt-md-4 pb-md-3">
                <Col>
                    <h1>SSA History</h1>
                </Col>
            </Row>
            <Row className="pb-1 pb-md-2">
                <Col>
                    <h3>Saved Maintainable SSA Request List</h3>
                </Col>
            </Row>
            <div
                ref={myGrid}
                id="myGrid"
                className="ag-theme-alpine">
                <AgGridReact

                    frameworkComponents={{
                        searchNameRenderer: searchNameRenderer,
                        actionsRenderer: ActionsRenderer,
                    }}
                    paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                    domLayout={'autoHeight'}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    suppressSizeToFit={true}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={false}
                    pagination={true}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onDataRendered}
                    defaultColDef={defaultColDef}
                    columnDefs={updatedColumnDefs}
                    columnTypes={columnTypes}
                    rowData={rowData}
                    animateRows={true}
                    onCellClicked={cellClicked}
                    onPaginationChanged={onPaginationChange}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
        <ResponseToast isFullWidth={true}/>
        <ActionPanelLayout actionForAccount={actionForAccount}  show={show} setShow={setShow}  showExport={showExport} setShowExport={setShowExport}
                           showRename={showRename} setShowRename={setShowRename} showDelete={showDelete} setShowDelete={setShowDelete} gridApi={gridApi} showSSAActionModal={true} allowSpecialCharacters={true}/>
    </>);

}