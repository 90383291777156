import React, {useContext} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {WiresPanelContext} from "./context";
import WiresResultPanel from "./WiresResultPanel";
import {ActionPanelContextProvider} from "context/action";
import SavedWires from "./SavedWires";
import {WIRE_PANEL_VIEW_WIRE_PAGE} from "./context/WiresPanelContext";

export default function WiresPanel() {

    const {wirePanelPageState} = useContext(WiresPanelContext);

    function mainComponent() {
        if(wirePanelPageState && wirePanelPageState.currentPage === WIRE_PANEL_VIEW_WIRE_PAGE) {
            return <ActionPanelContextProvider><WiresResultPanel/></ActionPanelContextProvider>;
        }

        return <ActionPanelContextProvider><SavedWires/></ActionPanelContextProvider>;
    }

    return(<>
        <Container fluid>
            <Row>
                <Col style={{padding:0}}>
                    {mainComponent()}
                </Col>
            </Row>
            <Row>
                <br/>
            </Row>
        </Container>
    </>);

}