import React from "react";
import {Dropdown} from "react-bootstrap";

export default function DropDownMenu(props) {
    const {title, showDropdown, setShowDropdown} = props

    return (
        <Dropdown
            style={{height: 27, display: 'flex', alignSelf: 'center', alignContent: 'center', alignItems: 'center'}}
            className={'dropdownstyle'}
            show={showDropdown} onToggle={() => {
            setShowDropdown(!showDropdown)
        }}>
            <Dropdown.Toggle variant="success" id="dropdown-basic"
                             style={{
                                 background: 'none',
                                 border: 'none',
                                 fontSize:12,
                                 color: '#038203',
                                 fontWeight: 'normal',
                                 fontFamily: "TD Graphik SemiLight",
                                 paddingLeft:0,
                                 paddingRight:0,
                             }}>
                {title}
            </Dropdown.Toggle>

            <Dropdown.Menu className="pt-0 pb-3 dropdown-custom">
                {props.children}
            </Dropdown.Menu>
        </Dropdown>
    )
}