import {Col, Container, Row} from "react-bootstrap";
import React, {useState} from "react";
import ActionModal from "components/ActionModal";
import useWindowDimensions from "hooks/Dimensions";
import ApproveModal from "components/AgGridToolbar/ApproveModal";
import RejectModal from "components/AgGridToolbar/RejectModal";
import ApproveButton from "components/AgGridToolbar/ToolBarButtons/ApproveButton";
import RejectButton from "components/AgGridToolbar/ToolBarButtons/RejectButton";


export default function ActionApproveRejectPanelLayout(props) {

    const {showApprove, setShowApprove, showReject, setShowReject, show, setShow, modalTitle, approveEnabledOnWire, rejectEnabledOnWire} = props;
    const [showBackButton, setShowBackButton] = useState(false);
    const {isDesktop, isTab, isMobile} = useWindowDimensions();


    const handleApproveButtonClick=()=>{
        setShowApprove(true)
        setShowBackButton(isMobile);
    }

    const handleRejectButtonClick=()=>{
        setShowReject(true);
        setShowBackButton(isMobile);
    }


    const actionModalBody=(approveEnabledOnWire, rejectEnabledOnWire)=> {
        return (<>
            <Container>
                {approveEnabledOnWire &&  <ApproveButton onDeleteButtonClick={handleApproveButtonClick} isActionColBtn={true}/>}
                {rejectEnabledOnWire && <RejectButton onDeleteButtonClick={handleRejectButtonClick} isActionColBtn={true}/> }
            </Container>
        </>)
    }

    const actionModel=()=> {
        if(showApprove) return <ApproveModal setShow={setShow} setShowApprove={setShowApprove}/>
        if(showReject) return <RejectModal setShow={setShow} setShowReject={setShowReject} />
        if(!isDesktop) return actionModalBody(approveEnabledOnWire, rejectEnabledOnWire)
    }

    const actionTitle=()=> {
        if(!isDesktop  ) return `${modalTitle}`
        else return `Action For - ${modalTitle}`
    }

    const handleHide = ()=>{
        setShow(false);
        if(showApprove) setShowApprove(false);
        if(showReject) setShowReject(false);
        setShowBackButton(false);
    }

    const handleGoBackClick = ()=>{
        if(showApprove) setShowApprove(false);
        if(showReject) setShowReject(false);
        setShowBackButton(false);
    }

    return (<>
        <Container fluid>
            <Row>
                <Col xs={"12"}>
                    <ActionModal body={actionModel} title={actionTitle} show={show} onHide={handleHide} onGoBackClick={handleGoBackClick}  dialogClassName={isTab?'custom-dialog' : isMobile? 'fullscreen-dialog':''} centered={isDesktop} showBackButton={showBackButton}/>
                </Col>
            </Row>
        </Container>
    </>)
}
