import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ActionPanelContextProvider} from "context/action";
import SavedReports from "./SavedReports";
import ReportPdfPanel from "./ReportPdfPanel";
import {ReportsPanelContext} from "./context";
import {REPORTS_PANEL_VIEW_REPORT_PAGE} from "./context/ReportsPanelContext";

export default function ReportPanel() {

    const {reportsPanelPageState} = useContext(ReportsPanelContext);

    function currentComponent() {
            if (reportsPanelPageState && reportsPanelPageState.currentPage === REPORTS_PANEL_VIEW_REPORT_PAGE)
                return <ActionPanelContextProvider><ReportPdfPanel/></ActionPanelContextProvider>
            else
                return <ActionPanelContextProvider><SavedReports/></ActionPanelContextProvider>;
    }

    return (<>
        <Container fluid>
            <Row>
                <Col style={{padding: 0}}>
                    {currentComponent()}
                </Col>
            </Row>
            <Row>
                <br/>
            </Row>
        </Container>
    </>);
}
