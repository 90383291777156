import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {EntityAccountsComponent} from "../../components/entity-accounts/EntityAccountsComponent";
import {dateToString, getCurBusDate, getMinPermissibleBusDate, pickerDateFormatToDate} from "../../utils/FormatUtils";
import {getAllErrorValuesAsString, filterFormErrors} from "../../utils/ErrorUtils";
import {SHORT_SALE_URL_SEARCH_APPROVALS} from "../../service/UrlConstant";
import {SecurityContext} from "../../context/security";
import {ShortSaleContext} from "./context";
import {EntityAccountsContext} from "../../components/entity-accounts/context";
import {ToastContext} from "../../context/Toast";


export default function ShortSaleActivitiesFilter() {

    const {setShortSaleApprovalSearchDisplayState,showSearch,shortSaleState,setShortSaleState,clearShortSaleState} = useContext(ShortSaleContext);
    const {handlePageRequest,setPanelDisplayState,getErrorInitState} = useContext(ShortSaleContext);
    const {entityAccounts,setShowAccounts,entityAccountsOptions, clearEntityAccounts,validateEntityAccountSearch, paramBadgeInfo, populateDefaultEntity, setShowAccountValidateAccount} = useContext(EntityAccountsContext);
    const {resetFlagLeft} = useContext(SecurityContext);
    const {makePostRequest} = useContext(SecurityContext);
    const {success,error} = useContext(ToastContext);
    const [formErrors, setFormErrors] = useState(getErrorInitState());
    const [accountOptional, setAccountOptional] = useState(true);


    const handleSearchClick = (e) => {
        const errors = validateSearch()
        const newFormErrors = {...formErrors,...{'search':errors}}
        setFormErrors(newFormErrors)
        if(Object.keys(errors).length!=0){
            return
        }

        const searchCriteria =  {
            "managerId": entityAccounts.managerId,
            "subManagerId": '',
            "counterPartyAcct" : entityAccounts.accountCode,
            "security": shortSaleState.search.security,
            "fromProcessingDate": dateToString(shortSaleState.search.startDate),
            "toProcessingDate": dateToString(shortSaleState.search.endDate),
            "badgeInfo": paramBadgeInfo()
        }

        const onSuccessResponse = (response) => {
            console.log("Success")
            const shortSaleApprovalSearchDisplayDetails = {
                resData : response.data,
                searchCriteria : searchCriteria
            }
            setShortSaleApprovalSearchDisplayState(shortSaleApprovalSearchDisplayDetails);
            shortSaleState['currentPage'] = 'search';
            setPanelDisplayState({...shortSaleState});
        }

        const onErrorResponse = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error("Error occured in processing",errorMsg);
            error("Error occured in processing. " + errorMsg);
        }

        makePostRequest(searchCriteria,
            SHORT_SALE_URL_SEARCH_APPROVALS , onSuccessResponse, onErrorResponse);
    };

    const handleSearchClear = (e) => {
        clearShortSaleState();
        clearEntityAccounts(true,false);
        setFormErrors(getErrorInitState());
        handlePageRequest('defaultlanding');
    }

    const validateSearch = ()=>{
        let errors = {}

        errors = validateEntityAccountSearch(false);

        const {startDate, endDate} = shortSaleState.search;

        if (!startDate)
            errors['startDate'] = 'Please enter start date'

        if (!endDate)
            errors['endDate'] = 'Please enter end date'

        if (startDate && endDate){
            let startDateD = new Date(startDate);
            let endDateD = new Date(endDate);
            if (startDateD.getTime() > endDateD.getTime()){
                errors['startDate'] = 'Start date should be less than end date'
                errors['endDate'] = 'Start date should be less than end date'
            }
        }

        if (dateToString(startDate) < getMinPermissibleBusDate())
            errors['startDate'] = `Start date should be greater than or equal to ${pickerDateFormatToDate(getMinPermissibleBusDate())}`;

        if (dateToString(startDate) > getCurBusDate())
            errors['startDate'] = `Start date should be less than or equal to ${pickerDateFormatToDate(getCurBusDate())}`;
        if (dateToString(endDate) > getCurBusDate())
            errors['endDate'] = `End date should be less than or equal to ${pickerDateFormatToDate(getCurBusDate())}`;

        return errors
    }

    const handleSearchOnChange = (e) => {
        const targetName = e.currentTarget.name;
        const targetValue = e.currentTarget.value;
        const shortSaleNewState = {...shortSaleState}
        shortSaleNewState['search'][e.currentTarget.name] = e.currentTarget.value;
        setShortSaleState(shortSaleNewState);
        filterFormErrors(targetName, targetValue,
            formErrors, setFormErrors,shortSaleState.search)
    }

    useEffect(() => {
        setShowAccounts(showSearch);
        populateDefaultEntity(showSearch);
    }, [entityAccountsOptions])

    useEffect(()=>{
        setShowAccountValidateAccount(showSearch, false);
    },[]);


    return(<>
        <Container fluid className="mt-md-3 mt-sm-0">
            <Form className={'mt-4'} onSubmit={(e) => e.preventDefault()}>
                <h2 className="d-none d-md-block">Search</h2>
                <Row>
                    <EntityAccountsComponent accountOptional={accountOptional} ssaEntityWidthFull={true}/>

                    <Col xs={12} sm={12} md={12} className={'py-0'}>
                        <Form.Group controlId="formGridSecurity">
                            <label className='my-0'>Security (Optional)</label>
                            <InputGroup>
                                <FormControl className='pl-3'
                                             placeholder="Name or Symbol" name="security"
                                             value={shortSaleState.search.security}
                                             onChange={handleSearchOnChange}/>

                            </InputGroup>
                        </Form.Group>
                    </Col>

                    <Col xs={6} sm={6} md={12} className={'py-0'}>
                        <Form.Group controlId="shortSaleState.search.startDate">
                            <Form.Label className="filter-input-label">Start Date *</Form.Label>
                            <Form.Control type="date" name="startDate" value={shortSaleState.search.startDate} onChange={handleSearchOnChange} isInvalid={!!formErrors.search.startDate}/>
                            <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.search.startDate}</b></Form.Control.Feedback>

                        </Form.Group>
                    </Col>
                    <Col xs={6} sm={6} md={12} className={'py-0'}>
                        <Form.Group controlId="shortSaleState.search.endDate">
                            <Form.Label className="filter-input-label">End Date *</Form.Label>
                            <Form.Control type="date" name="endDate" value={shortSaleState.search.endDate} onChange={handleSearchOnChange} isInvalid={!!formErrors.search.endDate}/>
                            <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.search.endDate}</b></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <button className="btn btn-block td-btn-primary-light my-3" type='submit'
                                onClick={handleSearchClick}>Search
                        </button>
                    </Col>
                    <Col md={12}>
                        <button className="btn btn-block td-btn-secondary-clear my-3" onClick={handleSearchClear}>Clear</button>
                    </Col>
                </Row>
            </Form>
        </Container>
    </>)

}