import React, {createRef, useEffect} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import {DEFAULT_GRID_PAGINATION_SIZE,SELECTED_PAGINATION_SIZE} from "utils/Constants";
import {getSessionAttribute, setSessionAttribute} from "../utils/SessionUtils";
export default function AgGridPageSizeSelect(props) {

    const pageSizeSelect = createRef();
    const gridApi = props.gridApi;

    const onPageSizeChanged = () => {
        let value = pageSizeSelect.current.value;
        setSessionAttribute(SELECTED_PAGINATION_SIZE, Number(value))
        props.setPaginationPageSize(Number(value));
        gridApi?.paginationSetPageSize(Number(value));
    };
    useEffect(()=>{
        const paginationSize = getSessionAttribute(SELECTED_PAGINATION_SIZE);
        props.setPaginationPageSize(paginationSize??DEFAULT_GRID_PAGINATION_SIZE);
        gridApi?.paginationSetPageSize(paginationSize??DEFAULT_GRID_PAGINATION_SIZE);
    },[])

    return(<>

        <Container fluid style={{margin: 0, padding: 0}} className={"justify-content-end navbar"}>
            <Row style={{margin: 0, padding :0}}>
                <Col style={{padding:0, paddingRight:10, textAlign: "center", alignSelf:"center"}} className={"align-middle d-none d-sm-inline-block"}>Show</Col>
                <Col style={{padding:0, paddingRight:10}}>
                    <Form.Control ref={pageSizeSelect} as="select" className={'unset-custom-arrows'}
                                  defaultValue={props.paginationPageSize??DEFAULT_GRID_PAGINATION_SIZE}
                                  style={{paddingLeft: 0, paddingRight: 0, width: 'auto', height: 30}}
                                  onChange={() => onPageSizeChanged()}>
                        <option>5</option>
                        <option>10</option>
                        <option>20</option>
                        <option>{DEFAULT_GRID_PAGINATION_SIZE}</option>
                        <option>50</option>
                        <option>100</option>

                    </Form.Control>
                </Col>
            </Row>
        </Container>

    </>);

}