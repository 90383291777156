import React, {useContext} from "react";
import {Container, Row, Col} from "react-bootstrap";
import TradeEntry from "./TradeEntry";
import {TradeCaptureContext} from "./context";
import {TRADE_CAPTURE_PANEL_ENTRY_RESULT} from "./context/TradeCaptureContext";
import TradeEntryResult from "./TradeEntryResult";
import ResponseToast from "components/ResponseToast";

export default function TradeEntryPanel() {

    const {tradeCaptureDisplayState} = useContext(TradeCaptureContext);

    const currentComponent = function () {

        if (tradeCaptureDisplayState && tradeCaptureDisplayState.currentPage === TRADE_CAPTURE_PANEL_ENTRY_RESULT)
            return <TradeEntryResult/>
        return <TradeEntry/>
    }

    return(<>
        <Container fluid>
            <Row>
                <Col style={{padding:0}}>
                    {currentComponent()}
                </Col>
            </Row>
            <Row>
                <ResponseToast isFullWidth={true}/>
                <br/>
            </Row>
        </Container>
    </>);

}
