import React, {useContext, useEffect, useState} from 'react';
import PortalAlertHistory from "pages/home/PortalAlertHistory";
import useWindowDimensions from "hooks/Dimensions";
import {Badge} from "@mui/material";
import {styled} from '@mui/material/styles';
import {
    NOTIFICATIONS_ALERTS_UNREAD_COUNT_URL
} from "../service/UrlConstant";
import {SecurityContext} from "../context/security";

export default function PortalAlertsBadge() {

    const initAlertUnreadCountState = {
        unreadCount: 0
    }

    const [alertUnreadCountState, setAlertUnreadCountState] = useState(JSON.parse(JSON.stringify(initAlertUnreadCountState)));
    let [showAlertHistory, setShowAlertHistory] = useState(false);

    const {
        makeGetRequest
    } = useContext(SecurityContext);

    const BellBadge = styled(Badge)(({theme}) => ({
        '& .MuiBadge-badge': {
            backgroundColor: "#FF9500",
            right: '0px',
            padding: '0 4px',
        },
    }));

    const {isMobile} = useWindowDimensions();
    const bellIcon = <img alt="logo" src="/assets/img/bell-fill.svg" height="24" className={isMobile ? 'bell-icon-mob' : 'bell-icon-tab'}></img>;

    const loadAlertsUnreadCount = () => {
        const onSuccess = (response) => {
            const updatedAlertUnreadCountState = {...alertUnreadCountState};
            updatedAlertUnreadCountState.unreadCount = response.data.unreadCount;
            setAlertUnreadCountState(updatedAlertUnreadCountState);
        }
        const onError = (err) => {
            console.error(err);
        }
        makeGetRequest(NOTIFICATIONS_ALERTS_UNREAD_COUNT_URL, {}, onSuccess, onError);
    };

    const portalAlertHistoryClosed = () => {
        setShowAlertHistory(false);
        loadAlertsUnreadCount();
    }

    useEffect(() => {
        loadAlertsUnreadCount();
    }, []);

    return (
        <>
            <a href="#" onClick={() => setShowAlertHistory(true)}style={{ marginTop:'2px' }}>
                <BellBadge badgeContent={alertUnreadCountState.unreadCount} color="primary" overlap="circular" anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}>
                    {bellIcon}
                </BellBadge>
            </a>

            <PortalAlertHistory show={showAlertHistory} setShow={setShowAlertHistory} onHide={() => portalAlertHistoryClosed()}/>
        </>
    );
}
