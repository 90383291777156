import React, {useState} from 'react';

const accountTypeJSON = [
    { "id": "1", "name": "Cash" },
    { "id": "2", "name": "Margin" },
    { "id": "5", "name": "Short Sale" }
];

export default function AccountTypeRenderer(props) {
    const[type, setType] = useState(props.value);

    const onAccountTypeChange = (event) => {
        props.onAccountTypeChange(event.target.value, props.rowIndex);
        setType(event.target.value);
    }
    return(
        (props.rowIndex !== 0 && !props.node.footer) &&

        <div>
            <select
                className={"accountTypeDropdown"}
                value={type} onChange={onAccountTypeChange}>
                {accountTypeJSON.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
            </select>
        </div>
    )
}

