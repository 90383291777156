import React, {useContext} from "react";
import {Col, Container, Row} from "react-bootstrap";
import useWindowDimensions from "hooks/Dimensions";
import ActionModal from "../ActionModal";
import {SecurityContext} from "context/security";
import AuthenticationService from "../../service/AuthenticationService";

export default function SessionExpiredModal(props) {
     const {isDesktop} = useWindowDimensions();

    const {setNeedLogin} = useContext(SecurityContext);

    const bodyStyle={paddingRight:0, paddingTop:15,margin:0}
    const actionTitle = () => 'Session Expired!';
    const modalBody = () => (<>
        <Container className="my-3 my-md-0">
            <Row className={"justify-content-center"}>
                <Col md={12}>
                    <h2 style={{textAlign: 'center'}}>Your session has expired you will need to re-authenticate</h2><br/>
                </Col>
            </Row>
            <Row className={"justify-content-center"}>
                <Col md={4} xs={4} className={!isDesktop?"pr-0":"p-0 pt-2"}>
                    <button className="btn btn-block td-btn-secondary-clear" href="#" onClick={() => {
                        setNeedLogin(true);
                        AuthenticationService.logout();
                    }}><h3><i className='td-icon-colour-primary td-icon icon-small'></i>&nbsp;OK
                    </h3></button>
                </Col>
            </Row>
        </Container>
    </>);

    return (
        <>
        <ActionModal body={modalBody} title={actionTitle} show={true} onHide={() => setNeedLogin(true)}
                             dialogClassName={'custom-dialog'}
                             centered={true}
                             bodyStyle={bodyStyle}
                />
        </>
    )

}

