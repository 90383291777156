import {Col, Container, Row, Table} from "react-bootstrap";
import React, {useContext, useState} from "react";
import useWindowDimensions from "../../hooks/Dimensions";
import * as Routes from "../../routes/Routes";
import {useHistory} from "react-router-dom";
import {TradeBreakContext} from "./context";

export default function InfoModalBody() {

    const {popUpData,setShowInfoModal} = useContext(TradeBreakContext);
    const {isMobile, isMobileRotated} = useWindowDimensions();
    const [view, setShowView] = useState(false);
    let history = useHistory();

    function navigateToPage(path) {
        history.push(path);
    }

    return (
        <Container fluid className={"my-3 my-md-0 popUp2"}>

            <Table striped bordered hover className={'custom-table'}>
                <thead>
                <tr>
                    <th ></th>
                    <th style={{width:'33%'}}>Client Trade</th>
                    <th style={{width:'33%'}}>Broker Trade</th>
                </tr>
                </thead>
                <tbody>
                {
                    popUpData
                        .map((e) =>
                            (
                                <tr style={{height:'10px'}}>
                                    <td>{e.header}</td>
                                    <td className={e.isBreak?'clr':''}>{e.client}</td>
                                    <td className={e.isBreak?'clr':''}>{e.broker}</td>
                                </tr>
                            )
                        )
                }
                </tbody>
            </Table>



            <Row className={"justify-content-end viewBtn"}>
                <Col md={4} xs={12} className='pr-0 d-none d-md-block'>
                    <button disabled={view} className='btn btn-block td-btn-secondary-clear' href="#" onClick={() => {
                        setShowInfoModal(false);
                        setShowView(false);
                    }}>Cancel
                    </button>
                </Col>
                <Col md={4} xs={11} sm={12}
                     className={isMobileRotated ? 'stick-to-botom' : isMobile ? 'stick-to-bottom' : ''}>
                    <button disabled={true} className={"btn btn-block td-btn-primary-light"} href="#" onClick={() => {
                        setShowInfoModal(false);
                        setShowView(true);
                        //clicked(value)
                        navigateToPage(Routes.TRADECAPTURE_ENTRY + "/TradeBreak")

                    }}>View
                    </button>
                </Col>
            </Row>

        </Container>
    );

}

