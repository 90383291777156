import React, {useState} from 'react';
import {NavDropdown} from "react-bootstrap";
import useWindowDimensions from "../../hooks/Dimensions";


export default function TDNavDropDown(props) {

    const [show, setShow] = useState(false);
    const {isMobile} = useWindowDimensions();

    const showDropdown = ()=>{
        setShow(true);
    }

    const hideDropdown = () => {
        setShow(false);
    }

    return (<>
        {isMobile ?
            <NavDropdown title={props.title} id={props.id}
                         alignRight={props.alignRight}>
                {props.children}
            </NavDropdown> :
            <NavDropdown title={props.title} id={props.id}
                         alignRight={props.alignRight}
                         show={show}
                         onMouseEnter={showDropdown}
                         onMouseLeave={hideDropdown}>
                {props.children}
            </NavDropdown>
        }
    </>);

}