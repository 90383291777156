import {useEffect, useState} from 'react';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    const isDesktop = width>=1024;
    const isTab = width<1024 && width>=768;
    const isMobile = width<768;
    const isMobileRotated = height<376;
    const isTabRotated = width>=1024 && height<=768;
    return {
        width,
        height,
        isDesktop,
        isTab,
        isMobile,
        isMobileRotated,
        isTabRotated
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}