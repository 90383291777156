import React, {createContext, useEffect, useRef, useState} from 'react';
import {
    DEFAULT_GRID_PAGINATION_SIZE,
    SELECTED_PAGINATION_SIZE
} from "../../utils/Constants";
import {getSessionAttribute, setSessionAttribute} from "../../utils/SessionUtils";

export const Context = createContext({})
export const Provider = props => {
    const {children} = props;
    const [exportColumns,setExportColumns] = useState();
    const [exportFilename,setExportFilename] = useState();
    const hiddenExportGridApi = useRef();
    const [paginationPageSize, setPaginationPageSize] = useState(DEFAULT_GRID_PAGINATION_SIZE);
    const [showButton, setShowButton] = useState(false)

    useEffect(() => {
        let paginationSize = getSessionAttribute(SELECTED_PAGINATION_SIZE);
        setPaginationPageSize(paginationSize? paginationSize : DEFAULT_GRID_PAGINATION_SIZE)
    }, [])

    const utilContext = {
        exportColumns,
        setExportColumns,
        hiddenExportGridApi,
        exportFilename,
        setExportFilename,
        paginationPageSize,
        setPaginationPageSize,
        showButton,
        setShowButton
    }
    return <Context.Provider value={utilContext}>
        {children}
    </Context.Provider>

}
export const {Consumer} = Context;
