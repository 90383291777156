import {Col, Container, Row} from "react-bootstrap";
import React, {useContext} from "react";
import useWindowDimensions from "hooks/Dimensions";
import {SecurityContext} from "context/security";
import AuthenticationService from "../../service/AuthenticationService";
import * as Routes from "routes/Routes"
import {useHistory} from "react-router-dom";
import {LoginContext} from "../login/context";

export default function PasswordResetSuccess() {
    const {isDesktop} = useWindowDimensions();
    const {setNeedLogin} = useContext(SecurityContext);
    const {clearLoginState} = useContext(LoginContext)
    let history = useHistory();

    function logout() {
        localStorage.clear();
        history.push(Routes.HOME);
        setNeedLogin(true);
        AuthenticationService.logout();
        clearLoginState();
    }

    return (<> <Container fluid style={{width: isDesktop?"100%":"", paddingLeft:isDesktop?100:""}}>
        <Row>
            <Col className={"text-center"}>
                <br/>
                <h1><span style={{top:3, textAlign: "center"}} className={`td-icon icon-large td-icon-approved`}></span></h1>
                <br/>
                <h4>Password Updated!</h4>
                <br/>
                <h4>Your password has been updated successfully</h4>
                <br/>
                <a href="#" onClick={()=> {logout()}}>Return to login page.</a>
            </Col>
        </Row>
    </Container>
    </>)
}