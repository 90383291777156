import React, {useContext, useEffect, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import useWindowDimensions from "hooks/Dimensions";
import {HomepageContext} from "./context";
import * as URL from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {ToastContext} from "context/Toast";
import {HOME_PAGE_TOP_POSITIONS_COUNT} from "./context/HomepageContext";
import {cellStyleChecker, numberFormatter} from "utils/NumberUtils";

export default function TopShortPositions() {

    const {entityAccounts} = useContext(HomepageContext);
    const {makeGetRequest} = useContext(SecurityContext);
    const [rowData, setRowData] = useState([]);
    const {isMobile} = useWindowDimensions()
    const {error} = useContext(ToastContext);


    const defaultColDef = {
        sortable:true,
        resizable:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:!!isMobile,
    };


    const columnTypes ={
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
            suppressColumnsToolPanel:true
        }
    }

    useEffect(() => {
        if(entityAccounts) {
            if (entityAccounts.accountId)
                getTopShortPosition(entityAccounts.accountId);
            else
                setRowData([]);
        }
    }, [entityAccounts])

    const getTopShortPosition = (accountId) =>{
        const params = {
            accountId: accountId,
            totalRowsRequested: HOME_PAGE_TOP_POSITIONS_COUNT
        }
        const onSuccess = (res) =>{
            setRowData(res.data);
        }
        const onError = (err) =>{
            console.log("Error retrieving top short positions", err);
            error("There was an error with loading short positions.");
        }
        if (accountId) {
            makeGetRequest(URL.HOME_TOP_SHORT_POSITIONS, params, onSuccess, onError);
        }
    }

    const columnDefs = [
        {headerName: 'Symbol', field: 'symbol'},
        {headerName: 'Quantity', field: 'quantity', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Value', field: 'value', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Gain', field: 'gain', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: 'Total Percent', field: 'percentTotal', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];

    const onDataRendered = (params) => {
        params.columnApi.autoSizeAllColumns();
    }

    return(<>
        <div
            id="myGrid"
            className="ag-theme-alpine"
            style={{height:220}}
        >
            <AgGridReact
                groupDefaultExpanded={'1'}
                enableRowGroup={false}
                suppressDragLeaveHidesColumns={true}
                suppressMakeColumnVisibleAfterUnGroup={true}
                suppressModelUpdateAfterUpdateTransaction={true}
                suppressScrollOnNewData={true}
                suppressAggFuncInHeader={true}
                allowShowChangeAfterFilter={false}
                rowGroupPanelShow={'never'}
                enableSorting={true}
                defaultColDef={defaultColDef}
                columnTypes={columnTypes}
                columnDefs={columnDefs}
                rowData={rowData}
                onRowDataChanged={onDataRendered}
                animateRows={true}
            >
            </AgGridReact>
        </div>
    </>);
}