import {Col, Container, Row} from "react-bootstrap";
import React, {useContext, useState} from "react";
import useWindowDimensions from "hooks/Dimensions";
import {ActionPanelContext} from "context/action";

export default function DeleteModal(props) {

    const {setShowDelete, setShow, confirmDeleteBtnClassName} = props
    const {isDesktop, isMobile} = useWindowDimensions();
    const {handleDelete} = useContext(ActionPanelContext);
    const [deleting, setDeleting] = useState(false);

    return (<>
        <Container fluid className={props.isActionColBtn?"d-none":"my-3 my-md-0"}>
            <Row>
                <Col md={12}>
                    <h4 style={{textAlign: isDesktop?'left':'center'}}>Are you sure you want to delete this?</h4><br/>
                </Col>
            </Row>
            <Row className={"justify-content-end"}>
                <Col md={4} xs={12} className='pr-0 d-none d-md-block'>
                    <button disabled={deleting} className='btn btn-block td-btn-secondary-clear' href="#" onClick={() => {
                        setShow(false);
                        setShowDelete(false);
                    }}>Cancel
                    </button>
                </Col>
                <Col md={4} xs={11} sm={12} className={isMobile?'stick-to-bottom':''}>
                    <button  disabled={deleting} className={confirmDeleteBtnClassName} href="#" onClick={() => {

                        const onSuccess = () => {
                            setShow(false);
                            setShowDelete(false);
                        }

                        const onError = (err) => {
                            setShow(false);
                            setShowDelete(false);
                        }

                        setDeleting(true);
                        handleDelete(onSuccess, onError);

                    }}>Delete
                    </button>
                </Col>
            </Row>
        </Container>
    </>)
}
DeleteModal.defaultProps = {
    confirmDeleteBtnClassName: "btn btn-block td-btn-red"
}
