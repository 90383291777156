    import {createContext, useState} from "react";
import {numberFormat3FractionalDigitsStr} from "utils/NumberUtils";
export const Context = createContext({});

export const Provider = props => {
    const {children} = props;
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedBrkType, setSelectedBrkType] = useState();
    const initPopUpDataState = [
        {'field':'tradeDate','header':'Trade Date','client':'','broker':'', isBreak:false},
        {'field':'settleDate','header':'Settle Date','client':'','broker':'', isBreak:false},
        {'field':'tranType','header':'Tran Type','client':'','broker':'', isBreak:false},
        {'field':'price','header':'Price','client':'','broker':'', isBreak:false},
        {'field':'quantity','header':'Quantity','client':'','broker':'', isBreak:false},
        {'field':'net','header':'Net','client':'','broker':'', isBreak:false},
        {'field':'interest','header':'Interest','client':'','broker':'', isBreak:false},
        {'field':'commission','header':'Commission','client':'','broker':'', isBreak:false},
        {'field':'execBroker','header':'Exec Brk','client':'','broker':'', isBreak:false},
        {'field':'dtcControlNumber','header':'DTC Control Number','client':'','broker':'', isBreak:false},
        {'field':'reason','header':'Reason','client':'','broker':'', isBreak:false},
    ]

    const [popUpData, setPopUpData] = useState(JSON.parse(JSON.stringify(initPopUpDataState)));

    const tradeBreakTypeHashmap = {
        "BRKS":"Client Only",
        "UNCN":"Broker Only",
        "MTPT":"Match with Differences",
        "MTCH":"Matches",
        "CACN":"Cancels"
    }
    const fieldToReasonColMapping = {
        'quantity': 'NetQuantity',
        'net': 'NetAmount',
        'execbroker': 'Executing Broker',
        'price':'Price',
        'settledate':'Settlement Date'
    }

    const getReasonEquivalentOfField =(field)=> {
        if(!!field)
            field = field.replace('broker','').replace('client','').toLowerCase()
        return fieldToReasonColMapping[field];
    }

    const mapTradeBreakType = (param) => {
        if(!!param)
            return tradeBreakTypeHashmap[param.data.breakType]
        return ''
    }

    const accountCodeMapping = (param) =>{
        if(!!param)
            return param.data.breakType === 'UNCN'? param.data.brokerAccountCode : param.data.clientAccountCode;
        return ''
    }

    const cellStyleChecker=(param)=>{
        let columnName = param.colDef.field;
        let reason = param.data.clientReason;
        if (checkColumnInReason(columnName,reason)) return {backgroundColor: '#F7E8E9', color:'#AE1100'}
        return
    }

    const numericCellStyleChecker=(param)=>{
        const numericStyle = {textAlign: 'right'};
        return {...numericStyle,...cellStyleChecker(param)}
    }

    const checkColumnInReason=(columnName,reason)=>{
        if(!!reason && !!columnName && reason.includes(getReasonEquivalentOfField(columnName)))
            return true;
        return false
    }

    const columnDefsTradeBreaks = [
        {headerName: 'Break Type', field: 'breakType', valueGetter:mapTradeBreakType, pinned:'left', width:120,flex:1,},
        {headerName: 'Entity', field: 'shortName', pinned:'left', width:'90'},
        {headerName: 'Account', field: 'accountCode',valueGetter:accountCodeMapping, pinned:'left', width:'90'},
        {headerName: 'Security', field: 'security', pinned:'left', width:100},

        {headerName:'Client',children:[

                {headerName: 'Trade Date', field: 'clientTradeDate', width:110},
                {headerName: 'Settle Date', field: 'clientSettleDate', width:110},
                {headerName: 'Tran Type', field: 'clientTranType', width:70},
                {headerName: 'Price', field: 'clientPrice',width:110,flex:1, valueFormatter: numberFormat3FractionalDigitsStr,cellStyle:numericCellStyleChecker},
                {headerName: 'Quantity', field: 'clientQuantity', width:100,flex:1, valueFormatter: numberFormat3FractionalDigitsStr,cellStyle:numericCellStyleChecker},
                {headerName: 'Net', field: 'clientNet',width:120,flex:1, valueFormatter: numberFormat3FractionalDigitsStr, cellStyle:numericCellStyleChecker},
                {headerName: 'Interest', field: 'clientInterest', width:110,flex:1, valueFormatter: numberFormat3FractionalDigitsStr,cellStyle:numericCellStyleChecker},
                {headerName: 'Commission', field: 'clientCommission', width:100,flex:1, valueFormatter: numberFormat3FractionalDigitsStr,cellStyle:numericCellStyleChecker},
                {headerName: 'Exec Brk', field: 'clientExecBroker', width:100,flex:1},
                {headerName: 'DTC Control Number', field: 'clientDtcControlNumber',width:90,flex:1},
                {headerName: 'Reason', field: 'clientReason', width:150,flex:1},
            ]},
        {headerName:'Broker',children:[
                {headerName: 'Trade Date', field: 'brokerTradeDate',  pinned:'right',width:100},
                {headerName: 'Settle Date', field: 'brokerSettleDate', pinned:'right',width:100},
                {headerName: 'Tran Type', field: 'brokerTranType', pinned:'right',width:10},
                {headerName: 'Price', field: 'brokerPrice', pinned:'right',width:100, valueFormatter: numberFormat3FractionalDigitsStr,cellStyle:numericCellStyleChecker},
                {headerName: 'Quantity', field: 'brokerQuantity', pinned:'right',width:120, valueFormatter: numberFormat3FractionalDigitsStr,cellStyle:numericCellStyleChecker},
                {headerName: 'Net', field: 'brokerNet', pinned:'right',width:110, valueFormatter: numberFormat3FractionalDigitsStr, cellStyle:numericCellStyleChecker},
                {headerName: 'Interest', field: 'brokerInterest', pinned:'right',width:90, valueFormatter: numberFormat3FractionalDigitsStr,cellStyle:numericCellStyleChecker},
                {headerName: 'Commission', field: 'brokerCommission', pinned:'right',width:90, valueFormatter: numberFormat3FractionalDigitsStr,cellStyle:numericCellStyleChecker},
                {headerName: 'Exec Brk', field: 'brokerExecBroker', pinned:'right',width:90},
                {headerName: 'DTC Control Number', field: 'brokerDtcControlNumber', pinned:'right',width:90},
                {headerName: 'Reason', field: 'brokerReason', pinned:'right', width:150},
            ]},

    ];
    const tradeBreakContext = {
        popUpData,
        setPopUpData,
        showInfoModal,
        setShowInfoModal,
        tradeBreakTypeHashmap,
        columnDefsTradeBreaks,
        selectedBrkType,
        setSelectedBrkType,
        mapTradeBreakType,
        accountCodeMapping,
        cellStyleChecker,
        getReasonEquivalentOfField,
    }

    return <Context.Provider value={tradeBreakContext}>{children}</Context.Provider>

};

export const {Consumer} = Context;
