import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import ContactUsIcon from "components/ContactUsIcon";
import AuthenticationService from "../service/AuthenticationService";

export default function AppFooter(props) {
    const baseurl = `${process.env.PUBLIC_URL}`
    return(<>
        <hr/>
        <footer className="td-bg-colour-7 pb-4" >
            <Container fluid className="px-6">
                <Row>
                    <Col className={`${props.isLogin?'text-left px-2':'text-center'} login-app-footer`}>
                        <br/>
                        Client Portal Users: {props.isLogin && <br/>} Please
                        <a className="td-cta td-link" href={baseurl+"/Content/pdf/TDPS_Client_Portal_Disclaimers_and_Disclosures.pdf"} target="_blank"> click here to view important legal, disclaimer and disclosure information</a> for our client portal.<br/>
                        <a className="td-cta td-link" href={baseurl+"/Content/pdf/TDPS_Financial_Statements.pdf"} target="_blank">Financial Statements</a>
                    </Col>
                </Row>
            </Container>
            {!props.isLogin && !AuthenticationService.isInternalUser() && <ContactUsIcon/>}
        </footer>
    </>);
}
