import React, {useContext, useEffect} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import * as URL from "service/UrlConstant";
import {
    dateToString, formatDateString, getPrevBusDate,
    yearMonthDayToPickerDateFormat
} from "utils/FormatUtils";
import {ReportsPanelContext} from "./context";
import {SecurityContext} from "context/security";
import * as b64 from "base64-js";
import {gunzipSync} from "fflate";
import {ToastContext} from "context/Toast";
import {
    REPORTS_PANEL_VIEW_REPORT_PAGE
} from "./context/ReportsPanelContext";
import {EntityAccountsComponent} from "components/entity-accounts/EntityAccountsComponent";
import {EntityAccountsContext} from "components/entity-accounts/context";
import ReportFilterFolders from "./ReportFilterFolders";
import {FilterContext} from "layouts/SidePanelLayout";
import {getAllErrorValuesAsString} from "../../utils/ErrorUtils";
import {isValidDate} from "../../utils/RegexUtils";
import {useLocation} from "react-router";

export default function ReportFilter() {

    const {
        reportsFilterSearchState,
        setReportsFilterSearchState,
        reportsFolderFileState,
        reportsPanelPageState,
        reportsPdfDisplayState,
        setReportsPdfDisplayState,
        displayReportPanelPage,
        clearReportSearchAndLoadReportsHome,
        validateReportFolderSearch,
        forceReloadOfFoldersAndFiles,
        checkForReloadOfFolders,
        isHomeRequest,
        setIsHomeRequest,
        setShowGridToolbar,
        savedReportDate
    } = useContext(ReportsPanelContext);
    const {
        entityAccounts,
        clearEntityAccounts,
        validateEntityAccountSearch,
        setManagerAndAccountDetails,
        setShowAccounts,
        entityAccountsOptions,
        populateDefaultEntity
    } = useContext(EntityAccountsContext);
    const {makeGetRequest} = useContext(SecurityContext);
    const {error} = useContext(ToastContext);
    const {showFilter, setShowFilter} = useContext(FilterContext);
    const location = useLocation();


    const handleSearchClear = () => {
        clearEntityAccounts();
        clearReportSearchAndLoadReportsHome();
    }

    useEffect(() => {
        populateDefaultEntity(true)
    }, [entityAccountsOptions])

    useEffect(() => {
        if(isHomeRequest)
            setIsHomeRequest(false)
    }, [])

    const handleChange = (e) => {

        const targetName = e.currentTarget.name;
        const targetValue = e.currentTarget.value;
        const reportsFilterSearchStateToUpdate = JSON.parse(JSON.stringify(reportsFilterSearchState));
        reportsFilterSearchStateToUpdate['formErrors']['reportsSearch'][e.currentTarget.name] = '';

        if (targetName === 'effectiveDate') {
            if (reportsFilterSearchStateToUpdate.reportsSearch.effectiveDate !== targetValue) {
                reportsFilterSearchStateToUpdate.reportsSearch.effectiveDate = targetValue;
                setReportsFilterSearchState(reportsFilterSearchStateToUpdate);

                checkForReloadOfFolders(reportsFilterSearchStateToUpdate.reportsSearch.accountId, reportsFilterSearchStateToUpdate.reportsSearch.effectiveDate);
            }
        }
    }

    const validateSearch = () => {

        const entityAccountErrors = validateEntityAccountSearch();

        let effectDateErrors = {}
        const effDt = reportsFilterSearchState.reportsSearch.effectiveDate;
        if (!effDt || !isValidDate(effDt)) {
            effectDateErrors['effectiveDate'] = 'Please select close of business date';

            const reportsFilterSearchErrors = JSON.parse(JSON.stringify(reportsFilterSearchState));
            reportsFilterSearchErrors.formErrors.reportsSearch = effectDateErrors;
            setReportsFilterSearchState(reportsFilterSearchErrors);
        }

        const folderFileErrors = validateReportFolderSearch();

        return (Object.keys(entityAccountErrors).length > 0 || Object.keys(effectDateErrors).length > 0 || Object.keys(folderFileErrors).length > 0);
    }

    const viewReportFile = (e) => {
        e.preventDefault();

        const errorsReported = validateSearch()

        if (errorsReported) {
            return
        }

        const searchParams = {
            accountId: reportsFilterSearchState.reportsSearch.accountId,
            effectiveDate: dateToString(reportsFilterSearchState.reportsSearch.effectiveDate.toString()),
            folder: reportsFolderFileState.reportsSearch.folder,
            fileName: reportsFolderFileState.reportsSearch.fileName
        }

        setShowFilter(!showFilter)

        const onSuccess = (response) => {

            const reportsPdfDisplay = JSON.parse(JSON.stringify(reportsPdfDisplayState));

            if (response.data) {
                const based64Decoded = b64.toByteArray(response.data);
                const decompressed = gunzipSync(based64Decoded);
                reportsPdfDisplay.reportPdfData = {data: decompressed};
                reportsPdfDisplay.isReportSavedSearch = false;
                reportsPdfDisplay.reportSavedSearchName = '';
                reportsPdfDisplay.reportSavedSearchId = '';

                reportsPdfDisplay.reportPdfSearchData = {
                    managerId: reportsFilterSearchState.reportsSearch.managerId,
                    managerName: reportsFilterSearchState.reportsSearch.managerName,
                    subManagerId: reportsFilterSearchState.reportsSearch.subManagerId,
                    accountId: reportsFilterSearchState.reportsSearch.accountId,
                    accountName: reportsFilterSearchState.reportsSearch.accountName,
                    clientCode: reportsFilterSearchState.reportsSearch.clientCode,
                    effectiveDate: reportsFilterSearchState.reportsSearch.effectiveDate,
                    fileName: reportsFolderFileState.reportsSearch.fileName,
                    folder: reportsFolderFileState.reportsSearch.folder
                };

            } else {
                reportsPdfDisplay.reportPdfData = [];
            }
            setShowGridToolbar(response.data && response.data.length > 0)
            setReportsPdfDisplayState(reportsPdfDisplay);
            displayReportPanelPage(REPORTS_PANEL_VIEW_REPORT_PAGE);
        }

        const onError = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error('Error when retrieving file list - ', errorMsg);
            error("There was an error with loading selected report");
        }

        makeGetRequest(URL.REPORT_FOLDERS_FILES_FILE_URL, searchParams, onSuccess, onError);
    }

    useEffect(() => {

        setShowAccounts(true)
        //Load the entity accounts component data into filter search - on initial load
        const reportsFilterSearchStateToSet = JSON.parse(JSON.stringify(reportsFilterSearchState));
        reportsFilterSearchStateToSet.reportsSearch.managerId = entityAccounts.managerId;
        reportsFilterSearchStateToSet.reportsSearch.managerName = entityAccounts.managerName;
        reportsFilterSearchStateToSet.reportsSearch.accountId = entityAccounts.accountId;
        reportsFilterSearchStateToSet.reportsSearch.clientCode = entityAccounts.accountCode;
        reportsFilterSearchStateToSet.reportsSearch.accountName = entityAccounts.accountName;

        //check if the context date is not empty we should use it.
        if (savedReportDate)
            reportsFilterSearchStateToSet.reportsSearch.effectiveDate = yearMonthDayToPickerDateFormat(savedReportDate)
        setReportsFilterSearchState(reportsFilterSearchStateToSet);

    }, []);

    useEffect(() => {

        if (REPORTS_PANEL_VIEW_REPORT_PAGE === reportsPanelPageState.currentPage && reportsPdfDisplayState.isReportSavedSearch === true) {
            //Load the pdf saved search details for saved report search
            let reportsFilterSearchStateSavedReport = JSON.parse(JSON.stringify(reportsFilterSearchState));
            reportsFilterSearchStateSavedReport.reportsSearch.managerId = reportsPdfDisplayState.reportPdfSearchData.managerId;
            reportsFilterSearchStateSavedReport.reportsSearch.managerName = reportsPdfDisplayState.reportPdfSearchData.managerName;
            reportsFilterSearchStateSavedReport.reportsSearch.accountId = reportsPdfDisplayState.reportPdfSearchData.accountId;
            reportsFilterSearchStateSavedReport.reportsSearch.clientCode = reportsPdfDisplayState.reportPdfSearchData.clientCode;
            reportsFilterSearchStateSavedReport.reportsSearch.accountName = reportsPdfDisplayState.reportPdfSearchData.accountName;
            reportsFilterSearchStateSavedReport.reportsSearch.effectiveDate = yearMonthDayToPickerDateFormat(reportsPdfDisplayState.reportPdfSearchData.effectiveDate);
            reportsFilterSearchStateSavedReport.formErrors.reportsSearch = {};
            setManagerAndAccountDetails(reportsPdfDisplayState.reportPdfSearchData.managerId, reportsPdfDisplayState.reportPdfSearchData.managerName,
                reportsPdfDisplayState.reportPdfSearchData.accountId, reportsPdfDisplayState.reportPdfSearchData.accountName,
                reportsPdfDisplayState.reportPdfSearchData.clientCode, true);

            setReportsFilterSearchState(reportsFilterSearchStateSavedReport);
            //Force reload and selection for folder and fileName
            forceReloadOfFoldersAndFiles(reportsPdfDisplayState.reportPdfSearchData.folder, reportsPdfDisplayState.reportPdfSearchData.fileName,
                reportsFilterSearchStateSavedReport.reportsSearch.accountId, reportsFilterSearchStateSavedReport.reportsSearch.effectiveDate);
        }

    }, [reportsPanelPageState]);

    useEffect(() => {
        const entityAccountsChange = {...entityAccounts};

        if(reportsFilterSearchState.reportsSearch.managerId !== entityAccounts.managerId
            || reportsFilterSearchState.reportsSearch.accountId !== entityAccounts.accountId) {

            const updatedEntityAccountsState = JSON.parse(JSON.stringify(reportsFilterSearchState));
            updatedEntityAccountsState.reportsSearch.managerId = entityAccounts.managerId;
            updatedEntityAccountsState.reportsSearch.managerName = entityAccounts.managerName;
            updatedEntityAccountsState.reportsSearch.accountId = entityAccounts.accountId;
            updatedEntityAccountsState.reportsSearch.clientCode = entityAccounts.accountCode;
            updatedEntityAccountsState.reportsSearch.accountName = entityAccounts.accountName;
            //check if the context date is not empty we should use it.
            if (savedReportDate)
                updatedEntityAccountsState.reportsSearch.effectiveDate = yearMonthDayToPickerDateFormat(savedReportDate)
            setReportsFilterSearchState(updatedEntityAccountsState);
            checkForReloadOfFolders(updatedEntityAccountsState.reportsSearch.accountId, updatedEntityAccountsState.reportsSearch.effectiveDate);
        }

    }, [entityAccounts]);

    //Defaults "Close of Business Date" to previous business date
    useEffect(()=>{
        if(!isHomeRequest && reportsPdfDisplayState.isReportSavedSearch !== true && reportsPanelPageState.currentPage !== REPORTS_PANEL_VIEW_REPORT_PAGE) {
            setReportsFilterSearchState((prevReportsFilterSearchState)=>({
                ...prevReportsFilterSearchState,
                reportsSearch:{
                    ...prevReportsFilterSearchState.reportsSearch,
                    effectiveDate: formatDateString(String(getPrevBusDate()), 'YYYY-MM-DD')
                }
            }));
        }
        setIsHomeRequest(false);

    }, [location.key])

    return (<>

        <Container fluid className="mt-md-3 mt-sm-0">
            <Form onSubmit={(e) => e.preventDefault()}>
                <h2 className="d-none d-md-block">Search</h2>
                <Row>

                    <EntityAccountsComponent/>

                    <Col sm={12} md={12}>
                        <Form.Group controlId="formGridAddress1">
                            <Form.Label className="filter-input-label">Close of Business Date *</Form.Label>
                            <Form.Control type="date"
                                          value={reportsFilterSearchState.reportsSearch.effectiveDate}
                                          onChange={handleChange} name={'effectiveDate'}
                                          isInvalid={!!reportsFilterSearchState.formErrors.reportsSearch.effectiveDate}/>
                            <Form.Control.Feedback type={'invalid'}>
                                <b><i className="bi bi-exclamation-circle"></i>{reportsFilterSearchState.formErrors.reportsSearch.effectiveDate}</b>
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <ReportFilterFolders/>
                    <Col md={12}>
                        <button className="btn btn-block td-btn-primary-light my-3" type='submit'
                                onClick={viewReportFile}>View Report
                        </button>
                    </Col>
                    <Col md={12}>
                        <button className="btn btn-block td-btn-secondary-clear my-3"
                                href="#" onClick={handleSearchClear}>Clear
                        </button>
                    </Col>
                </Row>
            </Form>

        </Container>

    </>);

}