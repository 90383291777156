const alpha2IsoCode = [
    {
        "alpha2IsoCode": "IE",
        "alpha3IsoCode": "IRL",
        "country": "Ireland",
        "numericCode": "353"
    },
    {
        "alpha2IsoCode": "CA",
        "alpha3IsoCode": "CAN",
        "country": "Canada",
        "numericCode": "1"
    },
    {
        "alpha2IsoCode": "US",
        "alpha3IsoCode": "USA",
        "country": "United States",
        "numericCode": "1"
    },
    {
        "alpha2IsoCode": "IN",
        "alpha3IsoCode": "IND",
        "country": "India",
        "numericCode": "91"
    },
    {
        "alpha2IsoCode": "PL",
        "alpha3IsoCode": "POL",
        "country": "Poland",
        "numericCode": "48"
    },
    {
        "alpha2IsoCode": "SG",
        "alpha3IsoCode": "SGP",
        "country": "Singapore",
        "numericCode": "65"
    },
    {
        "alpha2IsoCode": "AU",
        "alpha3IsoCode": "AUS",
        "country": "Australia",
        "numericCode": "61"
    },
    {
        "alpha2IsoCode": "PH",
        "alpha3IsoCode": "PHL",
        "country": "Philippines",
        "numericCode": "63"
    },
]

const northAmericanPhoneFormatter = phone => {
    return phone.length === 10 ? `+1 (${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}` :
        `+${phone.substring(0, 1)} (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7)}`
}

const singaPorePhoneFormatter = phone => {
    return `+${phone.substring(0, 2)}  ${phone.substring(2, 6)} ${phone.substring(6)}`;
}

const indiaPhoneFormatter = phone => {
    return phone.length === 11 ? `+${phone.substring(0, 2)} ${phone.substring(2, 6)} ${phone.substring(6)}` :
        `+${phone.substring(0, 2)} ${phone.substring(2, 7)} ${phone.substring(7)}`;
}

const polandPhoneFormatter = phone => {
    return `+${phone.substring(0, 2)} ${phone.substring(2, 4)} ${phone.substring(4, 7)} ${phone.substring(7)}`;
}

const irelandPhoneFormatter = phone => {
    return `+${phone.substring(0, 3)} ${phone.substring(3, 5)} ${phone.substring(5, 8)} ${phone.substring(8)}`;
}

const australiaPhoneFormatter = phone => {
    return `+${phone.substring(0, 3)} ${phone.substring(3, 7)} ${phone.substring(7)}`;
}

const philippinesPhoneFormatter = phone => {
    return `+${phone.substring(0, 2)} ${phone.substring(2, 5)}-${phone.substring(5, 8)}-${phone.substring(8)}`;
}

const invalidPhoneFormatter = phone => {
    return `+${phone}`;
}

const convertToString = phone => {
    return String(phone);
}

const isValidPhone = phone => {
    let isValid = false;
    if (phone === null || phone === undefined) {
        return isValid;
    }
    isValid = true;
    return isValid;
}

const stripTrailingSpecialChars = phone => {
    // Strip leading special chars
    let modifiedPhone = phone.replace(/^[ \+\-_]+/, '');

    // Strip trailing special chars
    return modifiedPhone.replace(/[ \+\-_]+$/, '');
}

const stripAllSpecialChars = phone => {
    return phone.replace(/\D/g, '');
}

const getPhoneFormatter = code => {
    const formatters = {
        "US": northAmericanPhoneFormatter,
        "CA": northAmericanPhoneFormatter,
        "SG": singaPorePhoneFormatter,
        "IN": indiaPhoneFormatter,
        "PL": polandPhoneFormatter,
        "IE": irelandPhoneFormatter,
        "AU": australiaPhoneFormatter,
        "PH": philippinesPhoneFormatter,
        "INV": invalidPhoneFormatter,
    }

    const formatter = formatters[code];
    if (!formatter) {
        return "Invalid Country code";
    }
    return formatter;
}

const extractPhoneAndCountryCode = phone => {
    const stripedNumber = stripAllSpecialChars(phone);
    const stripedTrailingChars = stripTrailingSpecialChars(phone);

    if (stripedNumber.length === 10 && stripedNumber.substring(0, 2) === "65") {
        return {phone: stripedNumber, code: "SG"};
    } else if (stripedNumber.length === 11 && stripedNumber.substring(0, 1) === "1") {
        return {phone: stripedNumber, code: "US"};
    } else if (stripedNumber.length === 10) {
        return {phone: stripedNumber, code: "US"};
    } else if ((stripedNumber.length === 12 || stripedNumber.length === 11) && stripedNumber.substring(0, 2) === "91") {
        return {phone: stripedNumber, code: "IN"};
    } else if (stripedNumber.length === 11 && stripedNumber.substring(0, 2) === "48") {
        return {phone: stripedNumber, code: "PL"};
    } else if (stripedNumber.length === 11 && stripedNumber.substring(0, 3) === "353") {
        return {phone: stripedNumber, code: "IE"};
    } else if (stripedNumber.length === 11 && stripedNumber.substring(0, 3) === "612") {
        return {phone: stripedNumber, code: "AU"};
    } else if (stripedNumber.length === 12 && stripedNumber.substring(0, 2) === "63") {
        return {phone: stripedNumber, code: "PH"};
    } else if (stripedNumber.length < 10) {
        return {phone: stripedNumber, code: "INV"};
    } else {
        return "Invalid phone detail";
    }
}

const formatPhone = phone => {
    const validPhone = isValidPhone(phone);
    if (validPhone) {
        const phoneToString = convertToString(phone);
        const phoneDetail = extractPhoneAndCountryCode(phone);
        if (phoneDetail !== "Invalid phone detail") {
            const formatPhone = getPhoneFormatter(phoneDetail.code);
            return formatPhone !== "Invalid Country code" ? formatPhone(phoneDetail.phone) : null;
        }
    }
    return null;
}

export default formatPhone;