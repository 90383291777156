import React, {useContext, useEffect} from 'react';
import {Col, Form} from "react-bootstrap";
import * as URL from "service/UrlConstant";
import {
    dateToString,
    isDatePickerDateValid
} from "utils/FormatUtils";
import {ReportsPanelContext} from "./context";
import {SecurityContext} from "context/security";
import {ToastContext} from "context/Toast";


export default function ReportFilterFolders() {

    const {
        reportsFolderFileState,
        setReportsFolderFileState,
        reportsFilterSearchState,
        reportsFolderFileReloadState
    } = useContext(ReportsPanelContext);

    const {makeAsyncGetRequest} = useContext(SecurityContext);
    const {error} = useContext(ToastContext);

    const loadFolderList = (accountId, effectiveDate, onSuccessFolders) => {
        const onSuccess = (response) => {
            if (onSuccessFolders) {
                onSuccessFolders(response.data);
            }
        }
        const onError = (err) => {
            console.error('Error when retrieving Folder list - ', err);
            error("There was an error with loading folder list for Entity Account");
        }

        makeAsyncGetRequest(URL.REPORT_FOLDERS_URL, {
            accountId: accountId,
            effectiveDate: dateToString(effectiveDate.toString())
        }, onSuccess, onError);
    }

    const loadFolderListIfRequired = (selectedAccountId, selectedEffectiveDate) => {

        console.log('selected Account: ' + selectedAccountId + ' selected effective date:' + selectedEffectiveDate);
        console.log('loaded folder selected Account: ' + reportsFolderFileState.loadedFoldersState.accountId + ' loaded folder selected effective date: ' + reportsFolderFileState.loadedFoldersState.effectiveDate);

        const effectiveDateChanged = reportsFolderFileState.loadedFoldersState.effectiveDate !== selectedEffectiveDate;
        const selectedAccountChanged = reportsFolderFileState.loadedFoldersState.accountId !== selectedAccountId;

        if (selectedAccountId !== '' && (effectiveDateChanged || selectedAccountChanged)) {

            const reportsFolderFilesToReload = JSON.parse(JSON.stringify(reportsFolderFileState));
            reportsFolderFilesToReload.loadedFoldersState.folders = [];
            reportsFolderFilesToReload.loadedFoldersState.folderFiles = [];
            reportsFolderFilesToReload.reportsSearch.folder = '';
            reportsFolderFilesToReload.reportsSearch.fileName = '';

            if (effectiveDateChanged) {
                reportsFolderFilesToReload.loadedFoldersState.effectiveDate = selectedEffectiveDate;
            }
            if (selectedAccountChanged) {
                reportsFolderFilesToReload.loadedFoldersState.accountId = selectedAccountId;
            }

            if (selectedEffectiveDate && isDatePickerDateValid(selectedEffectiveDate) && selectedAccountId) {
                const onSuccessFolders = (folders) => {
                    if (folders && folders.length > 0) {
                        reportsFolderFilesToReload.loadedFoldersState.folders = folders;
                    }
                    setReportsFolderFileState(reportsFolderFilesToReload);
                }
                loadFolderList(selectedAccountId, selectedEffectiveDate, onSuccessFolders);
            }
            else{
                setReportsFolderFileState(reportsFolderFilesToReload);
            }
        }
    }

    const loadFolderFileList = (folder, onSuccessFolderFiles) => {

        const onSuccess = (response) => {
            if (onSuccessFolderFiles) {
                onSuccessFolderFiles(response.data);
            }
        }
        const onError = (err) => {
            console.error('Error when retrieving file list - ', err);
            error("There was an error with loading folder file for Entity Account Folder");
        }

        makeAsyncGetRequest(URL.REPORT_FOLDERS_FILES_URL, {
            accountId: reportsFilterSearchState.reportsSearch.accountId,
            effectiveDate: dateToString(reportsFilterSearchState.reportsSearch.effectiveDate.toString()),
            folder: folder
        }, onSuccess, onError);
    }

    const handleChange = (e) => {

        const targetName = e.currentTarget.name;
        const targetValue = e.currentTarget.value;
        const reportsFolderFileUpdateState = JSON.parse(JSON.stringify(reportsFolderFileState));
        reportsFolderFileUpdateState['formErrors']['reportsSearch'][e.currentTarget.name] = '';

        if (targetName === 'folder') {
            const selectedFolder = targetValue;

            if (selectedFolder !== '') {
                const onSuccessFolderFiles = (folderFiles) => {
                    // const reportsFolderFileUpdateState = JSON.parse(JSON.stringify(reportsFolderFileState));
                    reportsFolderFileUpdateState.reportsSearch.folder = selectedFolder;
                    reportsFolderFileUpdateState.loadedFoldersState.folderFiles = folderFiles;
                    setReportsFolderFileState(reportsFolderFileUpdateState);
                }

                loadFolderFileList(selectedFolder, onSuccessFolderFiles);
            } else {
                const reportsFolderFileUpdateState = JSON.parse(JSON.stringify(reportsFolderFileState));
                reportsFolderFileUpdateState.reportsSearch.fileName = '';
                reportsFolderFileUpdateState.reportsSearch.folder = '';
                reportsFolderFileUpdateState.loadedFoldersState.folderFiles = [];
                setReportsFolderFileState(reportsFolderFileUpdateState);
            }


        } else if (targetName === 'fileName') {
            reportsFolderFileUpdateState.reportsSearch.fileName = targetValue;
            setReportsFolderFileState(reportsFolderFileUpdateState);
        }
    }

    useEffect(() => {
        const accountId = reportsFolderFileReloadState.check.accountId;
        const effectiveDate = reportsFolderFileReloadState.check.effectiveDate;
        if(accountId && effectiveDate)
            loadFolderListIfRequired(accountId, effectiveDate);
    }, [reportsFolderFileReloadState.check]);

    useEffect(() => {

        const folder = reportsFolderFileReloadState.force.folder;
        const fileName = reportsFolderFileReloadState.force.fileName;
        const accountId = reportsFolderFileReloadState.force.accountId;
        const effectiveDate = reportsFolderFileReloadState.force.effectiveDate;

        if (folder && fileName && accountId && effectiveDate) {

            reportsFolderFileState.formErrors.reportsSearch = {};
            reportsFolderFileState.loadedFoldersState.folders = [];
            reportsFolderFileState.loadedFoldersState.folderFiles = [];
            reportsFolderFileState.reportsSearch.folder = '';
            reportsFolderFileState.reportsSearch.fileName = '';

            const reportsFolderFileUpdateState = JSON.parse(JSON.stringify(reportsFolderFileState));
            reportsFolderFileUpdateState.reportsSearch.folder = folder;
            reportsFolderFileUpdateState.reportsSearch.fileName = fileName;

            const onSuccessFolders = (folders) => {
                if (folders && folders.length > 0) {
                    reportsFolderFileUpdateState.loadedFoldersState.folders = folders;
                    reportsFolderFileUpdateState.loadedFoldersState.accountId = accountId;
                    reportsFolderFileUpdateState.loadedFoldersState.effectiveDate = effectiveDate;

                    const onSuccessFolderFiles = (folderFiles) => {
                        reportsFolderFileUpdateState.loadedFoldersState.folderFiles = folderFiles;
                        setReportsFolderFileState(reportsFolderFileUpdateState);
                    }
                    loadFolderFileList(reportsFolderFileUpdateState.reportsSearch.folder, onSuccessFolderFiles);
                }
            }
            loadFolderList(accountId, effectiveDate, onSuccessFolders);
        }

    }, [reportsFolderFileReloadState.force]);

    const dropdownIndicatorStyles = (base, state) => {
        let changes = {
            backgroundColor: 'red'
        };
        return Object.assign(base,changes);
    };

    return (<>

        <Col sm={6} md={12} className={reportsFolderFileState.loadedFoldersState.folders.length === 0 && 'folder-dropDown'}>
            <Form.Group controlId="formGridEntity"  className={'td-select'} id={(!!reportsFolderFileState.formErrors.reportsSearch.folder? 'td-select-error':'')}>
                <Form.Label className="filter-input-label">Folder *</Form.Label>
                <Form.Control as="select" name={'folder'}
                              onChange={handleChange}
                              value={reportsFolderFileState.reportsSearch.folder}
                              isInvalid={!!reportsFolderFileState.formErrors.reportsSearch.folder}
                              disabled={reportsFolderFileState.loadedFoldersState.folders.length === 0}>
                    <option value={''}>Select</option>
                    {
                        reportsFolderFileState.loadedFoldersState.folders.map(data =>
                            <option
                                key={data["folder"]}>{data["folder"]}</option>)
                    }
                </Form.Control>
                <Form.Control.Feedback type={'invalid'}>
                    <b><i className="bi bi-exclamation-circle"></i>{reportsFolderFileState.formErrors.reportsSearch.folder}</b>
                </Form.Control.Feedback>
            </Form.Group>
        </Col>
        <Col sm={6} md={12} className={reportsFolderFileState.loadedFoldersState.folderFiles.length === 0 && 'folder-dropDown'}>
            <Form.Group controlId="formGridEntity" className={'td-select'} id={(!!reportsFolderFileState.formErrors.reportsSearch.fileName? 'td-select-error':'')}>
                <Form.Label className="filter-input-label">File *</Form.Label>
                <Form.Control as="select" name={'fileName'}
                              onChange={handleChange}
                              value={reportsFolderFileState.reportsSearch.fileName}
                              isInvalid={!!reportsFolderFileState.formErrors.reportsSearch.fileName}
                              disabled={reportsFolderFileState.loadedFoldersState.folderFiles.length === 0}>
                    <option value={''}>Select</option>
                    {
                        reportsFolderFileState.loadedFoldersState.folderFiles.map(data =>
                            <option
                                key={data["fileName"]}>{data["fileName"]}</option>)
                    }
                </Form.Control>
                <Form.Control.Feedback type={'invalid'}>
                    <b><i className="bi bi-exclamation-circle"></i>{reportsFolderFileState.formErrors.reportsSearch.fileName}</b>
                </Form.Control.Feedback>
            </Form.Group>
        </Col>
    </>);
}