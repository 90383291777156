import React from "react";
import {Col, Container, Row} from "react-bootstrap";

export default function SidePanelLoginLayout(props) {

    const SideComponent = props.sideComponent;
    const MainComponent = props.mainComponent;

    return(<>
        <Container fluid className="px-0">
            <Row className="p-0 m-0">
                <Col md={3} sm={6} xs={12} className="p-0">
                    <SideComponent/>
                </Col>
                <Col md={9} sm={6} className="d-none d-sm-block p-0">
                    <MainComponent/>
                </Col>
            </Row>
        </Container>
    </>);
}
