import React, {useState} from 'react';
import {Col, Container, Row, Toast} from "react-bootstrap";
import useWindowDimensions from "../hooks/Dimensions";
import ButtonMailto from "./ButtonMailTo";
import ActionModal from "./ActionModal";
import ContactUsHeader from "../pages/contactus/ContactUsHeader";
import ContactUsBody from "../pages/contactus/ContactUsBody";
import ContactUsModal from "../pages/contactus/ContactUsModal";
const contactusBody =()=><ContactUsBody/>;
const contactusHeader =()=> <ContactUsHeader/>;

export default function ContactUsIcon() {
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);

    const handleHide = ()=>{
        setShow(false);
    }

    return (<>
        <Container className={'toastStyle'} style={{
                display: 'flex', justifyContent: 'end', textAlign: 'right', alignSelf: 'right', alignContent: 'right'
            }}>
                <Row className={'pr-0'}>
                    <Col xs={12} className={'mr-0 pr-0 pr-md-2'} style={{display: 'flex', justifyContent: 'end'}}>
                        <ContactUsModal show={show} toggleShow={toggleShow} onHide={handleHide} body={contactusBody} title={contactusHeader}/>
                    </Col>
                    <Col xs={12} className={show?'d-none':'pr-1 pr-md-2'}>
                        <i className='td-icon icon-regular td-icon-contactUs contactusIcon' style={{ fontSize:'24px'}} onClick={toggleShow}></i>
                    </Col>
                    <Col xs={12} className={!show?'d-none':'pr-1 pr-md-2'}>
                        <i className='td-icon icon-regular td-icon-close contactusIcon' style={{fontSize: '24px'}} onClick={toggleShow}></i>
                    </Col>

                </Row>

            </Container>
        </>);
}