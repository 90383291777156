import React from 'react';
import SidePanelLayout from "layouts/SidePanelLayout";
import QueriesFilter from "./QueriesFilter";
import QueriesPanel from "./QueriesPanel";
export default function Queries() {

    return(<>
            <SidePanelLayout
                sideComponent={QueriesFilter}
                mainComponent={QueriesPanel}>
            </SidePanelLayout>
    </>);

}