import {Col, Container, Row} from "react-bootstrap";
import React, {useContext, useState} from "react";
import ActionModal from "components/ActionModal";
import DeleteModal from "components/AgGridToolbar/DeleteModal";
import useWindowDimensions from "hooks/Dimensions";
import ExportModal from "components/AgGridToolbar/ExportModal";
import RenameModal from "components/AgGridToolbar/RenameModal";
import ExportButton from "components/AgGridToolbar/ToolBarButtons/ExportButton";
import DeleteButton from "components/AgGridToolbar/ToolBarButtons/DeleteButton";
import RenameButton from "components/AgGridToolbar/ToolBarButtons/RenameButton";
import DownloadButton from "../components/AgGridToolbar/ToolBarButtons/DownloadButton";

export default function ActionPanelLayout(props) {

    const gridApi = props.gridApi;
    const {showExport, setShowExport, showRename, setShowRename, showDelete, setShowDelete, show, setShow} = props;
    const {actionForAccount, showSSAActionModal, showReportActionModal} = props;
    const [showBackButton, setShowBackButton] = useState(false);
    const {isDesktop, isTab, isMobile} = useWindowDimensions();

    let confirmDeleteBtnClassName = "btn btn-block ";
    confirmDeleteBtnClassName += isDesktop ? 'td-btn-primary-light' : 'td-btn-red ';

    const downloadButton=()=>{
        return (<>
            <DownloadButton/>
        </>)
    }

    const handleExportButtonClick=()=>{
        setShowExport(true)
        setShow(true)
        setShowBackButton(isMobile);
    }

    const handleRenameClick=()=>{
        setShowRename(true);
        setShow(true);
        setShowBackButton(isMobile);
    }

    const handleDeleteButtonClick=()=>{
        setShowDelete(true);
        setShow(true);
        setShowBackButton(isMobile);
    }

    const exportButton=()=>{
        return (<>
            <ExportButton onExportButtonClick={handleExportButtonClick} gridApi={gridApi} showExport={showExport} setShowExport={setShowExport} setShow={setShow} isActionColBtn={true}/>
        </>)
    }

    const actionModalBody=()=> {
        return (<>
            <Container>
                {(!showSSAActionModal && showReportActionModal && downloadButton()) || (exportButton())}
                <RenameButton onRenameClick={handleRenameClick} editable={true} isActionColBtn={true}/>
                <DeleteButton onDeleteButtonClick={handleDeleteButtonClick} isActionColBtn={true}/>
            </Container>
        </>)
    }

    const actionModel=()=> {
        if(showExport) return <ExportModal gridApi={gridApi} showExport={showExport} setShowExport={setShowExport} setShow={setShow} isActionColBtn={true}/>
        if(showRename) return <RenameModal allowSpecialCharacters={props.allowSpecialCharacters} setShow={setShow}  setShowRename={setShowRename}/>
        if(showDelete) return <DeleteModal gridApi={gridApi} setShow={setShow} setShowDelete={setShowDelete} confirmDeleteBtnClassName={confirmDeleteBtnClassName}/>
        if(!isDesktop) return actionModalBody()
    }

    const actionTitle=()=> {
        if(showExport) return 'Export'
        if(showRename) return 'Rename'
        if(showDelete) return 'Delete'
        if( (showSSAActionModal && !isDesktop) || !isDesktop ) return `Action for -  ${actionForAccount}`
    }

    const handleHide = ()=>{
        setShow(false);
        if(showExport) setShowExport(false);
        if(showRename) setShowRename(false);
        if(showDelete) setShowDelete(false);
        setShowBackButton(false);
    }

    const handleGoBackClick = ()=>{
        if(showExport) setShowExport(false);
        if(showRename) setShowRename(false);
        if(showDelete) setShowDelete(false);
        setShowBackButton(false);
    }

    return (<>
        <Container fluid>
            <Row>
                <Col xs={"12"}>
                    <ActionModal body={actionModel} title={actionTitle} show={show} onHide={handleHide} onGoBackClick={handleGoBackClick}  dialogClassName={isTab?'custom-dialog' : isMobile? 'fullscreen-dialog':''} centered={isDesktop} showBackButton={showBackButton}/>
                </Col>
            </Row>
        </Container>
    </>)
}

ActionPanelLayout.defaultProps={
    showSSAActionModal:false, showReportActionModal:false, allowSpecialCharacters:false
}
