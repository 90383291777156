import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Row, Col, Container, Card, Collapse} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import CheckboxRenderer from "components/CheckboxRenderer";
import useWindowDimensions from "hooks/Dimensions";
import {SecurityContext} from "context/security";
import * as URL from "service/UrlConstant"
import template from './template/yourCompany-userName-yyyy-mm-dd-version.xlsx'
import {Link, useHistory} from "react-router-dom";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {EntityAccountsComponent} from "components/entity-accounts/EntityAccountsComponent";
import {getCurrentDate, getCurrentDateToDatePickerFormat} from "utils/FormatUtils";
import {SHORT_SALE_URL_LOCATE, SHORT_SALE_URL_PERSIST_FILE} from "service/UrlConstant";
import {ToastContext} from "context/Toast";
import {ShortSaleContext} from "./context";
import {SHORTSALE_APPROVAL_HISTORY, SHORTSALE_APPROVAL_LOCATE} from "../../routes/Routes";
import ResponseToast from "../../components/ResponseToast";
import {
    DEFAULT_GRID_PAGINATION_SIZE,
    ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE,
    GRID_COLUMN_PROPERTY
} from "utils/Constants";
import AuthenticationService from "../../service/AuthenticationService";
import {managerGetter} from "../../utils/ManagerUtil";
import {API_CALL_ERR_MSG} from "../../utils/ConstantsMsg";
import {addPropertiesToGridColumns, getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";
import {UtilsContext} from "../../context/utils";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";


export default function ShortSaleUpload() {
    let history = useHistory();
    const {setShortSaleApprovalSearchDisplayState,handlePageRequest,panelDisplayState, setPanelDisplayState, setSSAToolBarRefreshCriteria, setShowBadge} = useContext(ShortSaleContext);
    const {success,alert,error} = useContext(ToastContext);
    const {setManagerDetails, setEntityAccountsOptions, entityAccountsOptions, entityAccounts, setShowAccounts} = useContext(EntityAccountsContext);
    const [upload, setUpload] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [showEntityAccountsDropdown, setShowEntityAccountsDropdown] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const {isMobile} = useWindowDimensions();
    const inputFile = useRef(null);
    const downloadFile = useRef(null);
    const [fileUploadState, setFileUploadState] = useState(
        {
            entity: '',
            responseData: []
        }
    );
    const {paginationPageSize, showButton, setShowButton} = useContext(UtilsContext);
    const {makePostRequest} = useContext(SecurityContext)

    const myGrid = useRef(null);

    const defaultColDef = {
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true,
        enableCellChangeFlash: true,
        lockPinned: !!isMobile,
    };

    const columnTypes = {
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu: true,
            flex: 1,
            suppressMovable: true,
        }
    }



    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const cellStyleChecker = (param) => {
        if(param !== null) return {backgroundColor: '#F7E8E9', color:'#AE1100'}
    }

    const columnDefs = [
        {headerName: 'Select', checkboxSelection:true,maxWidth :100},
        {headerName: 'Security', field: 'security', tooltipField:'errmsgSecurity', cellStyle:(params) => cellStyleChecker(params.data.errmsgSecurity)},
        {headerName: 'Quantity', field: 'quantity', tooltipField:'errmsgQuantity', cellStyle:(params) => cellStyleChecker(params.data.errmsgQuantity)},
        {headerName: 'Entity' ,  valueGetter : () => entityAccounts.managerName },
        {headerName: 'Account' ,  valueGetter : () => entityAccounts.accountCode?entityAccounts.accountCode+'-'+entityAccounts.accountName:'' },
        {headerName: 'errmsgSecurity', field: 'errmsgSecurity', hide:true},
        {headerName: 'errmsgQuantity', field: 'errmsgQuantity',hide:true},
        {headerName: 'fileName', field: 'fileName',hide:true },
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];

    const updatedColumnDefs = useMemo(() => addPropertiesToGridColumns(columnDefs, GRID_COLUMN_PROPERTY), []);

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }

    const onFirstDataRendered = (params) => {
        params.api.forEachNode((node) => {
            if (!(node.data.errmsgSecurity || node.data.errmsgQuantity)){
                node.setSelected(true);
            }else{
                node.selectable = false
            }
        });
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const handleUploadFile = (event) => {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        document.getElementById('file').value = null;
        formData.append('managerId', entityAccounts.managerId);
        if(entityAccounts.accountCode)
            formData.append('counterPartyAcct', entityAccounts.accountCode);
        const onSuccessResponse = (response) => {
            const newFileUploadState = {...fileUploadState};
            newFileUploadState['responseData'] = response.data
            setFileUploadState(newFileUploadState);
            setRowData(newFileUploadState.responseData)
            setUpload(true)
            setCancel(false)
        }

        const onErrorResponse = (err) => {
            console.error("Error on file upload", err)
            let errMsg = API_CALL_ERR_MSG
            if (err.response.status === 400)
                errMsg = err.response.data
            error(errMsg)
            setCancel(true)
        }

        makePostRequest(formData,
            URL.SHORT_SALE_URL_UPLOAD_FILE, onSuccessResponse, onErrorResponse);
    }

    const handleUploadCardClick = () => {
        if (entityAccounts.managerId === "") {
            alert("Please choose Entity");
            return;
        }
        inputFile.current.click()
    }

    const uploadComponent = function () {

        if (cancel) {
            return (
                <Card id="uploadCard" className="bg-td-colour-bg-notification-1 p-5"
                      align="center" onClick={handleUploadCardClick}>
                    <Card.Title><h1 className="text-td-colour-font-6">Upload Failed</h1></Card.Title>
                    <Card.Text>
                        Something seems to have gone wrong, please try again. <br/>
                        If the problem persists, download a template and try again.
                    </Card.Text>
                    <i className="text-td-colour-font-6 td-icon icon-large td-icon-downloadCertificate icon-rotate"></i>
                </Card>
            );
        } else {
            return (<>
                    <Card id="uploadCard" className="td-bg-colour-7 p-5" align="center" onClick={handleUploadCardClick}>
                        <Card.Title><h1>Upload</h1></Card.Title>
                        <Card.Text>
                            Please upload a file for short sale locates.<br/>
                            You can download a template if needed.
                        </Card.Text>
                        <i className="td-icon-colour-primary td-icon icon-large td-icon-downloadCertificate icon-rotate"></i>
                    </Card>
                </>
            );
        }
    }

    const handleDownloadTemplate = ()=> {
        if ( entityAccounts.managerId === "" ) {
            alert("Please choose Entity");
            return;
        }

        downloadFile.current.click()
    }

    const handlePersistFile = () => {
        const selectedData = gridApi.getSelectedRows();

        if(selectedData.length < 1){
            alert("No valid records are selected. Please review the file and reupload it")
            return
        }

        const data = [];
        selectedData.map(e => {
            let locateObj = {
                "processingDate": getCurrentDate(),
                "managerId": e.managerId,
                "counterPartyAcct" : e.counterPartyAcct,
                "security": e.security,
                "quantity": e.quantity,
                "fileName" : e.fileName
            };
            data.push(locateObj);
        })

        const onSuccessResponse = (response) => {
            success("Persist File Locate Processed successfully");
            history.push(SHORTSALE_APPROVAL_HISTORY);
        }

        const onErrorResponse = (err) => {
            if (err && err.response && err.response.status === 500)
                error("Persist File Locate failed")
            else
                error(err.response.data)
        }

        makePostRequest(data,
            SHORT_SALE_URL_PERSIST_FILE, onSuccessResponse, onErrorResponse);

    }

    const handleProcessFile = () => {
        const selectedData = gridApi.getSelectedRows();
        if(selectedData.length < 1){
            alert("No valid records are selected. Please review the file and reupload it")
            return
        }

        const data = [];
        selectedData.map(e => {
            let locateObj = {
                "processingDate": getCurrentDate(),
                "managerId": e.managerId,
                "counterPartyAcct" : e.counterPartyAcct,
                "srcSecCd": e.security,
                "requestedQty": e.quantity,
            };
            data.push(locateObj);
        })

        const onSuccessResponse = (response) => {
            success("Process File Locate Processed successfully");

            //Set Refresh Request Criteria
            setSSAToolBarRefreshCriteria(response, entityAccounts);

            const shortSaleStateNew = {...panelDisplayState}
            shortSaleStateNew.search.startDate = getCurrentDateToDatePickerFormat()
            shortSaleStateNew.search.endDate = getCurrentDateToDatePickerFormat()
            setPanelDisplayState({...shortSaleStateNew});
            handlePageRequest('search')
            setShowBadge(true)
            history.push(SHORTSALE_APPROVAL_LOCATE);
        }

        const onErrorResponse = (err) => {
            error("Error in processing")
        }

        makePostRequest(data,
            SHORT_SALE_URL_LOCATE, onSuccessResponse, onErrorResponse);

    }
    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    return (<>
        <Container fluid>
            <Row className="py-4">
                <Col xs={12} className="pl-4">
                    <h1>Short Sale Approval Upload</h1>
                </Col>
            </Row>

            <Collapse in={!upload} timeout={0}>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} className="px-4 pb-3 pt-0">
                        <input type='file' id='file'
                               accept=".xlsx,.csv,.xls"
                               ref={inputFile} onChange={(e) => handleUploadFile(e)} style={{display: 'none'}}/>
                        {uploadComponent()}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} className="px-4 pb-3 pt-0">
                        <Link to={template} target="_blank" download style={{display:'none'}} ref={downloadFile}/>
                        <Card id="downloadCard"  className="td-bg-colour-7 p-5" align="center" onClick={handleDownloadTemplate} style={{cursor:'pointer'}}>
                            <Card.Title><h1>Download Template</h1></Card.Title>
                            <Card.Text>
                                Download the template and fill out the required details, <br/>
                                You may then upload the template to our portal.
                            </Card.Text>
                            <i className="td-icon-colour-primary td-icon icon-large td-icon-downloadCertificate"></i>
                        </Card>
                    </Col>
                </Row>
            </Collapse>
            <Collapse in={upload} timeout={0}>
                <Row>
                    <Col xs={12}>
                        <div
                            ref={myGrid}
                            id="myGrid"
                            className="ag-theme-alpine">
                            <AgGridReact
                                frameworkComponents={{
                                    checkboxRenderer: CheckboxRenderer
                                }}
                                paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                                domLayout={'autoHeight'}
                                groupDefaultExpanded={'1'}
                                enableRowGroup={false}
                                suppressDragLeaveHidesColumns={true}
                                suppressMakeColumnVisibleAfterUnGroup={true}
                                suppressModelUpdateAfterUpdateTransaction={true}
                                suppressScrollOnNewData={true}
                                suppressAggFuncInHeader={true}
                                allowShowChangeAfterFilter={false}
                                rowGroupPanelShow={'never'}
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                onGridReady={onGridReady}
                                defaultColDef={defaultColDef}
                                columnDefs={updatedColumnDefs}
                                columnTypes={columnTypes}
                                rowData={rowData}
                                animateRows={true}
                                rowSelection={'multiple'}
                                onFirstDataRendered={onFirstDataRendered}
                                onRowDataChanged={onFirstDataRendered}
                                showDisabledCheckboxes={true}
                                onPaginationChanged={onPaginationChange}
                            >
                            </AgGridReact>
                        </div>
                    </Col>
                </Row>
            </Collapse>

            <Collapse in={upload} timeout={0}>
                <Row className="pt-3" style={{justifyContent: 'end'}}>

                    <Col sm={6} md={4} lg={2} className="pt-2 pt-sm-2 pt-md-2 mt-md-0 mb-sm-4">
                        <button style={{background:'#FF9500', color:'black'}} className="btn btn-block" href="#"
                                onClick={handlePersistFile}>Save File
                        </button>
                    </Col>
                    <Col sm={6} md={4} lg={2} className="pt-4 pt-sm-2 mb-xs-2">
                        <button className="btn btn-block td-btn-primary-light" href="#"
                                onClick={handleProcessFile}>Process File
                        </button>
                    </Col>
                    <Col sm={12} md={4} lg={2} className="pt-4 pt-sm-2 mb-xs-2">
                        <button style={{background:'#AE1100'}} className="btn btn-block" href="#" onClick={() => {
                            setUpload(false);
                        }}>Cancel
                        </button>
                    </Col>
                </Row>
            </Collapse>
            {showButton && <ScrollButton showButton={showButton}/>}
        </Container>
        <ResponseToast isFullWidth={true}/>
    </>);

}