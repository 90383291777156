import React, {useContext} from 'react';
import {Badge, Container} from "react-bootstrap";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import {QueriesPanelContext} from "./context";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {pickerDateFormatToDate} from "utils/FormatUtils";

export default function QueriesParamsBadge() {

    const {queryResultsDisplayState, clearQueryPanelDisplayState} = useContext(QueriesPanelContext);
    const {clearEntityAccounts} = useContext(EntityAccountsContext);

    const settings = {
        infinite: false,
        speed: 500,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: Number.MAX_VALUE,
                settings: "unslick"
            },
            {
                breakpoint: 767,
                settings: "slick"
            }
        ]
    };

    const clearSelection = (e) => {
        clearEntityAccounts();
        clearQueryPanelDisplayState();
    }

    return (<>

        <Container fluid style={{width: "100%"}} className="justify-content-start pt-0 px-md-0">
            <Slider {...settings}>
                <span>
                    <Badge
                        className={'badge-outline'}>{queryResultsDisplayState.querySearch.managerName}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{queryResultsDisplayState.querySearch.accountCode}-{queryResultsDisplayState.querySearch.accountName}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{queryResultsDisplayState.querySearch.queryName}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{pickerDateFormatToDate(queryResultsDisplayState.querySearch.startDate)}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{pickerDateFormatToDate(queryResultsDisplayState.querySearch.endDate)}</Badge>
                </span>
                <span>
                    <Badge className={'badge-button'} onClick={clearSelection}>
                        CLEAR
                    </Badge>
                </span>
            </Slider>
        </Container>
    </>)
}
