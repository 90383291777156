import React from 'react';
import MFALogin from "./MFALogin";
import MFAPanel from "./MFAPanel";
import LoginPanel from "./LoginPanel";
import LoginFilter from "./LoginFilter";
import LogoutFilter from "./LogoutFilter";
import SidePanelLoginLayout from "../../layouts/SidePanelLoginLayout";
import {LOGOUT_PATHNAME} from 'service/UrlConstant';

export default function Login() {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const profile = process.env.REACT_APP_PROFILE
    if(window.location.pathname === LOGOUT_PATHNAME) return <SidePanelLoginLayout
        sideComponent={LogoutFilter}
        mainComponent={LoginPanel}
    >
    </SidePanelLoginLayout>
    else if(!!code) return <MFALogin code={code}/>
    else if (!!profile && (profile==='local' || profile==='dev' || profile==='sit')) return <SidePanelLoginLayout
        sideComponent={LoginFilter}
        mainComponent={LoginPanel}
    >
    </SidePanelLoginLayout>
    else return <MFAPanel/>;

}