 import React, { useContext, useEffect, useRef, useState} from 'react';
import {Container} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import ActionsRenderer from "components/ActionsRenderer";
import AgGridToolbar from "components/AgGridToolbar/AgGridToolbar";
import PageHeader from "components/PageHeader";
import useWindowDimensions from "hooks/Dimensions";
import {QueriesPanelContext} from "./context";
import QueriesParamsBadge from "./QueriesParamsBadge";
import {ActionPanelContext} from "context/action";
import {QUERIES_QUERY_SAVE} from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {dateToString} from "utils/FormatUtils";
import {UtilsContext} from "context/utils";
import {ToastContext} from "context/Toast";
import * as URL from "service/UrlConstant";
import {QUERY_PANEL_VIEW_QUERY_PAGE} from "./context/QueriesPanelContext";
import {ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE} from "utils/Constants";
import {getColDef} from "./QueryColumnDefs";
import AuthenticationService from "../../service/AuthenticationService";
import {createExcelExportParams, getFilteredColumns} from "../../utils/ExcelExportUtils";
 import {getDisplayedNumberOfGridRows, updateGridColumDefs} from "../../utils/gridColumnUtils";
 import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";

export default function QueriesResultsPanel() {

    const {queryResultsDisplayState, setQueryResultsDisplayState, clearQueryPanelDisplayState, queryPanelPageState, showGridToolbar} = useContext(QueriesPanelContext)
    const {
        setHandleSaveFunction,
        setHandleRenameFunction,
        setHandleDeleteFunction,
        setHandleExportFunction,
        setSavedSearchName
    } = useContext(ActionPanelContext);
    const {hiddenExportGridApi,paginationPageSize, setPaginationPageSize, showButton, setShowButton} = useContext(UtilsContext);
    const {makePostRequest, makePatchRequestWithParams, makeDeleteRequest} = useContext(SecurityContext)
    const {clearEntityAccounts} = useContext(EntityAccountsContext);
    const {success, error} = useContext(ToastContext);

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const {isMobile} = useWindowDimensions()
    const [usePortalColumns,setUsePortalColumns] = useState(false);
    const queriesResultsGrid = useRef(null);

    const columnTypes ={
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: true,
            filter: true,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
        }
    }

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:isMobile? true : false,
    };

    const saveQuerySearch = (name, gridApi, onSuccess, onError) => {
        const params = {
            savedQueryName: name,
            clientCode: queryResultsDisplayState.querySearch.accountCode,
            managerId: queryResultsDisplayState.querySearch.managerId,
            subManagerId: queryResultsDisplayState.querySearch.subManagerId,
            accountId:queryResultsDisplayState.querySearch.accountId,
            queryId: queryResultsDisplayState.querySearch.queryId,
            startDate: (queryResultsDisplayState.querySearch.startDate ? dateToString(queryResultsDisplayState.querySearch.startDate) : ''),
            endDate: (queryResultsDisplayState.querySearch.endDate ? dateToString(queryResultsDisplayState.querySearch.endDate) : '')
        };

        const onSuccessResponse = (response) => {
            if(onSuccess) onSuccess();
            const queryResultsDisplay = JSON.parse(JSON.stringify(queryResultsDisplayState));
            queryResultsDisplay.isSavedQuery = true;
            queryResultsDisplay.savedName = response.data.savedQueryName;
            queryResultsDisplay.savedQueryId = response.data.savedQueryId;
            setQueryResultsDisplayState(queryResultsDisplay);
            setSavedSearchName(name);
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        makePostRequest(params, QUERIES_QUERY_SAVE, onSuccessResponse, onErrorResponse);
    }

    const updateQuerySearch = (updatedSavedName, gridApi, onSuccess, onError) => {

        const params = {
            "savedNameUpdated":updatedSavedName,
        }

        const onSuccessResponse = (response) => {
            if (onSuccess) onSuccess();
            const queryResultsDisplay = JSON.parse(JSON.stringify(queryResultsDisplayState));
            queryResultsDisplay.savedName = updatedSavedName;
            setQueryResultsDisplayState(queryResultsDisplay);
            setSavedSearchName(updatedSavedName);
            success("Successfully updated saved query search name");
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        makePatchRequestWithParams(
            URL.QUERIES_QUERY_SAVE + "/" + queryResultsDisplayState.savedQueryId , params, onSuccessResponse, onErrorResponse);
    };

    const deleteQuerySearch = (gridApi, onSuccess, onError) => {

        const onSuccessResponse = (response) => {
            if (onSuccess) onSuccess();
            clearEntityAccounts();
            clearQueryPanelDisplayState();
            success("Query search successfully deleted");
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if (onError) onError(err);
            error("Error occurred with deleting saved query search");
        }
        makeDeleteRequest(
            URL.QUERIES_QUERY_SAVE + "/" + queryResultsDisplayState.savedQueryId, onSuccessResponse, onErrorResponse);
    };

    const exportQueryList = (type, rowData, gridApi, onSuccess, onError) => {
        const legacyHeader = getLegacyHeader(queryResultsDisplayState.querySearch.managerId);

        //Set Column definition
        hiddenExportGridApi.current.api.setColumnDefs(getColDef(!legacyHeader, queryResultsDisplayState.querySearch.queryId));
        //Set Data
        hiddenExportGridApi.current.api.setRowData(rowData || queryResultsDisplayState.resData);
        let filteredColumnNames = getFilteredColumns(queriesResultsGrid);
        //Export as csv/excel
        type==='EXCEL' ?
            hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams('Queries', filteredColumnNames)) :
                hiddenExportGridApi.current.api.exportDataAsCsv({ columnKeys: filteredColumnNames})
    }

    const getLegacyHeader = function(entity){
        if (!entity) return false;
        const entityOptions = JSON.parse(JSON.stringify(AuthenticationService.getEntityAccounts()));
        const obj = entityOptions.filter(function(item) { return item.managerId.toString() === entity.toString()});
        if(obj && obj.length >0)
            return obj[0].legacyHeader;
        else
            return false;
    }

    useEffect(() => {
        setUsePortalColumns(false);
        if (QUERY_PANEL_VIEW_QUERY_PAGE === queryPanelPageState.currentPage) {
            const legacyHeader = getLegacyHeader(queryResultsDisplayState.querySearch.managerId);
            setUsePortalColumns(legacyHeader);
            // handle the special case of 'Margin Requirement'
            if (queryResultsDisplayState.resData != null && queryResultsDisplayState.resData.length > 0
                && queryResultsDisplayState.querySearch.queryId === 'Margin Requirements'
                && 'Comment_1' in queryResultsDisplayState.resData[0])
                setColumnDefs(getColDef(!legacyHeader,queryResultsDisplayState.querySearch.queryId,1));
            else // Normal Flow
                setColumnDefs(getColDef(!legacyHeader,queryResultsDisplayState.querySearch.queryId));
            setRowData(queryResultsDisplayState.resData);
            setHandleSaveFunction({handleSave: saveQuerySearch, gridApi: null});
            setHandleRenameFunction({handleRename: updateQuerySearch, gridApi: null});
            setHandleDeleteFunction({handleDelete: deleteQuerySearch, gridApi: null});
            setHandleExportFunction({handleExport:exportQueryList, gridApi: gridApi});
        }
    },[queryPanelPageState,queryResultsDisplayState])

    const onDisplayedColumnsChanged = ()=> {
        if(gridColumnApi){
            const updatedColumnDefs = updateGridColumDefs(gridColumnApi, columnDefs)
            setColumnDefs(updatedColumnDefs)
        }
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }

    function pageTitle(){
        return (<>Query</>)
    }

    const onDataRendered = (params) => {
        setGridApi(params.api);
        params.columnApi.autoSizeAllColumns();
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    return(<>

        <Container fluid>
            <PageHeader text={pageTitle()}>
                <QueriesParamsBadge />
            </PageHeader>

            {showGridToolbar && <AgGridToolbar queryScreen={true}
                           title={queryResultsDisplayState.savedName}
                           isSaved={queryResultsDisplayState.isSavedQuery}
                           gridApi={gridApi}
                           gridColumnApi={gridColumnApi}
                           columnDefs={columnDefs} setColumnDefs={setColumnDefs}
                           paginationPageSize={paginationPageSize}
                           setPaginationPageSize={setPaginationPageSize}
                           showButton={showButton} setShowButton={setShowButton}/>}

            <div className="ag-theme-alpine">
                <AgGridReact
                    ref={queriesResultsGrid}
                    domLayout={'autoHeight'}
                    frameworkComponents={{
                        actionsRenderer: ActionsRenderer,
                    }}
                    paginationPageSize={paginationPageSize}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onDataRendered}
                    onRowDataChanged={onDataRendered}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    columnTypes={columnTypes}
                    rowData={rowData}
                    animateRows={true}
                    onPaginationChanged={onPaginationChange}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
    </>);
}