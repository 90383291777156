import React, {useContext, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import TradeListPanel from "./TradeListPanel";
import TradeActivitiesPanel from "./TradeActivitiesPanel";
import {ActionPanelContextProvider} from "context/action";
import {TradeCaptureContext, TradeCaptureContextProvider} from "./context";
import {TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE} from "./context/TradeCaptureContext";
import {SecurityContext} from "context/security";

export default function TradePanel() {

    const {tradeCaptureDisplayState,clearTradeSearchAndLoadTradingHome} = useContext(TradeCaptureContext);
    const {resetFlagRight} = useContext(SecurityContext);

    if(resetFlagRight.current){
        resetFlagRight.current = false;
        clearTradeSearchAndLoadTradingHome();
    }

    const currentComponent = function () {

        if (tradeCaptureDisplayState && tradeCaptureDisplayState.currentPage === TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE) {
            return <TradeActivitiesPanel/>
        }
        else {
            return <TradeListPanel/>
        }

    }

    return(<>
            <Container fluid>
                <Row>
                    <Col style={{padding:0}}>
                        {currentComponent()}
                    </Col>
                </Row>
                <Row>
                   <br/>
                </Row>
            </Container>
    </>);

}
