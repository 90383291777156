import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Card, Col, Collapse, Container, Row} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import useWindowDimensions from "hooks/Dimensions";
import {Link, useHistory} from "react-router-dom";
import template from "../tradecapture/template/TDPS_PORTAL_TradeUpload.xlsx";
import * as URL from "service/UrlConstant";
import {TRADES_UPLOAD_SAVE_URL} from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {TRADECAPTURE_LIST} from "routes/Routes";
import {ToastContext} from "context/Toast";
import {TradeCaptureContext} from "./context";
import {TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE} from "./context/TradeCaptureContext";
import CheckboxRenderer from "components/CheckboxRenderer";
import ResponseToast from "components/ResponseToast";
import {DEFAULT_GRID_PAGINATION_SIZE, ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE} from "utils/Constants";
import TradeUploadValidationInfo from "./TradeUploadValidationInfo";
import {API_CALL_ERR_MSG} from "../../utils/ConstantsMsg";
import {UtilsContext} from "../../context/utils";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";
import {getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";

export default function TradeUpload() {
    const {showButton, setShowButton} = useContext(UtilsContext);
    let history = useHistory();
    const {
        tradeCaptureDisplayState,
        setTradeCaptureDisplayState,
        buildTradeActivitiesSearch,
        mapTradeStatusCodeToDisplayValue
    } = useContext(TradeCaptureContext);
    const {success,alert,error} = useContext(ToastContext);
    const {makePostRequest} = useContext(SecurityContext)
    const inputFile = useRef(null);
    const [upload, setUpload] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [validData, setValidData] = useState(true);
    const [errorCount, setErrorCount] = useState(0);
    const [showValidationInfo, setShowValidationInfo] = useState(false);
    const [overrideCommRules, setOverrideCommRules] = useState(false);
    const downloadFile = useRef(null);
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const {isMobile} = useWindowDimensions();
    const {resetFlagRight} = useContext(SecurityContext);
    const myGrid = useRef();

    useEffect(() => {
        if(resetFlagRight.current){
            window.scrollTo(0, 0);
            resetFlagRight.current = false;
            setUpload(false);
        }
    },[resetFlagRight.current])

    const cellStyleChecker = (param) => {
        if(param !== null && param.length > 0) return {backgroundColor: '#F7E8E9', color:'#AE1100'}
    }

    const validateSelectedNodes = (api) => {
        let isValid = true
        let isSelectedNodeExists = false
        let errorCount = 0
        api.forEachNode((node) => {
            if (node.isSelected()===true){
                node.data['wasselected'] = true
                isSelectedNodeExists = true
                if (node.data.validationError || node.data.commissionValidationMessage){
                    isValid = false;
                    errorCount+=1
                    //return
                }
            }else{
                node.data['wasselected'] = false
            }
        }
        );
        if (isSelectedNodeExists) {
            if (!isValid) {
                setErrorCount(errorCount)
                setShowValidationInfo(true);
            }
            setValidData(isValid);
        }
        else
            setValidData(false);
    }

    const nodeSelector = (api) => {
        api.forEachNode((node) => {
            if (node.data.wasselected === undefined || node.data.wasselected === true)
                node.setSelected(true);
            }
        );
    }
    const onDataRendered = (params) => {
        setGridApi(params.api);
        nodeSelector(params.api);
        params.columnApi.autoSizeAllColumns();
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:!!isMobile,
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    const getToolTipField = (data) => {
        if (data.commissionValidationMessage && data.commissionValidationMessage.length > 0)
            return data.commissionValidationMessage;

        return data.errmsgCommission;
    }

    const getCommCellStyle = (data) => {
        if (data.commissionValidationMessage && data.commissionValidationMessage.length > 0)
            return cellStyleChecker(data.commissionValidationMessage);

        return cellStyleChecker(data.errmsgCommission);
    }


    const columnDefs = useMemo(() => [
        {headerName: 'Select', checkboxSelection:true,maxWidth :100},
        {headerName: 'Client', field: 'clientId',  tooltipField:'errmsgClientId', cellStyle:(params) => cellStyleChecker(params.data.errmsgClientId)},
        {headerName: 'Cancel', field: 'indTradeCancel',  tooltipField:'errmsgIndTradeCancel', cellStyle:(params) => cellStyleChecker(params.data.errmsgIndTradeCancel)},
        {headerName: 'Trd Type', field: 'tradeType',  tooltipField:'errmsgTradeType', cellStyle:(params) => cellStyleChecker(params.data.errmsgTradeType)},
        {headerName: 'Security', field: 'securityCode',  tooltipField:'errmsgSecurityCode', cellStyle:(params) => cellStyleChecker(params.data.errmsgSecurityCode)},
        {headerName: 'Quantity', field: 'quantity',  tooltipField:'errmsgQuantity', cellStyle:(params) => cellStyleChecker(params.data.errmsgQuantity)},
        {headerName: 'Account', field: 'accountCode',  tooltipField:'errmsgAccountCode', cellStyle:(params) => cellStyleChecker(params.data.errmsgAccountCode)},
        {headerName: 'AcctType', field: 'accountType',  tooltipField:'errmsgAccountType', cellStyle:(params) => cellStyleChecker(params.data.errmsgAccountType)},
        {headerName: 'Comm Type', field: 'commissionType',  tooltipField:'errmsgCommissionType', cellStyle:(params) => cellStyleChecker(params.data.errmsgCommissionType)},
        {headerName: 'Comm', field: 'commission',  tooltipValueGetter:(params) => getToolTipField(params.data), cellStyle:(params) => getCommCellStyle(params.data)},
        {headerName: 'Price', field: 'price',  tooltipField:'errmsgPrice', cellStyle:(params) => cellStyleChecker(params.data.errmsgPrice)},
        {headerName: 'Bkr Code', field: 'brokerCode',  tooltipField:'errmsgBrokerCode', cellStyle:(params) => cellStyleChecker(params.data.errmsgBrokerCode)},
        {headerName: 'Trade Date', field: 'tradeDate',  tooltipField:'errmsgTradeDate', cellStyle:(params) => cellStyleChecker(params.data.errmsgTradeDate)},
        {headerName: 'Settle Date', field: 'settleDate',  tooltipField:'errmsgSettleDate', cellStyle:(params) => cellStyleChecker(params.data.errmsgSettleDate)},
        {headerName: 'CCY', field: 'currency',  tooltipField:'errmsgCurrency', cellStyle:(params) => cellStyleChecker(params.data.errmsgCurrency)},
        {headerName: 'FX Rate', field: 'tradeFxRate',  tooltipField:'errmsgTradeFxRate', cellStyle:(params) => cellStyleChecker(params.data.errmsgTradeFxRate)},
        {headerName: 'Acc Int', field: 'accruedInterest',  tooltipField:'errmsgAccruedInterest', cellStyle:(params) => cellStyleChecker(params.data.errmsgAccruedInterest)},
        {headerName: 'Comments', field: 'comments',  tooltipField:'errmsgComments', cellStyle:(params) => cellStyleChecker(params.data.errmsgComments)},
    ], []);

    const handleDownloadTemplate = ()=> {
        downloadFile.current.click()
    }

    const uploadComponent = function() {

            return(
                <Card id="uploadCard" onClick={handleUploadCardClick}  className="td-bg-colour-7 p-5" align="center">
                    <Card.Title><h1>Upload</h1></Card.Title>
                    <Card.Text>
                        Upload the trade upload file.
                    </Card.Text>
                    <i className="td-icon-colour-primary td-icon icon-large td-icon-downloadCertificate icon-rotate"></i>
                </Card>
            );
    }

    const handleUploadCardClick = () => {
        inputFile.current.click()
    }

    const handleUploadFile = (event) => {

        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        document.getElementById('file').value = null;
        setRowData([]);
        const onSuccessResponse = (response) => {
            setRowData(response.data);
            setUpload(true);
            setCancel(false);
        }

        const onErrorResponse = (err) => {
            console.error("Error on file upload", err)
            let errMsg = API_CALL_ERR_MSG
            if (err.response.status === 400)
                errMsg = err.response.data
            error(errMsg)
            setCancel(true)
        }

        makePostRequest(formData,
            URL.TRADES_UPLOAD_VALIDATE_URL, onSuccessResponse, onErrorResponse);

    }

    const handleProcessFile = () => {
        const selectedData = gridApi.getSelectedRows();
        const data = [];
        selectedData.map(e => {
            let settleDate = !!e.settleDate?e.settleDate:null;
            let tradeObj = {
                "fileName": e.fileName,
                "overrideCommissionRules": overrideCommRules,
                "clientId": e.clientId,
                "indTradeCancel": e.indTradeCancel,
                "tradeType": e.tradeType,
                "quantity" : e.quantity,
                "securityCode" : e.securityCode,
                "accountCode" : e.accountCode,
                "accountType" : e.accountType,
                "commissionType" : e.commissionType,
                "commission" : e.commission,
                "price" : e.price,
                "brokerCode" : e.brokerCode,
                "tradeDate" : e.tradeDate,
                "settleDate" : settleDate,
                "currency" : e.currency,
                "tradeFxRate" : e.tradeFxRate,
                "accruedInterest" : e.accruedInterest,
                "comments" : e.comments
            };
            data.push(tradeObj);
        })

        const onSuccessResponse = (response) => {
            success("Process Trades Processed successfully");
            let tradeCaptureDisplayStateShowActivities = JSON.parse(JSON.stringify(tradeCaptureDisplayState));

            if (!response.data || response.data.length === 0) {
                tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.resData = [];
                setTradeCaptureDisplayState(tradeCaptureDisplayStateShowActivities);
                alert('No Trade activities found matching your search');
            } else {
                let searchCriteria = response.data[0];
                tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch = buildTradeActivitiesSearch(
                    searchCriteria.managerId, searchCriteria.managerName, searchCriteria.subManagerId, searchCriteria.subManagerNameShort,
                    searchCriteria.accountId, searchCriteria.accountCode, searchCriteria.accountName, mapTradeStatusCodeToDisplayValue(searchCriteria.status),
                    searchCriteria.tradeDate.toString(), searchCriteria.settleDate.toString(), response.data);

                tradeCaptureDisplayStateShowActivities.currentPage = TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE;
                tradeCaptureDisplayStateShowActivities.isSavedTradeList = false;
                setTradeCaptureDisplayState(tradeCaptureDisplayStateShowActivities);
            }
            history.push(TRADECAPTURE_LIST);
        }

        const onErrorResponse = (err) => {
            error("Error occurred while processing Trade upload file. Please check data validity.");
            console.log('Error in processing Trade Upload.');
        }

        makePostRequest(data,
            TRADES_UPLOAD_SAVE_URL, onSuccessResponse, onErrorResponse);
    }

    const clearCommErrors = (row) => {
        let validationError = false
        let commErrObj = {}
        let newrow = Object.fromEntries(
        Object.entries(row).map(item => {

            if (item[0].startsWith("errmsg") && item[1]){
                validationError = true;
            }
            if (item[0] === "commissionValidationMessage" && item[1]){
                commErrObj = {commErrObj:JSON.parse(JSON.stringify(item))}
                item[1]  = null;
            }
            return item;
        }));

        let resetCommErrorsRow = {...newrow,"validationError":validationError,...commErrObj}
        return resetCommErrorsRow;
    }

    const restoreCommErrors = (row) => {
        console.log('Inside restoreCommErrors ',row);
        let newrow = Object.fromEntries(
            Object.entries(row).map(item => {

                if (item[0] === "commissionValidationMessage" && row.commErrObj){
                    item[1]  = row.commErrObj[1];
                }
                return item;
            }));

        let resetCommErrorsRow = {...row,...newrow}
        return resetCommErrorsRow;
    }

    const handleCommOverrideChange = (e) => {
        setOverrideCommRules(e.target.checked);
        let currData = {}
        currData = rowData.map(row => {
            let replaceObj =  {}
            if (e.target.checked) {
                replaceObj = clearCommErrors(row);
            }else{
                replaceObj = restoreCommErrors(row);
            }
            return {...row,...replaceObj}
        })

        setRowData(currData);
    }

    const onRowSelected = (params) => {
        validateSelectedNodes(params.api)
    }
    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    return(<>
        <Container fluid>
            <Row className="py-4">
                <Col xs={12} className="pl-4">
                    <h1>Trade Upload</h1>
                </Col>
            </Row>
            <Collapse in={!upload} timeout={0}>
                <Row>

                    <Col xs={12} sm={12} md={12} lg={6} className="px-4 pb-3 pt-0">
                        <input type='file' id='file'
                               accept=".xlsx,.csv,.xls"
                               ref={inputFile} onChange={(e) => handleUploadFile(e)} style={{display: 'none'}}/>
                        {uploadComponent()}
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={6} className="px-4 pb-3 pt-0">
                        <Card className="td-bg-colour-7 p-5" align="center"  onClick={handleDownloadTemplate} style={{cursor:'pointer'}}>
                            <Link to={template} target="_blank" download style={{display:'none'}} ref={downloadFile}/>
                            <Card.Title><h1>Download Template</h1></Card.Title>
                            <Card.Text>
                                Download the trade upload template.
                            </Card.Text>
                            <i className="td-icon-colour-primary td-icon icon-large td-icon-downloadCertificate"></i>
                        </Card>
                    </Col>
                </Row>

            </Collapse>
            <Collapse in={upload} timeout={0}>

                <Row>
                    <Col xs={12}>
                        <h4>
                            <input type="checkbox" onChange={(e) => handleCommOverrideChange(e) }/>
                             Override Commission Validation Rules
                        </h4>
                    </Col>

                    <Col xs={12}>
                        <div

                            id="myGrid"
                            className="ag-theme-alpine">
                            <AgGridReact
                                ref={myGrid}
                                frameworkComponents={{
                                    checkboxRenderer: CheckboxRenderer
                                }}
                                paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                                domLayout={"autoHeight"}
                                groupDefaultExpanded={'1'}
                                enableRowGroup={false}
                                suppressRowClickSelection={true}
                                suppressDragLeaveHidesColumns={true}
                                suppressMakeColumnVisibleAfterUnGroup={true}
                                suppressModelUpdateAfterUpdateTransaction={true}
                                suppressScrollOnNewData={true}
                                suppressAggFuncInHeader={true}
                                allowShowChangeAfterFilter={false}
                                rowGroupPanelShow={'never'}
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                rowData={rowData}
                                animateRows={true}
                                rowSelection={'multiple'}
                                onFirstDataRendered={onDataRendered}
                                onRowDataChanged={onDataRendered}
                                onRowSelected={onRowSelected}
                                showDisabledCheckboxes={true}
                                onPaginationChanged={onPaginationChange}
                            >
                            </AgGridReact>
                        </div>
                    </Col>
                </Row>
            </Collapse>
            <Collapse in={upload} timeout={0}>
            <Row className="pt-3">
                <Col lg={10} className="d-none d-lg-block"></Col>
                <Col xs={6} sm={6} md={6} lg={1} className="pt-2">
                    <button className="btn btn-block td-btn-secondary-clear" href="#" onClick={()=>{setUpload(false); }}>Cancel</button>
                </Col>
                <Col xs={6} sm={6} md={6} lg={1} className="pt-2">
                    <button className="btn btn-block td-btn-primary-light" href="#" disabled={!validData} onClick={handleProcessFile}>Process</button>
                </Col>
            </Row>
            </Collapse>
            <TradeUploadValidationInfo show={showValidationInfo} errorCount={errorCount} onHide={()=>setShowValidationInfo(false)}></TradeUploadValidationInfo>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
        <ResponseToast isFullWidth={true}/>
    </>);

}