import {Col, Container, Row, ListGroup} from "react-bootstrap";
import React, {useContext, useState} from "react";
import useWindowDimensions from "hooks/Dimensions";
import {ActionPanelContext} from "context/action";
import {formatDateString} from "../../utils/FormatUtils";

export default function CancelTradeModal(props) {

    const {setShowCancelTrade, setShow, selectedRows} = props
    const {isDesktop, isMobile} = useWindowDimensions();
    const {handleTradeCancel} = useContext(ActionPanelContext);
    const [canceling, setCanceling] = useState(false);

    const makePlural = ()=> selectedRows.length > 1 ? 's' : '';

    return (<div className='modal-container'>
        <Container fluid className={props.isActionColBtn?"d-none":"my-3 my-md-0"}>
            <Row>
                <Col md={12}>
                    <h4 style={{textAlign: isDesktop?'left':'center'}}>{`Are you sure you want to cancel the selected trade${makePlural()}?`}</h4>
                    <p>{`Selected Trade${makePlural()}:`}</p>
                    <ListGroup>
                        {selectedRows.map((row, index)=>(
                            <ListGroup.Item style={{border:'none'}} key={index}>
                                {formatDateString(`${row.tradeDate}`, 'MM/DD/YYYY')}
                                , {row.accountCode}, {row.tradeBridgeTradeId}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    <br/>
                </Col>
            </Row>
            <Row className={"justify-content-end pr-4"}>
                <Col md={6} xs={12}>
                    <button disabled={canceling} className='btn btn-block td-btn-secondary-clear' href="#" onClick={() => {
                        setShow(false);
                        setShowCancelTrade(false);
                    }}>Cancel
                    </button>
                </Col>
                <Col md={6} xs={12}>
                    <button disabled={canceling} className='btn btn-block td-btn-red' href="#" onClick={() => {
                        const onSuccess = () => {
                            setShow(false);
                            setShowCancelTrade(false);
                        }

                        const onError = (err) => {
                            setShow(false);
                            setShowCancelTrade(false);
                        }

                        setCanceling(true);
                        handleTradeCancel(selectedRows, onSuccess, onError);
                    }}>Cancel Trades
                    </button>
                </Col>
            </Row>
        </Container>
    </div>)
}
CancelTradeModal.defaultProps = {
    confirmCancelBtnClassName: "btn btn-block td-btn-red"
}
