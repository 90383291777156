import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import RightArrowButton from "./RightArrowButton";
import useWindowDimensions from "../../../hooks/Dimensions";

export default function RenameButton(props) {
    const {isTab,isTabRotated} = useWindowDimensions();

    return (<>
        <Container fluid className={props.isActionColBtn?"d-none":"m-0 p-0"} style={{cursor:'pointer'}}>
            <Col className={'d-none d-sm-block p-0 m-0'}>
                <Container fluid className={"justify-content-start navbar"}>
                    <Row>
                        <Col style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingRight: 5,
                            paddingLeft:5,
                            textAlign: "center",
                            alignSelf: "center",
                            whiteSpace: "nowrap"
                        }}>
                            <b><span className={isTab || isTabRotated?"rename":""}>&nbsp;&nbsp;{props.title}&nbsp;&nbsp;</span></b>
                        </Col>
                        <Col style={{padding: 0, paddingRight: 5, paddingLeft:0, textAlign: "center", alignSelf: "center"}}>
                            <b><span className="td-icon-colour-primary td-icon icon-small td-icon-edits" onClick={props.onRenameClick}></span></b>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Col className={'p-0 m-0 d-block d-sm-none'}>
                <Row onClick={props.onRenameClick}
                     className={`d-sm-none py-0 ${props.editable?'':'d-none'}`}>
                    <Col xs={6} className={'py-2 pr-2'} style={{alignSelf: 'center', alignItems: 'center'}}>
                        <Row style={{float: 'left'}}>
                            <Col style={{paddingRight: 5, paddingTop: 0, paddingBottom: 0, textAlign: "center", alignSelf: "center"}}>
                            </Col>
                            <Col style={{padding: 0, paddingRight: 5, textAlign: "center", alignSelf: "center"}}>
                                <>Rename</>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={6} className={'pt-0 pb-2 pr-2'} style={{alignSelf: 'center'}}> <RightArrowButton/></Col>
                    <Col xs={12} className={'py-2 pl-3 pr-0'} style={{alignSelf: 'center'}}>
                        <hr/>
                    </Col>
                </Row>
                <Row className={props.editable?'d-none':''}>
                    <Col style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: 5,
                        paddingLeft:5,
                        textAlign: "center",
                        alignSelf: "center",
                        whiteSpace: "nowrap"
                    }}>
                        <b><span className="rename">&nbsp;&nbsp;{props.title}&nbsp;&nbsp;</span></b>
                    </Col>
                </Row>
            </Col>
        </Container>
        <Container fluid className={!props.isActionColBtn?"d-none":"m-0 p-0"}>
            <button className="btn btn-block td-btn-primary-light my-3" href="#" onClick={props.onRenameClick}>
                Rename
            </button>
        </Container>
    </>)
}