import {Col, Row} from "react-bootstrap";
import React from "react";
import {useHistory} from "react-router-dom";
import * as Routes from "routes/Routes";
import RightArrowButton from "./RightArrowButton";

export default function SSARequestListButton(props){
    let history = useHistory();
    function navigateToPage(path) {
        history.push(path);
    }
    return (<>
        <a href="#" onClick={()=>navigateToPage(Routes.SHORTSALE_APPROVAL_HISTORY)} className={'d-none d-sm-block'}>Back </a>
        <Row onClick={ ()=>navigateToPage(Routes.SHORTSALE_APPROVAL_HISTORY)}
             className={'d-sm-none'}>
            <Col xs={6} className={'py-2 pl-4'} style={{alignSelf: 'center'}}><p style={{float:'left'}}>Back </p></Col>
            <Col xs={6} className={'py-2 pr-2'} style={{alignSelf: 'center'}}> <RightArrowButton/></Col>
            <Col xs={12} className={'py-2 pr-0 pl-3'}>
                <hr/>
            </Col>
        </Row>
    </>);
}
