import React  from "react";

export default function CheckboxRenderer(props) {
    const checkedHandler=(event)=> {
        let checked = event.target.checked;
        let colId = props.column.colId;
        props.node.setDataValue(colId, checked);
    }

    return (
        <input
            type="checkbox"
            onClick={checkedHandler}
            checked={props.value}
        />
    );
}
