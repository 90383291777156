import {AgGridReact} from "ag-grid-react";
import React, {useContext} from "react";
import {UtilsContext} from "context/utils";

export default function ExportGridToCsv(props) {
    const {hiddenExportGridApi} = useContext(UtilsContext);
    return (<>
    <div id="exportGridHidden">
        <AgGridReact
            ref={hiddenExportGridApi}
        >
        </AgGridReact>
    </div>
        </>);

}