import React from 'react';
import PropTypes from 'prop-types';

export default function ActionsApproveRejectRenderer(props) {
    const {clicked, action1, onApproveActionClicked, action2, onRejectActionClicked, showApproveFun, showRejectFun, showApproveRejectFun } = props;
    return (
        <>
            <div className={'d-md-block d-sm-none d-none'}>
                {showApproveFun(props.value) && <a className="td-link mr-4" href="#" onClick={()=>onApproveActionClicked(props.value)}>{action1}</a>}
                {showRejectFun(props.value) && <a className="td-link mr-4" href="#" onClick={()=>onRejectActionClicked(props.value)}>{action2}</a>}
            </div>
            <div className={'d-md-none d-sm-block d-block'} style={{textAlign: 'center'}}>
                <a className={showApproveRejectFun(props.value)?'td-link':'td-link disable'} href="#responsive">
                    <i className='td-icon-colour-primary td-icon icon-regular  td-icon-all'
                       onClick={() => clicked(props.value)}></i>
                </a>
            </div>
        </>
    );
}

ActionsApproveRejectRenderer.propTypes = {
    onActionIconClick: PropTypes.func
}

ActionsApproveRejectRenderer.defaultProps = {
    action1: "Approve", action2: "Reject"
}