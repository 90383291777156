import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import ActionModal from "components/ActionModal";
import useWindowDimensions from "hooks/Dimensions";
import InfoModal from "./InfoModalBody";

export default function InfoLayoutTradeBreak(props) {

    const {show, setShow,popUpData, actionForAccount, secondTitle} = props;
    const {isDesktop, isTab, isMobile} = useWindowDimensions();

    const actionModel=()=> {
        if(show) return <InfoModal show={show} setShow={setShow} popUpData={popUpData} />
    }

    const actionTitle=()=> {
        if(show) return `Action for ${actionForAccount}`
    }

    const handleHide = ()=>{
        setShow(false);
    }

    return (<>
        <Container fluid>
            <Row>
                <Col xs={"12"}>
                    <ActionModal body={actionModel} title={actionTitle} show={show} onHide={handleHide} dialogClassName={isTab?'custom-dialog' : isMobile? 'fullscreen-dialog':''} centered={isDesktop}  secondTitle={secondTitle}/>
                </Col>
            </Row>
        </Container>
    </>)
}
