import {Row, Col, Container, Form} from "react-bootstrap";
import refreshlogo from "../../assets/image/refresh.svg";
import React, {createRef, useContext, useState} from "react";
import useWindowDimensions from "hooks/Dimensions";
import {ActionPanelContext} from "context/action";

export default function RefreshOptionsModal(props) {
    const {isMobile} = useWindowDimensions();
    const {handleRefresh} = useContext(ActionPanelContext);
    const pageStatus = createRef();
    const [selectedStatus, setSelectedStatus] = useState(props.refreshCriteria?.status)

    const onRefreshClicked = () => {
        const onSuccess = () => {
        }
        const onError = () => {
        }

        props.refreshCriteria.status = selectedStatus;
        handleRefresh(props.refreshCriteria, onSuccess, onError);
    }

    const onStatusDropdownChange = () => {
        setSelectedStatus(pageStatus.current.value)
        setGridApi(pageStatus.current.value)
    }

    const onRadioButtonChange = (e) => {
        setSelectedStatus(e.currentTarget.id)
        props.refreshCriteria.status = e.currentTarget.id;
    }

    const onApplyButtonClicked = () => {
        props.setShow(false);
        props.setShowRefreshOptions(false);
        props.setShowRightSideOptions(false);
        setGridApi(selectedStatus)
    }

    const setGridApi = (status) => {
        props.gridApi.setRowData(status === "All" ? props.rowData : props.rowData.filter(e => e.status === status))
    }

    return (<>
        <Col style={{padding: 0, paddingRight: 20, textAlign: "center", alignSelf: "center"}}
             className="text-nowrap d-none d-sm-block">Refresh Options</Col>
        <Col style={{padding: 0, paddingRight: 5}} className={' d-none d-sm-block'}>
            <Form.Control ref={pageStatus} as="select" className={'unset-custom-arrows'}
                          defaultValue="All" onChange={onStatusDropdownChange}
                          style={{padding: 0, width: 'auto', height: 30}}
            >
                <option value={"All"}>All</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Processed"}>Complete</option>
                <option value={"Rejected"}>No Good</option>
                <option value={"Partial"}>Partial</option>
            </Form.Control>
        </Col>
        <Col style={{padding: 0, paddingLeft: 5, paddingRight: 10, textAlign: "center", alignSelf: "center"}}
             className={' d-none d-sm-block'}>
            <button style={{border: 'none', background: "none"}} onClick={onRefreshClicked}
            >
                <img
                    src={refreshlogo}
                    height="25"
                    className="d-inline-block"
                    alt="React Bootstrap logo"
                />
            </button>
        </Col>
        <Col md={4} xs={12} className={'p-0 m-0 d-sm-none'}>
            <Container className="py-0">
                <Form onSubmit={(e) => e.preventDefault()}>
                    <Form.Check type="radio" id='All' label='All' checked={selectedStatus === 'All'} name='status'
                                className='m-0' onChange={onRadioButtonChange}/>
                    <Form.Check type="radio" id='Pending' label='Pending' checked={selectedStatus === 'Pending'}
                                name='status' className='mt-2' onChange={onRadioButtonChange}/>
                    <Form.Check type="radio" id='Processed' label='Complete' checked={selectedStatus === 'Processed'}
                                name='status' className='mt-2' onChange={onRadioButtonChange}/>
                    <Form.Check type="radio" id='Kill' label='No Good' checked={selectedStatus === 'Kill'}
                                name='status' className='mt-2' onChange={onRadioButtonChange}/>
                    <Form.Check type="radio" id='Partial' label='Partial' checked={selectedStatus === 'Partial'}
                                name='status' className='mt-2' onChange={onRadioButtonChange}/>
                    <Row className={"justify-content-end"}>
                        <Col md={4} xs={11} sm={12} className={isMobile ? 'stick-to-bottom' : ''}>
                            <button href="#" className='btn btn-block td-btn-primary-light'
                                    onClick={onApplyButtonClicked}>Apply
                            </button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Col>
    </>);
}
