import React, {useContext} from 'react';
import {Badge, Container} from "react-bootstrap";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import {ReportsPanelContext} from "./context";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {pickerDateFormatToDate} from "utils/FormatUtils";

export default function ReportSearchParamBadge() {

    const {reportsPdfDisplayState, clearReportSearchAndLoadReportsHome} = useContext(ReportsPanelContext);
    const {
        clearEntityAccounts,
    } = useContext(EntityAccountsContext);

    const settings = {
        infinite: false,
        speed: 500,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: Number.MAX_VALUE,
                settings: "unslick"
            },
            {
                breakpoint: 767,
                settings: "slick"
            }
        ]
    };

    const handleClear = (e) => {
        clearEntityAccounts();
        clearReportSearchAndLoadReportsHome();
    }

    return (<>
        <Container fluid style={{width: "100%"}} className="justify-content-start pt-0 px-md-0">
            <Slider {...settings}>
                <span>
                    <Badge
                        className={'badge-outline'}>{reportsPdfDisplayState.reportPdfSearchData.managerName}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{reportsPdfDisplayState.reportPdfSearchData.accountName}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{pickerDateFormatToDate(reportsPdfDisplayState.reportPdfSearchData.effectiveDate)}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{reportsPdfDisplayState.reportPdfSearchData.folder}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{reportsPdfDisplayState.reportPdfSearchData.fileName}</Badge>
                </span>
                <span>
                    <Badge className={'badge-button'} onClick={handleClear}>
                        CLEAR
                    </Badge>
                </span>
            </Slider>
        </Container>

    </>)
}
