import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import useWindowDimensions from "hooks/Dimensions";
import {TradeCaptureContext, TradeBreakContext} from "./context";
import {yearMonthDayToPickerDateFormat} from "utils/FormatUtils";
import {TRADES_LIST_TRADE_BREAKS} from "service/UrlConstant";
import {SecurityContext} from "context/security";
import AuthenticationService from "service/AuthenticationService";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {DEFAULT_GRID_PAGINATION_SIZE} from "utils/Constants";
import InfoLayoutTradeBreak from "./InfoLayoutTradeBreak";
import AgGridToolbar from "components/AgGridToolbar/AgGridToolbar";


export default function TradeBreaks(){
    const {popUpData, setPopUpData, selectedBrkType, setSelectedBrkType, tradeBreakTypeHashmap, accountCodeMapping, columnDefsTradeBreaks, showInfoModal, setShowInfoModal,getReasonEquivalentOfField, cellStyleChecker} = useContext(TradeBreakContext);
    const {tradeCaptureDisplayState, setTradeCaptureDisplayState} = useContext(TradeCaptureContext);
    const {setManagerDetails, setEntityAccountsOptions, setAccountsOptions} = useContext(EntityAccountsContext);
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [columnDefs, setColumnDefs] = useState([]);
    const [gridColumnApi,setGridColumnApi] = useState(null);

    const myGrid = useRef(null);
    const [actionForAccount, setActionForAccount] = useState('');
    const {isMobile} = useWindowDimensions();

    useEffect(() => {
        setupActionColumns();
    }, [])


    const {
        makeGetRequest
    } = useContext(SecurityContext);

    useEffect(()=>{
        loadTradeBreaksList()
    },[])

    const loadTradeBreaksList = () => {
        const onSuccess = (response) => {
            if(!!response.data && response.data.length > 0){
                setRowData([...response.data]);
            }
        }
        const onError = (err) => {
            console.error("Trade Breaks data",err);
        }

        makeGetRequest(TRADES_LIST_TRADE_BREAKS, {
        }, onSuccess, onError);
    };


    const setupActionColumns = () => {
        let updatedColumnDefs = [...columnDefsTradeBreaks];
        updatedColumnDefs.splice(0, 0 ,{headerName: '', cellRenderer: 'viewCellRenderer', suppressColumnsToolPanel:true, pinned:'left', width:45,cellRendererParams: {
                clicked: (props) => onInfoClicked(props)
            }});
        setColumnDefs(updatedColumnDefs);
    }

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:isMobile?true:false,
        width:'auto',
        cellStyle: cellStyleChecker
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }

    const onViewClicked = (props) => {
        const newTradeState = JSON.parse(JSON.stringify(tradeCaptureDisplayState));
        newTradeState.tradeEntry.managerId = props.managerId
        newTradeState.tradeEntry.accountId = props.accountId
        newTradeState.tradeEntry.accountType = props.accountType
        newTradeState.tradeEntry.tradeType = props.tranType.trim()
        newTradeState.tradeEntry.quantity = Number(props.quantity.trim())
        newTradeState.tradeEntry.securityOption = props.securityOption
        newTradeState.tradeEntry.security = props.security
        newTradeState.tradeEntry.securityOptionSelected = props.security
        newTradeState.tradeEntry.price = props.price
        newTradeState.tradeEntry.status = props.status
        newTradeState.tradeEntry.currency = props.currency ? props.currency.trim() : -1
        newTradeState.tradeEntry.brokerCode = props.brokerCode ? props.brokerCode.trim() : ''
        newTradeState.tradeEntry.brokerDescription = props.brokerCode ? props.brokerCode.trim() : ''
        newTradeState.tradeEntry.commissionType = props.commissionType
        newTradeState.tradeEntry.commission = props.commission
        newTradeState.tradeEntry.tradeDate = props.tradeDate ? yearMonthDayToPickerDateFormat(props.tradeDate.toString()) : ''
        newTradeState.tradeEntry.settleDate = props.settleDate ? yearMonthDayToPickerDateFormat(props.settleDate.toString()) : ''
        newTradeState.tradeEntry.accruedInterest = props.accruedInterest
        newTradeState.tradeEntry.comments = props.comments
        newTradeState.tradeEntry.clientId = props.clientId
        newTradeState.tradeEntry.openClose = props.openClose
        newTradeState.tradeEntry.indTradeCancel = props.tradeCancelIndicator
        newTradeState.tradeEntry.fxUSDRate = props.fxUSDRate


        setTradeCaptureDisplayState(newTradeState)

        const userEntityAccounts = AuthenticationService.getEntityAccounts();
        setEntityAccountsOptions(JSON.parse(JSON.stringify(userEntityAccounts)));
        setManagerDetails(props.managerId,"")
        let accountsForEntity =  userEntityAccounts.find(({ managerId }) => managerId.toString() === props.managerId.toString());
        if(!!accountsForEntity)
        setAccountsOptions(JSON.parse(JSON.stringify(accountsForEntity.accounts)));
    }

    const onInfoClicked = (field) => {
        const popUpDataState = JSON.parse(JSON.stringify(popUpData));
        setShowInfoModal(true);
        setSelectedBrkType('Break Type : '+tradeBreakTypeHashmap[field.data.breakType]);
        Object.entries(field.data).map(([key, value]) => {
            popUpDataState.map(e=>{
                if(key.replace('client','').toLowerCase()===e.field.toLowerCase()){
                    e.client = value;
                    e.isBreak = field.data.clientReason.includes(getReasonEquivalentOfField(e.field))?true:false
                }
                else if(key.replace('broker','').toLowerCase()===e.field.toLowerCase()){
                    e.broker = value;
                    e.isBreak = field.data.brokerReason.includes(getReasonEquivalentOfField(e.field))?true:false
                }
            })
        })
        setPopUpData(popUpDataState)
        setActionForAccount(accountCodeMapping(field));
    }


    const viewCellRenderer = function (props){
        return (<>
            {(tradeBreakTypeHashmap[props.data['breakType']] === 'Match with Differences' || tradeBreakTypeHashmap[props.data['breakType']] === 'Client Only')  &&
                <a className="td-link" href="#" onClick={() => {
                    //onViewClicked(props.data);
                    onInfoClicked(props)
                }}>View</a>
            }
        </>);
    }

    const rowClassRules = useMemo(() => {
        return {
            'grey-out': (params) => {
                return params.data.isCorrected>0;
            },

        };
    }, []);

    const sizeToFit = useCallback(() => {
        gridRef.current.api.sizeColumnsToFit({
            defaultMinWidth: 100,
            columnLimits: [{ key: 'country', minWidth: 900 }],
        });
    }, []);

    return(<>

        <Container fluid style={{ height: '100%' }} >
            <Row className="pt-3 pb-2 pt-md-4 pb-md-3">
                <Col>
                    <h1>Trade Breaks</h1>
                </Col>
            </Row>
            <Row className="pb-1 pb-md-2">
            </Row>
            {<AgGridToolbar
                tradeBreak={true}
                gridApi={gridApi}
                gridColumnApi={gridColumnApi}
                sizeToFit = {sizeToFit}
                columnDefs = {columnDefs}
                setColumnDefs = {setColumnDefs}
                >
            </AgGridToolbar>}
            <div
                style={{ height: '100%' }}
                ref={myGrid}
                id="myGrid"
                className="ag-theme-alpine">
                <AgGridReact
                    ref={gridRef}
                    pagination={true}
                    paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                    frameworkComponents={{
                        viewCellRenderer:viewCellRenderer,
                    }}
                    domLayout={"autoHeight"}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={true}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    rowClassRules={rowClassRules}
                >
                </AgGridReact>
            </div>
        </Container>
        <InfoLayoutTradeBreak show={showInfoModal} setShow={setShowInfoModal} popUpData={popUpData} actionForAccount={actionForAccount} secondTitle={selectedBrkType}/>
    </>);
}


