import React from 'react';
import SidePanelLayout from "layouts/SidePanelLayout";
import ReportFilter from "./ReportFilter";
import ReportPanel from "./ReportPanel";
import {ReportsPanelContextProvider} from "./context";

export default function Reports() {

    return (<>
            <SidePanelLayout
                sideComponent={ReportFilter}
                mainComponent={ReportPanel}>
            </SidePanelLayout>
    </>);

}
