
export function createExcelExportParams (sheetName, columnNames) {
    if (typeof sheetName === 'object' || sheetName == null || sheetName === '') {
        sheetName = 'Customer Portal'; //Max is 31 chars
    }
    return {sheetName: sheetName, columnKeys: columnNames};
}

export const getFilteredColumns = (grid) => {
    let filteredColumns = (grid && grid.current.columnApi )?
        [...grid.current.columnApi.getAllDisplayedColumns()]: [];
    return filteredColumns.length === 0 ? [] : filteredColumns.map(item => {
        return item.getColId()
    });
}