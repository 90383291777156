import React, {useContext} from 'react';
import {Badge, Container} from "react-bootstrap";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import {ShortSaleContext} from "./context";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {pickerDateFormatToDate} from "utils/FormatUtils";
import AuthenticationService from "../../service/AuthenticationService";

export default function ShortSaleParamsBadge(props) {
    const {onPageRequest} = props;
    const {shortSaleApprovalSearchDisplayState,clearShortSaleState, showSearch, clearShortSaleApprovalSearchDisplayState, loadShortSaleApprovals, setShowBadge} = useContext(ShortSaleContext);
    const {entityAccounts, clearEntityAccounts} = useContext(EntityAccountsContext);
    const userEntities = AuthenticationService.getEntityAccounts();

    const settings = {
        infinite: false,
        speed: 500,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: Number.MAX_VALUE,
                settings: "unslick"
            },
            {
                breakpoint: 767,
                settings: "slick"
            }
        ]
    };

    const clearSelection = (e) => {
        showSearch ? clearEntityAccounts() : clearEntityAccounts(false, false)
        clearShortSaleState();
        clearShortSaleApprovalSearchDisplayState();
        onPageRequest('default');
        loadShortSaleApprovals(userEntities[0])
        setShowBadge(false)
    }

    return (<>

        <Container fluid style={{width: "100%"}} className="justify-content-start pt-0 px-md-0">
            <Slider {...settings}>
                <span>
                    <Badge
                        className={'badge-outline'}>{entityAccounts.managerName}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{shortSaleApprovalSearchDisplayState?.searchCriteria.badgeInfo}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{shortSaleApprovalSearchDisplayState?.searchCriteria.security}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{pickerDateFormatToDate(shortSaleApprovalSearchDisplayState?.searchCriteria.fromProcessingDate)}</Badge>
                </span>
                <span>
                    <Badge
                        className={'badge-outline'}>{pickerDateFormatToDate(shortSaleApprovalSearchDisplayState?.searchCriteria.toProcessingDate)}</Badge>
                </span>
                <span>
                    {shortSaleApprovalSearchDisplayState &&
                        <Badge className={'badge-button'} onClick={clearSelection}>
                            CLEAR
                        </Badge>
                    }
                </span>
            </Slider>
        </Container>
    </>)
}