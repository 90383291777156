import React, {useContext, useEffect, useState,useRef, useCallback} from 'react';
import {Container} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import ActionsRenderer from "components/ActionsRenderer";
import AgGridToolbar from "components/AgGridToolbar/AgGridToolbar";
import WiresParamsBadge from "./WiresParamsBadge";
import PageHeader from "components/PageHeader";
import useWindowDimensions from "hooks/Dimensions";
import {WiresPanelContext} from "./context";
import {ActionPanelContext} from "context/action";
import {SecurityContext} from "context/security";
import AuthenticationService from "service/AuthenticationService";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {dateToString, stringToDate} from "utils/FormatUtils";
import {UtilsContext} from "context/utils";
import {ToastContext} from "context/Toast";
import {WIRE_PANEL_VIEW_WIRE_PAGE} from "./context/WiresPanelContext";
import {
    ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE,
    GRID_COLUMN_PROPERTY
} from "utils/Constants";
import {createExcelExportParams, getFilteredColumns} from "../../utils/ExcelExportUtils";
import ActionsApproveRejectRenderer from "../../components/ActionsApproveRejectRenderer";
import ActionApproveRejectPanelLayout from "../../layouts/ActionApproveRejectPanelLayout";
import {
    WIRES_WIRE_SEARCH,
    WIRES_LIST,
    WIRES_WIRES_LIST_SAVE,
    WIRES_APPROVE_REJECT_VALIDATE_AND_APPROVE_REJECT
} from "service/UrlConstant";
import Service from "../../service/Service";
import {cellAlignment, numberFormatter} from "../../utils/NumberUtils";
import {
    addPropertiesToGridColumns,
    getDisplayedNumberOfGridRows,
    updateGridColumDefs
} from "../../utils/gridColumnUtils";
import ApproveRejectRenderer from "./ApproveRejectRenderer";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";
export default function WiresResultPanel(props) {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [columnDefs, setColumnDefs] = useState([]);
    const {isDesktop,isMobile} = useWindowDimensions();
    const [show, setShow] = useState(false);
    const [approveEnabledOnWire, setApproveEnabledOnWire] = useState(false)
    const [rejectEnabledOnWire, setRejectEnabledOnWire] = useState(false)
    const [modalTitle, setModalTitle] = useState('');
    const [showApprove, setShowApprove] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [actionForWire, setActionForWire] = useState('');
    const {wireResultsDisplayState,setWireResultsDisplayState,clearWirePanelDisplayState,
        wirePanelPageState,setHandleApproveFunction,setHandleRejectFunction,wirePanelFilterState, showGridToolbar} = useContext(WiresPanelContext)
    const {setHandleSaveFunction,setHandleDeleteFunction, setHandleRenameFunction, setHandleExportFunction,setSavedSearchName} = useContext(ActionPanelContext)
    const {
        makeDeleteRequest,
        makePatchRequestWithParams,
        makePostRequest
    } = useContext(SecurityContext);
    const {clearEntityAccounts} = useContext(EntityAccountsContext);
    const {hiddenExportGridApi,paginationPageSize, setPaginationPageSize, showButton, setShowButton} = useContext(UtilsContext);
    const {success, error,alert} = useContext(ToastContext);

    const lookupActionApproveReject = (key) => {
        let isApproveEnabled = key.isApproveEnabled?key.isApproveEnabled===1:false
        let isRejectEnabled = key.isRejectEnabled?key.isRejectEnabled===1:false

        return isApproveEnabled || isRejectEnabled
    };
    const lookupActionApprove = (key) => {
        let isApproveEnabled = key.isApproveEnabled?key.isApproveEnabled===1:false
        return isApproveEnabled
    };
    const lookupActionReject = (key) => {
        let isRejectEnabled = key.isRejectEnabled?key.isRejectEnabled===1:false
        return isRejectEnabled
    };
    const columnTypes ={
        actionColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            pinned:isDesktop?'':'right',
            width:isDesktop?250:100,
        },
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
        }
    }

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:!!isMobile,
    };

    const statusCellRules = {
        'red-cell': params => params.value === 'Rejected',
        'green-cell': params => params.value === 'Completed',
        'orange-cell': params => params.value === 'Pending',
    };

    const LogItem = function (params){
        return  <>{params.value}<br /></>
    }

    const logRenderer = function (props){
        const logArray = props.data.prevComments.toString().split("\r\n");

        return(<>
            {
                logArray.map( (log,i) => {
                    return <LogItem value ={log}/>
                })}
        </>);
    }

    const instructionsRenderer = function (props){
        const  instArray = props.data.instruction.toString().split("\r\n");

        return(<>
            {
                instArray.map( (log,i) => {
                    return <LogItem value ={log}/>
                })}
        </>);
    }


    const wireApproveOrRejectValidAndAction = (wireId,action, field,gridApi,selectedRow) => {
        const data = {
            status: action,
            wireId: wireId,
            comments: "",
        };

        Service.makePostRequest(data, WIRES_APPROVE_REJECT_VALIDATE_AND_APPROVE_REJECT)
            .then(response => {
                let statusResponseModel = response.data
                if (statusResponseModel) {
                    if (statusResponseModel.status === 'Success'){
                        // approve or reject success
                        if (action) {
                              if (action === 'REJECT'){
                                setShowReject(false)
                                success("Wire Rejected Successfully !!")
                            }
                            else if (action === 'APPROVE'){
                                setShowApprove(false)
                                success("Wire Approved Successfully !!")
                            }
                        }
                        reloadWireData(gridApi,selectedRow);
                        setActionForWire(field);
                        setShow(false);
                    } else {
                        error(statusResponseModel.message)
                    }
                }
            }).catch(err => {
            error(err);
        })
        ;
    }

    const refreshGridAfterApprove = ( gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];

        const field = {
            cdUser: AuthenticationService.getAuthenticatedUserName(),
            wireId: selectedRow.wireId,
            status:selectedRow.status,
            comments: selectedRow.prevComments,
            userCode: selectedRow.userCode,
            mnyIn:selectedRow.moneyIn,
            mnyOut:selectedRow.moneyOut,
            ccyIn:selectedRow.incomingCurrencyCode,
            ccyOut:selectedRow.outgoingCurrencyCode,
            clientCode:selectedRow.clientCode,
            uiStatus:selectedRow.uiStatus,
            pendingCltApprovals: selectedRow.pendingCltApprovals,
            isApproveEnabled: selectedRow.isApproveEnabled,
            isRejectEnabled: selectedRow.isRejectEnabled,
            managerName:wireResultsDisplayState.wireSearch.managerName
        }
        wireApproveOrRejectValidAndAction(field.wireId, 'APPROVE', field ,gridApi,selectedRow);

    };

    const refreshGridAfterReject = (gridApi,onSuccess,onerror) => {
        const selectedRow = gridApi.getSelectedRows()[0];

        const field = {
            cdUser: AuthenticationService.getAuthenticatedUserName(),
            wireId: selectedRow.wireId,
            status:selectedRow.status,
            comments: selectedRow.prevComments,
            userCode: selectedRow.userCode,
            mnyIn:selectedRow.moneyIn,
            mnyOut:selectedRow.moneyOut,
            ccyIn:selectedRow.incomingCurrencyCode,
            ccyOut:selectedRow.outgoingCurrencyCode,
            clientCode:selectedRow.clientCode,
            uiStatus:selectedRow.uiStatus,
            managerName:wireResultsDisplayState.wireSearch.managerName
        }
            wireApproveOrRejectValidAndAction(field.wireId, 'REJECT', field ,gridApi,selectedRow);
            setActionForWire(field);
    }

    const reloadWireData = (gridApi,selectedRow) =>{
        const status = ( (wirePanelFilterState.status ==="")?"ALL":wirePanelFilterState.status);
        const params = {
            legalEntity: selectedRow.managerName,
            cdAcct: selectedRow.account,
            clientCode: selectedRow.account,
            startDate:dateToString( wirePanelFilterState.startDate),
            endDate:dateToString(wirePanelFilterState.endDate),
            status: status,
            templateIndicator:"N",
            deskViewIndicator:"N",
            exportIndicator:0
        }

        const onSuccess = (res) => {
            const wireResultsDisplay = JSON.parse(JSON.stringify(wireResultsDisplayState));
            wireResultsDisplay.resData = ([...res.data]);
            setWireResultsDisplayState(wireResultsDisplay);
            setRowData(wireResultsDisplayState.resData);

            const updatedRow = res.data.filter(function (item){return item.wireId === selectedRow.wireId})[0];
            if(updatedRow) {
                selectedRow.uiStatus = updatedRow.uiStatus;
                selectedRow.userCode = updatedRow.userCode;
                selectedRow.status = updatedRow.status;
                selectedRow.comments = updatedRow.comments;
                selectedRow.prevComments = updatedRow.prevComments;

                selectedRow.pendingCltApprovals = updatedRow.pendingCltApprovals
                selectedRow.isApproveEnabled = updatedRow.isApproveEnabled
                selectedRow.isRejectEnabled = updatedRow.isRejectEnabled

                if(updatedRow.status === "REJECT" && status === "ALL") {
                    gridApi.applyTransaction({update: [selectedRow]});
                }

                else if(updatedRow.status === "REJECT" && status === "PEND") {
                    gridApi.applyTransaction({remove: [selectedRow]});
                }
                else {
                    gridApi.applyTransaction({update: [selectedRow]});
                }
            }
            else
            {
                if(status !== "ALL") {
                    gridApi.applyTransaction({remove: [selectedRow]})
                }
            }
        }
        const onError = (err) => {
            console.error('Error when retrieving wires - ', err);
            error("There was an error when loading wires !!");
        }
        makePostRequest(params, WIRES_WIRE_SEARCH, onSuccess, onError);
    }

    const wireAppRejectDataValueGetter = function (params) {
        let appRejData = {
            cdUser: AuthenticationService.getAuthenticatedUserName(),
            wireId:params.data.wireId,
            status:params.data.status,
            comments: params.data.prevComments,
            userCode: params.data.userCode,
            mnyIn:params.data.moneyIn,
            mnyOut:params.data.moneyOut,
            ccyIn:params.data.incomingCurrencyCode,
            ccyOut:params.data.outgoingCurrencyCode,
            clientCode:params.data.clientCode,
            uiStatus:params.data.uiStatus,
            pendingCltApprovals: params.data.pendingCltApprovals,
            isApproveEnabled: params.data.isApproveEnabled,
            isRejectEnabled: params.data.isRejectEnabled,
            managerName:wireResultsDisplayState.wireSearch.managerName
        }
        return appRejData;
    }

    const handleActionIconClick = (field) => {
        setShow(true);
        setApproveEnabledOnWire(field.isApproveEnabled && field.isApproveEnabled === 1?true:false)
        setRejectEnabledOnWire(field.isRejectEnabled && field.isRejectEnabled === 1?true:false)
        setModalTitle(field.clientCode);
    }

    const handleApproveActionClicked = (field)=>{
        setShow(true);
        setShowApprove(true);
        setModalTitle(field.clientCode);
    }

    const handleRejectActionClicked = (field)=>{
        setShow(true);
        setShowReject(true);
        setModalTitle(field.clientCode);
    }

    const setupActionColumns = () => {
        let updatedColumnDefs = [...columnDefsResultsPanel];
        updatedColumnDefs.splice(0, 0, {
            headerName: 'Actions',
            field: 'action', colId: 'action', width: 135,
            type: 'actionColType',
            cellRenderer: "approveRejectRenderer",
            valueGetter: wireAppRejectDataValueGetter,
            cellRendererParams: {
                clicked: (data) => handleActionIconClick(data),
                onApproveActionClicked: (appRejData) => handleApproveActionClicked(appRejData),
                onRejectActionClicked: (appRejData) => handleRejectActionClicked(appRejData),
                showApproveRejectFun: (appRejData) => lookupActionApproveReject(appRejData),
                showApproveFun: (appRejData) => lookupActionApprove(appRejData),
                showRejectFun: (appRejData) => lookupActionReject(appRejData),
            }
        });
        return updatedColumnDefs;
    }

    useEffect(() => {
        setupActionColumns();
        if (WIRE_PANEL_VIEW_WIRE_PAGE === wirePanelPageState.currentPage) {
            const rowDataNew = wireResultsDisplayState.resData;
            rowDataNew.map(e => (e["managerName"] = wireResultsDisplayState.wireSearch.managerName));
            const updatedColDefsResultsPanel = addPropertiesToGridColumns(setupActionColumns(), GRID_COLUMN_PROPERTY)
            setColumnDefs( updatedColDefsResultsPanel);
            setRowData(rowDataNew);
            setHandleSaveFunction({handleSave: saveWireList, gridApi:null});
            setHandleRenameFunction({handleRename: updateSavedWire, gridApi:  null});
            setHandleDeleteFunction({handleDelete: deleteSavedWire, gridApi:  null});
            setHandleExportFunction({handleExport:exportQueryList, gridApi: gridApi});
        }

    },[wirePanelPageState])

    const saveWireList = (name, gridApi, onSuccess, onError) => {
        const params = {
            name: name,
            idAccount: wireResultsDisplayState.wireSearch.accountId ? wireResultsDisplayState.wireSearch.accountId : 0,
            idManager:wireResultsDisplayState.wireSearch.managerId,
            status:wireResultsDisplayState.wireSearch.status,
            cdClient:wireResultsDisplayState.wireSearch.clientCode,
            startDate: (wireResultsDisplayState.wireSearch.startDate ? dateToString(wireResultsDisplayState.wireSearch.startDate) : ''),
            endDate: (wireResultsDisplayState.wireSearch.endDate ? dateToString(wireResultsDisplayState.wireSearch.endDate) : '')
        };

        const onSuccessResponse = (res) => {
            if(onSuccess) onSuccess();
            if(res.data.wireListId){
                wireResultsDisplayState.isSavedWire = true;
                wireResultsDisplayState.savedName = res.data.name;
                wireResultsDisplayState.savedWireListId = res.data.wireListId;
                setSavedSearchName(name);
                setWireResultsDisplayState(wireResultsDisplayState);

            }
            success("Successfully created wire search");
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }
        makePostRequest(params, WIRES_LIST, onSuccessResponse, onErrorResponse);
    }

    const updateSavedWire = (name, gridApi, onSuccess, onError) => {
        const params = {
            "savedNameUpdated":name,
        }
        const onSuccessResponse = (response) => {
            if(onSuccess) onSuccess();
            wireResultsDisplayState.savedName = name;
            setWireResultsDisplayState(wireResultsDisplayState);
            setSavedSearchName(name);
            success('Wire name saved successfully.');
        }

        const onErrorResponse = (err) => {
            const msg = "Error occurred when updating the Saved Wire name";
            console.error(msg + ": ", err);
            if(onError) onError(err);
        }

        makePatchRequestWithParams(
            WIRES_WIRES_LIST_SAVE + "/" + wireResultsDisplayState.savedWireListId , params, onSuccessResponse, onErrorResponse);
    };

    const deleteSavedWire = (gridApi, onSuccess, onError) => {
        const onSuccessResponse = (response) => {

            if(onSuccess) onSuccess();
            clearEntityAccounts();
            clearWirePanelDisplayState();
            success('Wire list deleted successfully.');
        }

        const onErrorResponse = (err) => {
            const msg = "Error when updating the saved Wire name";
            console.error(msg + ": ", err);
            if(onError) onError(err);
            error(msg);
        }

        makeDeleteRequest(
            WIRES_WIRES_LIST_SAVE + "/" + wireResultsDisplayState.savedWireListId, onSuccessResponse, onErrorResponse);
    };

    const exportQueryList = (type, rowData, gridApi) => {
        //Set Column definition
        hiddenExportGridApi.current.api.setColumnDefs(columnDefsResultsPanel);
        //Set Data
        hiddenExportGridApi.current.api.setRowData(rowData || wireResultsDisplayState.resData);
        let filteredColumnNames = getFilteredColumns(gridRef);
        //Export as csv/excel
        type==='EXCEL' ?
            hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams('Wires', filteredColumnNames)) :
                hiddenExportGridApi.current.api.exportDataAsCsv({columnKeys: filteredColumnNames})
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setHandleApproveFunction({handleApprove: refreshGridAfterApprove, gridApi: params.api});
        setHandleRejectFunction({handleReject: refreshGridAfterReject, gridApi: params.api});
    }

    function pageTitle(){
        return "Wires "
    }

    const cellClicked=(params)=> {
        params.node.setSelected(true)
        params.api.ensureIndexVisible(params.node.rowIndex,'top');
    }

    const onDisplayedColumnsChanged = ()=> {
        if(gridColumnApi){
            const updatedColumnDefs = updateGridColumDefs(gridColumnApi, columnDefs)
            setColumnDefs(updatedColumnDefs)
        }
    }

    const onDataRendered = (params) => {
        setGridApi(params.api);
        params.columnApi.autoSizeAllColumns();
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    return(<>
        <Container fluid>
            <PageHeader text={pageTitle()}>
                <WiresParamsBadge/>
            </PageHeader>

            { showGridToolbar && <AgGridToolbar title={wireResultsDisplayState.savedName}
                           wireScreen={true}
                           gridApi={gridApi}
                           gridColumnApi={gridColumnApi}
                           columnDefs={columnDefs}
                           setColumnDefs={setColumnDefs}
                           isSaved={wireResultsDisplayState.isSavedWire}
                                                paginationPageSize={paginationPageSize}
                                                setPaginationPageSize={setPaginationPageSize}
                                                showButton={showButton} setShowButton={setShowButton}>
            </AgGridToolbar>}
            <div className="ag-theme-alpine">
                <AgGridReact
                    ref={gridRef}
                    cellClassRules={statusCellRules}
                    domLayout={'autoHeight'}
                    pagination={true}
                    frameworkComponents={{
                        actionsRenderer: ActionsRenderer,
                        logRenderer: logRenderer,
                        approveRejectRenderer: ActionsApproveRejectRenderer,
                        instructionsRenderer: instructionsRenderer
                    }}
                    paginationPageSize={paginationPageSize}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={true}
                    onGridReady={onGridReady}
                    onRowDataChanged={onDataRendered}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    columnTypes={columnTypes}
                    rowData={rowData}
                    animateRows={true}
                    onCellClicked={cellClicked}
                    onPaginationChanged={onPaginationChange}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
        <ActionApproveRejectPanelLayout show={show} setShow={setShow} showApprove={showApprove} setShowApprove={setShowApprove} showReject={showReject} setShowReject={setShowReject}  gridApi={gridApi} 
           modalTitle={modalTitle} approveEnabledOnWire={approveEnabledOnWire} rejectEnabledOnWire={rejectEnabledOnWire}/>
    </>);
}

export const columnDefsResultsPanel = [
    {headerName: 'wireId', field: 'wireId',hide:true, colId: 'wireId', suppressColumnsToolPanel:true},
    {headerName: 'Date', field: 'effectiveDate', valueGetter: (params)=>stringToDate(dateToString(params.data.dtEffective)),width:130},
    {headerName: 'Status', field: 'status',width:120},
    {headerName: 'Account', field: 'clientCode',width:135},
    {headerName: '3rd Party', field: 'thirdPartyCode',width:120},
    {headerName: 'Outgoing Funds', field: 'moneyOut',cellStyle: cellAlignment,valueFormatter: numberFormatter},
    {headerName: 'Out CCY', field: 'outgoingCurrencyCode',width:100},
    {headerName: 'Reason', field: 'reasonCode'},
    {headerName: 'Incoming Funds', field: 'moneyIn',cellStyle: cellAlignment,valueFormatter: numberFormatter},
    {headerName: 'In CCY', field: 'incomingCurrencyCode',width:100},
    {headerName: 'Wire Instructions', field: 'instruction',wrapText :true,autoHeight:true,resizable:true,cellRenderer: 'instructionsRenderer'},
    {headerName: 'Comments', field: 'prevComments',wrapText :true,autoHeight:true,resizable:true,cellRenderer: 'logRenderer'},
    {headerName: 'userCode', field: 'userCode',hide:true,suppressColumnsToolPanel:true},
    {headerName: 'totApprovers', field: 'totApprovers',hide: true,suppressColumnsToolPanel:true},
    {headerName: 'uiStatus', field: 'uiStatus' ,hide: true,suppressColumnsToolPanel:true},
    {headerName: 'managerName',field:'managerName',hide: true,suppressColumnsToolPanel: true},
    {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
];

// const updatedColDefsResultsPanel = addPropertiesToGridColumns(columnDefsResultsPanel, GRID_COLUMN_PROPERTY)