
export const TRADE_ENTRY_WHOLE_NUMBER_MSG =  "Invalid value, must be a positive whole number";
export const TRADE_ENTRY_CLIENT_ID_MSG = "Invalid value. Must be a maximum of 50 alphanumeric characters";
export const TRADE_ENTRY_IND_TRADE_CANCEL_MSG = "Invalid value, must be 'C' for cancel or blank for active";
export const TRADE_ENTRY_TRADE_TYPE_MSG = "Invalid value, must be either 'B' or 'BC' or 'S' or 'SS'";
export const TRADE_ENTRY_SECURITY_CODE_MSG = "Invalid value. Must be between 1-21 alphanumeric characters";
export const TRADE_ENTRY_ACCOUNT_CODE_MSG = "Invalid value. Must be 8 numeric characters";
export const TRADE_ENTRY_ACCOUNT_TYPE_MSG =  "Invalid value. Must be either 1-Cash, 2-Margin, 3-Short";
export const TRADE_ENTRY_COMMISSION_TYPE_MSG = "Invalid value. Must be either 1-Cents per Share (Whole Numbers) or 3-Cash Value. 6-PointsPerBond is not a supported type";
export const TRADE_ENTRY_DECIMAL_NUMBER_MSG = "Invalid value, must be a decimal number";
export const TRADE_ENTRY_BROKER_CODE_MSG = "Invalid value. Must be between 4-20 alphabetic characters";
export const TRADE_ENTRY_TRADE_DATE_MSG = "Date format MM/DD/YYYY required";
export const TRADE_ENTRY_SETTLE_DATE_MSG = "Date format MM/DD/YYYY required";
export const TRADE_ENTRY_CURRENCY_MSG =  "Invalid value. Must be between 1-3 Uppercase alphabetic characters";
export const TRADE_ENTRY_COMMENTS_MSG =  "Invalid value. Must be a maximum of 249 alphanumeric characters and the following special characters are allowed: ()+-.:; /$/%*";
export const REGEX_TRADE_SEARCH_CRITERIA_STATUS_MSG = "Not a valid status value, Please use either ALL, PEND, SEND, CANCEL, REVOKE, REJECT, ACCEPT, TIMEOUT, MANUAL, INTRANSIT";

export const REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_SECURITY_MSG = "Invalid value. Must be between 1-21 alphanumeric characters";
export const REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_QUANTITY_MSG = "Invalid value. Quantity must be a numeric value";
export const REGEX_WIRES_NEW_COMMENTS_MSG = "Invalid value. Must be between 1-2000 characters";
export const REGEX_WIRES_NEW_REASON_MSG = "Invalid value. Must be between 1-120 characters";
export const REGEX_WIRES_NEW_TEMPLATE_NAME_MSG = "Invalid value. Must be between 1-30 characters";
export const REGEX_WIRES_NEW_WIRE_INST_MSG = "Invalid value. Must be between 1-512 characters";
export const REGEX_WIRES_NEW_INCOMING_CURRENCY_CODE_MSG = "Invalid value. Must be between 1-3 alphabetic upper case characters";
export const REGEX_WIRES_NEW_OUTGOING_CURRENCY_CODE_MSG = "Invalid value. Must be between 1-3 alphabetic upper case characters";
export const REGEX_WIRES_NEW_CLIENT_CODE_MSG = "Invalid value. Must be between 1-8 numeric characters";

export const WIRES_NEW_INCOMING_OUTGOING_VALUE_ZERO_ERROR = "Please enter either incoming value or outgoing value"



export const SAVED_NAME_INVALID_LENGTH_ERR_MSG = "Length should be less than or equal to 100"
export const SAVED_NAME_INVALID_CHARACTERS_ERR_MSG = "No special characters are allowed"
export const API_CALL_ERR_MSG = "Internal Error Occurred. Please check logs"









