import React, {useEffect, useState, useContext} from "react";
import {Col, Container, Row} from "react-bootstrap";
import $ from "jquery";
import 'jquery.gridstrap/dist/jquery.gridstrap.min.css'
import TopQueries from "./TopQueries";
import TopReports from "./TopReports";
import DraggableHeader from "components/DraggableHeader";
import PortalAlert from "./PortalAlert";
import PageHeader from "components/PageHeader";
import TopLongPositions from "./TopLongPositions";
import TopShortPositions from "./TopShortPositions";
import {HOME_PAGE_TOP_POSITIONS_COUNT} from "../home/context/HomepageContext"
import {UserPreferencesContext} from "../../context/userpreferences";
import {WIDGET_TOP_QUERIES} from "../../context/userpreferences/UserPreferencesContext";
import {WIDGET_TOP_REPORTS} from "../../context/userpreferences/UserPreferencesContext";
import {WIDGET_TOP_LONG_POS} from "../../context/userpreferences/UserPreferencesContext";
import {WIDGET_TOP_SHORT_POS} from "../../context/userpreferences/UserPreferencesContext";
import useWindowDimensions from "../../hooks/Dimensions";
import UserRoleService, {
    QUERIES_MENU_PERMISSION_KEY,
    REPORTS_MENU_PERMISSION_KEY,
    TRADE_MENU_PERMISSION_KEY
} from "../../service/UserRoleService";
import {SecurityContext} from "../../context/security";
import {PREFERENCES_URL} from "../../service/UrlConstant";
import {EntityAccountsContext} from "../../components/entity-accounts/context";


export default function HomePanel() {

    const [showAlerts, setShowAlerts] = useState(true);
    const [refreshState, setRefreshState] = useState(true);
    const {makeBackgroundPostRequest, makeGetRequest} = useContext(SecurityContext);
    const {isDesktop} = useWindowDimensions();
    const {setManagerAndAccountDetails, setInitEntityAccountsOptions} = useContext(EntityAccountsContext);

    const colStyle = "px-0 px-md-2 pt-2 pb-4"

    const {
        homePagePreferences,
        setHomePagePreferences,
        preferencesLoaded,
        setPreferencesLoaded
    } = useContext(UserPreferencesContext);

    const refresh = () => {
        setRefreshState(false);
        setTimeout(()=> setRefreshState(true), 10);
    }


    useEffect(() => {
        setInitEntityAccountsOptions()
    }, [])

    const initGrid = ()=> {
        window.jQuery = $;
        window.$ = $;
        global.jQuery = $;
        require('jquery.gridstrap');

        const basicGridTimeout = setTimeout(()=>{

            $('#basic-grid').gridstrap({
                resizeHandleSelector: '.resizeMeHorizontally'
            });

            let gs = $('#basic-grid').data('gridstrap');

            $('#basic-grid').on('mouseup', function (e) {
                e.preventDefault();

                gs.$getCells().each(function(index, value) {

                    let classValues = value.getAttribute('class').split(" ");

                    let cellId = '';
                    let cellOrder = index;
                    let cellSize = -1;

                    classValues.forEach((item) => {
                       if(item.startsWith('gridId')) {
                           cellId = item.substring(6, item.length);
                       }
                       if(item.startsWith('col-md-')) {
                           cellSize = item.substring(7, item.length);
                       }
                    });

                    let widgetConfig = homePagePreferences.widgets[cellId];
                    if(widgetConfig) {
                        widgetConfig.md = cellSize;
                        widgetConfig.index = cellOrder;
                    }


                });

                makeBackgroundPostRequest(homePagePreferences, PREFERENCES_URL);


            });

            $('#basic-grid').on('cellresize', function (e) {
               e.preventDefault();

                let index = gs.getCellIndexOfElement(e.target);
                gs.modifyCell(index, function ($getVisibleCell, $getHiddenCell) {
                    let $hiddenCell = $getHiddenCell();
                    let $visibleCell = $getVisibleCell();

                    // Remove Bootstrap's column classes, then add ones appropriate
                    // to the cell's outer width.
                    for (let i = 1; i <= 12; i++) {
                        $hiddenCell.removeClass('col-md-' + i);
                        $visibleCell.removeClass('col-md-' + i);
                    }

                    $hiddenCell.addClass('col-md-1');
                    $visibleCell.addClass('col-md-1');

                    let oneWidth = $hiddenCell.outerWidth();
                    for (let i = 1; i <= 12; i++) {
                        if ($hiddenCell.outerWidth() + oneWidth / 2 <= e.width) {
                            $hiddenCell.removeClass('col-md-' + (i - 1));
                            $visibleCell.removeClass('col-md-' + (i - 1));
                            $hiddenCell.addClass('col-md-' + i);
                            $visibleCell.addClass('col-md-' + i);

                        } else {
                            $visibleCell.removeClass('col-md-' + i);
                        }
                    }

                });

            });

            gs.$getCells().each(function(index, value) {

                let classValues = value.getAttribute('class').split(" ");
                classValues.forEach((item) => {

                    if(item.startsWith('gridId')) {
                        let widgetConfig =
                            homePagePreferences.widgets[item.substring(6, item.length)];
                        if(widgetConfig) {
                            let position = widgetConfig.index;
                            gs.moveCell(value,position);
                        }
                    }

                });

            });

            gs.$getCells().each(function(index, value) {
                let classValues = value.getAttribute('class').split(" ");
                classValues.forEach((item) => {
                    classValues.forEach((item) => {
                        if(item.startsWith('emptyGridPanel')) {
                            gs.moveCell(value,3);
                        }
                    });
                });
            });

        }, 200);

        return () => {
            clearTimeout(basicGridTimeout);
        }
    }

    const updateGridstrapView = () => {
        //I swap positions of cells in order to rerender after alert was hidden
        //Otherwise the grid will not adjust to a new position
        //There must be a better way (see gridstrap.js library for more info)
        let gr = $('#basic-grid').data('gridstrap');
        let $cells = gr.$getCells();
        let $cellToMove = $cells.eq(0);
        setTimeout(()=>gr.moveCell($cellToMove,1), 200);
        setTimeout(()=>gr.moveCell($cellToMove,0), 201);
    }

    useEffect(() => {
        refresh();

        if( isDesktop ) {

            if ( preferencesLoaded ) {
                initGrid();
                return;
            }
            makeGetRequest(PREFERENCES_URL, null,  (res ) => {
                if( res ) {
                    setHomePagePreferences(JSON.parse(JSON.stringify(res.data)));
                }
                initGrid();
            }, (a, b) => {
                initGrid();
            });
            setPreferencesLoaded(true);
        }
    },[isDesktop]);

    const showAlertsAndUpdateView = (value) => {
        setShowAlerts(value);
        if( isDesktop ) {
            updateGridstrapView();
        }
    }

    const getWidgetTitle = (title) => {
        return `Top ${HOME_PAGE_TOP_POSITIONS_COUNT} ${title}`
    }

    const checkPermission = (menuKey) => {
        return UserRoleService.hasPermission(menuKey);
    }

    return(<>

        {refreshState && <Container fluid style={{width: "100%"}}>
            <Container fluid>
                <Row className={`${showAlerts?"d-block":"d-none"}`}>
                    <Col className="px-0 px-md-2 pb-md-0 pb-2">
                        <PortalAlert loadedAlertsCallback={showAlertsAndUpdateView}/>
                    </Col>
                </Row>
            </Container>
            <Row className="pl-0 pl-md-2">
                <Col className="py-0">
                    <PageHeader text="Overview" bottomPadding="pb-0"/>
                </Col>
            </Row>

            <Row style={{margin: 0}}>
                <Container fluid style={{width:"100%", padding:0}}>
                    <Row id="basic-grid" style={{width: "100%", margin:0}}>

                        { checkPermission(QUERIES_MENU_PERMISSION_KEY)
                            ? <DraggableHeader text={getWidgetTitle('Queries')} gridId={WIDGET_TOP_QUERIES}>
                                    <TopQueries/>
                               </DraggableHeader>
                            : <Col gridId={WIDGET_TOP_QUERIES} className="emptyGridPanel" />
                        }

                        { checkPermission(REPORTS_MENU_PERMISSION_KEY)
                            ? <DraggableHeader text={getWidgetTitle('Reports')} gridId={WIDGET_TOP_REPORTS}>
                                <TopReports/>
                            </DraggableHeader>
                            : <Col gridId={WIDGET_TOP_REPORTS} className="emptyGridPanel" />
                        }

                        { checkPermission(REPORTS_MENU_PERMISSION_KEY) && checkPermission(QUERIES_MENU_PERMISSION_KEY)
                            ?  <DraggableHeader text={getWidgetTitle('Long Positions')} gridId={WIDGET_TOP_LONG_POS}>
                                    <TopLongPositions/>
                                </DraggableHeader>
                            : <Col gridId={WIDGET_TOP_LONG_POS} className="emptyGridPanel" />
                        }

                        { checkPermission(REPORTS_MENU_PERMISSION_KEY) && checkPermission(QUERIES_MENU_PERMISSION_KEY)
                            ?  <DraggableHeader text={getWidgetTitle('Short Positions')} gridId={WIDGET_TOP_SHORT_POS}>
                                <TopShortPositions/>
                            </DraggableHeader>
                            : <Col gridId={WIDGET_TOP_SHORT_POS} className="emptyGridPanel" />
                        }

                    </Row>
                </Container>
            </Row>
            <Row>
                <br/>
            </Row>
        </Container>}
    </>);
}
