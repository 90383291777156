import {Col, Container, Form, Row} from "react-bootstrap";
import React, {useContext, useState} from "react";
import useWindowDimensions from "hooks/Dimensions";
import {ActionPanelContext} from "context/action";
import {ToastContext} from "../../context/Toast";
import {NO_SPECIAL_CHARACTERS_WITHSPACE} from "../../utils/Constants";
import {SAVED_NAME_INVALID_LENGTH_ERR_MSG, SAVED_NAME_INVALID_CHARACTERS_ERR_MSG, API_CALL_ERR_MSG} from "../../utils/ConstantsMsg"

export default function SaveModal(props) {
    const {setSave, setShow}= props;
    const {isMobile} = useWindowDimensions();
    const {handleSave} = useContext(ActionPanelContext);
    const [saving, setSaving] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [savedSearchName, setSavedSearchName] = useState('');
    const {error} = useContext(ToastContext);
    const handleOnChange = (params) => {
        setSavedSearchName(params.target.value)
    }

    let errors = {}
    const handleSaveClick = (e) => {
        let trimmedSavedSearchName = savedSearchName.trim()
        if(trimmedSavedSearchName.length > 100 || trimmedSavedSearchName.length < 1){
            errors['savedName'] = SAVED_NAME_INVALID_LENGTH_ERR_MSG
        }
        if(!NO_SPECIAL_CHARACTERS_WITHSPACE.test(trimmedSavedSearchName)){
            errors['savedName'] = SAVED_NAME_INVALID_CHARACTERS_ERR_MSG
        }
        if(Object.keys(errors).length > 0){
            setErrorMessage(errors['savedName'])
            return
        }

        const onSuccess = () => {
            setSave(false);
            setShow(false);
        }
        const onError = (err) => {
            let errMsg = API_CALL_ERR_MSG
            if (err.response.status === 409)
                errMsg = err.response.data
            //setErrorMessage(errMsg);
            error(errMsg)
            setSaving(false);
        }
        setSaving(true);
        handleSave(trimmedSavedSearchName, onSuccess, onError);
    }

    const handleKeyPress = (e) => {
        if(e.key === "Enter"){
            handleSaveClick();
            e.preventDefault();
        }
    }

    return (<>

        <Container className="my-3 my-md-0">
            <Form onSubmit={(e) => e.preventDefault()} onKeyPress={handleKeyPress}>
            <Row>
                <Col md={12} className='pb-2'>
                    <Form.Group controlId="savedSearchName">
                        <Form.Control disabled={saving} name={"savedSearchName"} value={savedSearchName}
                                      onChange={handleOnChange}
                                      isInvalid={!!errorMessage}/>
                        <Form.Control.Feedback type={"invalid"}>
                            <b><i className="bi bi-exclamation-circle"></i>{errorMessage}</b>
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row className={"justify-content-end"}>
                <Col md={4} xs={12} className='pr-0 d-none d-md-block'>
                    <button className='btn btn-block td-btn-secondary-clear' href="#" disabled={saving} onClick={() => {
                        setSave(false);
                        setShow(false);
                    }}>Cancel
                    </button>
                </Col>
                <Col md={4} xs={11} sm={12} className={isMobile?'stick-to-bottom':''}>
                    <button className={'btn btn-block td-btn-primary-light'} href="#" disabled={saving} onClick={handleSaveClick}>Save</button>
                </Col>
            </Row>
            </Form>
        </Container>
    </>)
}