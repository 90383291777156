import React from 'react';
import buildingsDesktop from 'assets/image/buildings_desktop.png';
import buildingsTab from 'assets/image/buildings_tab.png';
import {Container, Image} from "react-bootstrap";
import useWindowDimensions from "hooks/Dimensions";
export default function LoginPanel() {
    const {isDesktop} = useWindowDimensions();
    return(<>
        <Container fluid style={{padding:0, margin:0, height:'100%'}} className={'min-vh-100'}>
        <Image fluid src={isDesktop?buildingsDesktop:buildingsTab}  className={'loginImage h-100'}/>
        </Container>
    </>);
}