import React, {useContext} from 'react';
import {Toast} from "react-bootstrap";
import {ToastContext} from "../context/Toast";

export default function ResponseToast(props){
    const {toast, hide} = useContext(ToastContext);
    return(<>
        <div className={'custom-toast-container'}>
       <Toast animation={true} autohide={true} delay={3000} onClose={hide}  show={toast.visible} className={'custom-toast'} style={{background:toast.background, minWidth:props.isFullWidth?'98%':'73%'}}>
            <Toast.Body style={{width:'100%',color:toast.variant=='success'?'#1A5336':toast.variant=='error'?'#AE1100':'#1C1C1C'}}>
                <h4><span style={{top:3}} aria-hidden={"true"} className={`td-icon icon-small p-2 ${toast.variant=='success'?'td-icon-approved':'td-icon-error'}`}></span> {toast.message}</h4>
            </Toast.Body>
       </Toast>
        </div>
    </>)
}