import {Col, Container, Row} from "react-bootstrap";
import React from "react";

export default function DefaultHome() {

    return (<> <Container>
        <Row className="pl-0 pl-md-2">
            <Col className="py-0">
                <h2 style={{paddingTop:20, paddingLeft:0, paddingRight:0, paddingBottom:20}}>No permission. Please check with admin.</h2>
            </Col>
        </Row>
    </Container> </>);
}