import React from "react";
import TradeActivitiesFilter from "./TradeActivitiesFilter";
import TradePanel from "./TradePanel";
import SidePanelLayout from "layouts/SidePanelLayout";
import {TradeCaptureContextProvider} from "./context";

export default function Trade() {

    return (<>
        <SidePanelLayout
            sideComponent={TradeActivitiesFilter}
            mainComponent={TradePanel}>
        </SidePanelLayout>
    </>);

}
