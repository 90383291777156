import {Col, Container, Row} from "react-bootstrap";
import React, {useContext, useState} from "react";
import useWindowDimensions from "hooks/Dimensions";
import {WiresPanelContext} from "../../pages/wires/context";

export default function ApproveModal(props) {

    const {setShowApprove, setShow} = props
    const {isDesktop, isMobile} = useWindowDimensions();
    const {handleApprove} = useContext(WiresPanelContext);
    const [approving, setApproving] = useState(false);

    return (<>
        <Container fluid className={"my-3 my-md-0"}>
            <Row>
                <Col md={12}>
                    <h4 style={{textAlign: isDesktop?'left':'center'}}>Are you sure you want to approve this?</h4><br/>
                </Col>
            </Row>
            <Row className={"justify-content-end"}>
                <Col md={4} xs={12} className='pr-0 d-none d-md-block'>
                    <button disabled={approving} className='btn btn-block td-btn-secondary-clear' href="#" onClick={() => {
                        setShow(false);
                        setShowApprove(false);
                    }}>Cancel
                    </button>
                </Col>
                <Col md={4} xs={11} sm={12} className={isMobile?'stick-to-bottom':''}>
                    <button  disabled={approving} className={'btn btn-block td-btn-primary-light'} href="#" onClick={() => {

                        const onSuccess = () => {
                        }

                        const onError = (err) => {
                        }
                        setShow(false);
                        setShowApprove(false);

                        setApproving(true);
                        handleApprove(onSuccess, onError);

                    }}>Approve
                    </button>
                </Col>
            </Row>
        </Container>
    </>)
}
ApproveModal.defaultProps = {
    confirmDeleteBtnClassName: "btn btn-block td-btn-red"
}

ApproveModal.defaultProps = {
    approveOrReject: "Approve"
}