import {Col, Toast} from "react-bootstrap";
import React from "react";
import ActionModal from "components/ActionModal";
import useWindowDimensions from "hooks/Dimensions";

export default function ContactUsModal(props) {
    const {isMobile} = useWindowDimensions();
    return (<>
        {!isMobile &&
                <Toast show={props.show} onClose={props.toggleShow} className={'mr-1'} >
                    <Toast.Header className={'pt-3 pb-3 pl-4'} style={{textAlign: 'center', background: 'green'}} closeButton={false} cancelable={true}>
                        {props.title()}
                    </Toast.Header>
                    <Toast.Body className={'pl-4'} style={{textAlign: 'left', background: 'white', color: 'black'}}>
                        {props.body()}
                    </Toast.Body>
                </Toast>
        }
        {isMobile && <ActionModal body={props.body} title={props.title} show={props.show} onHide={props.onHide} dialogClassName={'fullscreen-dialog'} centered={true}/>}

        </>)
}
