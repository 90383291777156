import {createContext, useState} from "react";
import {
    formatDateString, getPrevBusDate,
} from "utils/FormatUtils"
import {VALIDATION_IN_JS} from "../../../utils/Constants";

export const REPORTS_PANEL_HOME_PAGE = "report-home";
export const REPORTS_PANEL_VIEW_REPORT_PAGE = "show-report";

export const Context = createContext({});


const initReportsSearchState = {
    isSubManager: 'N',
    managerId: '',
    managerName: '',
    subManagerId: '',
    accountId: '',
    account: '',
    clientCode:'',
    effectiveDate:'',
    fileName: '',
    folder: ''
}


const initReportsPdfDisplayState = {
    reportPdfData: [],
    reportPdfSearchData:{},
    isReportSavedSearch : false,
    reportSavedSearchName:'',
    reportSavedSearchId: '',
}

const initReportsFilterSearchState = {
    reportsSearch: initReportsSearchState,
    formErrors:{reportsSearch:{}}
}

const loadedFoldersState = {
    accountId: '',
    effectiveDate: '',
    folders: [],
    folderFiles: []
}

const initFolderFileState = {
    loadedFoldersState: loadedFoldersState,
    reportsSearch: {
        folder:'',
        fileName:''
    },
    formErrors:{reportsSearch:{}}
}

const initReportsPanelPageState = {
    currentPage : REPORTS_PANEL_HOME_PAGE,
}

const initFolderFileReloadState = {
    force : {},
    check : {}
}

export const Provider = props => {

    const {children} = props;

    const [reportsPdfDisplayState, setReportsPdfDisplayState] = useState(JSON.parse(JSON.stringify(initReportsPdfDisplayState)));
    const [reportsFolderFileState, setReportsFolderFileState] = useState(JSON.parse(JSON.stringify(initFolderFileState)));
    const [reportsPanelPageState, setReportsPanelPageState] = useState(JSON.parse(JSON.stringify(initReportsPanelPageState)));
    const [reportsFilterSearchState, setReportsFilterSearchState] = useState(JSON.parse(JSON.stringify(initReportsFilterSearchState)));
    const [reportsFolderFileReloadState, setReportsFolderFileReloadState] = useState(JSON.parse(JSON.stringify(initFolderFileReloadState)));
    const [isHomeRequest, setIsHomeRequest] = useState(false)
    const [showGridToolbar, setShowGridToolbar] = useState(true);
    const [savedReportDate, setSavedReportDate] = useState(null);

    const displayReportPanelPage = (reportPanelPage) => {
        let reportsPanelPageStateToShow=JSON.parse(JSON.stringify(reportsPanelPageState));
        reportsPanelPageStateToShow.currentPage = reportPanelPage;
        setReportsPanelPageState(reportsPanelPageStateToShow);
    }

    const clearReportSearchAndLoadReportsHome = () => {
        //Get previous business date
        const prevBusDate = formatDateString(String(getPrevBusDate()), 'YYYY-MM-DD');
        const reportsPdfDisplayState = JSON.parse(JSON.stringify(initReportsPdfDisplayState));
        setReportsPdfDisplayState(reportsPdfDisplayState);
        const reportsFilterSearchState = JSON.parse(JSON.stringify(initReportsFilterSearchState));
        //Set effective date to previous business date on user clearing report filter search criteria
        reportsFilterSearchState.reportsSearch.effectiveDate = prevBusDate;
        setReportsFilterSearchState(reportsFilterSearchState);
        const reportsFolderFileState = JSON.parse(JSON.stringify(initFolderFileState));
        setReportsFolderFileState(reportsFolderFileState);
        const reportsPanelPageState = JSON.parse(JSON.stringify(initReportsPanelPageState));
        setReportsPanelPageState(reportsPanelPageState);
        const reportsFolderFileReloadState = JSON.parse(JSON.stringify(initFolderFileReloadState));
        setReportsFolderFileReloadState(reportsFolderFileReloadState);
    }

    const validateReportFolderSearch = () => {

        let errors = {};
        if (VALIDATION_IN_JS) {
            if (reportsFolderFileState.loadedFoldersState.folders.length > 0 && !reportsFolderFileState.reportsSearch.folder) {
                errors['folder'] = 'Please select folder'
            }else if (reportsFolderFileState.loadedFoldersState.folders.length === 0){
                errors['folder'] = 'No Report folders available.'
            }
            if (reportsFolderFileState.loadedFoldersState.folderFiles.length > 0 && !reportsFolderFileState.reportsSearch.fileName) {
                errors['fileName'] = 'Please select file'
            }else if (reportsFolderFileState.loadedFoldersState.folderFiles.length === 0){
                errors['fileName'] = 'No Report Files available.'
            }

            const reportsFolderFileSearchErrors = JSON.parse(JSON.stringify(reportsFolderFileState));
            reportsFolderFileSearchErrors.formErrors.reportsSearch = errors;
            setReportsFolderFileState(reportsFolderFileSearchErrors);
        }
        return errors;
    }

    const forceReloadOfFoldersAndFiles= (folder, fileName, accountId, fileDeliveryDate) => {
        // format effective date to 'YYYY-MM-DD'
        const effectiveDate = formatDateString(fileDeliveryDate, 'YYYY-MM-DD')
        const forceReload = {};
        forceReload.folder = folder;
        forceReload.fileName = fileName;
        forceReload.accountId = accountId;
        forceReload.effectiveDate = effectiveDate;
        reportsFolderFileReloadState.force = forceReload;
        setReportsFolderFileReloadState(reportsFolderFileReloadState);
    }

    const checkForReloadOfFolders= (accountId, fileDeliveryDate) => {
        const checkReload = {};
        const effectiveDate = fileDeliveryDate
        checkReload.accountId = accountId;
        checkReload.effectiveDate = effectiveDate;
        reportsFolderFileReloadState.check = checkReload;
        setReportsFolderFileReloadState(reportsFolderFileReloadState);
    }

    const reportsPanelContext = {
        reportsFilterSearchState,
        setReportsFilterSearchState,
        reportsPanelPageState,
        reportsFolderFileState,
        setReportsFolderFileState,
        reportsPdfDisplayState,
        setReportsPdfDisplayState,
        reportsFolderFileReloadState,
        isHomeRequest,
        setIsHomeRequest,
        showGridToolbar,
        setShowGridToolbar,
        displayReportPanelPage,
        clearReportSearchAndLoadReportsHome,
        validateReportFolderSearch,
        forceReloadOfFoldersAndFiles,
        checkForReloadOfFolders,
        savedReportDate,
        setSavedReportDate
    }

    return <Context.Provider value={reportsPanelContext}>{children}</Context.Provider>

};

export const {Consumer} = Context;