import {Container} from "react-bootstrap";
import React from "react";

export default function RejectButton(props) {
    return (<>
        <Container fluid className={!props.isActionColBtn?"d-none":"m-0 p-0"}>
            <button className="btn btn-block td-btn-secondary-clear my-3" href="#" onClick={props.onDeleteButtonClick}>
                {props.buttonTitle??'Reject'}
            </button>
        </Container>
    </>)
}