import React, {useContext, useEffect, useRef, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import useWindowDimensions from "hooks/Dimensions";
import * as URL from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {ToastContext} from "context/Toast";
import {HomepageContext} from "./context";
import {HOME_PAGE_TOP_QUERY_COUNT} from "../home/context/HomepageContext"
import {
    dateToString,
    stringToDate,
    getPrevBusDate,
    getCurrentDate,
    formatDateString,
    yearMonthDayToPickerDateFormat
} from "utils/FormatUtils";
import {QUERIES} from "routes/Routes";
import {useHistory} from "react-router-dom";
import {QueriesPanelContext} from "../query/context";
import {QUERY_PANEL_VIEW_QUERY_PAGE} from "../query/context/QueriesPanelContext";
import DateRangeRenderer from "./DateRangeRenderer";
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui"
import {EntityAccountsContext} from "components/entity-accounts/context";
import {getAllErrorValuesAsString} from "../../utils/ErrorUtils";


class DatePicker {
    init(params) {
        this.eInput = document.createElement('input');
        this.eInput.type = 'date';
        this.eInput.classList.add('form-control');
        this.eInput.style.height = '100%';
        this.eInput.value = params.value ? formatDateString(String(getPrevBusDate()), 'YYYY-MM-DD') : params.value ;
    }

    getGui() {
        return this.eInput;
    }

    afterGuiAttached() {
        this.eInput.focus();
        this.eInput.select();
    }

    getValue() {
        return this.eInput.value;
    }

    isPopup() {
        return false;
    }
}

export default function TopQueries() {

    let history = useHistory();
    const {entityAccounts} = useContext(HomepageContext);
    const {queryResultsDisplayState,setQueryResultsDisplayState,displayQueryPanelPage, queryPanelFilterState,
        setQueryPanelFilterState, setIsHomeRequest,setIsDefaultEndDate} = useContext(QueriesPanelContext);
    const [rowData, setRowData] = useState([]);
    const {isMobile} = useWindowDimensions()
    const {error} = useContext(ToastContext);
    const {makeGetRequest} = useContext(SecurityContext);
    const {setManagerAndAccountDetails} = useContext(EntityAccountsContext);

    const myGrid = useRef(null);

    const defaultColDef = {
        sortable:true,
        resizable:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:isMobile?true:false,
    };

    const columnTypes ={
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
            suppressColumnsToolPanel:true
        }
    }

    const loadTop5Queries = (accountId) => {
        const params = {
            totalRowsRequested:  HOME_PAGE_TOP_QUERY_COUNT,
            accountId: accountId
        }
        const onSuccess = (response) => {
            if (response.data || response.data.length !== 0) {
                let resRowData = [...response.data]
                let prevBusDate = getPrevBusDate()
                resRowData.map(e => {
                    e.fromDt = prevBusDate
                    e.toDt = ''
                })
                setRowData([...resRowData]);
            }
        }
        const onError = (err) => {
            error('Error occurred while loading top 5 queries');
            setRowData([]);
        }
        if (accountId) {
            makeGetRequest(URL.QUERIES_QUERY_SAVED_USER, params, onSuccess, onError);
        }
    }

    const handleSearchNameRendererClicked =(data)=> {
        const range = data.range;
        data.toDt = data.toDt === '' ? data.fromDt : data.toDt;

        let fromDt = (data.fromDt) ? data.fromDt :data.startDate;
        let toDt = (data.toDt) ? data.toDt : data.endDate;

        let startDate = new Date(stringToDate(dateToString(fromDt)));
        let endDate = new Date(stringToDate(dateToString(toDt)));
        let isRangeSet = false;


        if(range !== ""){
            isRangeSet = true;
            startDate = new Date(startDate);
            if(range === "1W")
                startDate.setDate(endDate.getDate() - 7);
            else if(range === "1M")
                startDate.setMonth(endDate.getMonth() - 1);
            else if(range === "3M")
                startDate.setMonth(endDate.getMonth() - 3);
            else if(range === "6M")
                startDate.setMonth(endDate.getMonth() - 6);
            else if(range === "1Y")
                startDate.setMonth(endDate.getMonth() - 12);
        }

        fromDt = getCurrentDate(startDate);
        toDt = getCurrentDate(endDate);

        const searchParams = {
            clientCode:data.accountCode,
            queryId:data.queryId,
            startDate:dateToString(fromDt.toString()),
            endDate:dateToString(toDt.toString())
        }

        const onSuccess = (res) => {

            const queryResultsDisplay = JSON.parse(JSON.stringify(queryResultsDisplayState));
            queryResultsDisplay.resData = ([...res.data])
            queryResultsDisplay.isSavedQuery = false;
            queryResultsDisplay.savedName = '';
            queryResultsDisplay.savedQueryId = '';
            queryResultsDisplay.querySearch = {
                queryId : data.queryId,
                queryName : data.queryName,
                startDate : fromDt,
                endDate : toDt,
                managerId : data.managerId,
                managerName : data.managerNameLong,
                subManagerId : data.subManagerId,
                subManagerName : data.subManagerNameShort,
                accountId : data.accountId,
                accountName : data.accountNameShort,
                accountCode : data.accountCode
            };
            setQueryResultsDisplayState(queryResultsDisplay);
            const queryFilterState = JSON.parse(JSON.stringify(queryPanelFilterState));
            queryFilterState.queryId = data.queryId
            queryFilterState.queryName = data.queryName
            queryFilterState.startDate = formatDateString(fromDt, 'YYYY-MM-DD');
            queryFilterState.endDate = formatDateString(endDate, 'YYYY-MM-DD')
            queryFilterState.isRangeSet = isRangeSet;
            setQueryPanelFilterState(queryFilterState)

            setManagerAndAccountDetails(data.managerId, data.managerNameShort+"-"+data.managerNameLong, data.accountId, data.accountNameShort, data.accountCode, true)

            setIsHomeRequest(true)
            displayQueryPanelPage(QUERY_PANEL_VIEW_QUERY_PAGE);
            history.push(QUERIES);

        }
        const onError = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error('Error when retrieving Query - ', errorMsg);
            error("There was an error with loading selected query");
        }

        if(searchParams.startDate && searchParams.endDate){
            const fromDt= yearMonthDayToPickerDateFormat(searchParams.startDate);
            const toDt = yearMonthDayToPickerDateFormat(searchParams.endDate);

            const isFromDtGreater = new Date(fromDt).getTime() > new Date(toDt).getTime();
            if(isFromDtGreater) {
                error("Please ensure that the Date From is earlier than the Date To");
                return;
            }


        }

        makeGetRequest(URL.QUERIES_QUERY, searchParams, onSuccess, onError);

    }

    function managerGetter(params) {
        return params.data.managerNameShort + "-" + params.data.managerNameLong;
    }

    function accountGetter(params) {
        return params.data.accountCode + "-" + params.data.accountNameLong;
    }

    useEffect(() => {
        if(entityAccounts){
            if(entityAccounts.accountId)
                loadTop5Queries(entityAccounts.accountId);
            else
                setRowData([]);
        }

    }, [entityAccounts])

    const onRangeChange = (range, rowIndex) => {
        const newRowData = JSON.parse(JSON.stringify(rowData))
        newRowData[rowIndex]["range"] = range;
        setRowData(newRowData);
    }


    const columnDefs = [
        {headerName: 'saved Query Id',field: 'savedQueryId', hide: true,suppressColumnsToolPanel: true},
        {headerName: 'Manager Id',field: 'managerId',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'Sub Manager Id',field: 'subManagerId',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'client Code',field: 'clientCode',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'Account Id',field: 'accountId',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'Query Id',field: 'queryId',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'Name', field: 'savedQueryName', cellRenderer: "queryNameRenderer"},
        {headerName: 'Entity', valueGetter: managerGetter},
        {headerName: 'Account', valueGetter: accountGetter},
        {headerName: 'Query', field:'queryName',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'Date From', field:'fromDt',width:135,
            editable:true,cellEditor: DatePicker,cellEditorPopup: true,
            valueFormatter:params => formatDateString(String(params.value), 'MM/DD/YYYY'),
        },
        {headerName: 'Date To', field:'toDt',width:135,
            editable:true,cellEditor: DatePicker,cellEditorPopup: true,
            valueFormatter:params => formatDateString(String(params.value), 'MM/DD/YYYY')
        },
        {headerName: 'Range',field:'range',cellRenderer:'dateRangeRenderer',
            cellRendererParams:{
               // value:"",
                onRangeChange:onRangeChange
            }
        },
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];

    const searchNameRenderer = function (props){
        return(<>
            <a className="td-link" href="#" onClick={()=>{handleSearchNameRendererClicked(props.data)}}>{props.value}</a>
        </>);
    }

    const handleCellValueChanged = (e)=>{
        const {colDef, newValue } = e;
        if(colDef.field === 'toDt' && newValue) {
            setIsDefaultEndDate(false);
        }else {
            setIsDefaultEndDate(true)
        }
    }

    return(<>
        <div
            ref={myGrid}
            id="myGrid"
            className="ag-theme-alpine"
            style={{height:220}}
        >
            <AgGridReact
                frameworkComponents={{
                    queryNameRenderer: searchNameRenderer,
                    dateRangeRenderer: DateRangeRenderer
                }}
                groupDefaultExpanded={'1'}
                enableRowGroup={false}
                suppressDragLeaveHidesColumns={true}
                suppressMakeColumnVisibleAfterUnGroup={true}
                suppressModelUpdateAfterUpdateTransaction={true}
                suppressScrollOnNewData={true}
                suppressAggFuncInHeader={true}
                allowShowChangeAfterFilter={false}
                rowGroupPanelShow={'never'}
                enableSorting={true}
                defaultColDef={defaultColDef}
                columnTypes={columnTypes}
                columnDefs={columnDefs}
                rowData={rowData}
                animateRows={true}
                onCellValueChanged={handleCellValueChanged}
            >
            </AgGridReact>
        </div>
    </>);
}