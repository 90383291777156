import {
    getCurrentBusDateOrCurrentDate
} from "utils/FormatUtils"
import {createContext, useEffect, useState, useContext} from "react";
import {dateToString, getCurrentDate} from "utils/FormatUtils"
import {SecurityContext} from "../../../context/security";
import {EntityAccountsContext} from "../../../components/entity-accounts/context";
import {SHORT_SALE_URL_SEARCH_APPROVALS} from "../../../service/UrlConstant"
import {ToastContext} from "../../../context/Toast";
import {
    SHORTSALE_APPROVAL_LOCATE,
    SHORTSALE_APPROVAL_SEARCH,
    SHORTSALE_APPROVAL_PASTE_FROM_CLIPBOARD,
    SHORTSALE_APPROVAL_UPLOAD
} from "../../../routes/Routes";
import {useLocation} from "react-router";

const startEndDate = getCurrentBusDateOrCurrentDate();


const initialState={
    'search':{
        'managerId':'',
        'subManagerId':'',
        'security':'',
        'startDate': startEndDate,
        'endDate': startEndDate
    },
    'securityLending': {
        'entity': '',
        'security': '',
        'quantity': '',
        'secQty': []
    },
    'currentPage': 'defaultlanding'
}
const errorInitState = {'search': {},'securityLending':{}}

export const Context = createContext({});

export const Provider = props => {

    const {children} = props;
    const [shortSaleApprovalSearchDisplayState, setShortSaleApprovalSearchDisplayState] = useState(null);
    const [shortSaleApprovalPasteFromClipboardState, setShortSaleApprovalPasteFromClipboardState] = useState({});
    const initState = JSON.parse(JSON.stringify(initialState));
    const [shortSaleState, setShortSaleState] = useState(initState);
    const [panelDisplayState,setPanelDisplayState] = useState(initState);
    const [shortSalePersistState,setShortSalePersistState] = useState({});
    const [showSearch, setShowSearch] = useState(true);
    const [currentSSALayout, setCurrentSSALayout] = useState(null)
    const location = useLocation();
    const [loadTrigger, setLoadTrigger] = useState(false)
    const [navPathHistory, setNavPathHistory] = useState([])
    const [showBadge, setShowBadge] = useState(false);
    const {makePostRequest} = useContext(SecurityContext)
    const {paramBadgeInfo} = useContext(EntityAccountsContext)
    const {error} = useContext(ToastContext);



    useEffect(()=>{
        setNavPathHistory((prevHistory)=> [...prevHistory, location.pathname])

        const currentPath = location.pathname;
        const previousPath = navPathHistory[navPathHistory.length - 1]  || '';

        const fileUploadRoutes = [SHORTSALE_APPROVAL_PASTE_FROM_CLIPBOARD,SHORTSALE_APPROVAL_UPLOAD]

        const shouldLoad =
            ((currentPath === SHORTSALE_APPROVAL_LOCATE) && !fileUploadRoutes.includes(previousPath)) ||
            (currentPath === SHORTSALE_APPROVAL_SEARCH || previousPath === SHORTSALE_APPROVAL_LOCATE)
        setLoadTrigger(shouldLoad)

    },[location])

    const getErrorInitState = () => {
        return JSON.parse(JSON.stringify(errorInitState));
    }
    const clearShortSaleState = () =>{
        setShortSaleState({...JSON.parse(JSON.stringify(initialState))});
    }

    const clearShortSaleApprovalSearchDisplayState = () =>{
        setShortSaleApprovalSearchDisplayState(null);
    }

    const handlePageRequest =(pageName)=>{
        const newFilterState = {...panelDisplayState}
        newFilterState['currentPage'] = pageName;
        setPanelDisplayState(newFilterState);
    }

    const setSSAToolBarRefreshCriteria = (response, entityAccounts)=>{
        let idsList = null
        if (response && response.data && response.data.length > 0) {
            idsList = []
            response.data.map(r => {
                idsList.push(r.id)
            })
        }
        const shortSaleApprovalSearchDisplayDetails = {
            resData : response.data,
            searchCriteria : {
                "managerId": entityAccounts.managerId,
                "subManagerId": '',
                "security": '',
                "fromProcessingDate": getCurrentDate(),
                "toProcessingDate": '',
                "ids": idsList
            }
        }
        setShortSaleApprovalSearchDisplayState({...shortSaleApprovalSearchDisplayDetails});
    }

    const loadShortSaleApprovals = (entity) => {
        const entityManagerId = String(entity.managerId);
        const searchCriteria =  {
            "managerId": entityManagerId,
            "subManagerId": '',
            "counterPartyAcct" : null,
            "security": '',
            "fromProcessingDate": dateToString(shortSaleState.search.startDate),
            "toProcessingDate": dateToString(shortSaleState.search.endDate),
            "badgeInfo": paramBadgeInfo()
        }
        const onSuccess = (response) => {
            if(!response.data || response.data.length === 0){
                const searchDisplay = {
                    searchCriteria : searchCriteria
                }
                setShortSaleApprovalSearchDisplayState(searchDisplay);
                console.log("No short sales approvals searches found")
            }
            else {
                const shortSaleApprovalSearchDisplayDetails = {
                    resData : response.data,
                    searchCriteria : searchCriteria
                }
                setShortSaleApprovalSearchDisplayState(shortSaleApprovalSearchDisplayDetails);
                shortSaleState['currentPage'] = 'search';
                setPanelDisplayState({...shortSaleState});
            }
        }
        const onError = (err) => {
            console.error(err);
            error('Error loading short sales list');
        }
        makePostRequest(searchCriteria, SHORT_SALE_URL_SEARCH_APPROVALS, onSuccess, onError);
    };


    const shortSaleContext = {
        shortSaleApprovalSearchDisplayState,
        setShortSaleApprovalSearchDisplayState,
        shortSaleState,
        setShortSaleState,
        clearShortSaleState,
        initialState,
        handlePageRequest,
        panelDisplayState,
        setPanelDisplayState,
        shortSalePersistState,
        setShortSalePersistState,
        getErrorInitState,
        showSearch,
        setShowSearch,
        shortSaleApprovalPasteFromClipboardState,
        setShortSaleApprovalPasteFromClipboardState,
        currentSSALayout,
        setCurrentSSALayout,
        loadTrigger,
        setLoadTrigger,
        setSSAToolBarRefreshCriteria,
        clearShortSaleApprovalSearchDisplayState,
        loadShortSaleApprovals,
        setShowBadge,
        showBadge
    }

    return <Context.Provider value={shortSaleContext}>{children}</Context.Provider>

};

export const {Consumer} = Context;
