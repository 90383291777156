import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import useWindowDimensions from "../../hooks/Dimensions";
import ActionModal from "../../components/ActionModal";
import td_logo from "../../assets/image/td-logo.png";
import tooltip_indication from "../../assets/image/toolTip_Indication.png";

export default function TradeUploadValidationInfo(props) {
    const {isDesktop, isTab,isMobile} = useWindowDimensions();
    const bodyStyle=isDesktop?{paddingTop:0}:{};
    let {errorCount} = props
    const modalBody = () => (
                    <Container>
                        <Row>
                            <Col style={{textAlign: isDesktop?"left":"left", padding:20}}>
                                <div id="errorInfoBox" style={{display: 'block'}}>
                                        <br/>
                                        <div className="td-logo"><a href="/"><img src={td_logo} srcSet=""
                                                                                  alt="TD Securities TD Prime Services"/></a>
                                        </div>
                                        <br/>
                                        <br/>
                                        <h3 style={{fontSize:"x-large"}}>File Upload Review: {errorCount} Issue(s) found</h3>
                                        <br/>
                                        <h3>
                                            Please hover over red highlighted cell(s) for error details.<br/>Review your
                                            spreadsheet for those values. Edit as needed and resubmit the file.
                                        </h3>
                                        <br/>
                                        <div style={{maxWidth:"fit-content"}}><img src={tooltip_indication} width={700}/></div>

                                        <br/>
                                        <br/>
                                        <table border="0" cellPadding="0" cellSpacing="0">
                                            <tr>
                                                <td align="left"></td>
                                                <td align="center">&nbsp;</td>
                                                <td align="right">
                                                    <button tabIndex="0.21" type="button"
                                                            className="td-button td-button-block td-button-default"
                                                            onClick={props.onHide}>CANCEL
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                </div>
                            </Col>
                        </Row>

                    </Container>
    )

    const modalTitle = () => (
        <Container>
            <Row>
                <Col>Trade Upload Validation Info</Col>
            </Row>
        </Container>
    )

    return (<>
        <ActionModal body={modalBody} title={modalTitle} show={props.show} onHide={props.onHide} dialogClassName={isMobile? "mobile-fullscreen-dialog": (isTab ? "fullscreen-dialog" : "")} size={isDesktop?"lg":""} bodyStyle={bodyStyle} centered={true}></ActionModal>
    </>);
}