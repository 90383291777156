import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {QueriesPanelContext} from "./context";
import QueriesSavedPanel from "./QueriesSavedPanel";
import QueriesResultsPanel from "./QueriesResultsPanel";
import {ActionPanelContextProvider} from "context/action";
import {SecurityContext} from "context/security";
import {QUERY_PANEL_VIEW_QUERY_PAGE} from "./context/QueriesPanelContext";

export default function QueriesPanel() {
    const {queryPanelPageState} = useContext(QueriesPanelContext);
    const {resetFlagRight} = useContext(SecurityContext);

    if(resetFlagRight.current){
        resetFlagRight.current = false;
    }

    function currentComponent() {
        if(queryPanelPageState && queryPanelPageState.currentPage === QUERY_PANEL_VIEW_QUERY_PAGE) {
            return <ActionPanelContextProvider><QueriesResultsPanel /></ActionPanelContextProvider>;
        }

        return <ActionPanelContextProvider><QueriesSavedPanel/></ActionPanelContextProvider>;
    }

    return(<>
        <Container fluid>
            <Row>
                <Col style={{padding:0}} >
                    {currentComponent()}
                </Col>
            </Row>
            <Row>
                <br/>
            </Row>
        </Container>
    </>);
}
