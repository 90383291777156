import {Col, Container, Row, Button} from "react-bootstrap";
import AgGridPageSizeSelect from "../AgGridPageSizeSelect";
import React, {useContext, useEffect, useState} from "react";
import useWindowDimensions from "hooks/Dimensions";
import ColumnsModal from "./ColumnsModal";
import DeleteModal from "./DeleteModal";
import RenameModal from "./RenameModal";
import ActionModal from "../ActionModal";
import RightSideOptions from "./RightSideOptions";
import RenameButton from "./ToolBarButtons/RenameButton";
import DeleteButton from "./ToolBarButtons/DeleteButton";
import ExportButton from "./ToolBarButtons/ExportButton";
import ColumnsButton from "./ToolBarButtons/ColumnsButton";
import SaveQueryButton from "./ToolBarButtons/SaveQueryButton";
import ResetTableButton from "./ToolBarButtons/ResetTableButton";
import SSARequestListButton from "./ToolBarButtons/SSARequestListButton";
import RefreshOptionsModal from "./RefreshOptionsModal";
import ExportModal from "./ExportModal";
import RefreshOptionsButton from "./ToolBarButtons/RefreshOptionsButton";
import SaveModal from "./SaveModal";
import {ActionPanelContext} from "../../context/action";
import FitToWindowButton from "./ToolBarButtons/FitToWindowButton";
import CancelTradeButton from "./ToolBarButtons/CancelTradeButton";
import ClearSelectionButton from "./ToolBarButtons/ClearSelectionButton"
import CancelTradeModal from "./CancelTradeModal";

const toolBarColStyle = {
    padding: 0, paddingRight: 20, textAlign: "center", alignSelf: "center"
};

export default function AgGridToolbar(props) {
    const {gridColumnApi, gridApi, sizeToFit, shortSaleApprovalScreen, securitiesLending, ssaPersistDetails,
        isSaved, fileName, tradeScreen, queryScreen, tradeBreak, wireScreen, reportScreen, onPageRequest,
        refreshCriteria, columnDefs, setColumnDefs, pasteFromExcelPanel, headerCheckboxChecked, onClearButtonClick, selectedRows, handleCancelTrade, selectedAnyCheckbox,
        paginationPageSize,setPaginationPageSize, showButton, setShowButton} = props;
    const title = props.title ?? "Queries Results";
    const [show, setShow] = useState(false)
    const [showRename, setShowRename] = useState(false)
    const [showSave, setSave] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showExport, setShowExport] = useState(false)
    const [showColumns, setShowColumns] = useState(false)
    const [showRefreshOptions, setShowRefreshOptions] = useState(false)
    const [showColumnDropdown, setShowColumnDropdown] = useState(false)
    const [showExportDropdown, setShowExportDropdown] = useState(false)
    const [showRightSideOptions,setShowRightSideOptions] = useState(false);
    const [showBackButton, setShowBackButton] =  useState(false);
    const [showCancelTrade, setShowCancelTrade] = useState(false);
    const {isDesktop, isMobile} = useWindowDimensions();
    let confirmDeleteBtnClassName = "btn btn-block ";
    confirmDeleteBtnClassName += isDesktop ? 'td-btn-primary-light' : 'td-btn-red ';

    let confirmCancelBtnClassName  = "btn btn-block ";
    confirmCancelBtnClassName += isDesktop ? 'td-btn-primary-light' : 'td-btn-red ';

    const {setSavedSearchName} = useContext(ActionPanelContext);

    useEffect(()=>{
        if(props && props.title)
            setSavedSearchName(props.title)
    },[]);


    const handleResetTableButtonClick=()=> {
        if(props && props.columnDefs && props.columnDefs.length > 0) {
            gridApi.setFilterModel(null);
            const colDef = [...props.columnDefs]
            colDef.map(e => e.hide = !!e.suppressColumnsToolPanel);
            gridApi.setColumnDefs(colDef)
            setColumnDefs(colDef)
            gridColumnApi.resetColumnState();
        }
    }

    const handleColumnFilterClick=(colDef)=> {
        gridApi.setColumnDefs(colDef)
        setShowColumns(false);
        setShow(false);
        setColumnDefs(colDef);
        setShowColumnDropdown(false);
    }

    const modalBody=()=>{
        if (showColumns)
            return (<ColumnsModal columnDefs={columnDefs} setColumnDefs={setColumnDefs} gridApi={gridApi} onColumnFilterClick={handleColumnFilterClick}/>)
        else if(showExport)
            return (<ExportModal gridApi={gridApi} showExport={showExport} setShowExport={setShowExport} setShow={setShow} isDropDown={true}/>)
        else if(showDelete)
            return (<DeleteModal setShowDelete={setShowDelete} setShow={setShow} confirmDeleteBtnClassName={confirmDeleteBtnClassName} gridApi={gridApi}/>)
        else if(showRename)
            return (<RenameModal allowSpecialCharacters={props.allowSpecialCharacters} setShow={setShow} setShowRename={setShowRename}/>)
        else if(showSave)
            return (<SaveModal setShow={setShow} setSave={setSave}/>)
        else if(showRefreshOptions)
            return (<RefreshOptionsModal setShow={setShow} gridApi={gridApi} setShowRefreshOptions={setShowRefreshOptions} setShowRightSideOptions={setShowRightSideOptions} rowData={props.rowData} refreshCriteria={refreshCriteria} />)
        else if(showRightSideOptions)
            return (<RightSideOptions />)
        else if(showCancelTrade)
            return (<CancelTradeModal setShowCancelTrade={setShowCancelTrade} setShow={setShow} confirmCancelBtnClassName={confirmCancelBtnClassName} gridApi={gridApi} selectedRows={selectedRows}/>)
    }

    const handleHide = ()=>{
        setShowRename(false)
        setSave(false)
        setShowDelete(false)
        setShowExport(false)
        setShowColumns(false)
        setShow(false)
        setShowRightSideOptions(false)
        setShowBackButton(false)
    }

    const actionTitle=()=> {
        if (showExport) return 'Export'
        if (showRename) return ' Rename'
        if (showSave) return 'Save Search'
        if (showDelete) return 'Delete'
        if (showColumns) return 'Columns'
        if (showRefreshOptions) return 'Refresh Options'
        if(showCancelTrade) return 'Cancel Trade'
    }

    const handleShowRightSideOptions=()=>{
        setShowRightSideOptions(true);
    }

    const handleGoBackClick=()=>{
        setShowRename(false)
        setSave(false)
        setShowDelete(false)
        setShowExport(false)
        setShowColumns(false)
        setShow(false)
        setShowBackButton(false)
        setShowRefreshOptions(false)
    }

    const handleRenameClick=()=>{
        setShowRename(true);
        setShow(true);
        setShowBackButton(isMobile);
    }

    const handleCancelTradeClick = ()=>{
        handleCancelTrade();
        setShowCancelTrade(true);
        setShow(true);
        setShowBackButton(isMobile);
    }

    const handleClearClick = ()=> {
        onClearButtonClick();
    }


    const handleDeleteButtonClick=()=>{
        setShowDelete(true);
        setShow(true);
        setShowBackButton(isMobile);
    }

    const handleExportButtonClick=()=>{
        setShowExport(true)
        setShow(true)
        setShowBackButton(isMobile);
    }

    const handleColumnsButtonClick=()=>{
        setShowColumns(true)
        setShow(true)
        setShowBackButton(true);
    }

    const handleSaveQueryButtonClick=()=>{
        setSave(true)
        setShow(true)
    }
    const handleRefreshOptionsButtonClick=()=>{
        setShow(true);
        setShowRefreshOptions(true);
        setShowBackButton(isMobile);
    }
    if (shortSaleApprovalScreen) return (<>
        <Container fluid style={{background: "#F3F3F3", padding: 0, margin: 0, border: "1px solid rgba(0,0,0,.15)", borderBottom: 0}}>
            <Row style={{margin: 0, padding: 0}} >
                <Col style={{paddingLeft: 0, paddingRight: 0}}>
                    <Container style={{width: "100%", marginRight: 0, paddingRight: 10}}
                               className={"justify-content-end navbar"}>
                         <Row>
                            <RightSideOptions show={showRightSideOptions} setShow={setShowRightSideOptions} onShowRightSideOptions={handleShowRightSideOptions} onHide={handleHide}>
                                {!pasteFromExcelPanel &&
                                    <>
                                        <Col style={toolBarColStyle} className="text-nowrap">
                                            <ExportButton onExportButtonClick={handleExportButtonClick} title={"Export"} showDropdown={showExportDropdown} setShowDropdown={setShowExportDropdown} gridApi={gridApi} showExport={showExport} setShowExport={setShowExport} setShow={setShow}/>
                                        </Col>
                                         <Col style={toolBarColStyle} className="text-nowrap d-block py-0 align-self-center d-sm-none">
                                            <RefreshOptionsButton onRefreshOptionsButtonClick={handleRefreshOptionsButtonClick} refreshCriteria={refreshCriteria} gridApi={gridApi} rowData={props.rowData}/>
                                         </Col>
                                        {!isMobile && <RefreshOptionsModal refreshCriteria={refreshCriteria} gridApi={gridApi} rowData={props.rowData}/> }
                                    </>
                                }
                                <Col xs={"auto"} style={{padding: 0}} className={'d-none d-sm-block'}>
                                    <AgGridPageSizeSelect gridApi={gridApi}
                                                          paginationPageSize={paginationPageSize}
                                                          setPaginationPageSize={setPaginationPageSize}
                                                          showButton={showButton}
                                                          setShowButton={setShowButton}/>
                                </Col>
                            </RightSideOptions>
                        </Row>

                    </Container>
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <ActionModal body={modalBody} title={actionTitle} show={show}  onHide={handleHide} dialogClassName={isDesktop ? '': isMobile? 'fullscreen-dialog' : 'custom-dialog'} centered={isDesktop} showBackButton={showBackButton} onGoBackClick={handleGoBackClick}/>
                </Col>
            </Row>
        </Container>
    </>);
    else if (securitiesLending) return (<>
        <Container fluid style={{background: "#F3F3F3", padding: 0, margin: 0, border: "1px solid rgba(0,0,0,.15)", borderBottom: 0}}>
            <Row style={{margin: 0, padding: 0}}>
                <Col xs={"auto"} style={{textAlign: "left", alignSelf: "center"}}>
                    <RenameButton title={fileName} onRenameClick={handleRenameClick} editable={!isMobile}/>
                </Col>
                <Col style={{paddingLeft: 0, paddingRight: 0}}>
                    <Container style={{width: "100%", marginRight: 0, paddingRight: 10}} className={"justify-content-end navbar"}>
                        <Row>
                            <RightSideOptions show={showRightSideOptions} setShow={setShowRightSideOptions} onShowRightSideOptions={handleShowRightSideOptions} onHide={handleHide}>
                                <Col style={toolBarColStyle} className="text-nowrap d-block d-sm-none">
                                    <RenameButton title={fileName} onRenameClick={handleRenameClick} editable={true}/>
                                </Col>
                                <Col style={toolBarColStyle} className="text-nowrap d-block py-0 align-self-center">
                                    <DeleteButton onDeleteButtonClick={handleDeleteButtonClick} buttonTitle="Delete"/>
                                </Col>
                                <Col style={toolBarColStyle} className="text-nowrap d-block py-0 d-sm-none align-self-center">
                                    <RefreshOptionsButton onRefreshOptionsButtonClick={handleRefreshOptionsButtonClick} refreshCriteria={refreshCriteria} gridApi={gridApi} rowData={props.rowData}/>
                                </Col>
                                <Col style={toolBarColStyle} className="text-nowrap py-0">
                                    <SSARequestListButton onPageRequest={onPageRequest}/>
                                </Col>
                                {!isMobile && <RefreshOptionsModal /> }
                                <Col xs={"auto"} style={{padding: 0}} className={'d-none d-sm-block'}>
                                    <AgGridPageSizeSelect gridApi={gridApi}
                                                          paginationPageSize={paginationPageSize}
                                                          setPaginationPageSize={setPaginationPageSize}
                                                          showButton={showButton}
                                                          setShowButton={setShowButton}/>
                                </Col>
                            </RightSideOptions>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <ActionModal body={modalBody} title={actionTitle} show={show}  onHide={handleHide} dialogClassName={isDesktop ? '': isMobile? 'fullscreen-dialog' : 'custom-dialog'} centered={isDesktop} showBackButton={showBackButton} onGoBackClick={handleGoBackClick}/>
                </Col>
            </Row>
        </Container>
    </>);
    else if (ssaPersistDetails) return (<>
        <Container fluid style={{background: "#F3F3F3", padding: 0, margin: 0, border: "1px solid rgba(0,0,0,.15)", borderBottom: 0}}>
            <Row style={{margin: 0, padding: 0}}>
                <Col xs={"auto"} style={{textAlign: "left", alignSelf: "center"}}>
                    <RenameButton title={fileName} onRenameClick={handleRenameClick} editable={!isMobile}/>
                </Col>
                <Col style={{paddingLeft: 0, paddingRight: 0}}>
                    <Container style={{width: "100%", marginRight: 0, paddingRight: 10}} className={"justify-content-end navbar"}>
                        <Row>
                            <RightSideOptions show={showRightSideOptions} setShow={setShowRightSideOptions} onShowRightSideOptions={handleShowRightSideOptions} onHide={handleHide}>
                                <Col style={toolBarColStyle} className="text-nowrap">
                                    <ExportButton onExportButtonClick={handleExportButtonClick} title={"Export"} showDropdown={showExportDropdown} setShowDropdown={setShowExportDropdown} gridApi={gridApi} showExport={showExport} setShowExport={setShowExport} setShow={setShow}/>
                                </Col>
                                <Col style={toolBarColStyle} className="text-nowrap d-block d-sm-none">
                                    <RenameButton title={fileName} onRenameClick={handleRenameClick} editable={true}/>
                                </Col>
                                <Col style={toolBarColStyle} className="text-nowrap d-block py-0 align-self-center">
                                    <DeleteButton onDeleteButtonClick={handleDeleteButtonClick} buttonTitle="Delete"/>
                                </Col>
                                <Col style={toolBarColStyle} className="text-nowrap py-0">
                                    <SSARequestListButton onPageRequest={onPageRequest}/>
                                </Col>
                            </RightSideOptions>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <ActionModal body={modalBody} title={actionTitle} show={show}  onHide={handleHide} dialogClassName={isDesktop ? '': isMobile? 'fullscreen-dialog' : 'custom-dialog'} centered={isDesktop} showBackButton={showBackButton} onGoBackClick={handleGoBackClick}/>
                </Col>
            </Row>
        </Container>
    </>);
    else if (queryScreen || wireScreen) return (<>
        <Container fluid style={{background: "#F3F3F3", padding: 0, margin: 0, border: "1px solid rgba(0,0,0,.15)", borderBottom: 0}}>
            <Row style={{margin: 0, padding: 0}}>
                <Col xs={"auto"} style={{textAlign: "left", alignSelf: "center"}} className={`${isSaved?'d-block ':'d-none'}`}>
                    <RenameButton title={title} onRenameClick={handleRenameClick} editable={!isMobile}/>
                </Col>
                <Col style={{paddingLeft: 0, paddingRight: 0}}>
                    <Container style={{width: "100%", marginRight: 0, paddingRight: 0}}
                               className={"justify-content-end navbar"}>
                        <Row style={{marginRight: 0, paddingRight: 0}}>
                            <RightSideOptions show={showRightSideOptions} setShow={setShowRightSideOptions} onShowRightSideOptions={handleShowRightSideOptions} onHide={handleHide}>
                                    <Col style={toolBarColStyle} className={`text-nowrap d-sm-none ${isSaved?'':'d-none'}`}>
                                        <RenameButton onRenameClick={handleRenameClick} editable={true}/>
                                    </Col>
                                    <Col style={toolBarColStyle} className={`text-nowrap  ${isSaved?'d-none':'d-block'}`}>
                                        <SaveQueryButton onSaveQueryButtonClick={handleSaveQueryButtonClick}/>
                                    </Col>
                                    <Col style={toolBarColStyle} className={`text-nowrap py-0 align-self-center ${isSaved?' d-block':'d-none'}`}>
                                        <DeleteButton onDeleteButtonClick={handleDeleteButtonClick} buttonTitle="Delete"/>
                                    </Col>
                                    <Col style={toolBarColStyle} className="text-nowrap d-none d-sm-block">
                                        <ResetTableButton onResetTableButtonClick={handleResetTableButtonClick}/>
                                    </Col>
                                    <Col style={toolBarColStyle} className="text-nowrap">
                                        <ColumnsButton onColumnsButtonClick={handleColumnsButtonClick} title={"Columns"} showDropdown={showColumnDropdown} setShowDropdown={setShowColumnDropdown} columnDefs={columnDefs} setColumnDefs={setColumnDefs} gridApi={gridApi} onColumnFilterClick={handleColumnFilterClick} gridColumnApi={gridColumnApi}/>
                                    </Col>
                                    <Col style={toolBarColStyle} className="text-nowrap">
                                        <ExportButton onExportButtonClick={handleExportButtonClick} title={"Export"} showDropdown={showExportDropdown} setShowDropdown={setShowExportDropdown} gridApi={gridApi} showExport={showExport} setShowExport={setShowExport} setShow={setShow}/>
                                    </Col>
                                <Col xs={"auto"} style={{padding: 0}} className={'d-none d-sm-block'}>
                                    <AgGridPageSizeSelect gridApi={gridApi}
                                                          paginationPageSize={paginationPageSize}
                                                          setPaginationPageSize={setPaginationPageSize}
                                                          showButton={showButton}
                                                          setShowButton={setShowButton}/>
                                </Col>
                            </RightSideOptions>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row className={'p-0 m-0'}>
                <Col xs={"12"} className={'p-0 m-0'}>
                    <ActionModal body={modalBody} title={actionTitle} show={show}  onHide={handleHide} dialogClassName={isDesktop ? '': isMobile ? 'fullscreen-dialog' : 'custom-dialog'} centered={isDesktop} showBackButton={showBackButton} onGoBackClick={handleGoBackClick}/>
                </Col>
            </Row>
        </Container>
    </>);
    else if (tradeScreen) return (<>
        <Container fluid style={{background: "#F3F3F3", padding: 0, margin: 0, border: "1px solid rgba(0,0,0,.15)", borderBottom: 0}}>
            <Row style={{margin: 0, padding: 0}}>

                {(headerCheckboxChecked || selectedAnyCheckbox)?
                    <>
                        <Col xs={"auto"} style={{textAlign: "left", alignSelf: "center"}} className='d-block'>
                            <CancelTradeButton onCancelTradeClick={handleCancelTradeClick} editable={!isMobile} buttonTitle="Cancel Trade"/>
                        </Col>
                        <Col xs={"auto"} style={{textAlign: "left", alignSelf: "center"}} className='d-block'>
                            <ClearSelectionButton onClearSelectionClick={handleClearClick} editable={!isMobile} buttonTitle="Clear"/>
                        </Col>
                    </>
                    :
                    <Col xs={"auto"} style={{textAlign: "left", alignSelf: "center"}} className={`${isSaved?'d-block ':'d-none'}`}>
                        <RenameButton title={title} onRenameClick={handleRenameClick} editable={!isMobile}/>
                    </Col>
                }
                <Col style={{paddingLeft: 0, paddingRight: 0}}>
                    <Container style={{width: "100%", marginRight: 0, paddingRight: 0}}
                               className={"justify-content-end navbar"}>
                        <Row style={{marginRight: 0, paddingRight: 0}}>
                            <RightSideOptions show={showRightSideOptions} setShow={setShowRightSideOptions} onShowRightSideOptions={handleShowRightSideOptions} onHide={handleHide}>
                                {(!headerCheckboxChecked || !selectedAnyCheckbox) &&
                                    <>
                                    <Col style={toolBarColStyle} className={`text-nowrap d-sm-none ${isSaved?'':'d-none'}`}>
                                        <RenameButton onRenameClick={handleRenameClick} editable={true}/>
                                    </Col>
                                    <Col style={toolBarColStyle} className={`text-nowrap  ${isSaved?'d-none':'d-block'}`}>
                                        <SaveQueryButton onSaveQueryButtonClick={handleSaveQueryButtonClick}/>
                                    </Col>
                                    <Col style={toolBarColStyle} className={`text-nowrap py-0 align-self-center ${isSaved?' d-block':'d-none'}`}>
                                        <DeleteButton onDeleteButtonClick={handleDeleteButtonClick} buttonTitle="Delete"/>
                                    </Col>
                                    <Col style={toolBarColStyle} className="text-nowrap d-none d-sm-block">
                                        <ResetTableButton onResetTableButtonClick={handleResetTableButtonClick}/>
                                    </Col>
                                    <Col style={toolBarColStyle} className="text-nowrap">
                                        <ColumnsButton onColumnsButtonClick={handleColumnsButtonClick} title={"Columns"} showDropdown={showColumnDropdown} setShowDropdown={setShowColumnDropdown} columnDefs={columnDefs} setColumnDefs={setColumnDefs} gridApi={gridApi} onColumnFilterClick={handleColumnFilterClick} gridColumnApi={gridColumnApi}/>
                                    </Col>
                                    <Col style={toolBarColStyle} className="text-nowrap">
                                        <ExportButton onExportButtonClick={handleExportButtonClick} title={"Export"} showDropdown={showExportDropdown} setShowDropdown={setShowExportDropdown} gridApi={gridApi} showExport={showExport} setShowExport={setShowExport} setShow={setShow}/>
                                    </Col>
                                </>}

                                <Col xs={"auto"} style={{padding: 0}} className={'d-none d-sm-block'}>
                                    <AgGridPageSizeSelect gridApi={gridApi}
                                                          paginationPageSize={paginationPageSize}
                                                          setPaginationPageSize={setPaginationPageSize}
                                                          showButton={showButton}
                                                          setShowButton={setShowButton}/>
                                </Col>
                            </RightSideOptions>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row className={'p-0 m-0'}>
                <Col xs={"12"} className={'p-0 m-0'}>
                    <ActionModal body={modalBody} title={actionTitle} show={show}  onHide={handleHide} dialogClassName={isDesktop ? '': isMobile ? 'fullscreen-dialog' : 'custom-dialog'} centered={isDesktop} showBackButton={showBackButton} onGoBackClick={handleGoBackClick}/>
                </Col>
            </Row>
        </Container>
    </>);
    else if(reportScreen) return (<>
        <Container fluid style={{background: "#F3F3F3", padding: 0, margin: 0, border: "1px solid rgba(0,0,0,.15)", borderBottom: 0}}>
            <Row style={{margin: 0, padding: 0}}>
                <Col xs={"auto"} style={{textAlign: "left", alignSelf: "center"}} className={`${isSaved?'d-block ':'d-none'}`}>
                    <RenameButton title={title} onRenameClick={handleRenameClick} editable={!isMobile} />
                </Col>
                <Col style={{paddingLeft: 0, paddingRight: 0}}>
                    <Container style={{width: "100%", marginRight: 0, paddingRight: 0}}
                               className={"justify-content-end navbar"}>
                        <Row style={{marginRight: 0, paddingRight: 0}}>
                            <RightSideOptions show={showRightSideOptions} setShow={setShowRightSideOptions} onShowRightSideOptions={handleShowRightSideOptions} onHide={handleHide}>
                                <Col style={toolBarColStyle} className={`text-nowrap d-sm-none ${isSaved?'':'d-none'}`}>
                                    <RenameButton onRenameClick={handleRenameClick} editable={true}/>
                                </Col>
                                <Col style={toolBarColStyle} className={`text-nowrap py-0 align-self-center ${isSaved?' d-block':'d-none'}`}>
                                    <DeleteButton onDeleteButtonClick={handleDeleteButtonClick} buttonTitle="Delete"/>
                                </Col>
                                <Col style={toolBarColStyle} className={`text-nowrap  ${isSaved?'d-none':'d-block'}`}>
                                    <SaveQueryButton onSaveQueryButtonClick={handleSaveQueryButtonClick}/>
                                </Col>
                            </RightSideOptions>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row className={'p-0 m-0'}>
                <Col xs={"12"} className={'p-0 m-0'}>
                    <ActionModal body={modalBody} title={actionTitle} show={show}  onHide={handleHide} dialogClassName={isDesktop ? '': isMobile ? 'fullscreen-dialog' : 'custom-dialog'} centered={isDesktop} showBackButton={showBackButton} onGoBackClick={handleGoBackClick}/>
                </Col>
            </Row>
        </Container>
    </>);
    else if (tradeBreak) return (<>
        <Container fluid style={{background: "#F3F3F3", padding: 0, margin: 0, border: "1px solid rgba(0,0,0,.15)", borderBottom: 0}}>
            <Row style={{margin: 0, padding: 0}}>
                <Col style={{paddingLeft: 0, paddingRight: 0}}>
                    <Container style={{width: "100%", marginRight: 0, paddingRight: 0}}
                               className={"justify-content-end navbar"}>
                        <Row style={{marginRight: 0, paddingRight: 0}}>
                            <Col className="text-nowrap">
                                <ResetTableButton onResetTableButtonClick={handleResetTableButtonClick} />
                            </Col>
                            <Col className="text-nowrap">
                                <FitToWindowButton onFitToWindowButtonClick={sizeToFit} />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row className={'p-0 m-0'}>
                <Col xs={"12"} className={'p-0 m-0'}>
                    <ActionModal body={modalBody} title={actionTitle} show={show}  onHide={handleHide} dialogClassName={isDesktop ? '': isMobile ? 'fullscreen-dialog' : 'custom-dialog'} centered={isDesktop} showBackButton={showBackButton} onGoBackClick={handleGoBackClick}/>
                </Col>
            </Row>
        </Container>
    </>)
}


AgGridToolbar.defaultProps={
    allowSpecialCharacters:false
}