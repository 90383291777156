import React from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import SSASecurityQuantityRows from "./SSASecurityQuantityRows";

export default function SecurityQuantityExpand(props) {
    const {secQty,onDeleteClick} = props;

    return (<>
        <Col  xs={12}  style={{padding:0, margin:0}} className={'pb-3'}>
            <Container  style={{justifyContent:'center', alignSelf:'center', alignContent:'center', padding:0, margin:0}}>
                <Row style={{padding:0, margin:0}}>
                    <Col xs={8} className={'p-0'} style={{textAlign:'left'}}>Security</Col>
                    <Col xs={2} className={'p-0'} style={{textAlign:'left'}}>Quantity</Col>
                    <Col xs={2}></Col>
                </Row>
                <hr/>
                {secQty.map((e, index) => <SSASecurityQuantityRows key={index} data={e} onDeleteClick={onDeleteClick}/>)}
            </Container>
        </Col>
    </>)
}