import React from 'react';
import {Link} from "react-router-dom";

export default function ButtonMailto(props) {
    const {mailto} = props;
    return (
        <Link
            to='#'
            onClick={(e) => {
                window.location = `mailto:${mailto}`;
                e.preventDefault();
            }}
        >
            {mailto}
        </Link>
    );
}