import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Col, Container, Form, Overlay, Row} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import ActionsRenderer from "components/ActionsRenderer";
import useWindowDimensions from "hooks/Dimensions";
import * as URL from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {dateToString, getCurrentDate, getCurrentDateToDatePickerFormat} from "utils/FormatUtils";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {EntityAccountsComponent} from "components/entity-accounts/EntityAccountsComponent";
import {TradeCaptureContext} from "./context";
import {TRADE_CAPTURE_PANEL_ENTRY_RESULT} from "./context/TradeCaptureContext";
import AccountTypeRenderer from './AccountTypeDropdownRenderer'
import {ToastContext} from "context/Toast";
import {useHistory} from "react-router-dom";
import * as Routes from "routes/Routes";
import {
    DEFAULT_GRID_PAGINATION_SIZE,
    REGEX_DECIMAL_MATCH,
    REGEX_TRADE_ENTRY_BROKER_CODE,
    REGEX_TRADE_ENTRY_CLIENT_ID, REGEX_TRADE_ENTRY_COMMENTS,
    REGEX_TRADE_ENTRY_COMMISSION_TYPE,
    REGEX_TRADE_ENTRY_CURRENCY,
    REGEX_TRADE_ENTRY_DECIMAL_NUMBER,
    REGEX_TRADE_ENTRY_SECURITY_CODE,
    LIST_TRADE_ENTRY_TRADE_TYPE,
    REGEX_TRADE_ENTRY_WHOLE_NUMBER, VALIDATION_IN_JS, GRID_COLUMN_PROPERTY
} from "utils/Constants";
import {cellAlignment} from "../../utils/NumberUtils";
import {validateValueOnRegex, validateValueOnList} from "../../utils/RegexUtils";
import {
    TRADE_ENTRY_BROKER_CODE_MSG,
    TRADE_ENTRY_CLIENT_ID_MSG, TRADE_ENTRY_COMMENTS_MSG,
    TRADE_ENTRY_COMMISSION_TYPE_MSG,
    TRADE_ENTRY_CURRENCY_MSG,
    TRADE_ENTRY_DECIMAL_NUMBER_MSG,
    TRADE_ENTRY_SECURITY_CODE_MSG,
    TRADE_ENTRY_TRADE_TYPE_MSG,
    TRADE_ENTRY_WHOLE_NUMBER_MSG,
} from "../../utils/ConstantsMsg";
import {getAllErrorValuesAsString} from "../../utils/ErrorUtils";
import {addPropertiesToGridColumns} from "../../utils/gridColumnUtils";


const tradeTypeJSON = [
    { "id": "B", "name": "Buy" },
    { "id": "S", "name": "Sell" },
    { "id": "SS", "name": "Short Sale" },
    { "id": "BC", "name": "Buy Cover" },
];

const commissionTypeJSON = [
    { "id": "3", "name": "Total" },   //cash
    { "id": "1", "name": "Rate" },  //cps cents per share
];

export default function TradeEntry() {

    const {tradeCaptureDisplayState, setTradeCaptureDisplayState, clearTradeSearchAndLoadTradingHome, clearTradeEntry} = useContext(TradeCaptureContext);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [errors, setErrors] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);
    const {entityAccounts, showAccounts, setShowAccounts, validateEntityAccountSearch, clearEntityAccounts, accountsOptions, setManagerDetailsWithOptionalAccount, entityAccountsOptions, populateDefaultEntity} = useContext(EntityAccountsContext);
    const {makePostRequest, makeGetRequest, makeGetRequestNoParams} = useContext(SecurityContext)
    const {success, error} = useContext(ToastContext);
    const {isMobile} = useWindowDimensions();
    const myGrid = useRef(null);
    let history = useHistory();
    const [currencies, setCurrencies] = useState([]);
    const [secOption, setSecOption] = useState('');
    const [showToolTip, setShowToolTip] = useState(true);
    const target = useRef(null);
    let id = tradeCaptureDisplayState.tradeEntry.status;

    function navigateToPage(path) {
        history.push(path);
    }

    const autoGroupColumnDef = useMemo(() => {
        return {
            maxWidth: 0,
            cellRendererParams: {
                suppressCount: true
            }
        };
    }, []);

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:!!isMobile,
    };

    const columnTypes ={
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
            suppressColumnsToolPanel:true
        }
    }

    const allocationSum = (params) => {
        let total = 0;
        params.values.forEach(value => total += value);
        tradeCaptureDisplayState.tradeEntry.totalAllocation = total
        return total;
    }

    const setAllocation = (params) => {
        if(params.oldValue !== params.newValue) {
            params.data.allocation = parseFloat(params.newValue);
            getCommission(params);
            getAccruedInterest(params);
            return true;
        }
        return false
    }

    const getCommission = (params) =>{

        const {commission, commissionType, quantity, price} = tradeCaptureDisplayState.tradeEntry;
        const allocation = params.data.allocation
        let total = 0.0;
        if (commission > 0 && allocation > 0) {
            if (commissionType === "3") {
                total = parseFloat(((commission * allocation) / quantity).toFixed(6));
            } else if (commissionType === "1") {
                total = commission / 1
            }
            params.data.commission = total;
            return total;
        }

        return 0
    }

    const getAccruedInterest = (params) =>{
        let total = 0;
        if(tradeCaptureDisplayState.tradeEntry.accruedInterest && tradeCaptureDisplayState.tradeEntry.quantity) {
            total = parseFloat(((tradeCaptureDisplayState.tradeEntry.accruedInterest * params.data.allocation) / tradeCaptureDisplayState.tradeEntry.quantity).toFixed(6))
            params.data.accruedInterest = total
            return total
        }
        return 0
    }

    const onAccountTypeChange = (accountType, rowIndex) => {
        rowData[rowIndex-1].accountType = accountType;
    }

    const columnDefs = [
        {headerName: '', field: 'accountId', hide:true, suppressColumnsToolPanel:true},
        {headerName: '', field: 'managerId', hide:true, suppressColumnsToolPanel:true, rowGroup: true},
        {headerName: 'Name', field: 'accountName',aggFunc:()=>"Total"},
        {headerName: 'Account', field: 'accountCode'},
        {headerName: 'Type', field: 'accountType', cellRenderer: 'accountTypeRenderer', cellRendererParams: {onAccountTypeChange: onAccountTypeChange}},
        {headerName: 'Allocation', field: 'allocation', editable:!isDisabled, valueSetter: setAllocation, aggFunc:allocationSum,cellStyle: cellAlignment},
        {headerName: 'Commission', field: 'commission', valueGetter:getCommission, aggFunc:'sum',cellStyle: cellAlignment},
        {headerName: 'Accrued Interest', field: 'accruedInterest',valueGetter:getAccruedInterest, aggFunc:'sum',cellStyle: cellAlignment},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];

    const updatedColumnDefs = addPropertiesToGridColumns(columnDefs, GRID_COLUMN_PROPERTY)

    useEffect(() => {
        setShowAccounts(false)
    }, [])

    useEffect(() => {
        populateDefaultEntity(showAccounts)
    }, [entityAccountsOptions])

    useEffect(() => {
        if (tradeCaptureDisplayState.tradeEntry.managerId == null || tradeCaptureDisplayState.tradeEntry.managerId == "") {
            return;
        }

        let managerInfo = entityAccountsOptions.filter(item => item.managerId === tradeCaptureDisplayState.tradeEntry.managerId);
        let managerName = '';
        if (managerInfo && managerInfo.length > 0) {
            managerName = managerInfo[0].shortName + '-' + managerInfo[0].longName;
        }
        setManagerDetailsWithOptionalAccount(tradeCaptureDisplayState.tradeEntry.managerId, managerName, false);
    }, [tradeCaptureDisplayState.tradeEntry.managerId])

    useEffect(() => {
        let defaultAllocationObj = {
            'managerId': entityAccounts.managerId,
            'accountType': "2",
            'allocation': 0.0,
            'commission': 0.0,
            'accruedInterest': 0.0
        }
        let gridData = accountsOptions.map(item => {
            let accountAllocationObj = {...defaultAllocationObj,...{
                    'accountId': item.accountId,
                    'accountName': item.accountCode + "-" + item.name,
                    'accountCode': item.accountCode,
                }}
            if (item.accountId === tradeCaptureDisplayState.tradeEntry.accountId){
                accountAllocationObj = {...accountAllocationObj,...{
                        'accountType': JSON.parse(JSON.stringify(tradeCaptureDisplayState.tradeEntry.accountType)),
                        'allocation': JSON.parse(JSON.stringify(tradeCaptureDisplayState.tradeEntry.quantity)),
                        'commission': JSON.parse(JSON.stringify(tradeCaptureDisplayState.tradeEntry.commission)),
                        'accruedInterest': JSON.parse(JSON.stringify(tradeCaptureDisplayState.tradeEntry.accruedInterest))
                    }}
            }
            return accountAllocationObj
        })

        setRowData(gridData)

    }, [accountsOptions])

    useEffect(() => {
        getCurrencies()
    }, [])

    useEffect(() => {
        if(tradeCaptureDisplayState.tradeEntry.securityOption =='Y' && tradeCaptureDisplayState.tradeEntry.security) {
            searchSecurityOption();
        }
    }, [tradeCaptureDisplayState.tradeEntry.securityOption])

    useEffect(() => {
        handleSecurityOnBlur()
        handleBrokerOnBlur()
    }, [id !== null])

    useEffect(() => {
        handleBrokerOnBlur()
    }, [accountsOptions])

    useEffect(() => {
        setIsDisabled(handleFormDisable)
    }, [id, tradeCaptureDisplayState.tradeEntry.status, tradeCaptureDisplayState.tradeEntry.dbSource, tradeCaptureDisplayState.tradeEntry.indTradeCancel])

    const handleFormDisable = () =>{
        return (id && id !== 'ACCEPT') || (tradeCaptureDisplayState.tradeEntry.status && tradeCaptureDisplayState.tradeEntry.status !== 'ACCEPT') || tradeCaptureDisplayState.tradeEntry.dbSource === 'TRADING' || tradeCaptureDisplayState.tradeEntry.indTradeCancel === 'C'
    }

    const handleSecurityOnBlur = (e) => {
        if(tradeCaptureDisplayState.tradeEntry.security) {
            setSecOption('')
            setShowToolTip(false)

            searchSecurity();
            if(tradeCaptureDisplayState.tradeEntry.securityOption =='Y') {
                searchSecurityOption();
            }
        }
    }

    const handleBrokerOnBlur = () => {
        if(tradeCaptureDisplayState.tradeEntry.brokerCode && accountsOptions)
            searchBroker()
    }

    const getCurrencies = () => {
        const onSuccessResponse = (res) => {
            if(res.data) {
                setCurrencies(res.data);
            }
        }

        const onErrorResponse = (err) => {
            console.log("Error on creating new Trade: ", err);
        }

        makeGetRequestNoParams(URL.CURRENCY_LIST_UNIQUE_URL, onSuccessResponse, onErrorResponse)
    }

    const searchSecurity = () => {
        const securityCode = getSecurity()

        const params = {
            "securityCode": securityCode
        }

        const onSuccessResponse = (res) => {
            if(res.data) {
                let tradeCaptureDisplayStateChange = {...tradeCaptureDisplayState};
                tradeCaptureDisplayStateChange['tradeEntry']['securityDescription'] =
                        res.data[0] && res.data[0]['longName'] != null ? res.data[0]['longName'] : ''
                tradeCaptureDisplayStateChange['tradeEntry']['securityCode'] = securityCode ? securityCode.toUpperCase() : '';
                setTradeCaptureDisplayState(tradeCaptureDisplayStateChange);
            }
        }

        const onErrorResponse = (err) => {
            error("Error getting security description")
            console.error("Error on search security: ", err);
        }

        makeGetRequest(URL.SECURITY_SEARCH_URL, params, onSuccessResponse, onErrorResponse)
    }

    const searchSecurityOption = () => {
        const params = {
            "ticker": getSecurity()
        }

        const onSuccessResponse = (res) => {
            if(res.data) {
                let tradeCaptureDisplayStateChange = {...tradeCaptureDisplayState};
                tradeCaptureDisplayStateChange['tradeEntry']['securityOptionList'] = res.data;
                setTradeCaptureDisplayState(tradeCaptureDisplayStateChange);
            }
        }

        const onErrorResponse = (err) => {
            error("Error getting security options")
            console.error("Error on searchSecurityOption: ", err);
        }

        makeGetRequest(URL.SECURITY_SEARCH_OPTIONS_URL, params, onSuccessResponse, onErrorResponse)
    }

    const getSecurity = () => {
        let security = '';
        if (tradeCaptureDisplayState.tradeEntry.security) {
            security = tradeCaptureDisplayState.tradeEntry.security;
        }
        return security.trim();
    }

    const searchBroker = () => {
        let managerId = entityAccounts.managerId  ? entityAccounts.managerId : tradeCaptureDisplayState.tradeEntry.managerId;
        const data = {
            "brokerCode":tradeCaptureDisplayState.tradeEntry.brokerCode,
            "managerId": managerId
        }

        const onSuccessResponse = (res) => {
            if(res.data) {
                let brokerDescription = res.data['brokerDescription'] ? res.data['brokerCode'].toUpperCase().trim() +"/"+ res.data['brokerDescription'] : ""
                let tradeCaptureDisplayStateChange = {...tradeCaptureDisplayState}
                tradeCaptureDisplayStateChange['tradeEntry']["brokerDescription"] = brokerDescription
                setTradeCaptureDisplayState(tradeCaptureDisplayStateChange);
            }
        }

        const onErrorResponse = (err) => {
            console.log("Error on creating new Trade: ", err);
        }

        makePostRequest(data, URL.BROKER_SEARCH_URL, onSuccessResponse, onErrorResponse)
    }



    const handleOptionOnChange = async (e) => {
        let tradeCaptureDisplayStateChange = {...tradeCaptureDisplayState};
        let checkValue = e.currentTarget.checked ? "Y" : "N";
        setSecOption('')
        setShowToolTip(false)
        tradeCaptureDisplayStateChange['tradeEntry']["securityOption"] = checkValue;
        setTradeCaptureDisplayState(tradeCaptureDisplayStateChange);
    }

    const handleOnChange = (e) => {
        const field = e.currentTarget.name;
        const {type, checked} = e.target;
        if(type === 'checkbox' && checked === true) {
            if(Object.keys(errors).length !== 0 && errors.hasOwnProperty('commission')) {
                const {commission, ...otherErrors} = errors;
                setErrors(otherErrors);
            }
        }
        let tradeCaptureDisplayStateChange = {...tradeCaptureDisplayState};
        tradeCaptureDisplayStateChange['tradeEntry'][field] = e.currentTarget.value
        setTradeCaptureDisplayState(tradeCaptureDisplayStateChange);
        clearErrors(field)
    }

    const handleOnSecurityOptionChange = (e) => {
        const field = e.currentTarget.name;
        let tradeCaptureDisplayStateChange = {...tradeCaptureDisplayState};
        tradeCaptureDisplayStateChange['tradeEntry'][field] = e.currentTarget.value;
        tradeCaptureDisplayStateChange['tradeEntry']['securityCode'] = e.currentTarget.value;
        setTradeCaptureDisplayState(tradeCaptureDisplayStateChange);
        setSecOption(e.currentTarget.value)
        setShowToolTip(true)

        clearErrors(field)
    }

    const handleTradeDateBlur = (e)=>{
        if(e.currentTarget.value === null || e.currentTarget.value.length === 0) {
            setTradeCaptureDisplayState(prevTradeCaptureDisplayState => ({
                ...prevTradeCaptureDisplayState, tradeEntry : {
                    ...prevTradeCaptureDisplayState.tradeEntry, tradeDate: getCurrentDateToDatePickerFormat()
                }
            }));
        }
    }

    const clearErrors = (field) => {
        if(!!errors[field])
            setErrors({...errors, [field]:null});
    }

    useEffect( () => {
        clearErrors("totalAllocation")
    }, [tradeCaptureDisplayState.tradeEntry.totalAllocation])

    useEffect(() => {
        if (rowData.length === 1) {
            const quantity = parseFloat(tradeCaptureDisplayState.tradeEntry.quantity);
            const commission = parseFloat(tradeCaptureDisplayState.tradeEntry.commission);
            const accruedInterest = parseFloat(tradeCaptureDisplayState.tradeEntry.accruedInterest);

            const updatedRowData = rowData.map((row) => {
                return {...row, allocation: quantity, commission: commission, accruedInterest: accruedInterest,};
            });
            setRowData(updatedRowData);
        }
    }, [tradeCaptureDisplayState.tradeEntry.quantity, tradeCaptureDisplayState.tradeEntry.commission, tradeCaptureDisplayState.tradeEntry.accruedInterest]);

    const validateForm = () => {
        const { tradeType, quantity, security, price, brokerCode, brokerDescription, commission,
            commissionType,isOverride, tradeDate, settleDate, comments, clientId, totalAllocation,
            fxUSDRate, currency} = tradeCaptureDisplayState.tradeEntry
        let errors = {};

        errors = validateEntityAccountSearch();

        if(!tradeType)
            errors['tradeType'] = "Trade Type is required";

        if(quantity === 0 || quantity === '' | !quantity)
            errors['quantity'] = "Required field";

        if(security.length <= 0)
            errors['security'] = "Required field"

        if(parseFloat(price) === 0 || price === '' || !price)
            errors['price'] = "Required field"

        if(brokerCode.length === 0)
            errors['brokerCode']="Required field"
        else if(brokerDescription.length === 0)
            errors['brokerCode']="Invalid broker code"

        let maxTotalComm = 0.0;
        let totalPricePerShare = 0.0;
        let totalCentsPerShare = 0.0;
        if((commission === 0 || commission === '' || !commission) && isOverride === "false")
            errors['commission'] = "Required field"
        else if(commissionType === "3"){
            totalPricePerShare = quantity * price
            maxTotalComm = (totalPricePerShare * 5) / 100;
            if(parseFloat(commission) > maxTotalComm && isOverride === "false")
                errors['commission'] = "Invalid value - max. allowed comm 5%"

            if(commission < 1 && (commission !== 0 || commission.trim !== '' || !commission) && isOverride === "false")
                errors['commission'] = "Invalid value - comm is less than $1"
        }
        else if(commissionType === "1"){
            totalCentsPerShare = (quantity * commission) / 100.00
            totalPricePerShare = quantity * price;
            maxTotalComm = totalPricePerShare * 0.05
            if(totalCentsPerShare > maxTotalComm && isOverride === "false")
                errors['commission'] = "Invalid value - max. allowed comm 5%"
        }

        if(tradeDate ===null || tradeDate.length === 0) {
            let tradeCaptureDisplayStateChange = {...tradeCaptureDisplayState}
            tradeCaptureDisplayStateChange['tradeEntry']["tradeDate"] = getCurrentDate()
            setTradeCaptureDisplayState(tradeCaptureDisplayStateChange);
        }

        if(totalAllocation <= 0)
            errors['totalAllocation'] = "Please allocate quantity"

        if(totalAllocation != quantity)
            errors['totalAllocation'] = "Allocate and quantity must match"

        let errorsRegex = validateTradeEntryRegex(clientId, tradeType, quantity, security, commissionType,
            commission, price, brokerCode, currency, fxUSDRate, comments)
        if (errorsRegex){
            errors = {...errors, ...errorsRegex}
        }
        return errors
    }

    const validateTradeEntryRegex = (clientId, tradeType, quantity,security,commissionType,commission,price,
           brokerCode, currency, fxUSDRate, comments) => {
        let errors = {}
        // client id
        let match = false
        if(clientId && clientId.length > 0){
            match = validateValueOnRegex(clientId, REGEX_TRADE_ENTRY_CLIENT_ID)
            if (!match){
                errors['clientId'] = TRADE_ENTRY_CLIENT_ID_MSG
            }
        }
        // tradeType
        if(tradeType && tradeType.length > 0){
            match = validateValueOnList(tradeType, LIST_TRADE_ENTRY_TRADE_TYPE)
            if (!match){
                errors['tradeType'] = TRADE_ENTRY_TRADE_TYPE_MSG            }
        }
        // quantity
        if(quantity && quantity.length > 0){
            match = validateValueOnRegex(quantity, REGEX_TRADE_ENTRY_WHOLE_NUMBER)
            if (!match){
                errors['quantity'] = TRADE_ENTRY_WHOLE_NUMBER_MSG
            }
        }
        // security
        if(security && security.length > 0){
            match = validateValueOnRegex(security, REGEX_TRADE_ENTRY_SECURITY_CODE)
            if (!match){
                errors['security'] = TRADE_ENTRY_SECURITY_CODE_MSG
            }
        }

        // commissionType
        if(commissionType && commissionType.length > 0){
            match = validateValueOnRegex(commissionType, REGEX_TRADE_ENTRY_COMMISSION_TYPE)
            if (!match){
                errors['commissionType'] = TRADE_ENTRY_COMMISSION_TYPE_MSG
            }
        }
        // commission
        if(commission && commission.length > 0){
            match = validateValueOnRegex(commission, REGEX_TRADE_ENTRY_DECIMAL_NUMBER)
            if (!match){
                errors['commission'] = TRADE_ENTRY_DECIMAL_NUMBER_MSG
            }
        }
        // price
        if(price && price.length > 0){
            match = validateValueOnRegex(price, REGEX_TRADE_ENTRY_DECIMAL_NUMBER)
            if (!match){
                errors['price'] = TRADE_ENTRY_DECIMAL_NUMBER_MSG
            }
        }
        // brokerCode
        if(brokerCode && brokerCode.length > 0){
            match = validateValueOnRegex(brokerCode, REGEX_TRADE_ENTRY_BROKER_CODE)
            if (!match){
                errors['brokerCode'] = TRADE_ENTRY_BROKER_CODE_MSG
            }
        }
        // currency
        if(currency && currency.length > 0){
            match = validateValueOnRegex(currency, REGEX_TRADE_ENTRY_CURRENCY)
            if (!match){
                errors['currency'] = TRADE_ENTRY_CURRENCY_MSG
            }
        }
        // fxUSDRate
        if(fxUSDRate && fxUSDRate.length > 0){
            match = validateValueOnRegex(fxUSDRate, REGEX_TRADE_ENTRY_DECIMAL_NUMBER)
            if (!match){
                errors['fxUSDRate'] = TRADE_ENTRY_DECIMAL_NUMBER_MSG
            }
        }
        // comments
        if(comments && comments.length > 0){
            match = validateValueOnRegex(comments, REGEX_TRADE_ENTRY_COMMENTS)
            if (!match){
                errors['comments'] = TRADE_ENTRY_COMMENTS_MSG
            }
        }
        //
        return errors;
    }
    const handleOnClear = (e) => {
        e.preventDefault()
        clearTradeSearchAndLoadTradingHome()
        clearEntityAccounts(false, false)
        setErrors({})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let formErrors = {}
        if (VALIDATION_IN_JS){
            formErrors = validateForm();
        }
        if(Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            return
        }
        else {

            const params = rowData.filter(item => item.allocation > 0).map(item => {
                return {
                    "accountId": item.accountId,
                    "managerId":entityAccounts.managerId,
                    "accountType": item.accountType,
                    "accruedInterest": item.accruedInterest,
                    "commission": item.commission,
                    "quantity": item.allocation,
                    "tradeType": tradeCaptureDisplayState.tradeEntry.tradeType,
                    "securityCode": tradeCaptureDisplayState.tradeEntry.securityOption && tradeCaptureDisplayState.tradeEntry.securityOption === "Y" && tradeCaptureDisplayState.tradeEntry.securityCode && tradeCaptureDisplayState.tradeEntry.securityCode !== ""?tradeCaptureDisplayState.tradeEntry.securityCode:tradeCaptureDisplayState.tradeEntry.security,
                    "price": tradeCaptureDisplayState.tradeEntry.price,
                    "currency": tradeCaptureDisplayState.tradeEntry.currency,
                    "brokerCode": tradeCaptureDisplayState.tradeEntry.brokerCode,
                    "commissionType": tradeCaptureDisplayState.tradeEntry.commissionType,
                    "tradeDate": dateToString(tradeCaptureDisplayState.tradeEntry.tradeDate.toString()),
                    "settleDate": dateToString(tradeCaptureDisplayState.tradeEntry.settleDate.toString()),
                    "comments": tradeCaptureDisplayState.tradeEntry.comments,
                    "clientId": tradeCaptureDisplayState.tradeEntry.clientId,
                    "securityOption": tradeCaptureDisplayState.tradeEntry.securityOption ? tradeCaptureDisplayState.tradeEntry.securityOption : "N",
                    "fxUSDRate":'',
                    "isOverride": tradeCaptureDisplayState.tradeEntry.isOverride
                }
            })

            const onSuccessResponse = (res) => {
                if(res.data){
                    let tradeCaptureDisplayStateChange = {...tradeCaptureDisplayState}
                    tradeCaptureDisplayStateChange['tradeEntry']['resData'] = res.data
                    tradeCaptureDisplayStateChange.currentPage = TRADE_CAPTURE_PANEL_ENTRY_RESULT
                    setTradeCaptureDisplayState(tradeCaptureDisplayStateChange);
                }
            }

            const onErrorResponse = (err) => {
                let errorMsg = getAllErrorValuesAsString(err.response.data)
                console.error("Error on creating new Trade:",errorMsg)
                error("Error on creating new Trade")
            }

            makePostRequest(params, URL.TRADES_SAVE_URL, onSuccessResponse, onErrorResponse)
        }
    }

    const handleRebook = (e) => {
        e.preventDefault()
        const formErrors = validateForm();
        if(Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            return
        }
        else {

            const params = rowData.filter(item => item.allocation > 0).map(item => {
                return {
                    "accountId": item.accountId,
                    "accountType": item.accountType,
                    "accruedInterest": item.accruedInterest,
                    "commission": item.commission,
                    "quantity": item.allocation,
                    "tradeType": tradeCaptureDisplayState.tradeEntry.tradeType,
                    "securityCode": tradeCaptureDisplayState.tradeEntry.securityOption && tradeCaptureDisplayState.tradeEntry.securityOption === "Y" && tradeCaptureDisplayState.tradeEntry.securityCode && tradeCaptureDisplayState.tradeEntry.securityCode !== ""?tradeCaptureDisplayState.tradeEntry.securityCode :tradeCaptureDisplayState.tradeEntry.security,
                    "price": tradeCaptureDisplayState.tradeEntry.price,
                    "currency": tradeCaptureDisplayState.tradeEntry.currency,
                    "brokerCode": tradeCaptureDisplayState.tradeEntry.brokerCode,
                    "commissionType": tradeCaptureDisplayState.tradeEntry.commissionType,
                    "tradeDate": dateToString(tradeCaptureDisplayState.tradeEntry.tradeDate.toString()),
                    "settleDate": dateToString(tradeCaptureDisplayState.tradeEntry.settleDate.toString()),
                    "comments": tradeCaptureDisplayState.tradeEntry.comments,
                    "clientId": tradeCaptureDisplayState.tradeEntry.clientId,
                    "openClose":tradeCaptureDisplayState.tradeEntry.openCloseCode,
                    "indTradeCancel":tradeCaptureDisplayState.tradeEntry.indTradeCancel,
                    "fxUSDRate":tradeCaptureDisplayState.tradeEntry.fxUSDRate,
                    "securityOption": tradeCaptureDisplayState.tradeEntry.securityOption ? tradeCaptureDisplayState.tradeEntry.securityOption : "N",
                    "tradeBreakFlg":id==='TradeBreak'?'Y':'N',
                    "dbSource": tradeCaptureDisplayState.tradeEntry.dbSource,
                    "cdBlockIdClient" : tradeCaptureDisplayState.tradeEntry.cdBlockIdClient,
                    "tradeBridgeTradeId": tradeCaptureDisplayState.tradeEntry.tradeBridgeTradeId,
                    "tradeUploadVersion": tradeCaptureDisplayState.tradeEntry.version,
                    "isOverride": tradeCaptureDisplayState.tradeEntry.isOverride
                }
            })

            const onSuccessResponse = (res) => {
                if(res.data){
                    success("Trade was rebooked successfully")
                    clearEntityAccounts(false, false);
                    clearTradeEntry();
                    navigateToPage(Routes.TRADECAPTURE_LIST)
                    if(id==='TradeBreak')
                        handleBackTradeBreak()
                }
            }

            const onErrorResponse = () => {
                error("Error on rebooking Trade");
            }

            makePostRequest(params, URL.TRADES_REBOOK_URL, onSuccessResponse, onErrorResponse)
        }
    }

    const handleCancel = (e) => {
        e.preventDefault()
        const params = [{
            "tradeUploadId": tradeCaptureDisplayState.tradeEntry.tradeBridgeTradeId,
            "tradeUploadVersion": tradeCaptureDisplayState.tradeEntry.version,
            "dbSource": tradeCaptureDisplayState.tradeEntry.dbSource
        }]

        const onSuccessResponse = (res) => {
            if(res.data){
                success("Trade was cancelled successfully")
                clearEntityAccounts(false, false);
                clearTradeEntry();
                navigateToPage(Routes.TRADECAPTURE_LIST)
            }
        }

        const onErrorResponse = (err) => {
            error("Error on cancelling Trade");
        }

        makePostRequest(params, URL.TRADES_CANCEL_URL, onSuccessResponse, onErrorResponse)

    }

    const handleBack = () => {
        clearEntityAccounts(false, false);
        clearTradeEntry();
        id == 'TradeBreak' ? handleBackTradeBreak() : handleBackTradeList()
    }

    const handleBackTradeList = () => {
        clearEntityAccounts(false, false);
        clearTradeEntry();
        navigateToPage(Routes.TRADECAPTURE_LIST)
    }

    const handleBackTradeBreak = () => {
        clearEntityAccounts(false, false);
        clearTradeEntry();
        navigateToPage(Routes.TRADECAPTURE_BREAKS)
    }

    const searchNameRenderer = function (props){
        return(<>
            <a className="td-link" href="#" onClick={()=>{}}>{props.value}</a>
        </>);
    }

    const header = function() {
        //Need to investigate why this is called multiple times
        const status = !tradeCaptureDisplayState.tradeEntry.status ? '' : tradeCaptureDisplayState.tradeEntry.status==="ACCEPT" ? "Active" : tradeCaptureDisplayState.tradeEntry.status;
        return(
            <Container fluid>
                <Row>
                    <Col className="px-md-3">
                        <h1>Trade Status: {status}</h1>
                    </Col>
                </Row>
            </Container>
        );
    }

    const controlButtons = function() {
        //Need to investigate why this is called multiple times
        console.log('controlButtons func, id:' + id);
        console.log('indTradeCancel:' + tradeCaptureDisplayState.tradeEntry.indTradeCancel);

        if (isDisabled) {
            return (
                <Form onSubmit={(e) => e.preventDefault()}>
                    <Row className="py-3">
                        <Col lg={10} className="d-none d-md-block"></Col>
                        <Col xs={12} sm={12} md={2} className="pt-2">
                            <button className="btn btn-block td-btn-secondary-clear" onClick={() => handleBack()}>Back
                            </button>
                        </Col>
                    </Row>
                </Form>
            );
        }

        else if (id === 'ACCEPT') {
            return (
                <Row className="py-3">
                    <Col md={6} className="d-none d-md-block"></Col>
                    <Col xs={4} sm={4} md={2} className="pt-2">
                        <button className="btn btn-block td-btn-secondary-clear" onClick={() => handleBack()}>Back
                        </button>
                    </Col>
                    <Col xs={4} sm={4} md={2} className="pt-2">
                        <button disabled={disableSubmit} className="btn btn-block td-btn-CTA"
                                onClick={handleCancel}>Cancel Trade
                        </button>
                    </Col>
                    <Col xs={4} sm={4} md={2} className="pt-2">
                        <button disabled={disableSubmit} className="btn btn-block td-btn-primary-light" type={'submit'}
                                onClick={handleRebook}>Rebook
                        </button>
                    </Col>
                </Row>
            );
        }

        else if (id === 'TradeBreak') {
            return (
                <Row className="py-3">
                    <Col lg={8} className="d-none d-md-block"></Col>
                    <Col xs={6} sm={6} md={2} className="pt-2">
                        <button className="btn btn-block td-btn-secondary-clear"
                                onClick={() => handleBack()}>Back
                        </button>
                    </Col>
                    <Col xs={6} sm={6} md={2} className="pt-2">
                        <button disabled={disableSubmit} className="btn btn-block td-btn-primary-light" type={'submit'}
                                onClick={handleRebook}>Submit
                        </button>
                    </Col>
                </Row>
            );
        }
        else {
            return (
                <Row className="py-3">
                    <Col lg={8} className="d-none d-md-block"></Col>
                    <Col xs={6} sm={6} md={2} className="pt-2">
                        <button className="btn btn-block td-btn-secondary-clear" href="#" onClick={(event) => {
                            setDisableSubmit(false);
                            handleOnClear(event)
                        }}>Clear
                        </button>
                    </Col>
                    <Col xs={6} sm={6} md={2} className="pt-2">
                        <button className="btn btn-block td-btn-primary-light" type={'submit'}
                                onClick={handleSubmit}>Submit
                        </button>
                    </Col>
                </Row>
            );
        }
    }

    const tabletFilter = function() {

        return(
            <Row className="pb-2 pl-0">
                <Col xs={12} sm={10} md={4} className="p-0">
                    <EntityAccountsComponent isReadOnly={tradeCaptureDisplayState.tradeEntry.managerId !== ""}/>
                </Col>
            </Row>
        );

    }

    return (<>

        <Container fluid>
            <Row className="justify-content-start navbar td-" style={{paddingTop:20, paddingBottom:10}}>
                <Col style={{paddingLeft: 0}} xs={"auto"}>
                    {header()}
                </Col>
            </Row>
            <Row>
                <Col>
                    {tabletFilter()}
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr className="td-thin-divider-line-2 pt-3"></hr>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Row>
                            <Col xs={12} sm={5} md={5} xl={2} lg={4} className="pt-0">
                                <Form.Group controlId="formGridTradeType" className={'td-select'}>
                                    <Form.Label>Trade Type *</Form.Label>
                                    <Form.Control disabled={isDisabled} as="select" name={"tradeType"} value={tradeCaptureDisplayState.tradeEntry.tradeType} onChange={handleOnChange} isInvalid={!!errors.tradeType}>{
                                        tradeTypeJSON.map(trade => <option key={trade.id} value={trade.id}>{trade.name}</option>)}
                                    </Form.Control>
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.tradeType}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={9} sm={5} md={5} xl={1} lg={2} className="pt-0">
                                <Form.Group controlId="formGridQuantity" className="pt-0">
                                    <Form.Label>Quantity *</Form.Label>
                                    <Form.Control disabled={isDisabled} pattern={REGEX_TRADE_ENTRY_WHOLE_NUMBER} placeholder="0.00" name={"quantity"} value={tradeCaptureDisplayState.tradeEntry.quantity}
                                                  onChange={handleOnChange}
                                                  isInvalid={!!errors.quantity}/>
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.quantity}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={3} sm={2} md={2} xl={1} lg={2} className="pt-0">
                                <Form.Group controlId="formGridOptionCheckbox">
                                    <Form.Label>&nbsp;</Form.Label>
                                    <Form.Check disabled={isDisabled} type="checkbox" label="Option" value={tradeCaptureDisplayState.tradeEntry.securityOption} name={"securityOption"}
                                                onChange={handleOptionOnChange}
                                                defaultChecked={tradeCaptureDisplayState.tradeEntry.securityOption && tradeCaptureDisplayState.tradeEntry.securityOption == 'Y' ? true : false}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={5} md={5} xl={2} lg={4} className="pt-0">
                                <Form.Group controlId="formGridSecurity">
                                    <Form.Label ref={target}>Security *</Form.Label>
                                    <Form.Control disabled={isDisabled} placeholder="ABCD" name={"security"} value={tradeCaptureDisplayState.tradeEntry.security}
                                                  onChange={handleOnChange}
                                                  onBlur={handleSecurityOnBlur}
                                                  isInvalid={!!errors.security}
                                    />
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.security}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Overlay target={target.current} show={showToolTip} placement="right" >
                                <div style={{background:'yellow'}}>
                                    {secOption}
                                </div>
                            </Overlay>

                            <Col sm={5} md={5} xl={2} lg={4} className={"pt-0"}>
                                <Form.Group controlId="formGridSecurityDescription">
                                    <Form.Label>Security Description</Form.Label>
                                    {tradeCaptureDisplayState.tradeEntry.securityOption && tradeCaptureDisplayState.tradeEntry.securityOption == 'Y' ?
                                        <Form.Control disabled={isDisabled} as="select" name={"securityOptionSelected"} value={tradeCaptureDisplayState.tradeEntry.securityOptionSelected} onChange={handleOnSecurityOptionChange}>
                                            <option value="">Select</option>{
                                            tradeCaptureDisplayState.tradeEntry.securityOptionList.map(option => <option key={option['occSymbol']} value={option['occSymbol']}>{option['securityDes']}</option>)
                                        }
                                        </Form.Control>
                                        :
                                        <Form.Control placeholder="Read Only" value={tradeCaptureDisplayState.tradeEntry.securityDescription} disabled/>}


                                </Form.Group>
                            </Col>


                            <Col xs={6} sm={3} md={3} xl={1} lg={2} className="pt-0">
                                <Form.Group controlId="formGridPrice">
                                    <Form.Label>Price *</Form.Label>
                                    <Form.Control disabled={isDisabled} pattern={REGEX_DECIMAL_MATCH} placeholder="0.00" name={"price"} value={tradeCaptureDisplayState.tradeEntry.price} onChange={handleOnChange}
                                                  isInvalid={!!errors.price}/>
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.price}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={6} sm={2} md={2} xl={1} lg={2} className="pt-0">
                                <Form.Group controlId="formGridCurrency" className={'td-select'}>
                                    <Form.Label>Currency *</Form.Label>
                                    <Form.Control disabled={isDisabled} as="select" name={"currency"} value={tradeCaptureDisplayState.tradeEntry.currency} onChange={handleOnChange}>
                                        {
                                            currencies.map(item =>
                                                <option key={item['isoCurrencyCode']} value={item['isoCurrencyCode']}>{item['isoCurrencyCode']}</option>
                                            )
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col xs={6} sm={2} md={2} xl={1} lg={2} className="pt-0">
                                <Form.Group controlId="formGridBrokerCode">
                                    <Form.Label>Broker Code *</Form.Label>
                                    <Form.Control disabled={isDisabled || !entityAccounts.managerId} placeholder="ABCD" name={"brokerCode"} value={tradeCaptureDisplayState.tradeEntry.brokerCode} onChange={handleOnChange}
                                                  onBlur={handleBrokerOnBlur}
                                                  isInvalid={!!errors.brokerCode}/>
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.brokerCode}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={6} sm={5} md={5} xl={2} lg={2} className="pt-0">
                                <Form.Group controlId="formGridBroker">
                                    <Form.Label>Broker</Form.Label>
                                    <Form.Control placeholder="Read Only" value={tradeCaptureDisplayState.tradeEntry.brokerDescription} disabled/>
                                </Form.Group>
                            </Col>


                            <Col xs={6} sm={5} md={5} xl={1} lg={2} className="pt-0">
                                <Form.Group controlId="formGridCommType" className={'td-select'}>
                                    <Form.Label>Comm Type *</Form.Label>
                                    <Form.Control disabled={isDisabled} as="select" name={"commissionType"} value={tradeCaptureDisplayState.tradeEntry.commissionType} onChange={handleOnChange}>
                                        {
                                            commissionTypeJSON.map(item =>
                                                <option value={item.id}>{item.name}</option>)
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col xs={6} sm={2} md={2} lg={2} xl={1} className="pt-0">
                                <Form.Group controlId="formGridComm">
                                    <Form.Label>Comm *</Form.Label>
                                    <Form.Control disabled={isDisabled} pattern={REGEX_DECIMAL_MATCH} placeholder="0.00" name={"commission"} value={tradeCaptureDisplayState.tradeEntry.commission} onChange={handleOnChange}
                                                  isInvalid={!!errors.commission}/>
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.commission}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={5} md={5} lg={4} xl={2} className="pt-0">
                                <Form.Group controlId="formGridTradeDate">
                                    <Form.Label>Trade Date *</Form.Label>
                                    <Form.Control disabled={isDisabled} type="date" name={"tradeDate"} value={tradeCaptureDisplayState.tradeEntry.tradeDate} onChange={handleOnChange}
                                                  onBlur={handleTradeDateBlur} isInvalid={!!errors.tradeDate}/>
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.tradeDate}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={5} md={5} lg={4} xl={2} className="pt-0">
                                <Form.Group controlId="formGridSettleDate">
                                    <Form.Label>Settle Date</Form.Label>
                                    <Form.Control disabled={isDisabled} type="date" name={"settleDate"} value={tradeCaptureDisplayState.tradeEntry.settleDate} onChange={handleOnChange}
                                                  isInvalid={!!errors.settleDate}/>
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.settleDate}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={6} sm={2} md={2} lg={1} xl={1} className="pt-0">
                                <Form.Group controlId="formGridAccInt">
                                    <Form.Label>Acc Int</Form.Label>
                                    <Form.Control disabled={isDisabled} placeholder="0.00" name={"accruedInterest"} value={tradeCaptureDisplayState.tradeEntry.accruedInterest} onChange={handleOnChange}
                                                  isInvalid={!!errors.accruedInterest}/>
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.accruedInterest}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={6} sm={5} md={5} lg={6} xl={3} className="pt-0">
                                <Form.Group controlId="formGridComments">
                                    <Form.Label>Comments</Form.Label>
                                    <Form.Control disabled={isDisabled} placeholder="" name={"comments"} value={tradeCaptureDisplayState.tradeEntry.comments} onChange={handleOnChange}
                                                  isInvalid={!!errors.comments}/>
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.comments}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={5} md={5} lg={2} xl={1} className="pt-0">
                                <Form.Group controlId="formGridClientID">
                                    <Form.Label>Client ID</Form.Label>
                                    <Form.Control disabled={isDisabled} placeholder="1" name={"clientId"} value={tradeCaptureDisplayState.tradeEntry.clientId} onChange={handleOnChange}
                                                  isInvalid={!!errors.clientId}/>
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.clientId}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={6} sm={2} md={2} lg={1} xl={1} className="pt-0">
                                <Form.Group controlId="formGridFxRate">
                                    <Form.Label>Fx Rate</Form.Label>
                                    <Form.Control disabled={isDisabled} pattern={REGEX_DECIMAL_MATCH} placeholder="1.00" name={"fxUSDRate"} value={tradeCaptureDisplayState.tradeEntry.fxUSDRate} onChange={handleOnChange}
                                                  isInvalid={!!errors.fxUSDRate}/>
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.fxUSDRate}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={5} md={5} lg={4} xl={2} className="pt-0">
                                <Form.Group controlId="formGridOverrideCheckbox">
                                    <Form.Label>Override</Form.Label>
                                    <div className="td-colour-bg-4" style={{paddingTop: 2, paddingBottom:5, paddingLeft: 15, background: "#f2f2f7"}}>
                                        <Form.Check  disabled={isDisabled} type="checkbox" value={tradeCaptureDisplayState.tradeEntry.isOverride === "false" ? "true" : "false"} label="Commission validation rules." name={"isOverride"} onChange={handleOnChange}/>
                                    </div>
                                </Form.Group>
                            </Col>

                        </Form.Row>
                    </Form>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div
                        ref={myGrid}
                        id="myGrid"
                        className="ag-theme-alpine trade-group-grid">
                        <AgGridReact
                            frameworkComponents={{
                                allocationRenderer: searchNameRenderer,
                                accountTypeRenderer: AccountTypeRenderer,
                                actionsRenderer: ActionsRenderer,
                            }}
                            paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                            autoGroupColumnDef={{
                                minWidth: 300,
                                cellRendererParams: {
                                    innerRenderer: (params) => {
                                        if (params.node.footer) {
                                            const isRootLevel = params.node.level === -1;
                                            if (isRootLevel) {
                                                return `<span style={{"color":"navy","fontWeight":"bold"}}><B>Total</B></span>`;
                                            }
                                            return `<span style={{"color":"navy"}}>Sub Total ${params.value}</span>`;
                                        }
                                        return params.value;
                                    },
                                },
                            }}
                            domLayout={"autoHeight"}
                            groupDefaultExpanded={'1'}
                            enableRowGroup={true}
                            groupIncludeTotalFooter={true}
                            suppressDragLeaveHidesColumns={true}
                            suppressMakeColumnVisibleAfterUnGroup={true}
                            suppressModelUpdateAfterUpdateTransaction={true}
                            suppressScrollOnNewData={true}
                            suppressAggFuncInHeader={true}
                            allowShowChangeAfterFilter={false}
                            rowGroupPanelShow={'never'}
                            showOpenedGroup={true}
                            groupHideOpenParents={false}
                            groupSuppressBlankHeader={true}
                            enableSorting={true}
                            enableFilter={false}
                            defaultColDef={defaultColDef}
                            columnDefs={updatedColumnDefs}
                            columnTypes={columnTypes}
                            rowData={rowData}
                            animateRows={true}
                            autoGroupColumnDef={autoGroupColumnDef}
                        >
                        </AgGridReact>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={0} lg={4} className="px-0"></Col>
                <Col xs={10} lg={3} className="pl-0 pt-0">
                    <Form.Group controlId="formTotalAllocation" className="pt-0 pl-3 pl-lg-0 mr-5 pr-5">
                        <Form.Control type={"hidden"} isInvalid={!!errors.totalAllocation} />
                        <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{errors.totalAllocation}</b></Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col></Col>
            </Row>
            {controlButtons()}
        </Container>

    </>);

}