import React, {createContext, useContext, useState} from "react";
import AuthenticationService, {PROFILE_SESSION_ATTRIBUTE_NAME} from "service/AuthenticationService";
import {SecurityContext} from "context/security";
import {responsiveFontSizes} from "@mui/material";
import Service from "../../../service/Service";
import axios from "axios";
import {COMMON_BUS_DATES} from "../../../service/UrlConstant";
export const Context = createContext({});
const initialState = {username:'',password:''};
export const COMMON_SESSION_ATTRIBUTE_BUSDATES = 'BusDates'
export const Provider = props => {


    const {children} = props;
    const logo = <img alt="logo" src="/assets/img/tds_shield.svg" height="40" className="p-mr-2"></img>;
    const [showInvalidLogin, setShowInvalidLogin] = useState(false);
    const [authenticationFailedMessage, setAuthenticationFailedMessage] = useState('Invalid request. Please contact Administrator.');
    const [loginState, setLoginState] = useState(JSON.parse(JSON.stringify(initialState)));
    const [formErrors, setFormErrors] = useState(JSON.parse(JSON.stringify(initialState)));
    const [loader, setLoader] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const {setNeedLogin, setSessionExpired} = useContext(SecurityContext);
    const validateLogin = ()=>{
        const errors = {}
        if(!loginState.username){
            errors['username'] = 'Please enter valid login Id'
        }
        if(!loginState.password){
            errors['password'] = 'Please enter valid password'
        }
        return errors
    }
    const login = (e) => {
        e.preventDefault();
        const errors = validateLogin()
        setFormErrors(errors)
        if(Object.keys(errors).length!=0){
            return
        }

        setLoader(true);
        setShowInvalidLogin(false)
        //AuthenticationService.logout(); //Clear any old session data.
        setSessionExpired(false);

        AuthenticationService.authenticateUser(loginState.username, loginState.password)
            .then((response) => {
                AuthenticationService.setSessionVariables(response);
                setLoader(false);
                setNeedLogin(false);
                setLoginState(JSON.parse(JSON.stringify(initialState)))

            }).catch((error) => {
            console.log("Login Error:, " , error)
            setLoader(false);
            setShowInvalidLogin(true);
            generateLoginFailedMessage(error);

        })

        AuthenticationService.getBusinessDates()
            .then((response) =>{
                sessionStorage.setItem(COMMON_SESSION_ATTRIBUTE_BUSDATES, JSON.stringify(response.data))
            }).catch((error) => {
                console.log("Error Fetching business dates:, ", error)
            }
        )


    }

    const mfaLoginOption=()=>{
        AuthenticationService.MFALoginOption();
    }

    const loginMFA = (code) => {
        setSessionExpired(false);
        AuthenticationService.authenticateMFAUser(code)
            .then((response) => {
                AuthenticationService.setSessionVariables(response);
                setNeedLogin(false);
                AuthenticationService.redirectToHome();
            }).catch((error) => {
            console.log("MFA Login Error:, " , error)

        })

        AuthenticationService.getBusinessDates()
            .then((response) =>{
                sessionStorage.setItem(COMMON_SESSION_ATTRIBUTE_BUSDATES, JSON.stringify(response.data))
            }).catch((error) => {
                console.log("Error Fetching business dates:, ", error)
            }
        )


    }

    const handleLoginOnChange = (e) => {
        setLoginState({...loginState,[e.currentTarget.name] : e.currentTarget.value})
        setFormErrors({...formErrors,[e.currentTarget.name]:''});
    }
    const generateLoginFailedMessage=(error)=> {
        if (error.response && error.response.statusText) {
            console.log(error.response.data)

            if (error.response.statusText == 'Locked') {
                setAuthenticationFailedMessage('User account is locked. Please contact Administrator.');
                return;
            }
            if (error.response.status == '401' && !(error.response.data.includes('Expire') || error.response.data.includes('expire'))) {
                setAuthenticationFailedMessage('User credentials incorrect. Please try again.');
                return;
            }
            if (error.response.status == '401' && (error.response.data.includes('Expire') || error.response.data.includes('expire'))) {
                setIsExpired(true);
                //Redirect to change password screen instead of displaying error message
                setShowInvalidLogin(false);
                setAuthenticationFailedMessage('User account is expired. Please contact Administrator.');
                return;
            }
            else{
                setIsExpired(false);
            }
        }
    }

    const clearLoginState = ()=>{
        setLoginState(JSON.parse(JSON.stringify(initialState)))
        setFormErrors(JSON.parse(JSON.stringify(initialState)))
    }
    const loginContext = {
        mfaLoginOption:mfaLoginOption,
        loginMFA:loginMFA,
        logo:logo,
        showInvalidLogin:showInvalidLogin,
        authenticationFailedMessage:authenticationFailedMessage,
        login:login,
        loginState:loginState,
        username:loginState.username,
        handleLoginOnChange:handleLoginOnChange,
        formErrors:formErrors,
        loader:loader,
        setShowInvalidLogin:setShowInvalidLogin,
        setAuthenticationFailedMessage:setAuthenticationFailedMessage,
        setLoader:setLoader,
        validateLogin:validateLogin,
        isExpired:isExpired,
        setIsExpired:setIsExpired,
        clearLoginState:clearLoginState
    }

    return <Context.Provider value={loginContext}>{children}</Context.Provider>

};

export const {Consumer} = Context;