import {Col, Container, Form, Row} from "react-bootstrap";
import {ThreeDots} from "react-loader-spinner";
import React, {useContext} from "react";
import {LoginContext} from "./context";


export default function LogoutFormComponent(){
    const {logo, loader,mfaLoginOption } = useContext(LoginContext);
    return(<Container className="mt-md-3 px-4">
        <Row>
            <Col xs={12}>{logo}</Col>
            <Col xs={12}>&nbsp;</Col>
            <Col xs={12} className={'py-0 mb-3'}><h3><b>Welcome to Prime Brokerage</b></h3></Col>
            <Col xs={12} className={'pt-0'}><h1>Client Login </h1></Col>
            <Col xs={12} className={'pt-0'}><p>Access for authorized users only</p></Col>
        </Row>
        <Form onSubmit={e=>e.preventDefault()}>
            <Row>
                <Col md={'12'}>
                    <button className="btn btn-block td-btn-primary-light my-3" onClick={()=>mfaLoginOption()}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>{loader ?
                            <ThreeDots color="white" height={20} width={40}/> : 'Login'}</div>
                    </button>
                </Col>
            </Row>
        </Form>
        <Row>
            <Col xs={12} className={'pt-0'}><h2 className={'black-font h3'} style={{letterSpacing: '-0.3px'}}>We are here to help</h2></Col>
            <Col xs={12} className={'pt-0'}><h2 className={'black-font h3'} style={{letterSpacing: '-0.3px'}}>Call 1-212-827-2888</h2></Col>
            <Col xs={12} className={'pt-0 pr-0 mr-0 '}><p style={{fontSize: '11px',  letterSpacing: '-0.1px'}}>To speak to our TD Prime Services sales team</p>
            </Col>
        </Row>
        <Row>
            <Col xs={12} className={'pt-2'}><h2 className={'black-font h3'}>Service Announcements</h2></Col>
            <Col xs={12} className={'pt-0'}><p style={{fontSize: '11px', letterSpacing: '-0.2px'}}>TD Securities offers comprehensive Prime Brokerage services.</p></Col>
        </Row>
        <br/>
    </Container>)
}