import React, {useContext, useEffect, useState} from 'react';
import {LoginContext} from "./context";
import ErrorFilter from "../error/ErrorFilter";
import LoginPanel from "./LoginPanel";
import SidePanelLoginLayout from "../../layouts/SidePanelLoginLayout";
import ErrorSpinner from "../error/ErrorSpinner";

export default function MFALogin(props) {
    const {code} = props;
    const {loginMFA } = useContext(LoginContext);
    const [showError, setShowError] = useState(false);

    useEffect(()=>{
        setTimeout(() => { setShowError(true) }, 3000);
        loginMFA(code)
},[])

    return(<>
        {!showError &&
           <ErrorSpinner/>
        }
        {showError && <SidePanelLoginLayout
            sideComponent={ErrorFilter}
            mainComponent={LoginPanel}
        >
        </SidePanelLoginLayout>
        }
    </>);
}