import React from 'react';
import ReactDOM from 'react-dom';
import AppLayout from "./layouts/AppLayout";
import {BrowserRouter as Router} from "react-router-dom";

import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'td-emerald-standards/dist/emerald/assets/css/td-standards-utilities.css'
import 'td-emerald-standards/dist/emerald/assets/css/extra/td-borders.css'
import 'td-emerald-standards/dist/emerald/assets/css/extra/td-colour-palette.css'
import 'td-emerald-standards/dist/emerald/assets/css/extra/td-spacers.css'
import 'td-emerald-standards/dist/emerald/assets/css/td-standards.css'
import './index.css'

import {LicenseManager} from "ag-grid-enterprise";
import {SecurityContextProvider} from "./context/security";
import {LoginContextProvider} from "./pages/login/context";
LicenseManager.setLicenseKey("CompanyName=Softchoice LP (Toronto)_on_behalf_of_TORONTO-DOMINION BANK, THE,LicensedGroup=Prime Brokerage,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=1,AssetReference=AG-016790,ExpiryDate=29_June_2022_[v2]_MTY1NjQ1NzIwMDAwMA==f268b5324c9b434786eddba1ad026277");

ReactDOM.render(
    <React.StrictMode>
       <Router>
           <SecurityContextProvider>
               <LoginContextProvider>
               <AppLayout/>
               </LoginContextProvider>
           </SecurityContextProvider>

       </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

