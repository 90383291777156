import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {Col, Container, Row} from "react-bootstrap";
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import useWindowDimensions from "hooks/Dimensions";
import {ShortSaleContext} from "./context";
import {useHistory} from "react-router-dom";
import AgGridToolbar from "components/AgGridToolbar/AgGridToolbar";
import {
    SHORT_SALE_URL_DELETE_PERSIST_FILE,
    SHORT_SALE_URL_GET_PERSIST_FILE_DETAILS,
    SHORT_SALE_URL_RENAME_PERSIST_FILE
} from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {ActionPanelContext} from "context/action";
import * as Routes from "routes/Routes";
import {cellStyleChecker, numberFormatter} from "utils/NumberUtils";
import {
    DEFAULT_GRID_PAGINATION_SIZE,
    ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE,
    GRID_COLUMN_PROPERTY
} from "utils/Constants";
import {stringDateFormatter} from "utils/FormatUtils";
import {addPropertiesToGridColumns, getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";
import {UtilsContext} from "../../context/utils";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";
import {createExcelExportParams, getFilteredColumns} from "../../utils/ExcelExportUtils";

export default function ShortSalePersistDetails() {
    const { hiddenExportGridApi, showButton, setShowButton} = useContext(UtilsContext);
    let history = useHistory();
    const [rowData, setRowData] = useState([]);
    const {shortSalePersistState, handlePageRequest, setShortSalePersistState} = useContext(ShortSaleContext);
    const {isDesktop, isMobile } = useWindowDimensions();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const {makePostRequestWithParams} = useContext(SecurityContext);
    const { setHandleExportFunction, setHandleRenameFunction, setHandleDeleteFunction } = useContext(ActionPanelContext);
    const ssaPersistDetailsGrid = useRef(null);

    function navigateToPage(path) {
        history.push(path);
    }

    useEffect(() => {
        if(shortSalePersistState && shortSalePersistState.resData){
            setRowData([...shortSalePersistState.resData]);
        }else{
            setRowData([])
        }

    }, []);

    const columnTypes ={
        firstColType:{
            minWidth: isMobile?200:350,
            suppressSizeToFit: true,
        },
        actionColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            pinned:isDesktop?'':'right',
            width:isDesktop?200:100,
        },
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
        }
    }

    const cellClicked=( params )=> {
        params.node.setSelected(true);
        params.api.ensureIndexVisible(params.node.rowIndex,'top');
    }

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:!!isMobile,
    };

    const updatedColumnDefs = useMemo(() => addPropertiesToGridColumns(columnDefs, GRID_COLUMN_PROPERTY), []);

    const exportSSPersistDetails = (type, rowData, gridApi, onSuccess, onError) => {
        hiddenExportGridApi.current.api.setColumnDefs(columnDefs);
        //Set Data
        hiddenExportGridApi.current.api.setRowData(rowData)
        let filteredColumnNames = getFilteredColumns(ssaPersistDetailsGrid);
        //Export as csv/excel
        type==='EXCEL' ?
            hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams('SSA Persist Details', filteredColumnNames)) :
            hiddenExportGridApi.current.api.exportDataAsCsv({ columnKeys: filteredColumnNames})

    }

    const updatePersistFileName = (listName, gridApi, onSuccess, onError) => {
        const selectedRow = shortSalePersistState.resData[0];
        const newShortSalePersistState = {...shortSalePersistState}
        const onSuccessResponse = (response) => {
            newShortSalePersistState.savedName = listName
            setShortSalePersistState({...newShortSalePersistState})
            if(onSuccess) onSuccess();
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        makePostRequestWithParams({}, {
                manager:selectedRow.manager,
                counterPartyAcct:selectedRow.counterPartyAcct,
                requestFileName:selectedRow.requestFileName,
                renamedFileName:listName
            },
            SHORT_SALE_URL_RENAME_PERSIST_FILE, onSuccessResponse, onErrorResponse);

    };

    const deletePersistFile = (gridApi, onSuccess, onError) => {
        const selectedRow = shortSalePersistState.resData[0];
        const onSuccessResponse = (response) => {
            if(onSuccess) onSuccess();
            navigateToPage(Routes.SHORTSALE_APPROVAL_HISTORY)

        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        makePostRequestWithParams({},  {
                manager:selectedRow.manager,
                counterPartyAcct:selectedRow.counterPartyAcct,
                requestFileName:selectedRow.requestFileName,
            },
            SHORT_SALE_URL_DELETE_PERSIST_FILE, onSuccessResponse, onErrorResponse);


    };


    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setHandleExportFunction({handleExport: exportSSPersistDetails, gridApi: params.api})
        setHandleRenameFunction({handleRename: updatePersistFileName, gridApi: params.api});
        setHandleDeleteFunction({handleDelete: deletePersistFile, gridApi: params.api});


    }
    const onDataRendered = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    return(<>

        <Container fluid>
            <Row className="pt-3 pb-2 pt-md-4 pb-md-3">
                <Col>
                    <h1>SSA History</h1>
                </Col>
            </Row>
            <Row className="pb-1 pb-md-2">
                <Col>
                    <h3>Saved Maintainable SSA Request List</h3>
                </Col>
            </Row>
            <AgGridToolbar ssaPersistDetails={true}
                           fileName={shortSalePersistState.savedName}
                           title={shortSalePersistState.savedName}
                           onPageRequest={handlePageRequest}
                           gridApi={gridApi}
                           gridColumnApi={gridColumnApi}
                           columnDefs={updatedColumnDefs}
                           allowSpecialCharacters={true}
            />
            <div
                id="persistLocateGrid"
                className="ag-theme-alpine">
                <AgGridReact
                    ref={ssaPersistDetailsGrid}
                    paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                    domLayout={'autoHeight'}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    suppressSizeToFit={true}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={false}
                    pagination={true}
                    defaultColDef={defaultColDef}
                    columnDefs={updatedColumnDefs}
                    columnTypes={columnTypes}
                    rowData={rowData}
                    animateRows={true}
                    onCellClicked={cellClicked}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onDataRendered}
                    onPaginationChanged={onPaginationChange}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
    </>);

}

export  const columnDefs = [
    {headerName: 'Requested Date', field: 'requestedOn', valueFormatter: stringDateFormatter },
    {headerName: 'Manager', field: 'manager'},
    {headerName: 'Security', field: 'srcSecCd'},
    {headerName: 'Quantity', field: 'requestedQty', valueFormatter: numberFormatter, cellStyle: cellStyleChecker},
    {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
]