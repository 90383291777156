import axios from "axios";
import AuthenticationService from "./AuthenticationService";

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    (config) => {
        if (AuthenticationService.isUserAuthenticated()) {
            config.headers['Authorization'] =  `Bearer ${AuthenticationService.getUserAuthenticationToken()}`;
            config.headers['OpmToken'] = AuthenticationService.getUserAuthenticationOpmToken();
        }
        return config
    }, (error) => {
        return Promise.reject(error);
    }
);



class Service {



    async makePostRequest(data, url) {
        if (AuthenticationService.isUserAuthenticated()) {
            return await axios.post(url, data);
        } else {
            this.handleError()
        }
    }
    async makePostRequestWithParam(data,params, url) {
        if (AuthenticationService.isUserAuthenticated()) {
            return await axios.post(url, data,{params : params});
        } else {
            this.handleError()
        }
    }


    async makeGetRequest(url, params) {
        if (AuthenticationService.isUserAuthenticated()) {
            return await axios.get(url, {params:params});
        } else {
            this.handleError()
        }
    }

    async makeGetRequestNoParams(url) {
        if (AuthenticationService.isUserAuthenticated()) {
            return await axios.get(url);
        } else {
            this.handleError()
        }
    }

    async makePutRequest(data, url) {
        if (AuthenticationService.isUserAuthenticated()) {
            return await axios.put(url, data);
        } else {
            this.handleError()
        }

    }

    async makePutRequestWithParams(data, url, params) {
        if (AuthenticationService.isUserAuthenticated()) {
            return await axios.put(url, data, {
                params
            });
        } else {
            this.handleError()
        }

    }

    async makeDeleteRequest(url) {
        if (AuthenticationService.isUserAuthenticated()) {
            return await axios.delete(url);
        } else {
            this.handleError()
        }
    }

    async makePatchRequestWithParams(url,params) {
        if (AuthenticationService.isUserAuthenticated()) {
            return await axios.patch(url,null,{
                params
            });
        } else {
            this.handleError()
        }
    }

    handleError(){
        console.error('JWT Token not found');
    }

}

export default new Service();
