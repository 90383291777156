import React from 'react';
import SidePanelLayout from "layouts/SidePanelLayout";
import HomeFilter from "./HomeFilter";
import HomePanel from "./HomePanel";


export default function Home() {

    return(<>
        <SidePanelLayout
            sideComponent={HomeFilter}
            mainComponent={HomePanel}
            title="Accounts"
            refreshOnToggle={true}
        >
        </SidePanelLayout>
    </>);

}
