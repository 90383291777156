import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import AppFooter from "layouts/AppFooter";
import LogoutFormComponent from "../login/LogoutFormComponent";
import ErrorComponent from "./ErrorComponent";

export default function ErrorFilter() {
    return (
        <Container fluid className="pt-6 pt-md-4 min-vh-100"
                   style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            {<ErrorComponent/>}
            <Container fluid className={'p-0'}>
            <Row className={'p-0'}>
            <Col className={'p-0'}>
            <AppFooter isLogin={true}/>
            </Col>
            </Row>
            </Container>

        </Container>
    );
}
