import React, {useState, useEffect} from "react";


export default function ScrollButton (props) {
    const [topButtonVisible, setTopButtonVisible] = useState(false);
    const [bottomButtonVisible, setBottomButtonVisible] = useState(true);


    useEffect(()=>{
        const handleScroll = ()=>{
            const shouldShow = window.scrollY  > 100;
            const nearBottom = window.innerHeight + window.scrollY >=document.body.offsetHeight - 100;
            const nearTop = window.scrollY < 100;


            setTopButtonVisible(shouldShow && nearBottom);
            setBottomButtonVisible(nearTop)
        }

        window.addEventListener('scroll', handleScroll);

        return ()=> {
            window.removeEventListener('scroll', handleScroll)
        }
    },[])

    const scrollToTop = ()=>{
        window.scrollTo({
            top:0,
            behavior: 'smooth'
        })
    }

    const scrollToToBottom = ()=>{
        window.scrollTo({
            top:document.body.scrollHeight,
            behavior: 'smooth'
        })
    }

    return<>
        {topButtonVisible && (
            <button
                className={`scroll-to-top ${topButtonVisible ? 'show' : ''}`}
                onClick={scrollToTop}>
            <span
                className={'td-icon td-icon-upCaret td-copy-green icon-small'}>
            </span>
                Top
            </button>)}

        {bottomButtonVisible && (
            <button
                className={`scroll-to-bottom ${bottomButtonVisible ? 'show' : ''}`}
                onClick={scrollToToBottom}>
            <span
                className={'td-icon td-icon-downCaret td-copy-green icon-small'}>
            </span>
                Bottom
            </button>)}
    </>
}