import React, {useContext} from 'react';
import {Route, Switch} from "react-router-dom";
import Home from "pages/home/Home";
import TradeEntryPanel from "pages/tradecapture/TradeEntryPanel";
import TradeUpload from "pages/tradecapture/TradeUpload";
import Trade from "pages/tradecapture/Trade";
import Queries from "pages/query/Queries";
import Reports from "pages/reports/Reports";
import Wires from "pages/wires/Wires";
import Mmf from "pages/disclaimer/MMF";
import ShortSaleApprovals from "pages/shortsale/ShortSaleApprovals";
import ProfilePanel from "pages/profile/ProfilePanel";
import ChangePasswordPanel from "pages/profile/ChangePasswordPanel";
import PasswordResetSuccess from "pages/profile/PasswordResetSuccess";
import ShortSaleUpload from "pages/shortsale/ShortSaleUpload";
import ShortSaleHistory from "pages/shortsale/ShortSaleHistory";
import ShortSalePersistDetails from "pages/shortsale/ShortSalePersistDetails";
import QueriesPanel from "../pages/query/QueriesPanel";
import DefaultHome from "pages/home/DefaultHome";
import UserRoleService, {
    HOME_MENU_PERMISSION_KEY,
    QUERIES_MENU_PERMISSION_KEY,
    REPORTS_MENU_PERMISSION_KEY, SSA_MENU_PERMISSION_KEY, TRADE_MENU_PERMISSION_KEY,
    WIRES_MENU_SUBMITTER_PERMISSION_KEY, WIRES_MENU_CLIENT_APPROVER_PERMISSION_KEY, TRADE_MENU_UPLOAD_PERMISSION_KEY, WIRES_MENU_VIEW_ONLY_PERMISSION_KEY
} from "../service/UserRoleService";
import TradeBreaks from "pages/tradecapture/TradeBreaks";
import {ShortSaleContext} from "../pages/shortsale/context";

export const HOME = "/";
export const QUERIES = "/reporting/queries";
export const QUERIES_PANEL = "/reporting/QueriesPanel";
export const REPORTS = "/reporting";
export const WIRES = "/asset-transfers/wires";
export const TRADECAPTURE_LIST = "/trading/list";
export const TRADECAPTURE_ENTRY = "/trading/trade-entry-panel";
export const TRADECAPTURE_UPLOAD = "/trading/upload";
export const TRADECAPTURE_BREAKS = "/trading/breaks";
export const MMF = "/disclaimer/MMF";
export const SHORTSALE_APPROVAL_PERSIST_DETAILS = "/shortsale-approval-persist-details";
export const SHORTSALE_APPROVAL_HISTORY = "/shortsale-approval-history";
export const SHORTSALE_APPROVAL_LOCATE = "/shortsale-approval-locate";
export const SHORTSALE_APPROVAL_UPLOAD = "/shortsale-approval-upload";
export const SHORTSALE_APPROVAL_SEARCH = "/shortsale-approval-search"
export const SHORTSALE_APPROVAL_PASTE_FROM_CLIPBOARD = "/shortsale-approval-paste-from-clipboard"
export const PROFILE = "/Profile/ProfilePanel";
export const CHANGE_PASSWORD = "/Profile/ChangePasswordPanel";
export const CHANGE_PASSWORD_SUCCESS = "/Profile/PasswordResetSuccess";
export default function Routes() {

    const {setShowBadge} = useContext(ShortSaleContext);
    const checkPermission = (menuKey,menuName='') => {
        let permission = UserRoleService.hasPermission(menuKey)
        if(![SHORTSALE_APPROVAL_LOCATE, SHORTSALE_APPROVAL_UPLOAD, SHORTSALE_APPROVAL_PASTE_FROM_CLIPBOARD,SHORTSALE_APPROVAL_SEARCH].includes(menuName) && permission) {
            setShowBadge(false)
        }
        return permission;
    }

    return(
        <>
            <Switch>
                <Route exact path={HOME} render={()=> checkPermission(HOME_MENU_PERMISSION_KEY) ? <Home/> : <DefaultHome/> }/>
                <Route exact path={QUERIES} render={()=> checkPermission(QUERIES_MENU_PERMISSION_KEY) ? <Queries/> : <DefaultHome/> }/>
                <Route exact path={QUERIES_PANEL} render={()=> checkPermission(QUERIES_MENU_PERMISSION_KEY) ? <QueriesPanel/> : <DefaultHome/> }/>
                <Route exact path={REPORTS} render={()=> checkPermission(REPORTS_MENU_PERMISSION_KEY) ? <Reports/> : <DefaultHome/> }/>
                <Route exact path={WIRES} render={()=> checkPermission(WIRES_MENU_SUBMITTER_PERMISSION_KEY) || checkPermission(WIRES_MENU_CLIENT_APPROVER_PERMISSION_KEY)  || checkPermission(WIRES_MENU_VIEW_ONLY_PERMISSION_KEY) ? <Wires/> : <DefaultHome/> }/>
                <Route exact path={TRADECAPTURE_LIST} render={()=> checkPermission(TRADE_MENU_PERMISSION_KEY) ? <Trade/> : <DefaultHome/> }/>
                <Route exact path={TRADECAPTURE_ENTRY + "/:id"} render={()=> checkPermission(TRADE_MENU_PERMISSION_KEY) ? <TradeEntryPanel/> : <DefaultHome/> }/>
                <Route exact path={TRADECAPTURE_ENTRY} render={()=> checkPermission(TRADE_MENU_PERMISSION_KEY) ? <TradeEntryPanel/> : <DefaultHome/> }/>
                <Route exact path={TRADECAPTURE_UPLOAD} render={()=> checkPermission(TRADE_MENU_UPLOAD_PERMISSION_KEY) ? <TradeUpload/> : <DefaultHome/> }/>
                <Route exact path={TRADECAPTURE_BREAKS} render={()=> checkPermission(TRADE_MENU_PERMISSION_KEY) ? <TradeBreaks/> : <DefaultHome/> }/>
                <Route exact path={MMF} component={Mmf}/>
                <Route exact path={SHORTSALE_APPROVAL_LOCATE} render={()=> checkPermission(SSA_MENU_PERMISSION_KEY,SHORTSALE_APPROVAL_LOCATE) ? <ShortSaleApprovals/> : <DefaultHome/> }/>
                <Route exact path={SHORTSALE_APPROVAL_HISTORY} render={()=> checkPermission(SSA_MENU_PERMISSION_KEY) ? <ShortSaleHistory/> : <DefaultHome/> }/>
                <Route exact path={SHORTSALE_APPROVAL_UPLOAD} render={()=> checkPermission(SSA_MENU_PERMISSION_KEY,SHORTSALE_APPROVAL_UPLOAD) ? <ShortSaleApprovals/> : <DefaultHome/> }/>
                <Route exact path={SHORTSALE_APPROVAL_SEARCH} render={()=> checkPermission(SSA_MENU_PERMISSION_KEY,SHORTSALE_APPROVAL_SEARCH) ? <ShortSaleApprovals/> : <DefaultHome/> }/>
                <Route exact path={SHORTSALE_APPROVAL_PASTE_FROM_CLIPBOARD} render={()=> checkPermission(SSA_MENU_PERMISSION_KEY,SHORTSALE_APPROVAL_PASTE_FROM_CLIPBOARD) ? <ShortSaleApprovals/> : <DefaultHome/> }/>
                <Route exact path={SHORTSALE_APPROVAL_PERSIST_DETAILS} render={()=> checkPermission(SSA_MENU_PERMISSION_KEY) ? <ShortSalePersistDetails/> : <DefaultHome/> }/>
                <Route exact path={PROFILE} component={ProfilePanel}/>
                <Route exact path={CHANGE_PASSWORD} component={ChangePasswordPanel}/>
                <Route exact path={CHANGE_PASSWORD_SUCCESS} component={PasswordResetSuccess}/>
           </Switch>
        </>
    );
}

