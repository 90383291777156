import React, {useContext} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {FilterContext} from "../layouts/SidePanelLayout";

export default function PageHeader(props) {

    let headerText = props.text;
    const {showFilter, setShowFilter} = useContext(FilterContext);

    return(<>
        <Container fluid className={"pt-1 pt-md-3 " + props.bottomPadding}>
            <Row className="pb-2 pb-md-3">
                <Container fluid>
                    <Row>
                        <Col xs={11} className="px-0"><h1>{headerText}</h1></Col>
                        <Col xs={1} className="p-0 text-right"><i className="td-icon icon-regular td-icon-filter d-block d-md-none" onClick={()=>{setShowFilter(!showFilter)}}></i></Col>
                    </Row>
                </Container>
            </Row>
            <Row>
                <Col style={{padding:0}}>{props.children}</Col>
            </Row>
        </Container>
    </>);
}

PageHeader.defaultProps = {
    bottomPadding:"pb-3"
}