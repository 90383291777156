import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Container} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import ActionsRenderer from "components/ActionsRenderer";
import useWindowDimensions from "hooks/Dimensions"
import ActionPanelLayout from "layouts/ActionPanelLayout";
import PageHeader from "components/PageHeader";
import {SecurityContext} from "context/security";
import * as URL from "service/UrlConstant"
import {dateToString, stringToDate} from "utils/FormatUtils";
import {ActionPanelContext} from "context/action";
import {QueriesPanelContext} from "./context";
import {ToastContext} from "context/Toast";
import {UtilsContext} from "context/utils"
import {queryGridDataColumnDefs as gridDataColumnDefs} from "./QueryColumnDefs"
import {QUERY_PANEL_VIEW_QUERY_PAGE} from "./context/QueriesPanelContext";
import {DEFAULT_GRID_PAGINATION_SIZE, ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE} from "utils/Constants";
import {createExcelExportParams, getFilteredColumns} from "../../utils/ExcelExportUtils";
import {GRID_COLUMN_PROPERTY} from '../../utils/Constants';
import {addPropertiesToGridColumns, getDisplayedNumberOfGridRows} from '../../utils/gridColumnUtils'
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";

export default function QueriesSavedPanel(props) {

    const {setExportFilename, hiddenExportGridApi, paginationPageSize, showButton, setShowButton} = useContext(UtilsContext);
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [actionForAccount,setActionForAccount] = useState('');
    const savedQueryGrid = useRef(null);

    const {makeGetRequest, makeDeleteRequest, makePatchRequestWithParams, makePostRequest} = useContext(SecurityContext)
    const {setHandleDeleteFunction, setHandleRenameFunction, setSavedSearchName,setHandleExportFunction} = useContext(ActionPanelContext);
    const {queryResultsDisplayState, setQueryResultsDisplayState, displayQueryPanelPage} = useContext(QueriesPanelContext);

    const myGrid = useRef(null);
    const {isDesktop, isMobile} = useWindowDimensions();
    const {success, alert, error} = useContext(ToastContext);

    const querySearchDataValueGetter = function (params) {
        let querySearchData = {
            savedQueryId: params.data.savedQueryId,
            savedQueryName:params.data.savedQueryName,
            accountId:params.data.accountId,
            clientCode: params.data.clientCode,
            queryName: params.data.queryName,
            startDate: params.data.startDate,
            endDate: params.data.endDate
        }
        return querySearchData;
    }

    const columnTypes ={
        actionColType: {
            sortable: false,
            resizable: false,
            floatingFilter: true,
            filter: true,
            enableCellChangeFlash: false,
            pinned:isDesktop?'':'right',
            width:isDesktop?250:100,
        },
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: true,
            filter: true,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
        }
    }

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:isMobile?true:false,
    };

    const getSavedQueries = () => {

        const onSuccessResponse = (response) => {
            if(!response.data || response.data.length === 0){
                alert('No saved Queries found');
            }
            else {
                setRowData([...response.data]);
            }
        }

        const onErrorResponse = (err) => {
            const msg = 'Error on retrieving Saved Query list';
            console.log(msg + ": ", err);
            error(msg);
        }

        makeGetRequest(URL.QUERIES_QUERY_SAVED_USER, {}, onSuccessResponse, onErrorResponse);
    }

    const updateSavedQuery = (savedName, gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];
        const params = {
            "savedNameUpdated":savedName,
        }
        const onSuccessResponse = (response) => {
            selectedRow.savedQueryName = savedName;
            gridApi.applyTransaction({update: [selectedRow]});
            if(onSuccess) onSuccess();
            success('Query name saved successfully.');
        }

        const onErrorResponse = (err) => {
            if(onError) onError(err);
        }

        makePatchRequestWithParams(
            URL.QUERIES_QUERY_SAVE + "/" + selectedRow.savedQueryId , params, onSuccessResponse, onErrorResponse);
    };


    const deleteSavedQuery = (gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];
        const onSuccessResponse = (response) => {
            gridApi.applyTransaction({remove: [selectedRow]});
            if(onSuccess) onSuccess();
            success('Query deleted successfully.');
        }

        const onErrorResponse = (err) => {
            const msg = "Error when updating the saved query name";
            console.error(msg + ": ", err);
            if(onError) onError(err);
            error(msg);
        }

        makeDeleteRequest(
            URL.QUERIES_QUERY_SAVE + "/" + selectedRow.savedQueryId, onSuccessResponse, onErrorResponse);
    };

    useEffect(() => {
        getSavedQueries();
    }, [])

    const loadSavedQueryDetails = (savedSearchQueryData) => {

        const params = {
            clientCode:savedSearchQueryData.accountCode,
            queryId:savedSearchQueryData.queryId,
            startDate: (savedSearchQueryData.startDate ? savedSearchQueryData.startDate : ""),
            endDate: (savedSearchQueryData.endDate ? savedSearchQueryData.endDate : "")
        }

        const onSuccess = (response) => {
            if (!response.data || response.data.length === 0) {
                alert('No Queries found matching your saved search');
            } else {
                const queryResultsDisplay = JSON.parse(JSON.stringify(queryResultsDisplayState));
                queryResultsDisplay.resData =([...response.data])
                queryResultsDisplay.isSavedQuery = true;
                queryResultsDisplay.savedQueryId = savedSearchQueryData.savedQueryId;
                queryResultsDisplay.savedName = savedSearchQueryData.savedQueryName;
                queryResultsDisplay.querySearch = {
                    queryId : savedSearchQueryData.queryId,
                    queryName : savedSearchQueryData.queryName,
                    startDate : savedSearchQueryData.startDate,
                    endDate : savedSearchQueryData.endDate,
                    managerId : savedSearchQueryData.managerId,
                    managerName : savedSearchQueryData.managerNameLong,
                    subManagerId : savedSearchQueryData.subManagerId,
                    subManagerName : savedSearchQueryData.subManagerNameShort,
                    accountId : savedSearchQueryData.accountId,
                    accountName : savedSearchQueryData.accountNameShort,
                    accountCode : savedSearchQueryData.accountCode
                };

                queryResultsDisplay.savedNameListId = savedSearchQueryData.savedQueryId;
                setQueryResultsDisplayState(queryResultsDisplay);
                displayQueryPanelPage(QUERY_PANEL_VIEW_QUERY_PAGE);
            }
        }

        const onError = (err) => {
            const msg = "Error when retrieving Saved Query details";
            console.error(msg + ": ", err);
            error(msg);
        }
        makeGetRequest(URL.QUERIES_QUERY, params, onSuccess, onError);
    }

    const exportQueryList = (type, rowData, gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];
        const searchParam = {
            clientCode:selectedRow.accountCode,
            queryId:selectedRow.queryId,
            startDate: (selectedRow.startDate ? selectedRow.startDate : ""),
            endDate: (selectedRow.endDate ? selectedRow.endDate : "")
        }

        const onSuccessResponse = (response) => {
            //Set Column definition
            hiddenExportGridApi.current.api.setColumnDefs(gridDataColumnDefs.get(selectedRow.queryId));
            //Set Data
            hiddenExportGridApi.current.api.setRowData(response.data)
            let filteredColumnNames = getFilteredColumns(savedQueryGrid);
            //Export as csv/excel
            type==='EXCEL'?hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams(filteredColumnNames)):
                hiddenExportGridApi.current.api.exportDataAsCsv(filteredColumnNames)
        }

        const onErrorResponse = (err) => {
            const msg = "Error occurred when exporting the Query List";
            console.error(msg + ": ", err);
            if(onError) onError(err);
            error(msg);
        }

        makeGetRequest(URL.QUERIES_QUERY,searchParam, onSuccessResponse, onErrorResponse);
    };

    const cellClicked=(params)=> {
        params.node.setSelected(true)
        params.api.ensureIndexVisible(params.node.rowIndex,'top');
    }
    const handleActionIconClick = (field) => {
        setActionForAccount(field.savedQueryName);
        setSavedSearchName(field.savedQueryName);
        setShow(true);
    }

    const handleExportActionClicked = (searchData)=>{
        setShow(true);
        setShowExport(true);
    }

    const handleRenameActionClicked = (searchData)=>{
        setSavedSearchName(searchData.savedQueryName);
        setShow(true);
        setShowRename(true);
    }

    const handleDeleteActionClicked = (searchData)=>{
        setShow(true);
        setShowDelete(true);
    }

    function managerGetter(params) {
        return params.data.managerNameShort + "-" + params.data.managerNameLong;
    }

    function accountGetter(params) {
        return params.data.accountCode + "-" + params.data.accountNameLong;
    }

    const columnDefs = [
        {headerName: 'saved Query Id',field: 'savedQueryId',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'Manager Id',field: 'managerId',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'Sub Manager Id',field: 'subManagerId',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'client Code',field: 'clientCode',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'Account Id',field: 'accountId',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'Query Id',field: 'queryId',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'Name', field: 'savedQueryName', cellRenderer: "tradeNameRenderer"},
        {headerName: 'Entity', valueGetter: managerGetter},
        {headerName: 'Account', valueGetter: accountGetter},
        {headerName: 'Query', field:'queryName'},
        {headerName: 'Start Date', field: 'startDate', valueGetter: (params)=>stringToDate(dateToString(params.data.startDate))},
        {headerName: 'End Date', field: 'endDate', valueGetter: (params)=>stringToDate(dateToString(params.data.endDate))},
        {headerName: 'Actions',
            field: 'account',
            cellRenderer: "actionsRenderer",
            type:'actionColType',
            valueGetter:querySearchDataValueGetter,
            cellRendererParams:{
                clicked: (searchData) => handleActionIconClick(searchData),
                onAction1Clicked:(searchData)=>handleExportActionClicked(searchData),
                onAction2Clicked:(searchData)=>handleRenameActionClicked(searchData),
                onAction3Clicked:(searchData)=>handleDeleteActionClicked(searchData)}},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];

    const savedQueryColDefs = useMemo(() => addPropertiesToGridColumns(columnDefs, GRID_COLUMN_PROPERTY), []);

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setHandleRenameFunction({handleRename: updateSavedQuery, gridApi: params.api});
        setHandleDeleteFunction({handleDelete:deleteSavedQuery, gridApi: params.api});
        setHandleExportFunction({handleExport:exportQueryList, gridApi: params.api});
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);

    }

    const searchNameRenderer = function (props){
        return(<>
            <a className="td-link" href="#" onClick={() => {
                loadSavedQueryDetails(props.data);
            }}>{props.value}</a>
        </>);
    }
    const onDataRendered = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    return(<>

        <Container fluid>
            <PageHeader text="Query">
                <h3>Saved Searches</h3>
            </PageHeader>

            <div
                ref={myGrid}
                id="myGrid"
                className="ag-theme-alpine">
                <AgGridReact
                    ref={savedQueryGrid}
                    frameworkComponents={{
                        tradeNameRenderer: searchNameRenderer,
                        actionsRenderer: ActionsRenderer,
                    }}
                    paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                    domLayout={"autoHeight"}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    suppressSizeToFit={true}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={false}
                    pagination={true}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    onFirstDataRendered={onDataRendered}
                    columnDefs={savedQueryColDefs}
                    columnTypes={columnTypes}
                    rowData={rowData}
                    animateRows={true}
                    onCellClicked={cellClicked}
                    onPaginationChanged={onPaginationChange}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
        <ActionPanelLayout actionForAccount={actionForAccount} show={show} setShow={setShow} showRename={showRename} showExport={showExport} setShowExport={setShowExport} setShowRename={setShowRename} showDelete={showDelete} setShowDelete={setShowDelete} gridApi={gridApi}/>
    </>);
}
