import {Col, Row} from "react-bootstrap";
import React from "react";
import RightArrowButton from "./RightArrowButton";
export default function RefreshOptionsButton(props) {
    return (<>
        <Row onClick={props.onRefreshOptionsButtonClick}
             className={'d-sm-none'}>
            <Col xs={6} className={'py-2 pl-4'} style={{alignSelf: 'center'}}><p style={{float: 'left'}}>Refresh Options</p>
            </Col>
            <Col xs={6} className={'py-2 pr-2'} style={{alignSelf: 'center'}}> <RightArrowButton/></Col>
            <Col xs={12} className={'py-2 pr-0 pl-3'}>
                <hr/>
            </Col>
        </Row>
    </>)
}