import {Col, Form} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import useWindowDimensions from "hooks/Dimensions";


export default function ColumnsModal(props) {
    const {columnDefs, setColumnDefs, onColumnFilterClick, gridColumnApi} = props
    const {isMobile} = useWindowDimensions();
    let colDef = [...columnDefs];
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(true);

    const excludedFields = ['wireId', 'userCode', 'totApprovers', 'uiStatus', 'managerName', 'tradeCancelIndicator', 'cdBlockIdClient', 'version', ''];
    const checkExcludedFields = (headerName)=>{
        return !excludedFields.includes(headerName)
    }
    const hasHiddenColumns = ()=>{
        return columnDefs.some(col => col.hide === true && checkExcludedFields(col.field))
    }

    useEffect(()=>{
        if(Array.isArray(columnDefs)){
            if(hasHiddenColumns()) {
                setIsSelectAllChecked(false);
            } else {
                setIsSelectAllChecked(true)
            }
        }
    },[columnDefs])


    return (<>
        <Form.Group controlId="formGridState" className={"px-3"} style={{fontSize: "12px"}} >
            <Form.Group controlId="formBasicCheckbox"  className={'mb-0'}>
                <Form.Check type="checkbox"
                            onChange={(event) => {
                                colDef.map(e => e.hide = !!e.suppressColumnsToolPanel || !event.target.checked);
                                setColumnDefs(colDef);
                                setIsSelectAllChecked(!isSelectAllChecked);
                            }}
                            checked={isSelectAllChecked}
                            label='Select All'
                            className={'pl-3'}/>
            </Form.Group>
            {colDef.filter(e=>!e.suppressColumnsToolPanel && (e.field !== 'checkbox'  &&  e.field !== 'View')).map(e =>
                <Form.Group controlId="formBasicCheckbox" className={'mx-0'} key={e.headerName}>
                    <Form.Check type="checkbox"
                                onChange={() => {
                                    e.hide = !e.hide;
                                    setColumnDefs(colDef);
                                    if(e.hide)
                                        setIsSelectAllChecked(false)
                                }}
                                checked={!e.hide}
                                label={e.headerName}
                                className={'pl-3'}/>
                </Form.Group>)}
        </Form.Group>

        <Col md={12} xs={11} sm={12} className={isMobile?'stick-to-bottom':'p-0 px-3 mt-3'}>
            <button className="btn btn-block td-btn-primary-light" href="#" style={{fontSize: "12px"}}
                    onClick={() => {
                        onColumnFilterClick(colDef)
                    }}
            >Apply</button>
        </Col>
    </>)
}