export const validateValueOnRegex= (value, regexStr) => {
    let match = false
    if (regexStr && regexStr.trim().length > 0){
        if (value && value.length > 0 ){
            let regex = new RegExp(regexStr)
            let results = regex.exec(value)
            if (results && results.length === 1){
                match = (value === results[0])
            } else if (results && results.length > 1){
                // need match all results
                match = true
                for ( let result of results){
                    if (value !== result){
                        match = false
                        break
                    }
                }
            }
        }
    }
    return  match
}


export const validateValueOnList= (value, list) => {
    let match = false
    if (list && list.length>0) {
        if (value && value.length > 0 ){
            match = list.includes(value)
        }
    }
    return match
}

export const isValidDate = (dateString)=>{
    const regex = /^\d{4}(-?)(0[1-9]|1[0-2])\1(0[1-9]|[12][0-9]|3[01])$/;
    return regex.test(dateString)
}