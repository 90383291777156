import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Table} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import useWindowDimensions from "hooks/Dimensions";
import AuthenticationService from "service/AuthenticationService";
import {DEFAULT_GRID_PAGINATION_SIZE} from "utils/Constants";
import formatPhone from "utils/PhoneNumberUtils";

const defaultColDef = {
    sortable:true,
    menuTabs:false
};

export default function ProfilePanel(){

    const [rowData, setRowData] = useState([]);
    const {isDesktop} = useWindowDimensions();
    const profile = AuthenticationService.getProfile();

    const generateRowData = () => {
        let entityAccounts = AuthenticationService.getEntityAccounts();
        let rowData = [];
        let count = 1;
        entityAccounts.forEach((entityAccount) => {
            entityAccount.accounts.forEach((account) => {
                let data = {managerId: entityAccount.managerId, list: count++,
                    entity: generateEntityName(entityAccount), account:generateAccountName(account)};
                rowData.push(data);
            })})
        return rowData;
    }

    function generateEntityName(entityAccount) {
        return (entityAccount.shortName + "-" + entityAccount.longName);
    }

    function generateAccountName(account) {
        return account ? (account.accountCode + "-" + account.name) : "";
    }

    useEffect(() => {
        setRowData(generateRowData());
    }, [])

    const columnDefs = [
        {headerName: "List", field:"list", width: 100},
        {headerName: "Entity", field:"entity", width: 400},
        {headerName: "Account", field:"account", width: 500},
    ]

    const phone = formatPhone(profile.phone);

    const profileData = () => {
        return(<>
            <Row className="pt-md-5 pt-4 pb-3">
                <Col>
                    <h1>Profile</h1>
                </Col>
            </Row>
            <Row className={"text-nowrap mb-2"}>
                <Col sm={12} md={6}>
                    <Table responsive={"sm"} size={"sm"} borderless className="mb-0">
                        <tbody>
                            <tr>
                                <td className={"w-25"}>Full Name</td>
                                <td className="pl-5 pl-sm-0 pl-md-4"><b>{profile['fullName']}</b></td>
                            </tr>
                            <tr>
                                <td>Email Address &nbsp;</td>
                                <td className="pl-5 pl-sm-0 pl-md-4"><b>{profile.email}</b></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col sm={12} md={6}>
                    <Table responsive={"sm"} size={"sm"} borderless className="mb-0">
                        <tbody>
                        <tr>
                            <td className={"w-25"}>Username</td>
                            <td className="pl-5 pl-sm-0 pl-md-4"><b>{profile.username}</b></td>
                        </tr>
                        <tr>
                            <td>Phone Number</td>
                            <td className="pl-5 pl-sm-0 pl-md-4"><b>{phone}</b></td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>)
    }

    const rowRenderer = function(props){
        return(<>
            <a className="td-link" href="#" onClick={()=>{}}>{props.value}</a>
        </>);
    }

    return(<>
        <Container fluid style={{justifyContent:"center", width: isDesktop?"50%":""}}>
            {profileData()}
            <Row className="py-4">
                <Col><hr className="td-thin-divider-line-1"/></Col>
            </Row>
            <Row className="pb-3">
                <Col>
                    <h1>User Accounts</h1>
                </Col>
            </Row>
            <div className="ag-theme-alpine">
                <AgGridReact
                    paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                    frameworkComponents={{
                        rowRenderer: rowRenderer
                    }}
                    domLayout={'autoHeight'}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                >
                </AgGridReact>
            </div>
        </Container>
    </>)
}