import {Col, Container, Row} from "react-bootstrap";
import React, {useContext, useState}  from "react";
import RightArrowButton from "./RightArrowButton";
import DropDownMenu from "../DropDownMenu";
import ExportModal from "../ExportModal";
import {ActionPanelContext} from "../../../context/action";

export default function ExportButton(props) {

    const {exportRowData, setExportRowData} = useContext(ActionPanelContext)
    const updateRowData = ()=>{
        const updatedRowData = [];
        props.gridApi.forEachNodeAfterFilterAndSort((node)=>{
            updatedRowData.push(node.data);
        })
        setExportRowData(updatedRowData)
    }

    const handleClick = ()=>{
        updateRowData();
        props.onExportButtonClick();

    }

    return (<>

        <Container fluid className={props.isActionColBtn?"d-none" : "m-0 p-0"}>
            <Col className={"text-nowrap d-none d-md-block p-0 m-0" + (props.showDropdown ? " active-dropdown-button" : "" )} onClick={updateRowData}>
                <DropDownMenu style={{width:'100px'}} title={props.title} showDropdown={props.showDropdown}  setShowDropdown={props.setShowDropdown} >
                    <ExportModal gridApi={props.gridApi} showExport={props.showExport} setShowExport={props.setShowExport} setShow={props.setShow} />
                </DropDownMenu>
            </Col>
            <Col className="text-nowrap d-none d-sm-block d-md-none p-0 m-0">
                <a href="#" onClick={handleClick}>Export</a>
            </Col>
            <Col className="text-nowrap d-block d-sm-none p-0 m-0">
                <Row onClick={handleClick}>
                    <Col xs={6} className={'py-2 pl-4'} style={{alignSelf: 'center'}}><p style={{float: 'left'}}>Export</p></Col>
                    <Col xs={6} className={'py-2 pr-2'} style={{alignSelf: 'center'}}> <RightArrowButton/></Col>
                    <Col xs={12} className={'py-2 pr-0 pl-3'}><hr/></Col>
                </Row>
            </Col>
        </Container>
        <Container fluid className={!props.isActionColBtn?"d-none":"m-0 p-0"}>
            <button className="btn btn-block td-btn-primary-light my-3" href="#" onClick={handleClick}>
                Export
            </button>
        </Container>
    </>)
}
