import React from "react";
import Select from "react-select";

export class CustomSelect extends React.Component {
  render() {
    const {
      isValid, addNewWireModal
    } = this.props

    let errorCss = !isValid?{color: '#ae1100', borderBottomWidth: '3px',paddingBottom: '2px', borderColor: '#8c8c8c', borderBottomColor: '#ae1100', backgroundImage: 'none'}:{}
    let hoverCss = isValid?{paddingBottom: '0', borderBottomWidth: '3px', borderColor: '#8c8c8c', borderBottomColor: '#008a00', backgroundImage: 'none'}:{}
    let tdStandardsCss = {border:"1px solid #8c8c8c",paddingBottom: '2px', borderRadius:0}
    const customStyles = {
      control: (base, state) => ({
        ...base,
        ...tdStandardsCss,
        // state.isFocused can display different borderColor if you need it
        borderColor: state.isFocused ?
          '#ddd' : isValid ?
          '#ddd' : 'red',
          ...errorCss,
        // overwrittes hover style
        '&:hover': {
          ...hoverCss
        },
        color:'#1c1c1c',
        fontWeight:'bold',
        height: addNewWireModal ? '42px' : 'initial'
      })
    }
    return <Select styles={ customStyles } {...this.props}/>
  }
}