import React, {useContext, useEffect} from "react";
import ShortSaleFilter from "./ShortSaleFilter";
import ShortSaleActivitiesFilter from "./ShortSaleActivitiesFilter";
import ShortSalePanel from "./ShortSalePanel";
import SidePanelLayout from "layouts/SidePanelLayout";
import {
    SHORTSALE_APPROVAL_LOCATE,
    SHORTSALE_APPROVAL_PASTE_FROM_CLIPBOARD,
    SHORTSALE_APPROVAL_SEARCH,
    SHORTSALE_APPROVAL_UPLOAD
} from "../../routes/Routes";
import {useLocation} from "react-router";
import {ShortSaleContext} from "./context";
import AuthenticationService from "../../service/AuthenticationService";
import ShortSaleUpload from "./ShortSaleUpload";
import ShortSalePasteFromClipboard from "./ShortSalePasteFromClipboard";

export default function ShortSaleApprovals() {
    const {pathname} = useLocation();
    const {currentSSALayout, setCurrentSSALayout, loadTrigger, setLoadTrigger, loadShortSaleApprovals} = useContext(ShortSaleContext)

    const isUploadPath = (pathname)=>{
        return ((pathname !== SHORTSALE_APPROVAL_PASTE_FROM_CLIPBOARD) && (pathname !== SHORTSALE_APPROVAL_UPLOAD))
    }

    const getCurrentSSALayout = (pathname)=>{
        let page;
        if(pathname === SHORTSALE_APPROVAL_SEARCH)
            page = renderSSALayout(ShortSaleActivitiesFilter, ShortSalePanel )
        else if(pathname === SHORTSALE_APPROVAL_LOCATE)
            page = renderSSALayout(ShortSaleFilter, ShortSalePanel)
        else if(pathname === SHORTSALE_APPROVAL_UPLOAD)
            page = renderSSALayout(ShortSaleFilter, ShortSaleUpload)
        else if(pathname === SHORTSALE_APPROVAL_PASTE_FROM_CLIPBOARD)
            page = renderSSALayout(ShortSaleFilter, ShortSalePasteFromClipboard)
        setCurrentSSALayout(page)
    }

    useEffect(()=>{
        getCurrentSSALayout(pathname)
    },[pathname])

    useEffect(()=>{
        if(isUploadPath){
            if(loadTrigger) {
                const userEntities = AuthenticationService.getEntityAccounts();
                loadShortSaleApprovals(userEntities[0])

                setLoadTrigger(false)
            }
        }

    },[loadTrigger, setLoadTrigger])

    const renderSSALayout = (sideComponent, mainComponent)=>(
        <SidePanelLayout
            sideComponent={sideComponent}
            mainComponent={mainComponent}
        />
    )

    return(
        <>
            {currentSSALayout}
        </>
    )
}
