import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import {HomepageContext} from "./context";
import {SecurityContext} from "context/security";
import AuthenticationService from "service/AuthenticationService";
import useWindowDimensions from "hooks/Dimensions";
import * as URL from "../../service/UrlConstant";
import {ToastContext} from "../../context/Toast";
import {UserPreferencesContext} from "../../context/userpreferences";
import {PREFERENCES_URL} from "../../service/UrlConstant";
import {numberFormatter} from "utils/NumberUtils";
import {getSessionAttribute, setSessionAttribute} from "../../utils/SessionUtils";
import {SELECTED_ACCOUNT, SELECTED_ENTITY} from "../../utils/Constants";
import {CustomSelect} from "../../components/CustomSelect";


export default function HomeFilter() {
    const {setEntityAccountsOptions, setManagerDetails, accountsOptions, setAccountDetails, entityAccounts, setEntityAccounts,
        entityAccountsOptions, acctBalData, setAcctBalData, setManagerAndAccountDetails} = useContext(HomepageContext);
    const {makePostRequest, makeBackgroundPostRequest} = useContext(SecurityContext);
    const {error} = useContext(ToastContext);
    const [showEntityAccountsDropdown, setShowEntityAccountsDropdown] = useState(true);
    const RowDataStyle={padding:3, fontWeight: "normal"};
    const {isDesktop, isTab} = useWindowDimensions();
    const [localEntityAccountsOptions,setLocalEntityAccountsOptions] = useState(JSON.parse(JSON.stringify(entityAccountsOptions)))
    const [options,setOptions] = useState([])
    const [selectedOption,setSelectedOption] = useState({})

    const {
        homePagePreferences, preferencesLoaded
    } = useContext(UserPreferencesContext);

    useEffect(() => {
        const userEntityAccounts = AuthenticationService.getEntityAccounts();
        setEntityAccountsOptions(JSON.parse(JSON.stringify(userEntityAccounts)));
    }, [])

    useEffect(() => {
        if(entityAccountsOptions) {
            setShowEntityAccountsDropdown(entityAccountsOptions.length > 1);
            if ((entityAccountsOptions.length === 1 && entityAccounts.managerId !== entityAccountsOptions[0].managerId) || (entityAccountsOptions.length > 1 && getSessionAttribute(SELECTED_ENTITY) === null)) {
                const selectedEntity = {};
                selectedEntity['managerId']=entityAccountsOptions[0].managerId;
                selectedEntity['managerName']=entityAccountsOptions[0].shortName+'-'+entityAccountsOptions[0].longName;
                setSessionAttribute(SELECTED_ENTITY,selectedEntity);
                setManagerDetails(entityAccountsOptions[0].managerId, entityAccountsOptions[0].longName);
            }
        }
    }, [entityAccountsOptions])
    useEffect(() => {
        setTimeout(() => {
            const selectedEntityFromSession = getSessionAttribute(SELECTED_ENTITY);
            const selectedAccountFromSession = getSessionAttribute(SELECTED_ACCOUNT);
            if(selectedEntityFromSession!=null && Object.keys(selectedEntityFromSession).length > 0 && selectedAccountFromSession!=null && Object.keys(selectedAccountFromSession).length > 0)
              setManagerAndAccountDetails(selectedEntityFromSession.managerId, selectedEntityFromSession.managerName, selectedAccountFromSession.accountId,selectedAccountFromSession.accountCode);
            else if(selectedEntityFromSession!=null && Object.keys(selectedEntityFromSession).length > 0) setManagerAndAccountDetails(selectedEntityFromSession.managerId, selectedEntityFromSession.managerName, '', '');
        }, "500");


    },[entityAccountsOptions])


    useEffect(() => {
        if(entityAccounts.managerId && entityAccounts.managerId !== "" && accountsOptions && accountsOptions.length > 0) {
            getAccountsAndBalances();
        }
        else {
            setAcctBalData([])
            accountSelected('')
            setEntityAccounts({accountId:''})
        }
    }, [accountsOptions])

    useEffect(()=>{
        setLocalEntityAccountsOptions(JSON.parse(JSON.stringify(entityAccountsOptions)))
    },[entityAccountsOptions])

    useEffect(()=>{

        const options = []
        localEntityAccountsOptions.map(e=>{
            let option = {}
            option['value'] = e.managerId
            option['label'] = e.shortName+'-'+e.longName
            options.push(option)
        })
        setOptions(options)
    },[localEntityAccountsOptions])

    useEffect(()=>{
            const selectedEntity = options.filter(e=>e.value===entityAccounts.managerId)
            if(selectedEntity.length>0)
            {
                setSelectedOption(selectedEntity[0]);
            }
    },[entityAccounts.managerId,options])


    const handleInputChangeAutoComplete = (enteredValue)=>{
        const newData = entityAccountsOptions.filter(e=> {
                return e.shortName.toLowerCase().includes(enteredValue.toLowerCase()) || e.longName.toLowerCase().includes(enteredValue.toLowerCase())
            }
        )
        setLocalEntityAccountsOptions(newData)
    }
    const handleChangeAutocomplete = (e)=>{
        setManagerDetails(e.value, e.label);
        let selectedEntity = {};
        selectedEntity['managerId']=e.value;
        selectedEntity['managerName']=e.label;
        setSessionAttribute(SELECTED_ENTITY,selectedEntity);
    }

    const accountSelected = (data) => {
        let accountRows = document.getElementsByClassName("green-stripe");
        for (let i = 0; i < accountRows.length; i++) {
            accountRows[i].className = accountRows[i].className.replace(" green-stripe", "");
        }

        if(data) {
            if(document.getElementById(data.accountId) != null){
                console.log("Checking to see if this is null or not", document.getElementById(data.accountId))
                document.getElementById(data.accountId).className += " green-stripe";
                setAccountDetails(data.accountId, data.accountCode);
                let selectedAccount = {};
                selectedAccount['accountId']=data.accountId;
                selectedAccount['accountCode']=data.accountCode;
                setSessionAttribute(SELECTED_ACCOUNT,selectedAccount);
            }
        }
        else{
            setAccountDetails('', '');
        }
    }

    const saveUserPreferences = (data) => {
        //Save to the user preferences
        homePagePreferences.savedEntity.managerId = entityAccounts.managerId;
        homePagePreferences.savedEntity.accountId = data.accountId;
        makeBackgroundPostRequest(homePagePreferences, PREFERENCES_URL);
    }

    const getAccountsAndBalances = () => {
        let data = []
        accountsOptions.map(account => {
            if (account && account.accountId){
                data.push(account.accountId)
            }
        })

        const onSuccess = (res) =>{
            let resWithValueColumn = []
            res.data.map(d=> {
                let objWithValueCol = d;
                objWithValueCol.value = !!d.balance?d.balance:0;
                resWithValueColumn.push(objWithValueCol);
                }
            )
            setAcctBalData(resWithValueColumn)
            if(entityAccounts.managerId && entityAccounts.managerId !== "" && entityAccounts.accountId && entityAccounts.accountId !== "") {
                let selectedAccountData = {
                    accountCode: entityAccounts.accountCode,
                    accountId: entityAccounts.accountId
                }
                accountSelected(selectedAccountData);
            }
            else {
                accountSelected(res.data[0])
                setEntityAccounts({accountId:''})
            }

        }
        const onError = (err) =>{
            console.log("Error Occurred while fetching Account Balances..",err);
            error("Error Occurred while fetching Account Balances. Please contact support team.");
        }
        makePostRequest(data,URL.HOME_ACCOUNT_BALANCES, onSuccess, onError);
    }

    const getTotalBalance = () => {
        let formatTotalBalance = {}
        let totalBalance = 0
        acctBalData.map(data=> {
            totalBalance+=data.balance
        })
        formatTotalBalance.value = !!totalBalance?totalBalance:0
        return formatTotalBalance
    }

    const showDivider = () => {
        return <div className="d-sm-block d-md-none py-3" style={{width: "100%"}}><hr className="td-thin-divider-line-1"/></div>
    }

    const acctBal = function () {
        return <>
            <div>
                <Row className="justify-content-between pb-md-3" style={{fontSize:'1.125rem',paddingLeft:isDesktop?30:isTab?100:10, paddingRight:isDesktop?30:isTab?100:10}}>
                    <Col style={{textAlign:'left'}} xs={3} md={3} lg={5} className={'pr-md-0 mr-md-0'}><h3>Accounts</h3></Col>
                    <Col style={{textAlign:'right'}} xs={4} md={8} lg={7}><h3>Balance</h3></Col>
                </Row>
                {acctBalData.map((data) =>
                        <Row className={"d-flex justify-content-between "} id={data.accountId} key={data.accountId} style={RowDataStyle}
                             style={{whiteSpace:'nowrap',cursor:'pointer', fontSize:'1.125rem',paddingLeft:isDesktop?30:isTab?100:10, paddingRight:isDesktop?30:isTab?100:10}}
                             onClick={() => {accountSelected(data);saveUserPreferences(data)}}
                        >
                            <Col style={{textAlign:'left'}} xs={3} md={3} lg={5}>{data.accountCode}</Col>
                            <Col style={{ color:parseFloat(data.value.toString()) < 0?'red':'black',textAlign:'right'}} xs={5} md={8} lg={7}>$ {numberFormatter(data)}</Col>
                        </Row>

                )}
            </div>
        </>
    }
    return(<>
        <Container className={"p-0 pt-md-4 pt-sm-0 m-0"}>
            { showEntityAccountsDropdown &&
                <Container className={"p-2"}>
                    <Row>
                        <Col md={'12'}>
                            <Form.Group controlId="formGridHomeEntity">
                                <Form.Label className="filter-input-label">Entity *</Form.Label>
                                <CustomSelect
                                    placeholder={'Select'}
                                    key={selectedOption.value}
                                    options={options}
                                    value={Object.keys(selectedOption).length > 0?selectedOption:null}
                                    onChange={handleChangeAutocomplete}
                                    onInputChange={handleInputChangeAutoComplete}
                                    isValid={true}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            }
            <Container style={{paddingLeft:0, paddingRight:5, textAlign: "center", color: "black"}} fluid>
                <Row className="justify-content-between" style={{fontSize:'1.125rem',paddingLeft:isDesktop?30:isTab?100:10, paddingRight:isDesktop?30:isTab?100:10}} onClick={() => {}}>
                    <Col style={{textAlign:'left'}}  xs={3} md={3} lg={6}><h3>Total</h3></Col>
                    <Col style={{color:parseFloat(getTotalBalance().value.toString()) < 0?'red':'black', textAlign:'right'}} xs={5} md={8} lg={6}><h3>$ {numberFormatter(getTotalBalance())}</h3></Col>
                </Row>
                {showDivider()}
                {acctBal()}
            </Container>
        </Container>
    </>);

}