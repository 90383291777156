import React, {useState} from 'react';

const rangeJSON = [
    { "id": "0", "name": "" },
    { "id": "1", "name": "1W" },
    { "id": "2", "name": "1M" },
    { "id": "3", "name": "3M" },
    { "id": "4", "name": "6M" },
    { "id": "5", "name": "1Y" }
];

export default function DateRangeRenderer(props) {
    const[range, setRange] = useState(props.value);

    const onRangeChange = (event) => {
        props.onRangeChange(event.target.value,props.rowIndex);
        setRange(event.target.value);
    }

    return(
        (props.rowIndex >= 0 && !props.node.footer) &&

        <div>
            <select
                value={range} onChange={onRangeChange}>
                {rangeJSON.map(range => <option key={range.id} value={range.name}>{range.name}</option>)}
            </select>
        </div>
    )

}