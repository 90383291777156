import {Col, Form, Row} from "react-bootstrap";
import React from "react";

export default function SSASecurityQuantityRows(props){
    const {data, onDeleteClick} = props
    return(
        <>
        <Row style={{margin:0, display:'flex',alignItems:'center'}} className={'pt-2 pb-0'}>
            <Col xs={8} className={'p-0'} style={{textAlign:'left'}}>
                {data.security}
            </Col>
            <Col xs={2} className={'p-0'} style={{textAlign:'left'}}>
                {data.quantity}
            </Col>
            <Col xs={2} style={{
                display: 'flex',
                alignSelf: "right",
                textAlign: 'right',
                justifyContent: 'right',
                padding:0,
                margin:0
            }}>
                <i className="td-icon-colour-primary td-icon icon-small td-icon-outlinedCircleClose"
                   style={{fontSize: '2em', cursor: 'pointer', color:'#AE1100'}} onClick={()=>onDeleteClick(data.index)}></i>
            </Col>
        </Row>
        <Row>
            <Col style={{color: '#1A5336'}} className={"pt-0"}>{data.secDescription}</Col>
        </Row>

        <hr/>
        </>
    )
}
