
export const PASSWORD_RULES = [
    {id:1, value: "Has to be at least 8 characters"},
    {id:2, value: "Must include at least 1 special character (e.g. @,%,$,!)"},
    {id:3, value: "Must include a combination of upper case, and lower case characters"},
    {id:4, value: "Must not repeat any of the last 10 passwords"},
    {id:5, value: "Will expire in 60 days"},
    {id:6, value: "Account will be locked after 5 invalid password attempts"}
];
export const PASSWORD_FORMAT_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,32}$/;
export const PASSWORD_RULE_MSG = '!Your new password must be 8-32 characters in length, must include at least 1 special character and one number, must not include spaces, and can only include these special characters !@#$%^&* ' +
    '!Not strong enough';