import React, {useContext} from 'react';
import useWindowDimensions from "hooks/Dimensions";
import {SecurityContext} from "../../context/security";
import AuthenticationService from "service/AuthenticationService";
import {LoginContext} from "./context";

export default function MFAPanel() {
    const { needLogin, setNeedLogin} = useContext(SecurityContext);
    const {mfaLoginOption } = useContext(LoginContext);

    if (AuthenticationService.isUserAuthenticated()) {
        if (needLogin) {
            setNeedLogin(false);
        }
    } else {
        setNeedLogin(true);
        setTimeout(() => { mfaLoginOption() }, 1000);

    }


    return(<>
    </>);
}