import React, {useContext} from "react";
import {ActionPanelContext} from "context/action";

export default function DownloadButton() {
    const {handleDownload} = useContext(ActionPanelContext);

    return (<>
        <button className="btn btn-block td-btn-primary-light my-3" href="#" onClick={() => {
            handleDownload()
        }}> Download PDF
        </button>
    </>)
}