import React from 'react';
import {withRouter} from "react-router";
import TDMenu from "./TDMenu";

function AppHeader(props){

    return(<>
        <TDMenu/>
        <hr style={{boxShadow: "0 2px 4px rgba(204,204,204,0.5)", zIndex: 200000}}/>
    </>);
}

export default withRouter(AppHeader)