import React, {useContext} from "react";
import {Col, Container, Row} from "react-bootstrap";
import useWindowDimensions from "hooks/Dimensions";
import {ActionPanelContext} from "context/action";

export default function ExportModal(props) {
    const {setShowExport, setShow, isActionColBtn} = props;
    const {handleExport, exportRowData} = useContext(ActionPanelContext);
    const {isDesktop} = useWindowDimensions();

    const handleExportWrapper = (format,onSuccess,onError) => {
        handleExport(format, exportRowData, onSuccess, onError);
        setShowExport(false);
        setShow(false);
    }
    return (<>
        <Container className="my-3 my-md-0">
            <Row className={!isDesktop||isActionColBtn?"d-block":"d-none"}>
                <Col md={12} className="mt-4">
                    <h4 style={{textAlign: isDesktop?'left':'center'}}>Do you want to export this data ?</h4><br/>
                </Col>
            </Row>
            <Row className={"justify-content-end"}>
                <Col md={isActionColBtn?4:12} xs={12} className={isDesktop?isActionColBtn?"pr-0":"p-0 pt-2":""}>
                    <button className={isDesktop && !isActionColBtn?'btn btn-block td-btn-secondary-clear border-0 pb-0 text-left':'btn btn-block td-btn-secondary-clear  border-1' } href="#" onClick={() => {
                        const onSuccess = () => {
                            setShowExport(false);
                            setShow(false);
                        }

                        const onError = () => {
                            setShowExport(false);
                            setShow(false);
                        }

                        handleExportWrapper('EXCEL',onSuccess,onError)

                    }}
                    ><h4><i className='td-icon-colour-primary td-icon icon-small  td-icon-downloadCertificate'></i>&nbsp;Excel
                    </h4></button>
                </Col>
                <Col md={isActionColBtn?4:12} xs={12} className={isDesktop && !isActionColBtn?"p-0 pt-2":""}>
                    <button className={isDesktop && !isActionColBtn?'btn btn-block td-btn-secondary-clear border-0 py-0 text-left':'btn btn-block td-btn-secondary-clear  border-1' } href="#" onClick={() => {

                        const onSuccess = () => {
                            setShowExport(false);
                            setShow(false);
                        }

                        const onError = () => {
                            setShowExport(false);
                            setShow(false);
                        }

                        handleExportWrapper('CSV',onSuccess,onError)
                    }}
                    ><h4><i className='td-icon-colour-primary td-icon icon-small  td-icon-downloadCertificate'></i>&nbsp;CSV
                    </h4></button>
                </Col>
            </Row>
        </Container>
    </>)
}