import React, {useContext} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {UserPreferencesContext} from "../context/userpreferences";
import useWindowDimensions from "../hooks/Dimensions";

export default function DraggableHeader(props) {

    let {text, gridId} = props;
    const {homePagePreferences} = useContext(UserPreferencesContext);
    let colStyle = "gridId" + gridId + " px-0 px-md-2 pt-2 pb-4";
    const {isDesktop} = useWindowDimensions();


    return(<>
        <Col sm={12} md={homePagePreferences.widgets[gridId].md} className={colStyle}>
            <Container fluid >
                <Row>
                    <Container fluid style={{background:"#F3F3F3", padding:0, width: "100%", border: "1px solid rgba(0,0,0,.15)", borderBottom:0}} className={isDesktop ? "justify-content-end dragMe" : "justify-content-end"}>
                        <Row style={{margin:5, padding:0, width: "100%"}} className={"justify-content-end"}>
                            <Col sm={10} style={{padding:0, textAlign: "left", alignSelf:"center"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-grid-3x2-gap icon-move" viewBox="0 0 16 16">
                                    <path
                                        d="M4 4v2H2V4h2zm1 7V9a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm5 5V9a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm0-5V4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zM9 4v2H7V4h2zm5 0h-2v2h2V4zM4 9v2H2V9h2zm5 0v2H7V9h2zm5 0v2h-2V9h2zm-3-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V4zm1 4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-2z"/>
                                </svg>      <b>{text}</b>
                            </Col>
                            {isDesktop &&<Col style={{padding:0, paddingRight:10, textAlign: "right", alignSelf:"center"}} >
                                <i className="bi bi-arrows-angle-expand resizeMeHorizontally"></i>
                            </Col>}
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Col style={{padding:0}}>{props.children}</Col>
                </Row>
            </Container>
        </Col>
    </>);
}
