export function numberFormatter(param, fractionalDigits = 2){
    if(param && param.value) {
        let number = Intl.NumberFormat("en", {
            maximumFractionDigits: fractionalDigits,
            minimumFractionDigits: fractionalDigits,
        }).format(param.value);
        return parseFloat(param.value) < 0 ? '('+ number.toString().replace('-', '') +')' : number
    }
    return parseFloat(0).toFixed(fractionalDigits)
}

export const numberFormat = (param) => {
    let formatInput = param.value;
    return new Intl.NumberFormat().format(formatInput);
}

export const appendPercentSymbol = (param) => {
    let paramValue= param.value;
    if (paramValue) {
        return  paramValue+'%';
    }
    return '';
}

export const numberFormat5FractionalDigits = (param) => {
    return numberFormatter(param, 5)
}

export const numberFormat4FractionalDigits = (param) => {
    return numberFormatter(param, 4)
}

export const numberFormat4FractionalDigitsPer = (param) => {
    return numberFormat4FractionalDigits(param) + '%'
}
export const numberFormat3FractionalDigits = (param) => {
    return numberFormatter(param, 3)
}

export const numberFormat3FractionalDigitsStr = (param) => {
    if(param && param.value)
        return numberFormatter(param, 3)
    return ''
}

export const cellStyleChecker = (param) => {
    if(!!param.value)
        return parseFloat(param.value.toString()) < 0  ? { color:'red',textAlign: 'right' } : { color:'green',textAlign: 'right'}
    return {textAlign: 'right'}
}

export const cellAlignment = (param) => {
    return {textAlign: 'right'}
}

export const nonEmptyNumberFormatter = (param)=>{
    if(param && param.value) {
        return numberFormatter(param)
    }
    return
}
