import React from "react";
import ActionModal from "../ActionModal";
import useWindowDimensions from "hooks/Dimensions";

const bodyStyle={paddingRight:0, paddingTop:15,margin:0}
export default function RightSideOptions(props) {
    //This component is designed for displaying the ag-gridtoolbar Right side options

    const {show, onShowRightSideOptions, onHide} = props;

    const actionTitle = () => 'Action for table';
    const modalBody = () => (<div style={{color: 'black'}} className={'px-0'}>{props.children}</div>)
    const {isMobile} = useWindowDimensions();
    return (
        <>
            {!isMobile && props.children}
            <span className={'d-block d-sm-none py-0'}>
                <i className='td-icon-colour-primary td-icon icon-regular td-icon-all d-block d-sm-none py-0 my-0 mr-1 pr-2'
                   style={{fontSize:26}}
                   onClick={onShowRightSideOptions}
                ></i>
                <ActionModal body={modalBody} title={actionTitle} show={show} onHide={onHide}
                             dialogClassName={'fullscreen-dialog'}
                             centered={false}
                             bodyStyle={bodyStyle}
                             rightSideOptions={'y'}
                />
            </span>

        </>
    )

}