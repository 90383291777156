import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import RightArrowButton from "./RightArrowButton";
export default function DeleteButton(props) {
    return (<>
        <Container fluid className={props.isActionColBtn?"d-none":"m-0 p-0"}>
            <a href="#" onClick={props.onDeleteButtonClick}>
                <span
                    className='td-icon-colour-primary td-icon icon-small td-icon-delete d-none d-sm-block d-md-none'></span>
                <span className='d-none d-sm-none d-md-block'>{props.buttonTitle}</span>
            </a>
            <Row onClick={props.onDeleteButtonClick}
                 className={'d-sm-none'}>
                <Col xs={6} className={'py-2 pl-4'} style={{alignSelf: 'center'}}><p style={{float: 'left'}}>{props.buttonTitle} </p>
                </Col>
                <Col xs={6} className={'py-2 pr-2'} style={{alignSelf: 'center'}}> <RightArrowButton/></Col>
                <Col xs={12} className={'py-2 pr-0 pl-3'}>
                    <hr/>
                </Col>
            </Row>
        </Container>
        <Container fluid className={!props.isActionColBtn?"d-none":"m-0 p-0"}>
            <button className="btn btn-block td-btn-red my-3" href="#" onClick={props.onDeleteButtonClick}>
                {props.buttonTitle??'Delete'}
            </button>
        </Container>
    </>)
}
