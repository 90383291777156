import React from 'react';
import SidePanelLayout from "layouts/SidePanelLayout";
import WiresFilter from "./WiresFilter";
import WiresPanel from "./WiresPanel";
import {WiresPanelContextProvider} from "./context";

export default function Wires() {

    return(<>
        <WiresPanelContextProvider>
            <SidePanelLayout
                sideComponent={WiresFilter}
                mainComponent={WiresPanel}>
            </SidePanelLayout>
        </WiresPanelContextProvider>
    </>);

}