import {Col, Container, Row} from "react-bootstrap";
import React from "react";
export default function ClearSelectionButton(props) {

    return (<>
        <Container fluid className={props.isActionColBtn?"d-none":"m-0 p-0"}>
            <a href="#" onClick={props.onClearSelectionClick}>
                <span className='d-md-block'>{props.buttonTitle}</span>
            </a>
        </Container>
        <Container fluid className={!props.isActionColBtn?"d-none":"m-0 p-0"}>
            <button className="btn btn-block td-btn-red my-3" href="#" onClick={props.onClearSelectionClick}>
                {props.buttonTitle??'Clear'}
            </button>
        </Container>
    </>)
}
