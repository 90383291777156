import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import {TradeCaptureContext} from "./context";
import {dateToString, yearMonthDayToPickerDateFormat, getCurBusDate,
    getMinPermissibleBusDate,
    pickerDateFormatToDate} from "utils/FormatUtils";
import * as URL from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE} from "./context/TradeCaptureContext";
import {ToastContext} from "context/Toast";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {EntityAccountsComponent} from "components/entity-accounts/EntityAccountsComponent";
import {FilterContext} from "layouts/SidePanelLayout";
import {validateValueOnRegex, isValidDate} from "../../utils/RegexUtils";
import {REGEX_TRADE_ENTRY_CLIENT_ID, REGEX_TRADE_SEARCH_CRITERIA_STATUS, VALIDATION_IN_JS} from "../../utils/Constants";
import {REGEX_TRADE_SEARCH_CRITERIA_STATUS_MSG, TRADE_ENTRY_CLIENT_ID_MSG} from "../../utils/ConstantsMsg";
import {getAllErrorValuesAsString, updateTradesFilterFormErrors} from "../../utils/ErrorUtils";


export default function TradeActivitiesFilter() {

    const {
        tradePanelFilterState,
        setTradePanelFilterState,
        tradeCaptureDisplayState,
        setTradeCaptureDisplayState,
        clearTradeSearchAndLoadTradingHome,
        setShowGridToolbar,
        setHeaderCheckedboxChecked,
        setSelectedRows,
        setSelectedAnyCheckbox
    } = useContext(TradeCaptureContext);
    const {entityAccounts, clearEntityAccounts, setManagerAndAccountDetails, validateEntityAccountSearch, paramBadgeInfo, populateDefaultEntity, entityAccountsOptions} = useContext(EntityAccountsContext);

    const {makePostRequest} = useContext(SecurityContext);
    const {alert, error} = useContext(ToastContext);
    const [formErrors, setFormErrors] = useState({});
    const [accountOptional, setAccountOptional] = useState(true);
    const {showFilter, setShowFilter} = useContext(FilterContext);

    const handleSearchOnChange = (e) => {
        let tradePanelFilterStateUpdated = JSON.parse(JSON.stringify(tradePanelFilterState));
        const targetName = e.currentTarget.name;
        const targetValue = e.currentTarget.value;
        tradePanelFilterStateUpdated[targetName] = targetValue;
        setTradePanelFilterState(tradePanelFilterStateUpdated);

        updateTradesFilterFormErrors(targetName, targetValue,
            formErrors, setFormErrors, tradePanelFilterState);
    }

    const handleSearchClear = (e) => {
        clearEntityAccounts(true, true);
        clearTradeSearchAndLoadTradingHome();
        setAccountOptional(true)
    }

    useEffect(() => {
        populateDefaultEntity(true)
    }, [entityAccountsOptions])


    useEffect(() => {
        if (TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE === tradeCaptureDisplayState.currentPage) { //&& tradeCaptureDisplayState.savedName) {
            setManagerAndAccountDetails(tradeCaptureDisplayState.tradeActivitiesSearch.managerId, tradeCaptureDisplayState.tradeActivitiesSearch.managerName,
                tradeCaptureDisplayState.tradeActivitiesSearch.accountId, tradeCaptureDisplayState.tradeActivitiesSearch.accountName,
                tradeCaptureDisplayState.tradeActivitiesSearch.accountCode, true);

            let tradePanelFilterStateUpdated = {...tradePanelFilterState};
            tradePanelFilterStateUpdated.status = tradeCaptureDisplayState.tradeActivitiesSearch.status;
            tradePanelFilterStateUpdated.startDate = yearMonthDayToPickerDateFormat(tradeCaptureDisplayState.tradeActivitiesSearch.startDate);
            tradePanelFilterStateUpdated.endDate = yearMonthDayToPickerDateFormat(tradeCaptureDisplayState.tradeActivitiesSearch.endDate);
            setTradePanelFilterState(tradePanelFilterStateUpdated);
        }

    }, [tradeCaptureDisplayState.currentPage]);

    const validateSearch = ()=>{
        const {startDate, endDate} = tradePanelFilterState;
        let errors = {}
        errors = validateEntityAccountSearch(false);

        if(!startDate || !isValidDate(startDate))
            errors['startDate'] = 'Please input start date'

        if(!endDate || !isValidDate(endDate))
            errors['endDate'] = 'Please input end date'

        if (startDate && endDate){
            let startDateD = new Date(startDate).getTime();
            let endDateD = new Date(endDate).getTime();

            if (startDateD > endDateD){
                errors['startDate'] = 'Start date should be less than end date'
                errors['endDate'] = 'Start date should be less than end date'
            }
        }

        if (dateToString(startDate) < getMinPermissibleBusDate())
            errors['startDate'] = `Start date should be greater than or equal to ${pickerDateFormatToDate(getMinPermissibleBusDate())}`;

        if (dateToString(startDate) > getCurBusDate())
            errors['startDate'] = `Start date should be less than or equal to ${pickerDateFormatToDate(getCurBusDate())}`;
        if (dateToString(endDate) > getCurBusDate())
            errors['endDate'] = `End date should be less than or equal to ${pickerDateFormatToDate(getCurBusDate())}`;

        let errorsRegex = validateStatus()
        if (errorsRegex){
            errors = {...errors, ...errorsRegex}
        }
        return errors
    }
    const validateStatus = () => {
        let errors = {}
        const {status} = tradePanelFilterState;
        // client id
        let match = false
        if(status && status.length > 0){
            match = validateValueOnRegex(status, REGEX_TRADE_SEARCH_CRITERIA_STATUS)
            if (!match){
                errors['status'] = REGEX_TRADE_SEARCH_CRITERIA_STATUS_MSG
            }
        }
        return errors
    }

    const handleSearchClick = (e) => {
        e.preventDefault();

        let errors = {}
        if (VALIDATION_IN_JS) {
            errors = validateSearch()
        }
        if(Object.keys(errors).length > 0){
            setFormErrors(errors)
            return
        }

        const searchCriteria = {
            managerId: entityAccounts.managerId,
            subManagerId: entityAccounts.subManagerId,
            accountId: entityAccounts.accountId,
            status: tradePanelFilterState.status,
            startDate: dateToString(tradePanelFilterState.startDate),
            endDate: dateToString(tradePanelFilterState.endDate)
        }

        setShowFilter(!showFilter)

        const onSuccessResponse = (response) => {

            let tradeCaptureDisplayStateShowActivities = {...tradeCaptureDisplayState};
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.status = searchCriteria.status;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.startDate = searchCriteria.startDate;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.endDate = searchCriteria.endDate;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.managerId = searchCriteria.managerId;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.subManagerId = searchCriteria.subManagerId;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.accountId = searchCriteria.accountId;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.managerName = entityAccounts.managerName;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.subManagerName = entityAccounts.subManagerNameShort;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.accountName = entityAccounts.accountName;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.accountCode = entityAccounts.accountCode;
            tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.badgeInfo = paramBadgeInfo();

            if (!response.data || response.data.length === 0) {
                tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.resData = [];
                alert('No Trade activities found matching your search');
            } else {
                tradeCaptureDisplayStateShowActivities.tradeActivitiesSearch.resData = response.data;
            }
            setShowGridToolbar(response.data && response.data.length > 0)
            tradeCaptureDisplayStateShowActivities.isSavedTradeList = false;
            tradeCaptureDisplayStateShowActivities.currentPage = TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE;
            setTradeCaptureDisplayState(tradeCaptureDisplayStateShowActivities);

        }

        const onErrorResponse = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error("Error occurred with searching trade activities",errorMsg);
            error("Error occurred with searching trade activities");
        }

        makePostRequest(searchCriteria,
            URL.TRADES_SEARCH_URL, onSuccessResponse, onErrorResponse);

        setHeaderCheckedboxChecked(false);
        setSelectedAnyCheckbox(false)
        setSelectedRows([]);
    };

    return (<>
        <Container fluid className="mt-md-3 mt-sm-0">
            <Form>
                <h2 className="d-none d-md-block">Search</h2>
                <Row>
                    <EntityAccountsComponent accountOptional={accountOptional}/>

                    <Col md={12}>
                        <Form.Group controlId="formGridStatus" className={'td-select'} >
                            <Form.Label className="filter-input-label">Status</Form.Label>
                            <Form.Control as="select" name="status"
                                          value={tradePanelFilterState.status}
                                          onChange={handleSearchOnChange}
                                          isInvalid={!!formErrors.status}>
                                <option value='ALL'>ALL</option>
                                <option value='PEND'>PEND</option>
                                <option value='SEND'>SEND</option>
                                <option value='CANCEL'>CANCEL</option>
                                <option value='REVOKE'>REVOKE</option>
                                <option value='REJECT'>REJECT</option>
                                <option value='ACCEPT'>ACCEPT</option>
                                <option value='TIMEOUT'>TIMEOUT</option>
                                <option value='INTRANSIT'>INTRANSIT</option>
                                <option value='MANUAL'>MANUAL</option>
                            </Form.Control>
                            <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.status}</b></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6} md={12}>
                        <Form.Group
                            controlId="tradeCaptureDisplayState.tradeActivitiesSearch.startDate"
                            md={6}>
                            <Form.Label className="filter-input-label">Start Date *</Form.Label>
                            <Form.Control type="date" name="startDate"
                                          value={tradePanelFilterState.startDate}
                                          onChange={handleSearchOnChange}
                                          isInvalid={!!formErrors.startDate}/>
                            <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.startDate}</b></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6} md={12}>
                        <Form.Group
                            controlId="tradeCaptureDisplayState.tradeActivitiesSearch.endDate"
                            md={6}>
                            <Form.Label className="filter-input-label">End Date *</Form.Label>
                            <Form.Control type="date" name="endDate"
                                          value={tradePanelFilterState.endDate}
                                          onChange={handleSearchOnChange}
                                          isInvalid={!!formErrors.endDate}/>
                            <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.endDate}</b></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <button className="btn btn-block td-btn-primary-light my-3" href="#"
                                onClick={handleSearchClick}>View Activities
                        </button>
                    </Col>
                    <Col md={12}>
                        <button className="btn btn-block td-btn-secondary-clear my-3"
                                href="#" onClick={handleSearchClear}>Clear
                        </button>
                    </Col>
                </Row>
            </Form>
        </Container>
    </>);

}
