import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Container} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import ActionsRenderer from "components/ActionsRenderer";
import useWindowDimensions from 'hooks/Dimensions.js'
import ActionPanelLayout from "layouts/ActionPanelLayout";
import PageHeader from "components/PageHeader";
import {SecurityContext} from "context/security";
import {ToastContext} from "context/Toast";
import AuthenticationService from "service/AuthenticationService";
import {ActionPanelContext} from "context/action";
import * as URL from "service/UrlConstant";
import {UtilsContext} from "context/utils";
import {columnDefsResultsPanel as wiresResultsColumnDefs} from "./WiresResultPanel"
import {dateToString, stringDateFormatter, stringToDate} from "utils/FormatUtils";
import {WIRE_PANEL_VIEW_WIRE_PAGE} from "./context/WiresPanelContext";
import {WiresPanelContext} from "./context";
import {
    DEFAULT_GRID_PAGINATION_SIZE,
    ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE,
    GRID_COLUMN_PROPERTY
} from "utils/Constants";
import {createExcelExportParams, getFilteredColumns} from "../../utils/ExcelExportUtils";
import {addPropertiesToGridColumns, getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";


export default function SavedWires(props) {

    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const savedWiresGrid = useRef(null);
    const [actionForAccount, setActionForAccount] = useState('');
    const {setHandleDeleteFunction, setHandleRenameFunction, setSavedSearchName, setHandleExportFunction} = useContext(ActionPanelContext);
    const {hiddenExportGridApi, paginationPageSize, showButton, setShowButton} = useContext(UtilsContext);
    const {displayWirePanelPage ,wireResultsDisplayState ,setWireResultsDisplayState} = useContext(WiresPanelContext);
    const myGrid = useRef(null);
    const {isDesktop, isMobile} = useWindowDimensions();
    const {
        makeGetRequest,
        makeDeleteRequest,
        makePatchRequestWithParams,
        makePostRequest
    } = useContext(SecurityContext);
    const {success, alert, error} = useContext(ToastContext);
    const columnTypes ={
        actionColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            pinned:isDesktop?'':'right',
            width:isDesktop?250:100,
        },
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu:true,
            flex:1,
            suppressMovable:true,
        }
    }

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        filter:true,
        enableCellChangeFlash:true,
        lockPinned:isMobile?true:false,
    };

    const loadWireSavedSearches = () => {
        const onSuccess = (response) => {
            if (!response.data || response.data.length === 0) {
                console.log("No saved wirelist found")
            } else {
                setRowData([...response.data]);
            }
        }
        const onError = (err) => {
            console.error(err);
            error('Error occurred with loading wire saved searches');
        }

        makeGetRequest(URL.WIRES_SAVED_WIRES_LIST_URL, {}, onSuccess, onError);
    };

    function managerGetter(params) {
        return params.data.managerShortName + "-" + params.data.managerLongName;
    }

    function accountGetter(params) {
        if (!params.data.accountCode)
            return 'ALL' + "-" + 'ALL';
        else
            return params.data.accountCode + "-" + params.data.accountNameLong ;
    }
    const wireSearchDataValueGetter = function (params) {
        let searchData = {
            idAccount: params.data.idAccount,
            accountNameShort:params.data.accountNameShort,
            idManager:params.data.idManager,
            managerShortName: params.data.managerShortName,
            idSubManager: params.data.idSubManager,
            wireListId: params.data.wireListId,
            name: params.data.name,
            status: params.data.status,
            startDate: params.data.startDate,
            endDate: params.data.endDate
        }
        return searchData;
    }

    useEffect(() => {
        loadWireSavedSearches();
    }, [])

    const loadSavedWireDetails = (savedWiresData) => {

        const params = {
            legalEntity:(savedWiresData.managerShortName + "-" + savedWiresData.managerLongName),
            clientCode: savedWiresData.cdClient,
            startDate:dateToString(savedWiresData.startDate),
            endDate:dateToString(savedWiresData.endDate),
            status:savedWiresData.status,
            templateIndicator:"N",
            deskViewIndicator:"N",
            exportIndicator:0
        }
        const onSuccess = (response) => {
            if (!response.data || response.data.length === 0) {
                alert('No Wires found matching your saved wires list');
            } else {
                const wireResultsDisplay = JSON.parse(JSON.stringify(wireResultsDisplayState));
                wireResultsDisplay.resData = ([...response.data])
                wireResultsDisplay.isSavedWire = true;
                wireResultsDisplay.savedWireListId = savedWiresData.wireListId;
                wireResultsDisplay.savedName = savedWiresData.name;
                wireResultsDisplay.wireSearch = {
                    startDate : savedWiresData.startDate,
                    endDate : savedWiresData.endDate,
                    managerId : savedWiresData.idManager,
                    managerName : (savedWiresData.managerShortName + "-" + savedWiresData.managerLongName),
                    subManagerId : savedWiresData.idSubManager,
                    accountId : savedWiresData.idAccount,
                    accountName : savedWiresData.accountNameShort,
                    accountCode : savedWiresData.accountCode,
                    status : savedWiresData.status,
                    badgeInfo: !!savedWiresData.accountCode?savedWiresData.accountCode+'-'+savedWiresData.accountNameShort:savedWiresData.accountNameShort
                };
                setWireResultsDisplayState(wireResultsDisplay);
                displayWirePanelPage(WIRE_PANEL_VIEW_WIRE_PAGE);
            }
        }

        const onError = (err) => {
            const msg = "Error when retrieving Saved wires details";
            console.error(msg + ": ", err);
            error(msg);
        }
        makePostRequest(params, URL.WIRES_WIRE_SEARCH, onSuccess, onError);
    }

    function startDateGetter(params) {
        //dateToString, stringDateFormatter
        return stringToDate(dateToString(params.data.startDate));
    }

    function endDateGetter(params) {
        //dateToString, stringDateFormatter
        return stringToDate(dateToString(params.data.endDate));
    }

    const columnDefs = [

        {headerName: 'Name', field: 'name', cellRenderer: "tradeNameRenderer"},
        {headerName: 'idAccount', field: 'idAccount',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'idManager', field: 'idManager',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'Sub Manager Id',field: 'idSubManager',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'client Code',field: 'clientCode',hide: true,suppressColumnsToolPanel: true},
        {headerName: 'wireListId', field: 'wireListId',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'Entity', valueGetter: managerGetter},
        {headerName: 'Account', valueGetter: accountGetter},
        {headerName: 'Status', field: 'status'},
        {headerName: 'Start Date', valueGetter: startDateGetter},
        {headerName: 'End Date', valueGetter: endDateGetter},
        // {headerName: 'Date Created', field: 'createdAt'},
        {headerName: 'Actions',
            field: 'account',
            cellRenderer: "actionsRenderer",
            type:'actionColType',
            valueGetter:wireSearchDataValueGetter,
            cellRendererParams:{
                clicked: (searchData) => handleActionIconClick(searchData),
                onAction1Clicked:(searchData)=>handleExportIconClicked(searchData),
                onAction2Clicked:(searchData)=>handleRenameActionClicked(searchData),
                onAction3Clicked:(searchData)=>handleAction3IconClicked(searchData)}},
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];

    const updatedColumnDefs = useMemo(() => addPropertiesToGridColumns(columnDefs, GRID_COLUMN_PROPERTY), []);

    const updateSavedWire = (name, gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];
        const params = {
            "savedNameUpdated":name,
        }
        const onSuccessResponse = (response) => {
            selectedRow.name = name;
            gridApi.applyTransaction({update: [selectedRow]});
            if(onSuccess) onSuccess();
            success('Wire name saved successfully.');
        }

        const onErrorResponse = (err) => {
            const msg = "Error occurred when updating the Saved Wire name";
            console.error(msg + ": ", err);
            if(onError) onError(err);
        }

        makePatchRequestWithParams(
            URL.WIRES_WIRES_LIST_SAVE + "/" + selectedRow.wireListId , params, onSuccessResponse, onErrorResponse);
    };

    const exportWireList = (type, rowData, gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];
        const searchParam = {
            "userCode": selectedRow.cdUser,
            "legalEntity" : selectedRow.managerShortName,
            "clientCode":selectedRow.cdClient,
            "startDate":selectedRow.startDate,
            "endDate":selectedRow.endDate,
            "status": selectedRow.status,
            "templateIndicator":"N",
            "deskViewIndicator":"N",
            "exportIndicator":0
        }

        const onSuccessResponse = (response) => {
            //Set Column definition
            hiddenExportGridApi.current.api.setColumnDefs(wiresResultsColumnDefs);
            //Set Data
            hiddenExportGridApi.current.api.setRowData(response.data)
            let filteredColumnNames = getFilteredColumns(savedWiresGrid);
            //Export as csv/excel
            type==='EXCEL' ?
                hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams(filteredColumnNames)):
                    hiddenExportGridApi.current.api.exportDataAsCsv(filteredColumnNames)
        }

        const onErrorResponse = (err) => {
            const msg = "Error occurred when exporting the wirelist";
            console.error(msg + ": ", err);
            if(onError) onError(err);
            error(msg);
        }
        let url = URL.WIRES_WIRE_SEARCH
        makePostRequest(searchParam, url  , onSuccessResponse, onErrorResponse);
    };

    const deleteSavedWire = (gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];
        const onSuccessResponse = (response) => {
            gridApi.applyTransaction({remove: [selectedRow]});
            if(onSuccess) onSuccess();
            success('Wire deleted successfully.');
        }

        const onErrorResponse = (err) => {
            const msg = "Error when updating the saved Wire name";
            console.error(msg + ": ", err);
            if(onError) onError(err);
            error(msg);
        }

        makeDeleteRequest(
            URL.WIRES_WIRES_LIST_SAVE + "/" + selectedRow.wireListId, onSuccessResponse, onErrorResponse);
    };

    const onGridReady = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setHandleRenameFunction({handleRename: updateSavedWire, gridApi: params.api});
        setHandleDeleteFunction({handleDelete:deleteSavedWire, gridApi: params.api});
        setHandleExportFunction({handleExport:exportWireList, gridApi: params.api});
    }

    const cellClicked=(params)=> {
        params.node.setSelected(true)
        params.api.ensureIndexVisible(params.node.rowIndex,'top')
    }
    const handleActionIconClick = (field) => {
        setActionForAccount(field.name);
        setShow(true);
        setSavedSearchName(field.name);
    }
    const handleExportIconClicked = (field)=>{
        setActionForAccount(field);
        setShow(true);
        setShowExport(true);
    }

    const handleRenameActionClicked = (searchData)=>{
        setSavedSearchName(searchData.name);
        setShow(true);
        setShowRename(true);
    }

    const handleAction3IconClicked = (field)=>{
        setActionForAccount(field);
        setShow(true);
        setShowDelete(true);
    }

    const searchNameRenderer = function (props){
        return(<>
            <a className="td-link" href="#" onClick={() => {
                loadSavedWireDetails(props.data);
            }}>{props.value}</a>
        </>);
    }
    const onDataRendered = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    return(<>

        <Container fluid>
            <PageHeader text="Wires">
                <h3>Saved Searches</h3>
            </PageHeader>
            <div
                ref={myGrid}
                id="myGrid"
                className="ag-theme-alpine">
                <AgGridReact
                    ref={savedWiresGrid}
                    frameworkComponents={{
                        tradeNameRenderer: searchNameRenderer,
                        actionsRenderer: ActionsRenderer,
                    }}
                    paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                    domLayout={"autoHeight"}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    rowGroupPanelShow={'never'}
                    pagination={true}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    onFirstDataRendered={onDataRendered}
                    columnDefs={updatedColumnDefs}
                    columnTypes={columnTypes}
                    rowData={rowData}
                    animateRows={true}
                    onCellClicked={cellClicked}
                    onPaginationChanged={onPaginationChange}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
        <ActionPanelLayout actionForAccount={actionForAccount} show={show} setShow={setShow} showExport={showExport} setShowExport={setShowExport} showRename={showRename} setShowRename={setShowRename} showDelete={showDelete} setShowDelete={setShowDelete} gridApi={gridApi}/>
    </>);

}