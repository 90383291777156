import moment from "moment";
import {COMMON_SESSION_ATTRIBUTE_BUSDATES} from "../pages/login/context/LoginContext";
import {QUERIES, REPORTS} from "../routes/Routes";

export const stringDateFormatter = (params) => {
    if (params.value && params.value.toString().trim().length === 8) {
        return stringToDate(params.value.toString());
    }
    return '';
}

export const stringNextDateFormatter = (params) => {
    if (params.value && params.value.toString().trim().length === 8) {
        return stringToDate(getNextDate(params.value.toString()));
    }
    return '';
}

export function pickerDateFormatToDate(params){
    if(params){
        return stringToDate(dateToString(params));
    }
    return ''
}

export function stringToDate(str){
    if(str != null){
        let year = str.slice(0,4);
        const month = str.slice(4,6);
        const day = str.slice(6,8);
        return [month, day, year].join('/')
    }
}

export function isoFormatToSimpleTimestamp(str){
    const myDate = new Date(str.value);
    if (myDate){
        return myDate.toLocaleString(undefined, {
            day:    'numeric',
            month:  'numeric',
            year:   'numeric',
            hour:   'numeric',
            minute: 'numeric',
        }).replace(',','');
    }
    return '';
}

export function dateToString(date){
    if (!date) {
        return date;
    }
    return date.toString().replace(/-/g,'');
}

export function yearMonthDayToPickerDateFormat(str){
    if(str != null){
        let year = str.slice(0,4);
        const month = str.slice(4,6);
        const day = str.slice(6,8);
        return [year, month, day].join('-')
    }
}

export function isDatePickerDateValid(datePickerDateString){

    if(datePickerDateString === undefined || datePickerDateString.length <= 0)
        return false;

    const date = moment(datePickerDateString, 'YYYY-MM-DD', true);
    return date.isValid();
}


export function formatDateString(dateString, newStringFormat){
    if(!dateString) return '';
    return moment(String(dateString)).format(newStringFormat);
}

export const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
}

export function getCurrentDate (date = new Date()){
    return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
    ].join('');
}

export const formatDateStringToTimeStampFormat = function(data){
    return data.value ? (formatDateString(data.value, "MM/DD/yyyy hh:mm:ss")) : '';
}

export const formatDateStringToDate = function(data){
    return data.value ? (formatDateString(data.value, "MM/DD/yyyy")) : '';
}

export function formatDateStringToDatePickerFormat(date = new Date()){
    return formatDateString(date, 'yyyy-MM-DD');
}

export function getPreviousDateToDatePickerFormat(date = new Date()){
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);
    return formatDateStringToDatePickerFormat(previous)
}

export function getCurrentDateToDatePickerFormat (date = new Date()){
    return formatDateStringToDatePickerFormat(date)
}

export function getMinPermissibleBusDate()
{
    const businessDates = JSON.parse(sessionStorage.getItem(COMMON_SESSION_ATTRIBUTE_BUSDATES));
    return !businessDates ? '' : businessDates["minPermissibleStartDate"];
}

export function getCurBusDate()
{
    const businessDates = JSON.parse(sessionStorage.getItem(COMMON_SESSION_ATTRIBUTE_BUSDATES));
    return !businessDates ? '' : businessDates["curBusDt"];
}

export function getPrevBusDate()
{
    const businessDates = JSON.parse(sessionStorage.getItem(COMMON_SESSION_ATTRIBUTE_BUSDATES));
    return !businessDates ? '' : businessDates["prevBusDt"];
}

export function getPreviousDate(date) {
   return getNthDateFrom(date, -1)
}

export function getNextDate(date) {
    return getNthDateFrom(date, 1)
}

export function getNthDateFrom(date, n){
    const year = parseInt(date.substring(0, 4));
    let month = parseInt(date.substring(4, 6)) -1;
    let day = parseInt(date.substring(6, 8));
    const d = new Date(year, month, day);
    d.setDate(d.getDate() + n);
    return d.getFullYear() +
        ('0' + (d.getMonth() + 1)).slice(-2) + ('0' +
            d.getDate()).slice(-2);
}
export function getPreviousDatePickerFormatToDatePickerFormat(date){
    return yearMonthDayToPickerDateFormat(getPreviousDate(dateToString(date)))
}

export function showAsterisk(pathname) {
    const routes = [QUERIES, REPORTS];
    return routes.includes(pathname);
}

export function assignDate(name, value, queryPanelFilterState){
    let startDate, endDate;
    if(name === 'startDate'){
        startDate = value;
        ({endDate} = queryPanelFilterState);
    } else{
        endDate = value;
        ({startDate} = queryPanelFilterState);
    }

    return {startDate, endDate}
}

export function getCurrentBusDateOrCurrentDate(){
    return  getCurBusDate() ===  '' ? getCurrentDateToDatePickerFormat() :
        formatDateString(String(getCurBusDate()), 'YYYY-MM-DD');
}
