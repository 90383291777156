import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import "./spinner.css";
import spinner from "assets/image/spinner.gif";


export const GridSpinner = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress && (
            <div className="spinner">
                <div className="spinner-div">
                    <img
                        src={spinner}
                        height="50"
                        className="d-inline-block"
                        alt="React Bootstrap logo"
                    />
                </div>
            </div>
        )
    );
};
