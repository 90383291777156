import {createContext, useState} from "react";
import {
    getCurrentBusDateOrCurrentDate,
    getCurrentDateToDatePickerFormat
} from "utils/FormatUtils";

export const TRADE_CAPTURE_PANEL_HOME_PAGE = "trade-home";
export const TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE = "trade-activities";
export const TRADE_CAPTURE_PANEL_ENTRY_RESULT = "trade-entry-result";

export const Context = createContext({});

export const Provider = props => {

    const {children} = props;

    const startEndDate = getCurrentBusDateOrCurrentDate();

    const initTradeFilterState = {
        status: 'ALL',
        startDate: startEndDate,
        endDate: startEndDate
    }

    const initTradeCaptureDisplayState = {
        tradeEntry:{
            tradeType:'B',
            quantity:0,
            security:'',
            securityCode:'',
            securityDescription:'',
            securityOption:"N",
            securityOptionList:[],
            securityOptionSelected:'',
            price:0,
            currency:'USD',
            currencies:[],
            brokerCode:'',
            brokerDescription:'',
            commissionType:'3',
            commission:0,
            isOverride:"false",
            tradeDate: getCurrentDateToDatePickerFormat(),
            settleDate:'',
            accruedInterest:0,
            comments:'',
            clientId:'',
            gridState:[],
            totalAllocation:0,
            resData:[],
            openCloseCode:'',
            fxUSDRate:'1.00',
            originalAccountId:'',
            managerId: '',
            accountId: '',
            accountType:'',
            openClose:'',
            indTradeCancel:'',
            tradeBridgeTradeId:'',
            version:'',
            dbSource:'',
            cdBlockIdClient: ''
        },
        tradeActivitiesSearch: {
            managerId: '',
            managerName: '',
            subManagerId: '',
            subManagerName: '',
            accountId: '',
            accountCode: '',
            accountName: '',
            status: '',
            startDate: '',
            endDate: '',
            resData: [],
            badgeInfo: ''
        },
        isSavedTradeList : false,
        savedName:'',
        savedNameListId: '',
        currentPage : TRADE_CAPTURE_PANEL_HOME_PAGE
    }

    const [showGridToolbar, setShowGridToolbar] = useState(true);
    const [tradePanelFilterState, setTradePanelFilterState] = useState(JSON.parse(JSON.stringify(initTradeFilterState)));
    const [tradeCaptureDisplayState, setTradeCaptureDisplayState] = useState(JSON.parse(JSON.stringify(initTradeCaptureDisplayState)));
    const [headerCheckboxChecked, setHeaderCheckedboxChecked] = useState(false);
    const [selectedAnyCheckbox, setSelectedAnyCheckbox] = useState(false);
    const [selectedRows, setSelectedRows] = useState([])

    const clearTradeSearchAndLoadTradingHome = () => {
        setTradeCaptureDisplayState(JSON.parse(JSON.stringify(initTradeCaptureDisplayState)));
        setTradePanelFilterState(JSON.parse(JSON.stringify(initTradeFilterState)));
    }

    const clearTradeEntry = () => {
        tradeCaptureDisplayState.tradeEntry = JSON.parse(JSON.stringify(initTradeCaptureDisplayState.tradeEntry));
    }

    const buildTradeActivitiesSearch = (managerId, managerName, subManagerId, subManagerName, accountId, accountCode, accountName, status, startDate, endDate, resData) => {
        let tradeActivitiesSearch = JSON.parse(JSON.stringify(initTradeCaptureDisplayState.tradeActivitiesSearch));
        tradeActivitiesSearch.managerId = managerId;
        tradeActivitiesSearch.managerName = managerName;
        tradeActivitiesSearch.subManagerId = subManagerId;
        tradeActivitiesSearch.subManagerName = subManagerName;
        tradeActivitiesSearch.accountId = accountId;
        tradeActivitiesSearch.accountCode = accountCode;
        tradeActivitiesSearch.accountName = accountName;
        tradeActivitiesSearch.status = status;
        tradeActivitiesSearch.startDate = startDate;
        tradeActivitiesSearch.endDate = endDate;
        tradeActivitiesSearch.resData = resData;
        tradeActivitiesSearch.badgeInfo = accountCode && accountName ? accountCode + '-'+ accountName : 'ALL'

        return tradeActivitiesSearch;
    }

    const mapTradeStatusCodeToDisplayValue = (statusCode) => {
        if (statusCode === null) {
            return "";
        }

        if (statusCode == 'PEND') {
            return "PEND";
        }

        if (statusCode == 'SEND') {
            return "SEND";
        }

        return statusCode;
    }

    const tradeCaptureContext = {
        tradePanelFilterState,
        setTradePanelFilterState,
        tradeCaptureDisplayState,
        setTradeCaptureDisplayState,
        showGridToolbar,
        setShowGridToolbar,
        clearTradeSearchAndLoadTradingHome,
        clearTradeEntry,
        buildTradeActivitiesSearch,
        mapTradeStatusCodeToDisplayValue,
        headerCheckboxChecked,
        setHeaderCheckedboxChecked,
        selectedRows,
        setSelectedRows,
        selectedAnyCheckbox,
        setSelectedAnyCheckbox
    }

    return <Context.Provider value={tradeCaptureContext}>{children}</Context.Provider>

};

export const {Consumer} = Context;
