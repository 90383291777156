import React, {createContext, useCallback, useRef, useState} from 'react';
import {Alert, Overlay, Toast} from "react-bootstrap";
import {render} from "react-dom";

const initialToast = {
    message: '',
    type: null,
    visible: false,
};

export const Context = createContext({})
export const Provider = props => {
    const {children} = props;

    const [toast, setToast] = useState(initialToast);
    const success = useCallback(message => {
        setToast({...initialToast, visible: true,variant:'success', background:'#F0F4F0', message});
    }, []);
    const alert = useCallback(message => {
        setToast({...initialToast, visible: true,variant:'info', background:'#FDF2DF', message});
    }, []);
    const error = useCallback(message => {
        setToast({...initialToast, visible: true,variant:'error',background:'#F7E8E9', message});
    }, []);
    const hide = useCallback(() => {
        setToast({...toast, visible: false});
    }, [toast]);

    const toastContext = {
        toast,
        success,
        alert,
        error,
        hide
    }
    return <Context.Provider value={toastContext}>
        {children}
    </Context.Provider>

}
export const {Consumer} = Context;
