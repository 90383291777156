import {Col, Modal} from "react-bootstrap";
import React from "react";
import useWindowDimensions from "../hooks/Dimensions";

export default function ActionModal(props) {
    const {title, body, show, secondTitle } = props;
    const {isDesktop, isTab, isMobile} = useWindowDimensions();

    return (<Modal
            className={"modelContent"}
            backdrop={props.backdrop}
            keyboard={props.keyboard}
            aria-labelledby="contained-modal-title-vcenter"
            size={props.size}
            centered={props.centered? props.centered:(isTab?"":false)}
            show={show}
            onHide={props.onHide}
            fullscreen={props.fullScreen}
            dialogClassName={props.dialogClassName}>
            <Modal.Header closeButton style={{borderBottom:isDesktop?0:'1px solid #616161', paddingLeft:isMobile?0:'' ,paddingRight:isMobile?0:''}}>
                <Col xs={1} style={{textAlign: 'left', padding:0}} className={"d-block d-md-none"}><span className={props.showBackButton?'d-block':'d-none'}><i className={'td-icon icon-regular td-icon-arrowLeft'} onClick={props.onGoBackClick}/></span> </Col>
                <Col  className={"mobileScreen"} style={{textAlign: isDesktop && !secondTitle?'left':'center', paddingTop:0, paddingBottom:0,paddingLeft:isDesktop?15:'', width:'404px'}}><h2><span style={{maxWidth: isTab?'24em':isMobile?'16.5em':''}} className={"rn"}>{title()}</span>{!!secondTitle && <h4><b>{secondTitle}</b></h4>}</h2></Col>
            </Modal.Header>
            <Modal.Body style={props.bodyStyle}>{body()}</Modal.Body>
        </Modal>);
}

ActionModal.defaultProps = {
    backdrop: "static", keyboard: false, dialogClassName: 'custom-dialog',centered:false, showBackButton:false, size:"", bodyStyle:{}, fullscreen:false
}