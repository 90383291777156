import {Col, Container, Form, Row} from "react-bootstrap";

import React, {useContext} from "react";
import {LoginContext} from "../login/context";


export default function ErrorComponent(){
    const {logo, loader,mfaLoginOption } = useContext(LoginContext);
    return(<Container className="mt-md-3 px-4">
        <Row>
            <Col xs={12}>{logo}</Col>
            <Col xs={12}>&nbsp;</Col>
            <Col xs={12} className={'py-0'}><h3><b>Welcome to Prime Brokerage</b></h3></Col>
            <Col xs={12}>&nbsp;</Col>
            <Col xs={12} className={'pt-0'}><h1>Client Login </h1></Col>
            <Col xs={12} className={'pt-0'}><p>Access for authorized users only</p></Col>
        </Row>
        <Row>
            <Col xs={12} className={'pt-0'}><h2 className={'black-font'}>We are experiencing technical difficulties at this time</h2></Col>
            <Col xs={12} className={'pt-0'}><p>Please call 1-212-827-2888 to speak with one of our TD Prime Services Sales Teams</p></Col>
        </Row>

    </Container>)
}