import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Container, Form} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import ActionsRenderer from "components/ActionsRenderer";
import ActionPanelLayout from "layouts/ActionPanelLayout";
import useWindowDimensions from 'hooks/Dimensions.js'
import PageHeader from "components/PageHeader";
import {ActionPanelContext} from "context/action";
import {SecurityContext} from "context/security";
import {UtilsContext} from "context/utils"
import {
    TRADES_LIST_URL,
    TRADES_UPDATE_LIST_NAME_URL,
    TRADES_DELETE_LIST_URL
} from "service/UrlConstant";
import {ToastContext} from "context/Toast";
import {dateToString, stringToDate} from "utils/FormatUtils";
import {TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE} from "./context/TradeCaptureContext";
import * as URL from "service/UrlConstant";
import {TradeCaptureContext} from "./context";
import AuthenticationService from "service/AuthenticationService";
import { columnDefsActivitiesPanel as tradeActColumnDefs} from "./TradeActivitiesPanel"
import {
    DEFAULT_GRID_PAGINATION_SIZE,
    ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE,
    GRID_COLUMN_PROPERTY
} from "utils/Constants";
import {createExcelExportParams, getFilteredColumns} from "../../utils/ExcelExportUtils";
import {addPropertiesToGridColumns, getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";

export default function TradeListPanel(props) {
    const {hiddenExportGridApi,paginationPageSize, showButton, setShowButton} = useContext(UtilsContext);
    const {success, alert, error} = useContext(ToastContext);
    const {
        makeGetRequest,
        makePostRequest,
        makeDeleteRequest,
        makePatchRequestWithParams
    } = useContext(SecurityContext);

    const {tradeCaptureDisplayState, setTradeCaptureDisplayState, buildTradeActivitiesSearch} = useContext(TradeCaptureContext);
    const {setHandleDeleteFunction, setHandleRenameFunction, setSavedSearchName, setHandleExportFunction} = useContext(ActionPanelContext);

    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [actionForAccount, setActionForAccount] = useState('');
    const tradeListsGrid = useRef(null);
    const [saveTradeColumnDefs, setSavedTradeColumDefs] = useState(tradeActColumnDefs)

    const myGrid = useRef(null);
    const {isDesktop, isMobile} = useWindowDimensions();

    const tradeListSearchDataValueGetter = function (params) {
        let tradeSearchData = {
            savedName: params.data.savedName,
            managerId: params.data.managerId,
            subManagerId: params.data.subManagerId,
            status: params.data.status,
            accountId: params.data.accountId,
            startDate: params.data.startDate,
            endDate: params.data.endDate
        }
        return tradeSearchData;
    }

    const columnTypes = {
        actionColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            pinned: isDesktop ? '' : 'right',
            width: isDesktop ? 250 : 100,
        },
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu: true,
            flex: 1,
            suppressMovable: true,
        }
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true,
        enableCellChangeFlash: true,
        lockPinned: isMobile ? true : false,
    };

    const updateTradeSearch = (savedName, gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];
        const tradeListId = selectedRow.tradeListId;

        const onSuccessResponse = (response) => {
            if (onSuccess) onSuccess();
            selectedRow.savedName = savedName;
            gridApi.applyTransaction({update: [selectedRow]});
            success('Trade activities search successfully saved');
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if(onError) onError(err);
        }

        makePatchRequestWithParams(
            TRADES_UPDATE_LIST_NAME_URL + "/" + tradeListId, {savedName: savedName}, onSuccessResponse, onErrorResponse);
    };

    const deleteTradeSearch = (gridApi, onSuccess, onError) => {

        const selectedRow = gridApi.getSelectedRows()[0];
        const tradeListId = selectedRow.tradeListId;

        const onSuccessResponse = (response) => {
            if (onSuccess) onSuccess();
            gridApi.applyTransaction({remove: [selectedRow]});
            success('Trade activities search successfully deleted');
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if (onError) onError(err);
            error('Error occurred with deleting trade list');
        }

        makeDeleteRequest(
            TRADES_DELETE_LIST_URL + "/" + tradeListId, onSuccessResponse, onErrorResponse);
    };



    const loadSavedTradesList = () => {
        const onSuccess = (response) => {
            if(!response.data || response.data.length === 0){
                console.log("No saved trade activities searches found")
            }
            else {
                setRowData([...response.data]);
            }
        }
        const onError = (err) => {
            console.error(err);
            error('Error loading saved trade list');
        }

        makeGetRequest(TRADES_LIST_URL, {
            cdUser: AuthenticationService.getAuthenticatedUserName(),
        }, onSuccess, onError);
    };

    const loadTradeActivitiesForSavedList = (savedTradeListData) => {
        const searchCriteria = {
            savedName: savedTradeListData.savedName,
            managerId: savedTradeListData.managerId,
            managerNameShort: savedTradeListData.managerNameShort,
            subManagerId: savedTradeListData.subManagerId,
            subManagerNameShort: savedTradeListData.subManagerNameShort,
            accountId: savedTradeListData.accountId,
            accountNameShort: savedTradeListData.accountNameShort,
            status: savedTradeListData.status,
            startDate: (savedTradeListData.startDate ? savedTradeListData.startDate.toString() : ""),
            endDate: (savedTradeListData.endDate ? savedTradeListData.endDate.toString() : "")
        }

        const onSuccessResponse = (response) => {

            if (!response.data || response.data.length === 0) {
                alert('No Trade activities found matching your saved trades list');
            } else {
                let tradeCaptureDisplayActivities = JSON.parse(JSON.stringify(tradeCaptureDisplayState));
                tradeCaptureDisplayActivities.tradeActivitiesSearch = buildTradeActivitiesSearch(
                    searchCriteria.managerId,
                    searchCriteria.managerNameShort,
                    searchCriteria.subManagerId,
                    searchCriteria.subManagerNameShort,
                    searchCriteria.accountId,
                    savedTradeListData.accountCode,
                    searchCriteria.accountNameShort,
                    searchCriteria.status,
                    searchCriteria.startDate,
                    searchCriteria.endDate,
                    response.data
                );

                tradeCaptureDisplayActivities.isSavedTradeList = true;
                tradeCaptureDisplayActivities.savedName = searchCriteria.savedName;
                tradeCaptureDisplayActivities.savedNameListId = savedTradeListData.tradeListId;
                tradeCaptureDisplayActivities.currentPage = TRADE_CAPTURE_PANEL_ACTIVITIES_PAGE;
                setTradeCaptureDisplayState(tradeCaptureDisplayActivities);
            }
        }

        const onErrorResponse = (err) => {
            console.error("Error", err);
            error("Error occurred with load trade activities for saved trades list");
        }

        makePostRequest(searchCriteria,
            URL.TRADES_SEARCH_URL, onSuccessResponse, onErrorResponse);
    };

    const exportTradeList = (type, rowData, gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];
        const searchParam = {
            savedName: selectedRow.savedName,
            managerId: selectedRow.managerId,
            managerNameShort: selectedRow.managerNameShort,
            subManagerId: selectedRow.subManagerId,
            subManagerNameShort: selectedRow.subManagerNameShort,
            accountId: selectedRow.accountId,
            accountNameShort: selectedRow.accountNameShort,
            status: selectedRow.status,
            startDate: selectedRow.startDate,
            endDate: selectedRow.endDate
        }

        const onSuccessResponse = (response) => {
            const tradesColumnsDef = tradeActColumnDefs.filter(cols => cols.field !== 'checkbox' && cols.field !== 'View')
            hiddenExportGridApi.current.api.setColumnDefs(tradesColumnsDef);
            //Set Data
            hiddenExportGridApi.current.api.setRowData(response.data)
            let filteredColumnNames = getFilteredColumns(tradeListsGrid);
            //Export as csv/excel
            type==='EXCEL' ?
                hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams(filteredColumnNames)) :
                    hiddenExportGridApi.current.api.exportDataAsCsv(filteredColumnNames)
        }

        const onErrorResponse = (err) => {
            const msg = "Error occurred when exporting the TradeList";
            console.error(msg + ": ", err);
            if(onError) onError(err);
            error(msg);
        }

        makePostRequest(searchParam, URL.TRADES_SEARCH_URL  , onSuccessResponse, onErrorResponse);
    };

    useEffect(() => {
        loadSavedTradesList();
    }, [])

    const handleActionIconClick = (field) => {
        setActionForAccount(field.savedName);
        setSavedSearchName(field.savedName);
        setShow(true);
    }

    const handleExportActionClicked = (field) => {
        setActionForAccount(field.savedName);
        setShow(true);
        setShowExport(true);
    }

    const handleRenameActionClicked = (searchData) => {
        setSavedSearchName(searchData.savedName);
        setShow(true);
        setShowRename(true);
    }

    const handleDeleteActionClicked = (field) => {
        setActionForAccount(field.savedName);
        setShow(true);
        setShowDelete(true);
    }

    function managerGetter(params) {
        return params.data.managerNameShort;
    }

    function accountGetter(params) {
        const accountCode = params.data.accountCode
        const accountNameShort = params.data.accountNameShort
        return accountCode && accountNameShort ? accountCode + ' - ' + accountNameShort : 'ALL';
    }

    const columnDefs = [
        {headerName: 'tradeListId', field: 'tradeListId',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'accountCode', field: 'accountCode',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'accountId', field: 'accountId',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'managerId', field: 'managerId',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'Name', field: 'savedName', cellRenderer: "tradeNameRenderer"},
        {headerName: 'Status', field: 'status'},
        {headerName: 'Entity', valueGetter: managerGetter},
        {headerName: 'Account', valueGetter: accountGetter},
        {headerName: 'Start Date', field: 'startDate', valueGetter: (params)=>stringToDate(dateToString(params.data.startDate))},
        {headerName: 'End Date', field: 'endDate', valueGetter: (params)=>stringToDate(dateToString(params.data.endDate))},
        {
            headerName: 'Actions',
            field: 'account',
            cellRenderer: "actionsRenderer",
            type: 'actionColType',
            valueGetter: tradeListSearchDataValueGetter,
            cellRendererParams: {
                clicked: (searchData) => handleActionIconClick(searchData),
                onAction1Clicked: (searchData) => handleExportActionClicked(searchData),
                onAction2Clicked: (searchData) => handleRenameActionClicked(searchData),
                onAction3Clicked: (searchData) => handleDeleteActionClicked(searchData)
            }
        },
        ,{headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true}
    ];

    const updatedColumnDefs = useMemo(() => addPropertiesToGridColumns(columnDefs, GRID_COLUMN_PROPERTY), []);

    const cellClicked = (params) => {
        params.node.setSelected(true)
        params.api.ensureIndexVisible(params.node.rowIndex, 'top')
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setHandleRenameFunction({handleRename: updateTradeSearch, gridApi: params.api});
        setHandleDeleteFunction({handleDelete: deleteTradeSearch, gridApi: params.api});
        setHandleExportFunction({handleExport:exportTradeList, gridApi: params.api});
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const searchNameRenderer = function (props) {
        return (<>
            <a className="td-link" href="#" onClick={() => {
                loadTradeActivitiesForSavedList(props.data);
            }}>{props.value}</a>
        </>);
    }
    const onDataRendered = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }

    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    return (<>
        <Container fluid>
            <PageHeader text="Trade Activities">
                <h3>Saved Searches</h3>
            </PageHeader>

            <div
                ref={myGrid}
                id="myGrid"
                className="ag-theme-alpine">
                <AgGridReact
                    ref={tradeListsGrid}
                    frameworkComponents={{
                        tradeNameRenderer: searchNameRenderer,
                        actionsRenderer: ActionsRenderer,
                    }}
                    paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                    domLayout={"autoHeight"}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={false}
                    pagination={true}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    onFirstDataRendered={onDataRendered}
                    columnDefs={updatedColumnDefs}
                    columnTypes={columnTypes}
                    rowData={rowData}
                    animateRows={true}
                    onCellClicked={cellClicked}
                    onPaginationChanged={onPaginationChange}
                >
                </AgGridReact>
            </div>

        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
        <ActionPanelLayout actionForAccount={actionForAccount} show={show} setShow={setShow}
                           showExport={showExport} setShowExport={setShowExport}
                           showRename={showRename} setShowRename={setShowRename}
                           showDelete={showDelete} setShowDelete={setShowDelete} gridApi={gridApi}
        />

    </>);
}
