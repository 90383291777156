import {Col, Row} from "react-bootstrap";
import ButtonMailto from "components/ButtonMailTo";
import React, {useContext, useEffect, useState} from "react";
import * as URL from "service/UrlConstant"
import {SecurityContext} from "context/security";
import {ToastContext} from "context/Toast";

const initClientRepInfo = {
    "name" : "",
    "title" : "",
    "phonePrimary" : "",
    "emailAddress1" :""
}

export default function ContactUsBody(){

    const {makeGetRequestNoParams} = useContext(SecurityContext)
    const {error} = useContext(ToastContext);
    const [clientRepInfo,setClientRepInfo] = useState(JSON.parse(JSON.stringify(initClientRepInfo)));



    const getClientReps = () => {

        const onSuccessResponse = (response) => {
            if(response.data && response.data.length > 0){
                const clientInfo = {};
                clientInfo['name'] = response.data[0]['name'];
                clientInfo['title'] = response.data[0]['title'];
                clientInfo['phonePrimary'] = response.data[0]['phonePrimary'];
                clientInfo['emailAddress1'] = response.data[0]['emailAddress1'];
                setClientRepInfo(clientInfo);
            }
        }

        const onErrorResponse = (err) => {
            const msg = 'Error on retrieving client rep Info';
            error(msg);
        }

        makeGetRequestNoParams(URL.HOME_USERS_CLIENTREP +"/", onSuccessResponse, onErrorResponse);

    }

    useEffect(() => {
         getClientReps();
    },[])


    return(<Row className='h4 px-4 px-sm-0'>
        <Col xs='12' className={'pb-0'}><h2 style={{color:'black'}}><b>{clientRepInfo['name']}</b></h2></Col>

        <Col xs='12' className={'pb-0'}><h4>{clientRepInfo['title']}</h4></Col>

        <Col xs='12' className={'pb-0'}>{clientRepInfo['phonePrimary']}</Col>

        <Col xs='12'><ButtonMailto mailto={clientRepInfo['emailAddress1']}/></Col>
    </Row>)
}

