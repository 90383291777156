import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Container} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import ActionsRenderer from "components/ActionsRenderer";
import useWindowDimensions from 'hooks/Dimensions.js'
import ActionPanelLayout from "layouts/ActionPanelLayout";
import PageHeader from "components/PageHeader";
import {
    REPORT_SAVED_REPORTS_URL
} from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {ActionPanelContext} from "context/action";
import {ReportsPanelContext} from "./context";
import * as URL from "service/UrlConstant";
import * as b64 from "base64-js";
import {gunzipSync} from "fflate";
import {REPORTS_PANEL_VIEW_REPORT_PAGE} from "./context/ReportsPanelContext";
import {ToastContext} from "context/Toast";
import {saveAs} from 'file-saver';
import {dateToString, stringToDate} from "utils/FormatUtils";
import {
    DEFAULT_GRID_PAGINATION_SIZE,
    ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE,
    GRID_COLUMN_PROPERTY
} from "utils/Constants";
import {getAllErrorValuesAsString} from "../../utils/ErrorUtils";
import {addPropertiesToGridColumns, getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";
import {UtilsContext} from "../../context/utils";

const action1 = "Download PDF";

export default function SavedReports() {
    const {paginationPageSize, showButton, setShowButton} = useContext(UtilsContext);

    const {success, alert, error} = useContext(ToastContext);
    const {
        reportsPdfDisplayState,
        setReportsPdfDisplayState,
        displayReportPanelPage,
        setSavedReportDate
    } = useContext(ReportsPanelContext);
    const {setHandleDeleteFunction, setHandleRenameFunction, setHandleDownloadFunction, setSavedSearchName} = useContext(ActionPanelContext);

    const {
        makeGetRequest,
        makeDeleteRequest,
        makePutRequest
    } = useContext(SecurityContext);

    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const [actionForAccount, setActionForAccount] = useState('');

    const myGrid = useRef(null);
    const {isDesktop, isMobile} = useWindowDimensions();

    const columnTypes = {
        actionColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            pinned: isDesktop ? '' : 'right',
            width: isDesktop ? 300 : 100,
        },
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu: true,
            flex: 1,
            suppressMovable: true,
        }
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true,
        enableCellChangeFlash: true,
        lockPinned: !!isMobile,
    };

    const updateReportSavedSearch = (savedName, gridApi, onSuccess, onError) => {
        const selectedRow = gridApi.getSelectedRows()[0];
        const savedReportId = selectedRow.savedReportId;

        const param = {
            savedName: savedName,
            savedReportId: selectedRow.savedReportId,
            clientCode: selectedRow.clientCode,
            managerId: selectedRow.managerId,
            subManagerId: selectedRow.subManagerId,
            accountId: selectedRow.accountId,
            effectiveDate: dateToString(selectedRow.effectiveDate),
            folder: selectedRow.folder,
            fileName: selectedRow.fileName
        }
        const onSuccessResponse = (response) => {
            if (onSuccess) onSuccess();
            selectedRow.savedName = savedName;
            gridApi.applyTransaction({update: [selectedRow]});
            success('Report search successfully saved');
        }

        const onErrorResponse = (err) => {
            if (onError) onError(err);
        }

        makePutRequest(param,
            REPORT_SAVED_REPORTS_URL + "/" + savedReportId,  onSuccessResponse, onErrorResponse);
    };

    const deleteReportSavedSearch = (gridApi, onSuccess, onError) => {

        const selectedRow = gridApi.getSelectedRows()[0];
        const savedReportId = gridApi.getSelectedRows()[0].savedReportId;

        const onSuccessResponse = (response) => {
            if (onSuccess) onSuccess();
            gridApi.applyTransaction({remove: [selectedRow]});
            success('Report saved search successfully deleted');
        }

        const onErrorResponse = (err) => {
            console.error(err);
            if (onError) onError(err);
            error('Error occurred with deleting trade list');
        }

        makeDeleteRequest(
            REPORT_SAVED_REPORTS_URL + "/" + savedReportId, onSuccessResponse, onErrorResponse);
    };

    const loadReportSavedSearches = () => {
        const onSuccess = (response) => {
            if (!response.data || response.data.length === 0) {
                console.log("No saved trade activities searches found")
            } else {
                setRowData([...response.data]);
            }
        }
        const onError = (err) => {
            console.error(err);
            error('Error occurred with loading report saved searches');
        }

        makeGetRequest(REPORT_SAVED_REPORTS_URL, {}, onSuccess, onError);
    };

    function managerGetter(params) {
        return params.data.managerNameShort;
    }

    function accountGetter(params) {
        return params.data.accountCode + "-" + params.data.accountNameLong;
    }

    const loadReportForSavedSearch = (savedReportListData) => {

        const reportsSearch = {
            accountId: savedReportListData.accountId,
            effectiveDate: savedReportListData.effectiveDate,
            folder: savedReportListData.folder,
            fileName: savedReportListData.fileName
        }

        const onSuccess = (response) => {

            if (!response.data || response.data.length === 0) {
                alert('No report found matching your saved search');
            } else {

                const reportsPdfDisplay =  JSON.parse(JSON.stringify(reportsPdfDisplayState));
                const based64Decoded = b64.toByteArray(response.data);
                const decompressed = gunzipSync(based64Decoded);
                reportsPdfDisplay.isReportSavedSearch = true;
                reportsPdfDisplay.reportSavedSearchName = savedReportListData.savedName;
                reportsPdfDisplay.reportSavedSearchId = savedReportListData.savedReportId;
                reportsPdfDisplay.reportPdfData = {data: decompressed}
                reportsPdfDisplay.reportPdfSearchData = {
                    managerId: savedReportListData.managerId,
                    managerName: savedReportListData.managerNameShort,
                    subManagerId: savedReportListData.subManagerId,
                    accountId: savedReportListData.accountId,
                    accountName: savedReportListData.accountNameShort,
                    clientCode: savedReportListData.clientCode,
                    effectiveDate: savedReportListData.effectiveDate,
                    fileName: savedReportListData.fileName,
                    folder: savedReportListData.folder
                };
                setReportsPdfDisplayState(reportsPdfDisplay);
                setSavedReportDate(savedReportListData.effectiveDate)
                displayReportPanelPage(REPORTS_PANEL_VIEW_REPORT_PAGE);
            }
        }

        const onError = (err) => {
            console.error('Error on loading report saved search ', err);
            error("Error occurred with loading report");
        }

        makeGetRequest(URL.REPORT_FOLDERS_FILES_FILE_URL, reportsSearch, onSuccess, onError);
    }

    useEffect(() => {
        setSavedReportDate(null)
        loadReportSavedSearches();
    }, []);

    const reportSearchDataValueGetter = function (params) {
        let reportSearchData = {
            savedReportId: params.data.savedReportId,
            savedName: params.data.savedName,
            accountId: params.data.accountId,
            managerId: params.data.managerId,
            subManagerId: params.data.subManagerId,
            clientCode: params.data.clientCode,
            effectiveDate: params.data.effectiveDate,
            folder: params.data.folder,
            fileName: params.data.fileName
        }
        return reportSearchData;
    }

    const columnDefs = [
        {headerName: 'savedReportId', field: 'savedReportId',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'clientCode', field: 'clientCode',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'idAccount', field: 'accountId',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'idManager', field: 'managerId',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'idSubManager', field: 'subManagerId',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'date', field: 'effectiveDate',hide: true, suppressColumnsToolPanel: true},
        {headerName: 'Name', field: 'savedName', cellRenderer: "tradeNameRenderer"},
        {headerName: 'Entity', valueGetter: managerGetter},
        {headerName: 'Account', valueGetter: accountGetter},
        {
            headerName: 'Close of Business Date',
            field: 'effectiveDate',
            valueGetter: (params)=>stringToDate(dateToString(params.data.effectiveDate)),
        },
        {headerName: 'Folder', field: 'folder'},
        {headerName: 'File', field: 'fileName',},
        {
            headerName: 'Actions',
            cellRenderer: "actionsRenderer",
            type: 'actionColType',
            valueGetter: reportSearchDataValueGetter,
            cellRendererParams: {
                clicked: (searchData) => handleActionIconClick(searchData),
                onAction1Clicked: (searchData) => handleDownloadActionClicked(searchData),
                action1: action1,
                onAction2Clicked: (searchData) => handleRenameActionClicked(searchData.accountId, searchData.savedName),
                onAction3Clicked: (searchData) => handleDeleteActionClicked(searchData.accountId)
            },

        },
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];

    const updatedColumnDefs = useMemo(() => addPropertiesToGridColumns(columnDefs, GRID_COLUMN_PROPERTY), [])

    const handleActionIconClick = (field) => {
        setActionForAccount(field.savedName);
        setSavedSearchName(field.savedName);
        setShow(true);
    }
    const handleDownloadActionClicked = (reportSearchData) => {

        const downloadReportParams = {
            accountId: reportSearchData.accountId,
            effectiveDate: dateToString(reportSearchData.effectiveDate.toString()),
            folder: reportSearchData.folder,
            fileName: reportSearchData.fileName
        }

        const onSuccess = (response) => {

            if (response.data) {
                const based64Decoded = b64.toByteArray(response.data);
                const decompressed = gunzipSync(based64Decoded);
                let blob = new Blob([decompressed], {type: "application/octet-stream"});
                saveAs(blob, downloadReportParams.fileName);
            } else {
                error("There was an error with loading selected report");
            }
        }

        const onError = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error('Error when retrieving report data - ', errorMsg);
            error("There was an error with loading selected report");
        }

        makeGetRequest(URL.REPORT_FOLDERS_FILES_FILE_URL, downloadReportParams, onSuccess, onError);

    }

    //Rename
    const handleRenameActionClicked = (accountId, savedName) => {
        setSavedSearchName(savedName);
        setActionForAccount(accountId);
        setShow(true);
        setShowRename(true);
    }

    //Delete
    const handleDeleteActionClicked = (field) => {
        setActionForAccount(field);
        setShow(true);
        setShowDelete(true);
    }

    const cellClicked = (params) => {
        params.node.setSelected(true)
        params.api.ensureIndexVisible(params.node.rowIndex, 'top')
    }

    const onGridReady = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE)
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setHandleDownloadFunction({handleDownload: handleDownloadActionClicked, gridApi: params.api});
        setHandleRenameFunction({handleRename: updateReportSavedSearch, gridApi: params.api});
        setHandleDeleteFunction({handleDelete: deleteReportSavedSearch, gridApi: params.api});
    }

    const searchNameRenderer = function (props) {
        return (
            <>
                <a className="td-link" href="#" onClick={() => {
                    loadReportForSavedSearch(props.data)
                }}>{props.value}</a>
            </>
        );
    }
    const onDataRendered = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE)
    }

    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE)
    }
    return (<>

        <Container fluid>
            <PageHeader text="Reports">
                <h3>Saved Searches</h3>
            </PageHeader>

            <div
                ref={myGrid}
                id="myGrid"
                className="ag-theme-alpine">
                <AgGridReact
                    frameworkComponents={{
                        tradeNameRenderer: searchNameRenderer,
                        actionsRenderer: ActionsRenderer,
                    }}
                    paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                    domLayout={'autoHeight'}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={false}
                    pagination={true}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    onFirstDataRendered={onDataRendered}
                    columnDefs={updatedColumnDefs}
                    columnTypes={columnTypes}
                    rowData={rowData}
                    animateRows={true}
                    onCellClicked={cellClicked}
                    onPaginationChanged={onPaginationChange}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
        <ActionPanelLayout actionForAccount={actionForAccount} showReportActionModal={true}
                           show={show} setShow={setShow} action1={action1}
                           showRename={showRename} setShowRename={setShowRename}
                           showDelete={showDelete} setShowDelete={setShowDelete} gridApi={gridApi}/>
    </>);

}