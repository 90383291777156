import React, {useContext, useState} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import useWindowDimensions from "hooks/Dimensions";
import * as URL from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {useHistory} from "react-router-dom";
import * as Routes from "routes/Routes";
import {ToastContext} from "context/Toast";
import {PASSWORD_RULES, PASSWORD_FORMAT_REGEX, PASSWORD_RULE_MSG} from "./ProfileConstants";
import ResponseToast from "components/ResponseToast";
import AuthenticationService from "service/AuthenticationService";
import {ThreeDots} from "react-loader-spinner";

const resetDefaultDetails = {password: '', newPassword:'', confirmNewPassword:''};

export default function ChangePasswordPanel(props){
    const {isLogin, username, setShowInvalidLogin, setAuthenticationFailedMessage} = props
    const {isDesktop} = useWindowDimensions();
    let history = useHistory();
    const [resetDetails, setResetDetails] = useState(resetDefaultDetails);
    const [formErrors, setFormErrors] = useState({reset: {}});
    const {makePostRequest} = useContext(SecurityContext);
    const {error} = useContext(ToastContext);
    const {setNeedLogin} = useContext(SecurityContext);
    const [loader, setLoader] = useState(false)
    function logout() {
        localStorage.clear();
        history.push(Routes.HOME);
        setNeedLogin(true);
        AuthenticationService.logout();
        window.location.reload()
    }

    const handleDataChange = (e) => {
        setResetDetails({...resetDetails, [e.currentTarget.name]:e.currentTarget.value});
    }

    const handleReset = (e) => {
        e.preventDefault();

        const errors = validateReset()
        const newFormErrors = {...formErrors}
        newFormErrors['reset'] = errors;
        setFormErrors(newFormErrors)
        if(Object.keys(errors).length>0){
            return
        }

        const params = {
            password: resetDetails.password,
            newPassword: resetDetails.newPassword,
            confirmNewPassword: resetDetails.confirmNewPassword
        }

        if(isLogin){

            setLoader(true);

            AuthenticationService.resetExpiredPassword(username, resetDetails.password, resetDetails.newPassword, resetDetails.confirmNewPassword)
                .then((response) => {
                    setLoader(false);
                    logout()
                }).catch((error) => {
                setLoader(false);
                let msg = generateResetFailedMessage(error);
                setShowInvalidLogin(true);
                setAuthenticationFailedMessage(msg);
            })
        }
        else{
            const onSuccess = (res) => {
                navigateToPage(Routes.CHANGE_PASSWORD_SUCCESS);
            }
            const onError = (err) => {
                console.error('Error when resetting password - ', err);
                let msg = generateResetFailedMessage(err);
                error(msg);
            }
            makePostRequest(params, URL.RESET_URL, onSuccess, onError);
        }


    };

    function generateResetFailedMessage(error) {
        let defaultMsg = 'There was an unexpected error during password reset. Please contact your Administrator.';
        if (error.response && error.response.data) {
            if (error.response.status == '400') {
                return error.response.data;
            }
        }

        return defaultMsg;
    }


    const validateReset = () => {
        let errors = {}

        if(!resetDetails.password){
            errors['password'] = 'Please enter current password'
        }

        if(!resetDetails.newPassword){
            errors['newPassword'] = 'Please enter new password'
        }

        if(!resetDetails.confirmNewPassword){
            errors['confirmNewPassword'] = 'Please enter confirm password'
        }

        if (errors.password || errors.newPassword || errors.confirmNewPassword) {
            return errors;
        }

        if (!(resetDetails.newPassword === resetDetails.confirmNewPassword)) {
            errors['confirmNewPassword'] = 'Your new password values do not match. Please re-enter your new password';
            return errors;
        }

        if (!resetDetails.newPassword.match(PASSWORD_FORMAT_REGEX)) {
            errors['newPassword'] = PASSWORD_RULE_MSG;
            return errors;
        }

        if (!resetDetails.confirmNewPassword.match(PASSWORD_FORMAT_REGEX)) {
            errors['confirmNewPassword'] = PASSWORD_RULE_MSG;
        }

        return errors;
    }

    function navigateToPage(path) {
        history.push(path);
    }

    return(<>
        <Container fluid style={{width: isDesktop && !isLogin?"60%":"", paddingLeft:isDesktop && !isLogin?100:""}}>
            {!isLogin && <Row className="pt-md-5 pt-4 pb-4">
                <Col>
                    <h1>Change Password</h1>
                </Col>
            </Row>
            }
            <Form>
                <Row>
                    <Col sm={12} md={isLogin?12:6}>
                        <Form.Group>
                            <Form.Label className="filter-input-label">Current Password</Form.Label>
                            <Form.Control type="password" name={'password'} value={resetDetails.password}
                                          isInvalid={!!formErrors.reset.password} onChange={handleDataChange}/>
                            <Form.Control.Feedback type={'invalid'}>
                                <b><i className="bi bi-exclamation-circle"></i>{formErrors.reset.password}</b>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="filter-input-label">Password</Form.Label>
                            <Form.Control type="password" name={'newPassword'} value={resetDetails.newPassword}
                                          isInvalid={!!formErrors.reset.newPassword} onChange={handleDataChange}/>
                            <Form.Control.Feedback type={'invalid'}>
                                <b><i className="bi bi-exclamation-circle"></i>{formErrors.reset.newPassword}</b>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="filter-input-label">Confirm Password</Form.Label>
                            <Form.Control type="password" name={'confirmNewPassword'} value={resetDetails.confirmNewPassword}
                                          isInvalid={!!formErrors.reset.confirmNewPassword} onChange={handleDataChange}/>
                            <Form.Control.Feedback type={'invalid'}>
                                <b><i className="bi bi-exclamation-circle"></i>{formErrors.reset.confirmNewPassword}</b>
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={isLogin?12:6} className="pt-md-3 pt-4">
                        <p style={{paddingLeft:5, marginBottom:0}}>Password Rules</p>
                        <ol style={{paddingLeft:20}}>
                            {PASSWORD_RULES.map((data) => <li key={data.id}>{data.value}</li>)}
                        </ol>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={isLogin?12:6}>
                        <button className="btn btn-block td-btn-primary-light my-md-3 my-4" type='submit' onClick={handleReset} >
                            <div style={{display: 'flex', justifyContent: 'center'}}>  { loader ?
                                <ThreeDots color="white" height={20} width={40}/> : 'Submit'}</div>
                        </button>
                        {isLogin && <a href="#" style={{padding:0,margin:0}} onClick={()=> {logout()}}>Return to login page.</a>}
                    </Col>
                </Row>
            </Form>
        </Container>
        {!isLogin && <ResponseToast isFullWidth={true}/>}
    </>)
}